import { Moment } from 'moment';
import { IOrders } from 'app/shared/model/orders.model';
import { IContractExpense } from 'app/shared/model/contract-expense.model';
import { IContractFacilities } from 'app/shared/model/contract-facilites.model';
import { ILiveInfo } from 'app/shared/model/live-info.model';
import { IUser } from 'app/shared/model/user.model';
import { IHouseRoom } from 'app/shared/model/house-room.model';
import { IFilter, IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { IAttachment } from 'app/shared/model/attachment.model';

export const enum ContractState {
  WAITING = 'WAITING',
  RENT_OUT = 'RENT_OUT',
  END = 'END',
  NULLIFIED = 'NULLIFIED',
  PREREFUNDED = 'PREREFUNDED' // 已提前退租
}

export const enum ParkingContractState {
  RENT_OUT = 'RENT_OUT',
  END = 'END',
  NULLIFIED = 'NULLIFIED',
  PREREFUNDED = 'PREREFUNDED' // 已提前退租
}

export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING'
}

export const enum CalcExpense {
  FIXED = 'FIXED',
  NATRUE = 'NATRUE'
}

export const enum ContractCycle {
  REAL = 'REAL',
  NATURE = 'NATURE'
}

export const enum ContractPushStatus {
  UNPUSH = 'UNPUSH',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS'
}

export interface Itcontract {
  id?: number;
  code?: string;
}

export interface IContract {
  tcontract?: Itcontract;
  id?: number;
  code?: string;
  paymentCycle?: number;
  cycle?: ContractCycle;
  signSettingId?: number;
  deposit?: number;
  hasDeposit?: boolean;
  depositDif?: number;
  hasDepositDif?: boolean;
  singleMonthPrice?: number;
  state?: ContractState;
  belatedInterest?: number;
  belatedCycle?: number;
  startAt?: Moment;
  endAt?: Moment;
  auditState?: AuditState;
  auditAt?: Moment;
  auditor?: IUser;
  signAt?: Moment;
  auditDescription?: string;
  ammeter?: number;
  waterMeter?: number;
  gasMeter?: number;
  name?: string;
  idCard?: string;
  idCardFront?: string;
  idCardBackend?: string;
  idCardWithHandle?: string;
  signImage?: string;
  lessorIdCard?: string;
  lessorIdCardFront?: string;
  lessorIdCardBackend?: string;
  lessorIdCardWithHandle?: string;
  lessorSignImage?: string;
  emergencyContact?: string;
  emergencyMobile?: string;
  extraDescription?: string;
  firstBillDay?: number;
  billDay?: number;
  calcExpense?: CalcExpense;
  contractImage?: string;
  safetyCommitment?: string;
  supplementaryAgreement?: string;
  createdAt?: Moment;
  createdBy?: string;
  updatedBy?: string;
  updatedAt?: Moment;
  userId?: number;
  user?: IUser;
  manager?: IUser;
  houseId?: number;
  houseRoomId?: number;
  houseRoom?: IHouseRoom;
  auditorId?: number;
  orders?: IOrders[];
  contractExpenses?: IContractExpense[];
  contractFacilities?: IContractFacilities[];
  liveInfos?: ILiveInfo[];
  rentRangeAt?: [Moment, Moment];
  propertyFee?: number;
  hasPropertyFee?: boolean;
  noHouseCert?: string;
  socialInsurance?: string;
  academicCert?: string;
  academicCert2?: string;
  academicCert3?: string;
  academicCerts?: IAttachment[];
  contractPushStatus?: ContractPushStatus;
  wqdjh?: string;
  badjh?: string;
  parmName?: string;
  parmValue?: string;
  remark?: string;
  showDownloadIcon?: boolean;
  houseRentalAgreement?: string;
  managementSpecification?: string;
  source?: string;
}

export const defaultValue: Readonly<IContract> = {
  hasDepositDif: false,
  contractExpenses: [],
  signSettingId: 1,
  contractFacilities: [],
  user: undefined,
  houseRoom: undefined,
  paymentCycle: 1,
  cycle: ContractCycle.NATURE,
  firstBillDay: 2,
  billDay: 15,
  belatedCycle: 1,
  belatedInterest: 0.1,
  contractPushStatus: ContractPushStatus.UNPUSH,
  singleMonthPrice: 0,
  depositDif: 0,
  deposit: 0,
  hasDeposit: true,
  hasPropertyFee: true,
  code: ''
};

export interface ISearchForm {
  state?: IFilter;
  auditState?: IFilter;
  houseRoomId?: IRangeFilter;
  userId?: IRangeFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
  customerQuery?: IStringFilter;
  code?: IStringFilter;
  houseId?: IRangeFilter;
  startAt?: IRangeFilter;
  endAt?: IRangeFilter;
  source?: IFilter;
}

export const defaultSearchForm: ISearchForm = {
  source: {
    equals: ''
  },
  state: {
    in: []
  },
  auditState: {
    in: []
  },
  code: {
    contains: ''
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  startAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  endAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  houseId: {
    in: []
  },
  houseRoomId: {
    in: []
  },
  userId: {
    in: []
  }
};

import { Moment } from 'moment';
import { IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';

export interface IVisit {
  id?: number;
  username?: string;
  phone?: string;
  content?: string;
  channel?: string;
  houseChannel?: string;
  budget?: string;
  personalNumber?: number;
  roomNumber?: number;
  hallNumber?: number;
  area?: number;
  regional?: string;
  visitedAt?: Moment;
  createdAt?: Moment;
  createdBy?: string;
  updatedBy?: string;
  updatedAt?: Moment;
}

export const defaultValue: Readonly<IVisit> = {};

export interface ISearchForm {
  username?: IStringFilter;
  phone?: IStringFilter;
  budget?: IRangeFilter;
  visitedAt?: IRangeFilter;
  roomNumber?: IRangeFilter;
  hallNumber?: IRangeFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
}

export const defaultSearchForm: ISearchForm = {
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  phone: { contains: '' },
  username: { contains: '' },
  budget: { equals: '' },
  visitedAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  roomNumber: {
    equals: ''
  },
  hallNumber: {
    equals: ''
  }
};

import { Moment } from 'moment';
import { IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';

export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING'
}

export interface IChangeHouse {
  id?: number;
  auitState?: AuditState;
  auditAt?: Moment;
  auditDescription?: string;
  createdAt?: Moment;
  createdBy?: string;
  updatedBy?: string;
  updatedAt?: Moment;
  houseId?: number;
  content?: string;
  houseRoomId?: number;
  contractId?: number;
  userId?: number;
  auditorId?: number;
  auditorName?: string;
}

export const defaultValue: Readonly<IChangeHouse> = {};

export interface ISearchForm {
  houseRoomId?: IRangeFilter;
  userId?: IRangeFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
  houseId?: IRangeFilter;
}

export const defaultSearchForm: ISearchForm = {
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  houseId: {
    in: []
  },
  houseRoomId: {
    in: []
  },
  userId: {
    in: []
  }
};

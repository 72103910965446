import { IRefundRental } from 'app/shared/model/refund-rental.model';

export interface IDeductionItem {
  id?: number;
  name?: string;
  amount?: number;
  refundRental?: IRefundRental;
}

export const defaultValue: Readonly<IDeductionItem> = {};

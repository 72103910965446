import { Moment } from 'moment';
import { IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';

export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING'
}

export interface ITemporary {
  pcontracts?: any[];
  carNumber?: string; // 车牌号
  houseName?: string; // 房源
  userName?: string; // 租户
  licensePlateNumber?: string;
  itemId?: string; // 收费记录id
  parkName?: string; // 停车场名称
  parkCode?: string; // 停车场编号
  feesTime?: Moment; // 收费时间，格式yyyy-MM-dd HH:mm:ss
  ysMoney?: number; // 应收金额(元)
  yhMoney?: number; // 优惠金额(元)
  ssMoney?: number; // 实收金额(元)
  inBlackList?: boolean; // 是否在黑名单
  inTime?: Moment; // 入场时间。异常情况时，无值 格式yyyy-MM-dd HH:mm:ss
  netDiscountMoney?: number; // 全网优惠券金额(元)
  hgMoney?: number; // 回滚减免金额(元)
  operatorId?: string; // 收费操作员ID。异常情况时，无值
  operatorName?: string; // 收费操作员名称。异常情况时，无值
  equipmentId?: string; // 收费设备id
  parkInId?: number; // 车辆入场记录id
  payType?: string; // 付款方式
  payFrom?: string; // 支付来源
  appUserId?: number; // APP用户ID,代扣时必须提供
  userTypeId?: number; // 套餐ID
  userTypeName?: string; // 套餐名称
  cardId?: number; // 卡ID
}

export const defaultValue: Readonly<ITemporary> = {};

export interface ISearchForm {
  parkName?: IStringFilter; // 停车场名字
  carNumber?: IStringFilter; // 车牌号
  feesTime?: IRangeFilter; // 收费时间
  page?: number;
  size?: number;
  userId?: IRangeFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
}

export const defaultSearchForm: ISearchForm = {
  feesTime: {
    lessThan: '',
    greaterThan: ''
  },
  carNumber: { contains: '' },
  parkName: { contains: '' }
};

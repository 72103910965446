import { INIT_TODO_TIME, INIT_TODO_TIME_INTERVAL_SECONDS } from 'app/config/constants';
import axios from 'axios';
import { Storage } from 'react-jhipster';
import { notification as Notification } from 'antd';
import { getCurrentTime } from '../util/date-utils';

export const getToDo = async () => {
  axios.get('api/statistics/todo-count', {}).then(res => {
    const current = Number(getCurrentTime());
    const before = Number(Storage.local.get(INIT_TODO_TIME));
    const todo = res.data;

    if (!before || current - before > INIT_TODO_TIME_INTERVAL_SECONDS) {
      Storage.local.set(INIT_TODO_TIME, getCurrentTime());
      // 待处理的合同数量
      if (todo.todoContractCount) {
        Notification.info({
          message: `待办事项`,
          description: `待审核的房源集中式合同： ${todo.todoContractCount}份`,
          placement: 'bottomRight',
          duration: INIT_TODO_TIME_INTERVAL_SECONDS,
          onClick: () => {
            window.location.href = '/rental/contract/contract?page=1&sort=createdAt,desc&state.in[0]=WAITING&auditState.in[0]=WAITING';
          }
        });
      }

      // 待处理的车位合同数量
      if (todo.todoPcontractCount) {
        Notification.info({
          message: `待办事项`,
          description: `待审核的车位合同： ${todo.todoPcontractCount}份`,
          placement: 'bottomRight',
          duration: INIT_TODO_TIME_INTERVAL_SECONDS,
          onClick: () => {
            window.location.href = '/parking/parking-contract?page=1&sort=createdAt,desc&state.in[0]=WAITING&auditState.in[0]=WAITING';
          }
        });
      }

      // 待处理的账单数量
      if (todo.todoOrdersCount) {
        Notification.info({
          message: `待办事项`,
          description: `待确认房源的账单: ${todo.todoOrdersCount}份`,
          placement: 'bottomRight',
          duration: INIT_TODO_TIME_INTERVAL_SECONDS,
          onClick: () => {
            window.location.href = '/financial/orders?page=1&sort=createdAt,desc&state.equals=WAITING_CONFIRM';
          }
        });
      }

      // 待处理的车位账单数量
      if (todo.todoPorderCount) {
        Notification.info({
          message: `待办事项`,
          description: `待确认车位的账单: ${todo.todoPorderCount}份`,
          placement: 'bottomRight',
          duration: INIT_TODO_TIME_INTERVAL_SECONDS,
          onClick: () => {
            window.location.href = '/parking/parking-financial?page=1&sort=createdAt,desc&state.equals=WAITING_CONFIRM';
          }
        });
      }
      // 待处理的团租合同数量
      if (todo.todoTcontractCount) {
        Notification.info({
          message: `待办事项`,
          description: `待审核的房源团租式合同： ${todo.todoTcontractCount}份`,
          placement: 'bottomRight',
          duration: INIT_TODO_TIME_INTERVAL_SECONDS,
          onClick: () => {
            window.location.href =
              '/rental/contract/t-contract?page=1&sort=createdAt,desc&state.in[0]=WAITING&auditState.in[0]=WAITING&buildingType.equals=GROUPED';
          }
        });
      }
    }
  });
};

import axios from 'axios';
import { ICrudPutAction } from 'react-jhipster';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { flattenKey } from 'app/shared/util/object-utils';
import * as qs from 'qs';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { objectToParameters } from 'app/shared/util/url-utils';

export const ACTION_TYPES = {
  GET_RENT: 'home/GET_RENT', // 当前租金
  GET_HOUSE_RENT_RATE: 'home/GET_HOUSE_RENT_RATE', // 房产出租率
  GET_PARKING_RENT_RATE: 'home/GET_PARKING_RENT_RATE', // 车位出租率
  GET_MONTH_RENT_RATE: 'home/GET_MONTH_RENT_RATE', // 月出租率
  GET_CUSTOMER_SERVICE: 'home/GET_CUSTOMER_SERVICE', // 客户满意度
  GET_HEALTH: 'home/GET_HEALTH', // 康明
  GET_ASSET: 'home/GET_ASSET', // 资产类型
  GET_SOURCE: 'home/GET_SOURCE', // 客户来源
  GET_STATISTICS: 'home/GET_STATISTICS' // 招商统计
};

const initialState = {
  loading: false,
  rentloading: false,
  rentRentloading: false,
  monthRentRateloading: false,
  customerServiceloading: false,
  assetloading: false,
  sourceloading: false,
  statisticsloading: false,
  rent: null,
  rentRate: null,
  monthRentRate: null,
  customerService: null,
  health: null, // 康明
  healthLoading: false, // 康明loading
  asset: null,
  source: null,
  statistics: null,
  errorMessage: null
};

export type HomeState = Readonly<typeof initialState>;

// Reducer
export default (state: HomeState = initialState, action): HomeState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_RENT):
      return {
        ...state,
        errorMessage: null,
        rentloading: true
      };
    case REQUEST(ACTION_TYPES.GET_ASSET):
      return {
        ...state,
        errorMessage: null,
        assetloading: true
      };
    case REQUEST(ACTION_TYPES.GET_MONTH_RENT_RATE):
      return {
        ...state,
        errorMessage: null,
        monthRentRateloading: true
      };
    case REQUEST(ACTION_TYPES.GET_CUSTOMER_SERVICE):
      return {
        ...state,
        errorMessage: null,
        customerServiceloading: true
      };
    case REQUEST(ACTION_TYPES.GET_HEALTH):
      return {
        ...state,
        errorMessage: null,
        healthLoading: true
      };
    case REQUEST(ACTION_TYPES.GET_PARKING_RENT_RATE):
    case REQUEST(ACTION_TYPES.GET_HOUSE_RENT_RATE):
      return {
        ...state,
        errorMessage: null,
        rentRentloading: true
      };
    case REQUEST(ACTION_TYPES.GET_SOURCE):
      return {
        ...state,
        errorMessage: null,
        sourceloading: true
      };

    case FAILURE(ACTION_TYPES.GET_RENT):
      return {
        ...state,
        assetloading: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.GET_ASSET):
      return {
        ...state,
        assetloading: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.GET_MONTH_RENT_RATE):
      return {
        ...state,
        monthRentRateloading: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.GET_CUSTOMER_SERVICE):
      return {
        ...state,
        customerServiceloading: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.GET_HEALTH):
      return {
        ...state,
        healthLoading: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.GET_PARKING_RENT_RATE):
    case FAILURE(ACTION_TYPES.GET_HOUSE_RENT_RATE):
      return {
        ...state,
        rentRentloading: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.GET_SOURCE):
      return {
        ...state,
        sourceloading: false,
        errorMessage: action.payload
      };
    // 当前租金
    case SUCCESS(ACTION_TYPES.GET_RENT):
      return {
        ...state,
        rentloading: false,
        rent: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.GET_MONTH_RENT_RATE):
      const monthData =
        action.payload.data &&
        action.payload.data.length > 0 &&
        action.payload.data.map(item => ({
          x: item.name,
          y: item.total,
          a: item.rate
        }));
      return {
        ...state,
        monthRentRateloading: false,
        monthRentRate: monthData
      };
    case SUCCESS(ACTION_TYPES.GET_ASSET):
      return {
        ...state,
        assetloading: false,
        asset: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.GET_CUSTOMER_SERVICE):
      const { data } = action.payload;
      const rdata = [];
      for (const [key, value] of Object.entries(data)) {
        key !== 'total' && rdata.push({ item: key, count: value });
      }

      return {
        ...state,
        customerServiceloading: false,
        customerService: rdata
      };
    case SUCCESS(ACTION_TYPES.GET_HEALTH):
      return {
        ...state,
        healthLoading: false,
        health: action.payload.data ? action.payload.data.reverse() : null
      };
    case SUCCESS(ACTION_TYPES.GET_SOURCE):
      const rdataSource =
        action.payload.data &&
        action.payload.data.length > 0 &&
        action.payload.data.map(item => ({
          item: item.source,
          a: item.count
        }));
      return {
        ...state,
        sourceloading: false,
        source: rdataSource
      };
    case SUCCESS(ACTION_TYPES.GET_PARKING_RENT_RATE):
    case SUCCESS(ACTION_TYPES.GET_HOUSE_RENT_RATE):
      return {
        ...state,
        rentRentloading: false,
        rentRate: action.payload
      };
    default:
      return state;
  }
};

const apiUrl = 'api/statistics';

// Actions
// 租金
export const getRent: ICrudPutAction<any> = searchForm => async dispatch =>
  dispatch({
    type: ACTION_TYPES.GET_RENT,
    payload: axios.get(`api/statistics/rental-overview`, {
      // params: flattenKey({ houseId: { in: houseId }, type }),
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params)
    })
  });

// 资产统计
export const getASSET = houseId => ({
  type: ACTION_TYPES.GET_ASSET,
  payload: axios.get(`api/statistics/asset-type`, {
    params: { houseId }
  })
});

// 房产出租率
export const getHouseRentRate = houseId => ({
  type: ACTION_TYPES.GET_HOUSE_RENT_RATE,
  payload: axios.get(`api/statistics/house-rental-ratio`, {
    params: { houseId }
  })
});

// 车位出租率
export const getParkingRentRate = houseId => ({
  type: ACTION_TYPES.GET_PARKING_RENT_RATE,
  payload: axios.get(`api/statistics/parking-lot-rental-ratio`, {
    params: { houseId }
  })
});

// 月度出租率
export const getMonthRentRate = searchForm => ({
  type: ACTION_TYPES.GET_MONTH_RENT_RATE,
  payload: axios.get(`api/statistics/rental-rate`, {
    params: flattenKey(searchForm),
    paramsSerializer: params => qs.stringify(params)
  })
});

// 满意度
export const getCustomerService = houseId => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_CUSTOMER_SERVICE,
    payload: axios.post('api/houseOut/count/reason', cleanEntity(houseId))
  });
  return result;
};

// 康明
export const getHealth = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_HEALTH,
    payload: axios.get('api/index/medicine_date_money')
  });
  return result;
};

// 来源
export const getSource = houseId => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_SOURCE,
    payload: axios.post('api/occupancy/count', cleanEntity(houseId))
  });
  return result;
};

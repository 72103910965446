/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { defaultsDeep, dropRight, isArray, set } from 'lodash';
import * as H from 'history';
import 'url-search-params-polyfill';
import moment, { isMoment, isDate } from 'moment';
import { APP_LOCAL_DATETIME_T_FORMAT } from 'app/config/constants';

export function urlToList(url) {
  const urllist = url.split('/').filter(i => i);
  return urllist.map((urlItem, index) => `/${urllist.slice(0, index + 1).join('/')}`);
}

export function objectToParameters(obj, parentKey = null): string {
  return Object.entries(obj)
    .map(([key, val]) => {
      if (!val) return '';
      let fullKey = key;
      if (parentKey) {
        fullKey = `${parentKey}.${fullKey}`;
      }
      if (isMoment(val) || isDate(val)) {
        return `${fullKey}=${moment(val)
          .utc()
          .format(APP_LOCAL_DATETIME_T_FORMAT)}`;
      }
      if (isArray(val)) {
        return val
          .reduce((acc, cur, i) => {
            acc.push(`${fullKey}[${i}]=${cur}`);
            return acc;
          }, [])
          .join('&');
      }
      if (typeof val === 'object') {
        return objectToParameters(val, fullKey);
      }
      return `${fullKey}=${val}`;
    })
    .filter(value => value)
    .join('&');
}

export function searchToObject(location) {
  const obj = {};
  if (!location) {
    return obj;
  }
  const url: any = new URLSearchParams(location.search);
  url.forEach((value, name) => {
    // let arrayValue = value.split(',');
    // if (arrayValue.length === 1) arrayValue = arrayValue[0].toString();
    set(obj, name, value);
  });
  return obj;
}

export function urlDropRight(url, number = 1) {
  return dropRight(url.split('/'), number).join('/');
}

export function newToList(url = '') {
  return urlDropRight(url);
}

export function newToListTow(url = '') {
  return urlDropRight(url, 2);
}

export function editToList(url = '') {
  return urlDropRight(url, 2);
}

export function deleteToList(url = '') {
  return urlDropRight(url, 2);
}

export function detailsToList(url = '') {
  return urlDropRight(url);
}

export function actionToOtherAction(domain, url = '', action = '', key = '') {
  const suffix = [key, action].filter(value => !!value && value.toString().length > 0).join('/');
  return [domain].concat(suffix).join('/');
}

export function detailsToEdit(url = '', key = '') {
  return actionToOtherAction(urlDropRight(url), url, 'edit', key);
}

export function detailsToDelete(url = '', key = '') {
  return actionToOtherAction(urlDropRight(url), url, 'delete', key);
}

export function getDefaultSort(propsLocation: H.Location, sort = 'createdAt,desc'): H.Location {
  const location = defaultsDeep(propsLocation);
  const search = searchToObject(location);
  if (!search['sort']) {
    search['sort'] = sort;
  }
  location.search = `?${objectToParameters(search)}`;
  return location;
}

// 生成get请求的url
export function getUrl(url, data) {
  return url + objectToParameters(data);
}

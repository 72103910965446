import axios from 'axios';
import * as qs from 'qs';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ISearchForm, IStatisticalYear } from 'app/shared/model/statisticalYear.model';
// 请求
export const ACTION_TYPES = {
  FETCH_BY_YEAR_LIST: 'statistical/FETCH_BY_YEAR_LIST',
  FETCH_BY_MONTH_LIST: 'statistical/FETCH_BY_MONTH_LIST',
  FETCH_TOTAL: 'statistical/FETCH_TOTAL',
  FETCH_MONTH_TOTAL: 'statistical/FETCH_MONTH_TOTAL',
  FETCH_EXPIRED_COUNT: 'statistical/FETCH_EXPIRED_COUNT'
};
// state
const initialState = {
  loading: false,
  totalLoading: false,
  entities: [] as IStatisticalYear[],
  monthentities: [] as IStatisticalYear[],
  reality: 0,
  expiredCount: 0,
  realTimeRant: 0,
  realTimeRate: 0,
  roomNub: '',
  monthrealTimeRant: 0,
  monthrealTimeRate: 0,
  monthroomNub: ''
};

export type StatisticalYearState = Readonly<typeof initialState>;

export default (state: StatisticalYearState = initialState, action): StatisticalYearState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_BY_MONTH_LIST):
    case REQUEST(ACTION_TYPES.FETCH_BY_YEAR_LIST):
      return {
        ...state,
        loading: true
      };

    case REQUEST(ACTION_TYPES.FETCH_TOTAL):
      return {
        ...state,
        totalLoading: true
      };
    case REQUEST(ACTION_TYPES.FETCH_MONTH_TOTAL):
      return {
        ...state,
        totalLoading: true
      };
    case FAILURE(ACTION_TYPES.FETCH_BY_MONTH_LIST):
    case FAILURE(ACTION_TYPES.FETCH_BY_YEAR_LIST):
      return {
        ...state,
        loading: false
      };
    case FAILURE(ACTION_TYPES.FETCH_TOTAL):
      return {
        ...state,
        totalLoading: false
      };
    case FAILURE(ACTION_TYPES.FETCH_MONTH_TOTAL):
      return {
        ...state,
        totalLoading: false
      };
    case SUCCESS(ACTION_TYPES.FETCH_BY_MONTH_LIST):
      return {
        ...state,
        loading: false,
        monthentities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_BY_YEAR_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_TOTAL):
      return {
        ...state,
        totalLoading: false,
        realTimeRate: action.payload.data.realTimeRate,
        realTimeRant: action.payload.data.realTimeRant,
        roomNub: action.payload.data.roomNub
      };
    case SUCCESS(ACTION_TYPES.FETCH_MONTH_TOTAL):
      return {
        ...state,
        totalLoading: false,
        monthrealTimeRate: action.payload.data.realTimeRate,
        monthrealTimeRant: action.payload.data.realTimeRant,
        monthroomNub: action.payload.data.roomNub
      };
    case SUCCESS(ACTION_TYPES.FETCH_EXPIRED_COUNT):
      return {
        ...state,
        expiredCount: action.payload.data
      };
    default:
      return state;
  }
};

const apiUrl = 'api/zsStats'; // 财务报表
// 财务报表 type=house(按房源统计)|cacheBuster(按日期统计)
export const search = (searchFrom: ISearchForm, type: string) => ({
  type: type === 'year' ? ACTION_TYPES.FETCH_BY_YEAR_LIST : ACTION_TYPES.FETCH_BY_MONTH_LIST,
  payload: axios.post(`${apiUrl}/${type}/count`, cleanEntity(searchFrom))
});

export const byMonth = (searchFrom: ISearchForm, type) => search(searchFrom, type);

export const byYear = (searchFrom: ISearchForm) => search(searchFrom, 'year');
// 财务报表全部数据
export const getTotal = (searchFrom: ISearchForm, type: string) => ({
  type: type === 'year' ? ACTION_TYPES.FETCH_TOTAL : ACTION_TYPES.FETCH_MONTH_TOTAL,
  payload: axios.post(`${apiUrl}/realTime/count`, cleanEntity(searchFrom))
});

export const getExpiredCount = () => ({
  type: ACTION_TYPES.FETCH_EXPIRED_COUNT,
  payload: axios.get(`${apiUrl}/expired-count`)
});

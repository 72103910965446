import axios from 'axios';
import * as qs from 'qs';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IVisit } from 'app/shared/model/visit.model';
import { flattenKey } from 'app/shared/util/object-utils';
import { ICrudGetListAction } from 'app/shared/model/list';
// 请求
export const ACTION_TYPES = {
  FETCH_VISIT_LIST: 'visit/FETCH_VISIT_LIST',
  LOAD_VISIT_LIST: 'visit/LOAD_VISIT_LIST',
  FETCH_VISIT: 'visit/FETCH_VISIT',
  CREATE_VISIT: 'visit/CREATE_VISIT',
  UPDATE_VISIT: 'visit/UPDATE_VISIT',
  DELETE_VISIT: 'visit/DELETE_VISIT',
  SMS_VISIT: 'visit/SMS_VISIT',
  RESET: 'visit/RESET'
};
// state
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IVisit>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
  smsLoading: false
};

export type VisitState = Readonly<typeof initialState>;

// Reducer

export default (state: VisitState = initialState, action): VisitState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_VISIT_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_VISIT_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_VISIT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_VISIT):
    case REQUEST(ACTION_TYPES.UPDATE_VISIT):
    case REQUEST(ACTION_TYPES.DELETE_VISIT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_VISIT_LIST):
    case FAILURE(ACTION_TYPES.LOAD_VISIT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_VISIT):
    case FAILURE(ACTION_TYPES.CREATE_VISIT):
    case FAILURE(ACTION_TYPES.UPDATE_VISIT):
    case FAILURE(ACTION_TYPES.DELETE_VISIT):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_VISIT_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_VISIT_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_VISIT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_VISIT):
    case SUCCESS(ACTION_TYPES.UPDATE_VISIT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_VISIT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case REQUEST(ACTION_TYPES.SMS_VISIT):
      return {
        ...state,
        smsLoading: true
      };
    case FAILURE(ACTION_TYPES.SMS_VISIT):
    case SUCCESS(ACTION_TYPES.SMS_VISIT):
      return {
        ...state,
        smsLoading: false
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/visits'; // 来访管理

// Actions
// 来访管理列表
export const getEntities: ICrudGetListAction<IVisit[]> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_VISIT_LIST,
    payload: axios.get<IVisit[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const loadEntities: ICrudGetListAction<IVisit[]> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.LOAD_VISIT_LIST,
    payload: axios.get<IVisit[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};
// 来访管理详情
export const getEntity: ICrudGetAction<IVisit> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_VISIT,
    payload: axios.get<IVisit>(requestUrl)
  };
};
// 创建来访管理
export const createEntity: ICrudPutAction<IVisit> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_VISIT,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};
// 更新来访管理
export const updateEntity: ICrudPutAction<IVisit> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_VISIT,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};
// 删除来访管理
export const deleteEntity: ICrudDeleteAction<IVisit> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_VISIT,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const sms = id => ({
  type: ACTION_TYPES.SMS_VISIT,
  payload: axios.put(`${apiUrl}/${id}`)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

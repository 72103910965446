export const MULTITYPE = {
  'audio/ac3': 'ac3',
  'allpication/vnd.ms-asf': 'asf',
  'audio/basic': 'au',
  'text/css': 'css',
  'text/csv': 'csv',
  'application/msword': 'doc/dot',
  'application/xml-dtd': 'dtd',
  'image/vnd.dwg': 'dwg',
  'image/vnd.dxf': 'dxf',
  'image/gif': 'gif',
  'text/html': 'htm/html',
  'image/jp2': 'jp2',
  'image/jpeg': 'jpeg/jpg',
  'text/javascript,application/javascript': 'js',
  'application/json': 'json',
  'audio/mpeg,video/mpeg': 'mp2',
  'audio/mpeg': 'mp3',
  'audio/mp4,video/mp4': 'mp4',
  'video/mpeg': 'mpeg/mpg',
  'application/vnd.ms-project': 'mpp',
  'application/ogg,audio/ogg': 'ogg',
  'application/pdf': 'pdf',
  'image/png': 'png',
  'application/vnd.ms-powerpoint': 'pot/pps/ppt',
  'application/rtf,text/rtf': 'rtf',
  'image/vnd.svf': 'svf',
  'image/tiff': 'tif/tiff',
  'text/plain': 'txt',
  'application/vnd.ms-works': 'wdb/wps',
  'application/xhtml+xml': 'xhtml',
  'application/vnd.ms-excel': 'xlc/xlm/xls/xlt/xlw',
  'text/xml,application/xml': 'xml',
  'aplication/zip': 'zip',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
};

/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { find, get } from 'lodash';
import { Translate, translate } from 'react-jhipster';
import { Button, Card, Checkbox, Form, Input, List, Spin } from 'antd';

import { createRole, getPermission, getRole, updateRole, reset } from 'app/modules/administration/roles/role-management.reducer';
import { IRootState } from 'app/shared/reducers';
import { IPermission } from 'app/shared/model/permission.model';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { editToList, newToList } from 'app/shared/util/url-utils';

interface IRoleManagementUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: any }> {
  form: any;
}

export interface IRoleManagementUpdateState {
  isNew: boolean;
}

export class RoleManagementUpdate extends React.Component<IRoleManagementUpdateProps, IRoleManagementUpdateState> {
  state: IRoleManagementUpdateState = {
    isNew: !this.props.match.params || !this.props.match.params.id
  };

  componentDidMount() {
    this.props.getPermission();
    !this.state.isNew && this.props.getRole(this.props.match.params.id);
  }

  private save = e => {
    e.preventDefault();
    const { permissions, form, role } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        const postRole = {
          id: role.id,
          name: values.name,
          permissions: role.permissions || []
        };
        permissions.forEach(p => {
          const actions = get(values, p.resource);
          const rPermission = find(postRole.permissions, ['resource', p.resource]);
          if (rPermission) {
            rPermission.actions = actions;
          } else {
            postRole.permissions.push({ ...p, actions });
          }
        });
        const result: any = this.state.isNew ? this.props.createRole(postRole) : this.props.updateRole(postRole);
        result.then(() => {
          this.handleClose();
        });
      }
    });
  };

  handleClose = () => {
    const { history, match } = this.props;
    this.state.isNew ? history.replace(newToList(match.url)) : history.replace(editToList(match.url));
  };

  componentWillUnmount() {
    this.props.reset();
  }

  renderPermission() {
    const {
      permissions,
      role,
      form: { getFieldDecorator }
    } = this.props;
    const { isNew } = this.state;

    if (permissions && permissions.length > 0) {
      const renderDescription = (item: IPermission) => {
        let actions = [];
        if (isNew) {
          actions = item.actions.filter(a => a === 'onlyManager' || a === 'onlyRegional');
        }
        if (role.permissions && role.permissions.length > 0) {
          const permission = find(role.permissions, ['resource', item.resource]) as IPermission;
          if (permission) {
            actions = permission.actions;
          }
        }

        const options = item.actions.map(p => ({ label: translate(`entity.action.${p}`), value: p }));

        return getFieldDecorator(item.resource, {
          initialValue: actions
        })(<Checkbox.Group options={options} />);
      };
      const renderItem = (item: IPermission) => (
        <List.Item>
          <List.Item.Meta title={translate(`entity.model.${item.resource}`)} description={renderDescription(item)} />
        </List.Item>
      );
      return <List itemLayout={'horizontal'} dataSource={permissions} renderItem={renderItem} />;
    }
    return <></>;
  }

  render() {
    const {
      loading,
      updating,
      role,
      form: { getFieldDecorator }
    } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      }
    };
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <Spin spinning={loading}>
            <Form onSubmit={this.save}>
              <Form.Item {...formItemLayout} label={translate('roles.name')}>
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: translate('roles.validation.name.required')
                    }
                  ],
                  initialValue: role.name
                })(<Input />)}
              </Form.Item>
              {this.renderPermission()}
              <Form.Item className={'tc'}>
                <Button type="primary" htmlType="submit" size={'large'} loading={updating}>
                  <Translate contentKey={'entity.action.save'} />
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

const mapStateTopProps = ({ roleManagement }: IRootState) => ({
  role: roleManagement.role,
  permissions: roleManagement.permissions,
  loading: roleManagement.loading,
  updating: roleManagement.updating
});

const mapDispatchTopProps = { getRole, createRole, updateRole, getPermission, reset };

type StateProps = ReturnType<typeof mapStateTopProps>;
type DispatchProps = typeof mapDispatchTopProps;

const formWrapper = Form.create()(RoleManagementUpdate);
export default connect(
  mapStateTopProps,
  mapDispatchTopProps
)(formWrapper);

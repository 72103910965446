/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios';
import * as qs from 'qs';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction, IPayload } from 'react-jhipster';
import { defaultsDeep } from 'lodash';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { defaultSearchForm, defaultValue, IRole, ISearchForm } from 'app/shared/model/role.model';
import { flattenKey } from 'app/shared/util/object-utils';
import { IPermission } from 'app/shared/model/permission.model';

export declare type ICrudGetListAction<T> = (
  page?: number,
  size?: number,
  sort?: string,
  searchForm?: ISearchForm
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_ROLES: 'roleManagement/FETCH_ROLES',
  FETCH_ROLE: 'roleManagement/FETCH_ROLE',
  CREATE_ROLE: 'roleManagement/CREATE_ROLE',
  UPDATE_ROLE: 'roleManagement/UPDATE_ROLE',
  DELETE_ROLE: 'roleManagement/DELETE_ROLE',
  UPDATE_ROLES: 'roleManagement/UPDATE_ROLES',
  RESET: 'roleManagement/RESET',
  FETCH_PERMISSION: 'roleManagement/FETCH_PERMISSION',
  UPDATE_SEARCH_FORM: 'roleManagement/UPDATE_SEARCH_FORM',
  CLEAR_ROLE: 'roleManagement/CLEAR_ROLE'
};

const initialState = {
  loading: false,
  errorMessage: null,
  roles: [] as IRole[],
  role: defaultValue,
  permissions: [] as IPermission[],
  updating: false,
  updateSuccess: false,
  totalItems: 0,
  searchForm: defaultSearchForm
};

export type RoleManagementState = Readonly<typeof initialState>;

export default (state: RoleManagementState = initialState, action): RoleManagementState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ROLES):
    case REQUEST(ACTION_TYPES.FETCH_ROLE):
    case REQUEST(ACTION_TYPES.FETCH_PERMISSION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_ROLE):
    case REQUEST(ACTION_TYPES.UPDATE_ROLE):
    case REQUEST(ACTION_TYPES.DELETE_ROLE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_ROLES):
    case FAILURE(ACTION_TYPES.FETCH_ROLE):
    case FAILURE(ACTION_TYPES.FETCH_PERMISSION):
    case FAILURE(ACTION_TYPES.CREATE_ROLE):
    case FAILURE(ACTION_TYPES.UPDATE_ROLE):
    case FAILURE(ACTION_TYPES.DELETE_ROLE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_ROLES):
      return {
        ...state,
        loading: false,
        roles: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_ROLE):
      return {
        ...state,
        loading: false,
        role: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_ROLE):
    case SUCCESS(ACTION_TYPES.UPDATE_ROLE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        role: action.payload.data
      };
    case ACTION_TYPES.RESET:
      return {
        ...state,
        role: defaultValue
      };
    case SUCCESS(ACTION_TYPES.DELETE_ROLE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        role: {}
      };
    case SUCCESS(ACTION_TYPES.FETCH_PERMISSION):
      return {
        ...state,
        loading: false,
        permissions: action.payload.data
      };
    case ACTION_TYPES.UPDATE_SEARCH_FORM:
      return {
        ...state,
        searchForm: defaultsDeep(action.payload.searchForm, defaultSearchForm)
      };
    case ACTION_TYPES.UPDATE_ROLES:
      const index = state.roles.findIndex(role => role.id === state.role.id);
      const roles = state.roles;
      roles[index] = state.role;
      return {
        ...state,
        roles
      };
    case ACTION_TYPES.CLEAR_ROLE:
      return {
        ...state,
        role: defaultValue
      };
    default:
      return state;
  }
};

const apiUrl = '/api/roles'; // 角色管理
// 角色管理列表
export const getMRoles: ICrudGetListAction<IRole> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_ROLES,
    payload: axios.get<IRole>(requestUrl, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};
export const reset = () => ({
  type: ACTION_TYPES.RESET
});
export const getRole: ICrudGetAction<IRole> = (id: number) => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ROLE,
    payload: axios.get<IRole>(requestUrl),
    meta: { transformUserName: true }
  };
};
// 创建角色管理
export const createRole: ICrudPutAction<IRole> = role => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ROLE,
    payload: axios.post(apiUrl, role)
  });
  dispatch(getMRoles());
  return result;
};
// 更新角色管理
export const updateMRoles = () => ({
  type: ACTION_TYPES.UPDATE_ROLES
});

export const updateRole: ICrudPutAction<IRole> = role => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ROLE,
    payload: axios.put(apiUrl, role)
  });
  dispatch(updateMRoles());
  return result;
};
// 删除角色管理
export const deleteRole: ICrudDeleteAction<IRole> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ROLE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getMRoles());
  return result;
};

export const search = (page, size, sort, searchForm) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SEARCH_FORM,
    payload: {
      searchForm
    }
  });
  dispatch(getMRoles(page, size, sort, searchForm));
  return result;
};

export const getPermission = () => ({
  type: ACTION_TYPES.FETCH_PERMISSION,
  payload: axios.get('/api/permissions')
});

export const clearRole = () => ({
  type: ACTION_TYPES.CLEAR_ROLE
});

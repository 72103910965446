import { G2, Chart, Geom, Axis, Tooltip, Coord, Label, Legend, View, Guide, Shape, Facet, Util } from 'bizcharts';
import React, { Component } from 'react';
import DataSet from '@antv/data-set';
import Debounce from 'lodash.debounce';
import autoHeight from '../commons/autoHeight';
import styles from './index.less';

export interface IRadarProps {
  data: string[];
  autoLabel?: boolean;
}

export default class Radar extends Component<
  IRadarProps,
  {
    autoHideXLabels: boolean;
  }
> {
  state = {
    autoHideXLabels: false
  };

  root: HTMLDivElement | undefined = undefined;

  node: HTMLDivElement | undefined = undefined;

  resize = Debounce(() => {
    if (!this.node || !this.node.parentNode) {
      return;
    }
    const canvasWidth = (this.node.parentNode as HTMLDivElement).clientWidth;
    const { data = [], autoLabel = true } = this.props;
    if (!autoLabel) {
      return;
    }
    const minWidth = data.length * 30;
    const { autoHideXLabels } = this.state;

    if (canvasWidth <= minWidth) {
      if (!autoHideXLabels) {
        this.setState({
          autoHideXLabels: true
        });
      }
    } else if (autoHideXLabels) {
      this.setState({
        autoHideXLabels: false
      });
    }
  }, 500);

  componentDidMount() {
    window.addEventListener('resize', this.resize, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  handleRoot = (n: HTMLDivElement) => {
    this.root = n;
  };

  handleRef = (n: HTMLDivElement) => {
    this.node = n;
  };

  render() {
    const { DataView } = DataSet;
    const { data } = this.props;
    if (!data) {
      return <></>;
    }
    const dv = new DataView().source(data);
    dv.transform({
      type: 'fold',
      fields: ['a'],
      // 展开字段集
      key: 'user',
      // key字段
      value: 'score' // value字段
    });
    const cols = {
      score: {
        min: 0,
        max: 80
      }
    };
    return (
      <div>
        <Chart data={dv} padding={[10, 10, 10, 10]} width={160} scale={cols} forceFit height={205}>
          <Coord type="polar" radius={0.7} />
          <Axis
            name="item"
            line={null}
            label={{
              // offset: 20,
              autoRotate: true
            }}
            title={{
              position: 'start'
            }}
            tickLine={null}
            grid={{
              lineStyle: {
                lineDash: null
              },
              hideFirstLine: false
            }}
          />
          <Tooltip
            // showTitle={false}
            shared={false}
            itemTpl="<li>{value}</li>"
          />
          <Axis
            name="score"
            line={null}
            tickLine={null}
            title={null}
            label={null}
            // 网格线条
            grid={{
              type: 'polygon',
              lineStyle: {
                lineDash: null
              },
              alternateColor: 'rgba(0, 0, 0, 0.04)'
            }}
          />
          {/* <Legend name="rgba(230, 33, 41, 0.3)" marker="circle" /> */}
          <Geom type="area" position="item*score" color="rgba(230, 33, 41, 0.3)" />
          <Geom type="line" position="item*score" color="rgba(230, 33, 41, 0.3)" size={2} />
          <Geom
            type="point"
            position="item*score"
            color="rgba(230, 33, 41, 1)"
            shape="circle"
            size={4}
            style={{
              stroke: '#E62129',
              lineWidth: 1,
              fillOpacity: 1
            }}
          />
        </Chart>
      </div>
    );
  }
}

// export default autoHeight()(Radar);

import axios from 'axios';
import * as qs from 'qs';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction, IPayload } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IUser, defaultValue, ISearchForm, ICert, defaultCert } from 'app/shared/model/user.model';
import { flattenKey } from 'app/shared/util/object-utils';

export declare type ICrudGetListAction<T> = (
  page?: number,
  size?: number,
  sort?: string,
  searchForm?: ISearchForm
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_ROLES: 'userManagement/FETCH_ROLES',
  FETCH_USERS: 'userManagement/FETCH_USERS',
  LOAD_USERS: 'userManagement/LOAD_USERS',
  FETCH_SIMPLE_USERS: 'userManagement/FETCH_SIMPLE_USERS',
  LOAD_SIMPLE_USERS: 'userManagement/LOAD_SIMPLE_USERS',
  FETCH_USER: 'userManagement/FETCH_USER',
  FETCH_USER_CERT: 'userManagement/FETCH_USER_CERT',
  CREATE_USER: 'userManagement/CREATE_USER',
  UPDATE_USER: 'userManagement/UPDATE_USER',
  UPDATE_PHONE: 'userManagement/UPDATE_PHONE',
  DELETE_USER: 'userManagement/DELETE_USER',
  RESET: 'userManagement/RESET',
  CLEAR_USER: 'userManagement/CLEAR_USER'
};

const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  users: [] as ReadonlyArray<IUser>,
  simpleUser: [] as ReadonlyArray<IUser>,
  authorities: [] as string[],
  user: defaultValue,
  cert: defaultCert,
  updating: false,
  updateSuccess: false,
  totalItems: 0
};

export type UserManagementState = Readonly<typeof initialState>;

// Reducer
export default (state: UserManagementState = initialState, action): UserManagementState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ROLES):
      return {
        ...state
      };
    case REQUEST(ACTION_TYPES.FETCH_USERS):
    case REQUEST(ACTION_TYPES.FETCH_SIMPLE_USERS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_USERS):
    case REQUEST(ACTION_TYPES.LOAD_SIMPLE_USERS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_USER):
    case REQUEST(ACTION_TYPES.FETCH_USER_CERT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_USER):
    case REQUEST(ACTION_TYPES.UPDATE_USER):
    case REQUEST(ACTION_TYPES.UPDATE_PHONE):
    case REQUEST(ACTION_TYPES.DELETE_USER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_USERS):
    case FAILURE(ACTION_TYPES.FETCH_USER_CERT):
    case FAILURE(ACTION_TYPES.LOAD_USERS):
    case FAILURE(ACTION_TYPES.FETCH_USER):
    case FAILURE(ACTION_TYPES.LOAD_SIMPLE_USERS):
    case FAILURE(ACTION_TYPES.FETCH_SIMPLE_USERS):
    case FAILURE(ACTION_TYPES.FETCH_ROLES):
    case FAILURE(ACTION_TYPES.CREATE_USER):
    case FAILURE(ACTION_TYPES.UPDATE_USER):
    case FAILURE(ACTION_TYPES.UPDATE_PHONE):
    case FAILURE(ACTION_TYPES.DELETE_USER):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_ROLES):
      return {
        ...state,
        authorities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERS):
      return {
        ...state,
        loading: false,
        fetching: false,
        // @ts-ignore
        users: [...action.payload.data],
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_USERS):
      return {
        ...state,
        loadMore: false,
        users: [...state.users.concat(action.payload.data)],
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_SIMPLE_USERS):
      return {
        ...state,
        loading: false,
        fetching: false,
        simpleUser: [...action.payload.data],
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_SIMPLE_USERS):
      return {
        ...state,
        loadMore: false,
        simpleUser: [...state.users.concat(action.payload.data)],
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_USER):
      return {
        ...state,
        loading: false,
        user: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_USER_CERT):
      return {
        ...state,
        loading: false,
        cert: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_USER):
    case SUCCESS(ACTION_TYPES.UPDATE_USER):
    case SUCCESS(ACTION_TYPES.UPDATE_PHONE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        user: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_USER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        user: defaultValue
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.CLEAR_USER:
      return {
        ...state,
        user: defaultValue
      };
    default:
      return state;
  }
};

const apiUrl = 'api/users'; // 用户管理
// Actions
// 用户管理
export const getUsers: ICrudGetListAction<IUser> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_USERS,
    payload: axios.get<IUser>(requestUrl, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

// 微信解绑
export const confirmUnlock = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USER,
    payload: axios.put(`${apiUrl}/${id}/third-unbind?provider=WECHAT`)
  });
  return result;
};

export const loadUsers: ICrudGetListAction<IUser> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.LOAD_USERS,
    payload: axios.get<IUser>(requestUrl, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const getUsersSimple: ICrudGetListAction<IUser> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}/simple${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_SIMPLE_USERS,
    payload: axios.get<IUser>(requestUrl, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const getUsersSuvey: ICrudGetListAction<IUser> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `api/users/nameList?roles.equals=管家`;
  return {
    type: ACTION_TYPES.FETCH_SIMPLE_USERS,
    payload: axios.get<IUser>(requestUrl, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const loadUsersSimple: ICrudGetListAction<IUser> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}/simple${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.LOAD_SIMPLE_USERS,
    payload: axios.get<IUser>(requestUrl, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const getRoles = () => ({
  type: ACTION_TYPES.FETCH_ROLES,
  payload: axios.get(`${apiUrl}/authorities`)
});

export const getUser: ICrudGetAction<IUser> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_USER,
    payload: axios.get<IUser>(requestUrl)
  };
};

export const getCert: ICrudGetAction<ICert> = id => {
  const requestUrl = `api/certs/byuser/${id}`;
  return {
    type: ACTION_TYPES.FETCH_USER_CERT,
    payload: axios.get<ICert>(requestUrl)
  };
};

export const createUser: ICrudPutAction<IUser> = user => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USER,
    payload: axios.post(apiUrl, user)
  });
  dispatch(getUsers());
  return result;
};

export const updateUser: ICrudPutAction<IUser> = (user?: any) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_USER,
    payload: axios.put(apiUrl, user)
  });
  dispatch(getUsers());
  return result;
};

export const updatePhone: ICrudPutAction<IUser> = (user?: any) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PHONE,
    payload: axios.put('api/users/change-mobile', user)
  });
  dispatch(getUsers());
  return result;
};

export const deleteUser: ICrudDeleteAction<IUser> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_USER,
    payload: axios.delete(requestUrl)
  });
  dispatch(getUsers());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const clearUser = () => ({
  type: ACTION_TYPES.CLEAR_USER
});

export const getNatureName = (user: IUser, defaultStr = '') => (user ? (user.name ? user.name : user.login) : defaultStr);
export const getMobile = (user: IUser, prefix = '', suffix = '', defaultStr = '') =>
  user ? (user.mobile ? prefix + user.mobile + suffix : defaultStr) : defaultStr;

export const getUserSimple = id => {
  const requestUrl = `${apiUrl}/simple/${id}`;
  return axios.get<IUser>(requestUrl);
};

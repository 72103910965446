import { Moment } from 'moment';

export const enum PopularizeChannel {
  FIVE_EIGHT = 'FIVE_EIGHT',
  JIAN_RONG = 'JIAN_RONG'
}

export interface IPopularizeRecord {
  id?: number;
  remark?: string;
  channel?: PopularizeChannel;
  createdAt?: Moment;
  updatedAt?: Moment;
  createdBy?: string;
  updatedBy?: string;
  popularizeId?: number;
}

export const defaultValue: Readonly<IPopularizeRecord> = {};

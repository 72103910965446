import axios, { AxiosRequestConfig } from 'axios';

import { AUTH_TOKEN_KEY, SERVER_API_URL, API_LAST_TIME_KEY, API_LAST_TIME_INTERVAL } from 'app/config/constants';
import { checkOverTime, getToken, setToken } from 'app/shared/util/token-utils';
import { refreshNewToken } from 'app/shared/common/refresh-token';
import { getKey, setKey } from 'app/shared/util/localstore-utils';
import moment from 'moment';

const TIMEOUT = 60 * 60 * 1000; // 设置请求超时一个小时
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

let isRefreshing = false;
const refreshSubscribers = [];
// 将所有的请求都push到数组中,其实数组是[function(token){}, function(token){},...]
const subscribeTokenRefresh = cb => refreshSubscribers.push(cb);
// 数组中的请求得到新的token之后自执行，用新的token去请求数据
const onRefreshed = token => refreshSubscribers.map(cb => cb(token));

const setupAxiosInterceptors = onUnauthenticated => {
  const onRequestSuccess = configAxios => {
    const { token, refreshToken } = getToken();
    const thisMoment = parseInt(moment().format('X'), 0);
    const lastMoment = parseInt(getKey(API_LAST_TIME_KEY, 'LOCAL'), 0);
    // console.log(thisMoment, lastMoment);
    // console.log('before', token, refreshToken);
    if (token) {
      configAxios.headers.Authorization = `Bearer ${token}`;
      if (configAxios.url === 'api/refresh-token') {
        configAxios.headers.Authorization = 'Bearer ' + refreshToken;
      }

      // token 超时 请求url不是刷新token的
      if (checkOverTime(token) && configAxios.url !== 'api/refresh-token') {
        // 超过了api最后一次请求的限定间隔时间就给退出登录
        if (lastMoment > 0 && thisMoment - lastMoment > API_LAST_TIME_INTERVAL) {
          onUnauthenticated();
          window.location.href = '/user/login';
        }
        if (!isRefreshing) {
          isRefreshing = true;
          configAxios.headers.Authorization = 'Bearer ' + refreshToken;
          refreshNewToken().then(res => {
            const newToken = res.data.id_token;
            // 更新token
            const rememberMe = getKey(AUTH_TOKEN_KEY, 'LOCAL') ? true : false;
            setToken(rememberMe, newToken, refreshToken);
            isRefreshing = false;
            // 执行数组里的请求，重新发起被挂起的请求
            onRefreshed(newToken);
          });
          const retry = new Promise((resolve, reject) => {
            // (token) => {...}这个函数就是cb
            subscribeTokenRefresh(toToken => {
              configAxios.headers.Authorization = 'Bearer ' + toToken;
              // 将请求挂起
              resolve(configAxios);
            });
          });
          return retry;
        } else {
          const retry = new Promise((resolve, reject) => {
            // (token) => {...}这个函数就是cb
            subscribeTokenRefresh(toToken => {
              configAxios.headers.Authorization = 'Bearer ' + toToken;
              // 将请求挂起
              resolve(configAxios);
            });
          });
          return retry;
        }
      } else {
        return configAxios;
      }
    }
    setKey(API_LAST_TIME_KEY, 0, 'LOCAL');
    return configAxios;
  };
  // 请求成功返回
  const onResponseSuccess = response => {
    setKey(API_LAST_TIME_KEY, moment().format('X'), 'LOCAL');
    return response;
  };
  // 请求失败返回
  const onResponseError = err => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 401) {
      onUnauthenticated();
      // window.location.href = '/user/login';
    }
    return Promise.reject(err);
  };

  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;

import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, IPayload } from 'react-jhipster';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import * as qs from 'qs';
import { defaultValue, IEntityAudit, ISearchForm } from 'app/shared/model/entity-audit.model';
import { flattenKey } from 'app/shared/util/object-utils';
// 接口
export const ACTION_TYPES = {
  FETCH_ENTITY_AUDIT_LIST: 'entity-audit/FETCH_ENTITY_AUDIT_LIST',
  FETCH_ENTITY_AUDIT: 'entity-audit/FETCH_ENTITY_AUDIT',
  FETCH_PREVEW_VERSION_ENTITY_AUDIT: 'entity-audit/FETCH_PREVEW_VERSION_ENTITY_AUDIT',
  DELETE_ENTITY_AUDIT: 'entity-audit/DELETE_ENTITY_AUDIT',
  CLEAR_ENTITY_AUDIT: 'entity-audit/CLEAR_ENTITY_AUDIT',
  RESET: 'entity-audit/RESET'
};
export declare type ICrudGetListAction<T> = (
  page?: number,
  size?: number,
  sort?: string,
  searchForm?: ISearchForm
) => IPayload<T> | ((dispatch: any) => IPayload<T>);
const initialState = {
  loading: false,
  dialogLoading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IEntityAudit>,
  entity: defaultValue,
  previewVersion: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type EntityAuditEventState = Readonly<typeof initialState>;

// Reducer

export default (state: EntityAuditEventState = initialState, action): EntityAuditEventState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ENTITY_AUDIT):
    case REQUEST(ACTION_TYPES.FETCH_PREVEW_VERSION_ENTITY_AUDIT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        dialogLoading: true
      };
    case REQUEST(ACTION_TYPES.FETCH_ENTITY_AUDIT_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.DELETE_ENTITY_AUDIT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_ENTITY_AUDIT):
    case FAILURE(ACTION_TYPES.FETCH_PREVEW_VERSION_ENTITY_AUDIT):
      return {
        ...state,
        loading: false,
        updating: false,
        dialogLoading: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.FETCH_ENTITY_AUDIT_LIST):
    case FAILURE(ACTION_TYPES.DELETE_ENTITY_AUDIT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_ENTITY_AUDIT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_PREVEW_VERSION_ENTITY_AUDIT):
      return {
        ...state,
        dialogLoading: false,
        previewVersion: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_ENTITY_AUDIT):
      return {
        ...state,
        dialogLoading: false,
        previewVersion: action.payload.data,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_ENTITY_AUDIT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
        entities: state.entities
      };

    case ACTION_TYPES.CLEAR_ENTITY_AUDIT:
      return {
        ...state,
        entity: defaultValue
      };
    default:
      return state;
  }
};

const apiUrl = 'api/audits'; // 日志

// Actions
// 日志详情
export const getEntities: ICrudGetListAction<IEntityAudit> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?cacheBuster=${new Date().getTime()}&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_ENTITY_AUDIT_LIST,
    payload: axios.get<IEntityAudit>(`${requestUrl}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};
export const getPreviewVersion = (qualifiedName, entityId, commitVersion) => {
  const requestUrl = `${apiUrl}/entity/changes/version/previous`;
  const searchForm = { qualifiedName, entityId, commitVersion };
  return {
    type: ACTION_TYPES.FETCH_PREVEW_VERSION_ENTITY_AUDIT,
    payload: axios.get<IEntityAudit>(requestUrl, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    })
  };
};
export const getEntity: ICrudGetAction<IEntityAudit> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ENTITY_AUDIT,
    payload: axios.get<IEntityAudit>(requestUrl)
  };
};
// 清空日志
export const clearEntity = () => ({
  type: ACTION_TYPES.CLEAR_ENTITY_AUDIT
});
// 删除日志
export const deleteEntity: ICrudDeleteAction<IEntityAudit> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ENTITY_AUDIT,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

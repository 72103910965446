import moment, { Moment } from 'moment';
import { IHouseExpense } from 'app/shared/model/house-expense.model';
import { IFilter, IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { AuditState, defaultValue as houseDefaultValue, IHouse } from 'app/shared/model/house.model';
import { defaultValue as defaultLayout, IHouseLayout } from 'app/shared/model/house-layout.model';
import { IAttachment } from 'app/shared/model/attachment.model';

export const enum ParkingState {
  IDLE = 'IDLE',
  RENTED = 'RENTED',
  FIXED = 'FIXED',
  UN_PUBLISH = 'UN_PUBLISH',
  PULLED_OFF_SHELVES = 'PULLED_OFF_SHELVES'
}

export const enum ParkingShow {
  FALSE = 'FALSE',
  TRUE = 'TRUE'
}

export interface IHouseRoomExpense {
  singleMonthPrice: number;
  deposit: number;
  propertyFee: number;
  paymentCycle: number;
  expenses: IHouseExpense[];
  rooms?: IParkingRoom[];
}

export interface IParkingRoom {
  id?: number;
  code?: string;
  floor?: number;
  toogleFloor?: number;
  buildingCode?: string;
  unitCode?: string;
  waterCode?: string;
  electricityCode?: string;
  roomCode?: string;
  fullName?: string;
  description?: string;
  attachments?: IAttachment[];
  state?: ParkingState;
  singleMonthPrice?: number;
  paymentCycle?: number;
  extraDeposit?: number;
  currentPayAt?: Moment;
  lastUsedAt?: Moment;
  createdAt?: Moment;
  updatedAt?: Moment;
  createdBy?: string;
  hasMaintenance?: boolean;
  authState?: AuditState;
  updatedBy?: string;
  houseExpenses?: IHouseExpense[];
  houseLayoutId?: number;
  houseLayout?: IHouseLayout;
  houseId?: number;
  house?: IHouse;
  remark?: string;
  area?: number;
  district?: string;
  parkingLotDisplay?: Boolean;
}

export const defaultHouseRoomExpense: Readonly<IHouseRoomExpense> = {
  deposit: 0.0,
  singleMonthPrice: 0.0,
  propertyFee: 0.0,
  paymentCycle: 3,
  expenses: [],
  rooms: []
};
export const defaultValue: Readonly<IParkingRoom> = {
  house: { ...houseDefaultValue },
  lastUsedAt: moment(),
  attachments: [],
  hasMaintenance: false,
  houseLayout: { ...defaultLayout }
};

export interface ISearchForm {
  name?: IStringFilter;
  roomCode?: IStringFilter;
  buildingType?: IStringFilter;
  unitCode?: IStringFilter;
  floor?: IRangeFilter;
  id?: IFilter;
  parkingLotId?: IFilter;
  regionalDivideId?: IStringFilter;
  state?: IStringFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
  updatedAt?: IRangeFilter;
  updatedBy?: IStringFilter;
  filterFields?: boolean;
  yunDingPasswordId?: IRangeFilter;
  houseId?: IFilter;
  itemsPerPage?: string;
  code?: number; // 车位编号
}

export const defaultSearchForm: Readonly<ISearchForm> = {
  name: {
    contains: ''
  },
  parkingLotId: {
    equals: ''
  },
  buildingType: {
    equals: ''
  },
  id: {
    equals: ''
  },
  floor: {
    equals: ''
  },
  regionalDivideId: {
    equals: ''
  },
  state: {
    in: []
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  updatedAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  updatedBy: { contains: '' },
  filterFields: false
};
export interface ISimpleSearchForm {
  area?: IStringFilter;
  floor?: IStringFilter; // 层
  id?: IFilter; // id
  parkingLotId?: IFilter; // 车位
  state?: IStringFilter; // 状态
  createdAt?: IRangeFilter; // 创建时间
  createdBy?: IStringFilter; // 创建人
  updatedAt?: IRangeFilter; // 更新开始时间
  updatedBy?: IStringFilter; // 更新人
  houseId?: IFilter; // 房产id
  code?: IStringFilter; // 车位编号
  filterFields?: boolean;
}

export const defaultSimpleSearchForm: Readonly<ISimpleSearchForm> = {
  area: {
    equals: ''
  },
  code: {
    contains: ''
  },
  parkingLotId: {
    equals: ''
  },
  houseId: {
    equals: ''
  },
  id: {
    equals: ''
  },
  floor: {
    equals: ''
  },
  state: {
    in: []
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  updatedAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  updatedBy: { contains: '' },
  filterFields: false
};

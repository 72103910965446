import axios from 'axios';
import * as qs from 'qs';
import { ICrudGetAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { defaultValue, IHouseSurvey } from 'app/shared/model/house_survey';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_HOUSESURVEY_LIST: 'housesurvey/FETCH_HOUSESURVEY_LIST', // 列表
  CREATE_HOUSESURVEY: 'housesurvey/CREATE_HOUSESURVEY', // 创建
  FETCH_HOUSESURVEY: 'housesurvey/FETCH_HOUSESURVEY', // 详情
  UPDATE_HOUSESURVEY: 'housesurvey/UPDATE_HOUSESURVEY', // 更新
  DELETE_HOUSESURVEY: 'housesurvey/DELETE_HOUSESURVEY', // 删除
  RESET: 'housesurvey/RESET' // 重置
};
// state
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IHouseSurvey>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type houseSurveyState = Readonly<typeof initialState>;

// Reducer
export default (state: houseSurveyState = initialState, action): houseSurveyState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_HOUSESURVEY):
    case REQUEST(ACTION_TYPES.CREATE_HOUSESURVEY):
    case REQUEST(ACTION_TYPES.UPDATE_HOUSESURVEY):
    case REQUEST(ACTION_TYPES.DELETE_HOUSESURVEY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case REQUEST(ACTION_TYPES.FETCH_HOUSESURVEY_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case FAILURE(ACTION_TYPES.UPDATE_HOUSESURVEY):
    case FAILURE(ACTION_TYPES.CREATE_HOUSESURVEY):
    case FAILURE(ACTION_TYPES.FETCH_HOUSESURVEY):
    case FAILURE(ACTION_TYPES.FETCH_HOUSESURVEY_LIST):
    case FAILURE(ACTION_TYPES.DELETE_HOUSESURVEY):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.DELETE_HOUSESURVEY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case SUCCESS(ACTION_TYPES.FETCH_HOUSESURVEY):
      const { data } = action.payload;
      let { industry, source, satisfaction } = data;

      const otherChange = (value, name) => {
        const arr = value.split(':');
        data[`${name}_other`] = arr[1];
        return arr[0];
      };

      industry = industry && industry.indexOf('其他') > -1 ? otherChange(industry, 'reason') : industry;
      source = source && source.indexOf('其他') > -1 ? otherChange(source, 'source') : source;

      if (satisfaction && satisfaction.indexOf('其他') > -1) {
        const arr = satisfaction.split(',');
        const num = arr.findIndex(item => item.indexOf('其他') > -1);
        arr[num] = otherChange(arr[num], 'satisfaction');
        satisfaction = arr;
      }

      return {
        ...state,
        loading: false,
        entity: { ...data, satisfaction, industry, source }
      };
    case SUCCESS(ACTION_TYPES.UPDATE_HOUSESURVEY):
    case SUCCESS(ACTION_TYPES.CREATE_HOUSESURVEY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_HOUSESURVEY_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data || [],
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/housesView'; // 看房调查
// Actions
// 看房调查，列表
export const getEntities: ICrudGetListAction<IHouseSurvey> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiUrl}/page?page=${page}&size=${size}`;
  return {
    type: ACTION_TYPES.FETCH_HOUSESURVEY_LIST,
    payload: axios.get<IHouseSurvey>(
      `${requestUrl}${sort ? `&sort=${sort}&cacheBuster=${new Date().getTime()}` : `?cacheBuster=${new Date().getTime()}`}`,
      {
        params: flattenKey(searchForm),
        paramsSerializer: params => qs.stringify(params)
      }
    ),
    meta: { transformUserName: true }
  };
};
// 新增看房调查
export const createEntity = entity => async (dispatch, getState) => {
  const {
    expenseGrade: { searchData }
  } = getState();
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_HOUSESURVEY,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });

  return result;
};
// 获取看房调查数据
export const getEntity: ICrudGetAction<IHouseSurvey> = id => {
  const requestUrl = `${apiUrl}/detail/${id}`;
  return {
    type: ACTION_TYPES.FETCH_HOUSESURVEY,
    payload: axios.get<IHouseSurvey>(requestUrl)
  };
};
export const updateEntity: ICrudPutAction<IHouseSurvey> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_HOUSESURVEY,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};
// 删除
export const deleteEntity: ICrudDeleteAction<IHouseSurvey> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_HOUSESURVEY,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

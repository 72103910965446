import styles from './style.less';
import React from 'react';
import { List, Checkbox } from 'antd';
import { ListProps } from 'antd/lib/list';
import { TableRowSelection } from 'antd/lib/table';
import { pullAll, pullAllBy } from 'lodash';

export interface ISelectionListProps<T> extends ListProps<T> {
  rowSelection?: Pick<TableRowSelection<T>, 'selectedRowKeys' | 'onChange' | 'getCheckboxProps'>;
  itemClick?: (record: T) => any;
}

export interface ISelectionListStates<T> {
  selectedRowKeys?: any[];
  selectedRows?: T[];
}

export default class SelectionList<T> extends React.Component<ISelectionListProps<T>, ISelectionListStates<T>> {
  state: ISelectionListStates<T> = {
    selectedRowKeys: [],
    selectedRows: []
  };

  static getDerivedStateFromProps(nextProps: Readonly<ISelectionListProps<any>>) {
    // Should be a controlled component.
    if ('rowSelection' in nextProps) {
      if (nextProps.rowSelection && 'selectedRowKeys' in nextProps.rowSelection) {
        return {
          ...{ selectedRowKeys: nextProps.rowSelection.selectedRowKeys || [] }
        };
      }
    }
    return null;
  }

  getKey = (record: T) => {
    const { rowKey } = this.props;
    return typeof rowKey === 'function' ? rowKey(record) : rowKey;
  };

  triggerCheckChange(selectRowKeys: any[], selectedRows: T[]) {
    const { rowSelection } = this.props;
    if (rowSelection && rowSelection.onChange) {
      rowSelection.onChange(selectRowKeys, selectedRows);
    }
  }

  triggerItemClick = record => () => {
    const { itemClick } = this.props;
    if (itemClick) {
      itemClick(record);
    }
  };

  handleChecked = record => ({ target: { checked } }) => {
    const key = this.getKey(record);
    const { selectedRowKeys, selectedRows } = this.state;
    const selectedRowKeysValue = checked ? selectedRowKeys.concat([record[key]]) : pullAll(selectedRowKeys, [record[key]]);
    const selectedRowsValue = checked ? selectedRows.concat([record]) : pullAllBy(selectedRows, [record], key);
    this.setState({
      selectedRowKeys: selectedRowKeysValue,
      selectedRows: selectedRowsValue
    });
    this.triggerCheckChange(selectedRowKeysValue, selectedRowsValue);
  };

  handleCheckAll = ({ target: { checked } }) => {
    let selectedRowKeys = [];
    let selectedRows = [];
    if (checked) {
      const { dataSource } = this.props;
      selectedRowKeys = dataSource.map(d => d[this.getKey(d)]);
      selectedRows = dataSource;
    }
    this.setState({
      selectedRowKeys,
      selectedRows
    });
    this.triggerCheckChange(selectedRowKeys, selectedRows);
  };

  renderItem = (record: T, index: number) => {
    const { renderItem, rowSelection } = this.props;
    if (rowSelection) {
      const { selectedRowKeys } = this.state;
      const checkboxProps = rowSelection.getCheckboxProps ? rowSelection.getCheckboxProps(record) : {};
      return (
        <List.Item className={styles.listItem}>
          <Checkbox
            {...checkboxProps}
            checked={selectedRowKeys.includes(record[this.getKey(record)])}
            className={styles.listCheckbox}
            onChange={this.handleChecked(record)}
          >
            {renderItem(record, index)}
          </Checkbox>
        </List.Item>
      );
    }
    return (
      <List.Item
        onClick={this.triggerItemClick(record)}
        className={styles.listItem + ' ' + (this.props.itemClick && styles.listItemActive)}
      >
        {renderItem(record, index)}
      </List.Item>
    );
  };

  render() {
    const { renderItem, rowSelection, ...other } = this.props;
    const { dataSource } = this.props;
    const { selectedRowKeys } = this.state;
    return (
      <>
        {rowSelection && (
          <div>
            <div className={styles.block} />
            <div style={{ padding: '6px 0' }}>
              <Checkbox
                onChange={this.handleCheckAll}
                checked={dataSource.length === selectedRowKeys.length}
                indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < dataSource.length}
              >
                全选
              </Checkbox>
            </div>
          </div>
        )}
        <List {...other} renderItem={this.renderItem} />
      </>
    );
  }
}

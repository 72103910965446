import { Moment } from 'moment';
import { IFilter, IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { IHouseRoom, defaultValue as defaultHouseRoom } from 'app/shared/model/house-room.model';
import { IUser, defaultValue as defaultUser } from 'app/shared/model/user.model';

export enum LifePriceType {
  ELECTRICITY = 'ELECTRICITY',
  WATER = 'WATER'
}

export interface ILifePrice {
  id?: number;
  readNumber?: number;
  total?: number;
  price?: number;
  createdAt?: Moment;
  createdBy?: string;
  updatedBy?: string;
  type?: LifePriceType;
  updatedAt?: Moment;
  paymenterId?: number;
  paymenter?: IUser;
  roomId?: number;
  houseRoom?: IHouseRoom;
  houseId?: number;
}

export interface ISearchForm {
  paymenterId?: IFilter;
  type?: IStringFilter;
  roomId?: IStringFilter;
  mobile?: IStringFilter;
  createdAt?: IRangeFilter;
  fixedAt?: IRangeFilter;
}

export const defaultValue: Readonly<ILifePrice> = {
  readNumber: 0,
  paymenter: { ...defaultUser },
  houseRoom: { ...defaultHouseRoom },
  type: LifePriceType.ELECTRICITY
};

export const defaultSearchForm: ISearchForm = {
  paymenterId: {
    equals: []
  },
  type: {
    in: []
  },
  roomId: {
    equals: ''
  },
  mobile: {
    contains: ''
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  fixedAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  }
};

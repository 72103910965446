import { IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { Moment } from 'moment';

export const enum ExpenseType {
  IN = 'IN',
  OUT = 'OUT'
}

export interface IExpenseGrade {
  id?: number;
  name?: string;
  ancestry?: string;
  parentId?: number;
  type?: ExpenseType;
  createdAt?: Moment;
  createdBy?: string;
  houseExpenses?: IExpenseGrade[];
  hasPayment?: any;
}

export const defaultValue: Readonly<IExpenseGrade> = {
  type: ExpenseType.IN
};

export interface ISearchForm {
  name?: IStringFilter;
  type?: IStringFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
}

export const defaultSearchForm: Readonly<ISearchForm> = {
  name: {
    contains: ''
  },
  type: {
    equals: ''
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' }
};

export interface IExpenseGradeTree {
  deposit?: IExpenseGrade[];
  lifeFee?: IExpenseGrade[];
  inGrades?: IExpenseGrade[];
  outGrades?: IExpenseGrade[];
  expenseGradesWithMap?: {
    [field: number]: IExpenseGrade;
  };
}

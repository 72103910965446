import { Moment } from 'moment';
import { IAttachment } from 'app/shared/model/attachment.model';
import { IDeductionItem } from 'app/shared/model/deduction-item.model';
import { IContract, defaultValue as contractDefaultValue } from 'app/shared/model/contract.model';
import { IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { IHouse } from './house.model';
// import { ITContract, defaultValue as tContractDefaultValue } from './t-contract.model';

export const enum RefundRentalState {
  WAITING = 'WAITING',
  VERIFIED = 'VERIFIED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}

export interface IRefundRental {
  id?: number;
  auditState?: RefundRentalState; // 状态
  verifiedAt?: Moment;
  house?: IHouse;
  verifiedContent?: string;
  auditAt?: Moment; // 审核时间
  auditDescription?: string; // 审核备注
  auditorName?: string; // 审核人名称
  content?: string; // 申请内容
  refundAmount?: number;
  parkingLotName?: string;
  userName?: string; // 用户名称
  mobile?: string; // 电话
  createdAt?: Moment; // 创建时间
  createdBy?: string; // 创建人
  updatedBy?: string; // 更新人
  updatedAt?: Moment; // 更新时间
  parkingLotId?: number; // 车位id
  pcontractId?: number; // 车位合同id
  userId?: number; // 用户id
  auditorId?: number; // 审核人id
  validatorId?: number;
  attachments?: IAttachment[];
  deductionItems?: IDeductionItem[];
  contract?: IContract;
}

export const defaultValue: Readonly<IRefundRental> = {
  // attachments: [],
  // deductionItems: []
  // contract: contractDefaultValue,
  // tcontract: tContractDefaultValue
};

export interface ISearchForm {
  parkingLotId?: IRangeFilter;
  userId?: IRangeFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
  houseId?: IRangeFilter;
}

export const defaultSearchForm: ISearchForm = {
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  houseId: {
    in: []
  },
  parkingLotId: {
    in: []
  },
  userId: {
    in: []
  }
};

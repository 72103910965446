import { Moment } from 'moment';
import { IBillRecordItem } from 'app/shared/model/bill-record-item.model';
import { IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';

export const enum PaymentMethodType {
  WECHAT = 'WECHAT',
  ALIPAY = 'ALIPAY',
  OFFONLE = 'OFFONLE',
  UNIONPAY = 'UNIONPAY'
}

export interface IBillRecord {
  id?: number;
  number?: string;
  username?: string;
  mobile?: string;
  roomName?: string;
  amount?: number;
  type?: PaymentMethodType;
  source?: string;
  createdAt?: Moment;
  createdBy?: string;
  updatedBy?: string;
  updatedAt?: Moment;
  ordersId?: number;
  paymentId?: number;
  billRecordItems?: IBillRecordItem[];
}

export const defaultValue: Readonly<IBillRecord> = {};

export interface ISearchForm {
  houseRoomId?: IRangeFilter;
  userId?: IRangeFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
  houseId?: IRangeFilter;
  number?: IStringFilter;
}

export const defaultSearchForm: ISearchForm = {
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  houseId: {
    in: []
  },
  houseRoomId: {
    in: []
  },
  userId: {
    in: []
  },
  number: {
    contains: ''
  }
};

import axios from 'axios';

import { SUCCESS } from 'app/shared/reducers/action-type.util';

export const ACTION_TYPES = {
  GET_PROFILE: 'applicationProfile/GET_PROFILE',
  GET_FEEDBACK_TYPES: 'applicationProfile/GET_FEEDBACK_TYPES'
};

export interface IFeedbackType {
  value: string;
  name: string;
}

const initialState = {
  ribbonEnv: '',
  inProduction: true,
  isSwaggerEnabled: false,
  version: '',
  feedbackTypes: [] as IFeedbackType[]
};

export type ApplicationProfileState = Readonly<typeof initialState>;

export default (state: ApplicationProfileState = initialState, action): ApplicationProfileState => {
  switch (action.type) {
    case SUCCESS(ACTION_TYPES.GET_PROFILE):
      const { data } = action.payload;
      return {
        ...state,
        ribbonEnv: data['display-ribbon-on-profiles'],
        inProduction: data.activeProfiles.includes('prod'),
        isSwaggerEnabled: data.activeProfiles.includes('swagger'),
        version: data.build.version
      };
    case SUCCESS(ACTION_TYPES.GET_FEEDBACK_TYPES):
      return {
        ...state,
        feedbackTypes: action.payload.data
      };
    default:
      return state;
  }
};

export const getProfile = () => ({
  type: ACTION_TYPES.GET_PROFILE,
  payload: axios.get('management/info')
});
export const getFeedbackType = () => ({
  type: ACTION_TYPES.GET_FEEDBACK_TYPES,
  payload: axios.get('api/feedbacks/types')
});

import styles from '../../home.less';

import React from 'react';
import { Row, Col, Dropdown, Statistic } from 'antd';

import customIcon from '../static/image/source-icon.png';
import { isMobile } from 'app/shared/util/screen-utils';
import Radar from 'app/shared/components/radar';
import Line from './Line';

interface ISourceProp {
  data: string[]; // 数据
}

export default class Source extends React.Component<ISourceProp, {}> {
  renderIcon(title) {
    return (
      <div>
        <i>
          <img src={customIcon} width={24} />
        </i>
        <span className={styles.icontitle}>{title}</span>
      </div>
    );
  }

  renderTitle() {
    return (
      <Row className={`${styles.rentTitle} ${styles.titleBorder}`}>
        <Col>{this.renderIcon('智慧健康服务')}</Col>
      </Row>
    );
  }

  rederContain() {
    return <Line data={this.props.data} />;
  }

  render() {
    return (
      <div className={`${styles.rent} ${styles.mt16} ${!isMobile() ? styles.ml16 : ''}`}>
        {this.renderTitle()}
        {this.rederContain()}
      </div>
    );
  }
}

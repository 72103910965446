import axios from 'axios';
import * as qs from 'qs';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IBillRecord } from 'app/shared/model/parking-bill-record.model';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';

export const ACTION_TYPES = {
  FETCH_BILLRECORD_LIST: 'billRecord/FETCH_BILLRECORD_LIST',
  FETCH_BILLRECORD: 'billRecord/FETCH_BILLRECORD',
  CREATE_BILLRECORD: 'billRecord/CREATE_BILLRECORD',
  UPDATE_BILLRECORD: 'billRecord/UPDATE_BILLRECORD',
  DELETE_BILLRECORD: 'billRecord/DELETE_BILLRECORD',
  LOAD_BILLRECORD_LIST: 'orders/LOAD_BILLRECORD_LIST',
  RESET: 'billRecord/RESET'
};
// state定义
const initialState = {
  loading: false,
  errorMessage: null,
  fetching: false,
  entities: [] as ReadonlyArray<IBillRecord>,
  entity: defaultValue,
  updating: false,
  loadMore: false,
  totalItems: 0,
  updateSuccess: false
};

export type ParkingBillRecordState = Readonly<typeof initialState>;

// Reducer

export default (state: ParkingBillRecordState = initialState, action): ParkingBillRecordState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_BILLRECORD_LIST):
    case REQUEST(ACTION_TYPES.FETCH_BILLRECORD):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_BILLRECORD):
    case REQUEST(ACTION_TYPES.UPDATE_BILLRECORD):
    case REQUEST(ACTION_TYPES.LOAD_BILLRECORD_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.DELETE_BILLRECORD):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_BILLRECORD_LIST):
    case FAILURE(ACTION_TYPES.FETCH_BILLRECORD):
    case FAILURE(ACTION_TYPES.LOAD_BILLRECORD_LIST):
    case FAILURE(ACTION_TYPES.CREATE_BILLRECORD):
    case FAILURE(ACTION_TYPES.UPDATE_BILLRECORD):
    case FAILURE(ACTION_TYPES.DELETE_BILLRECORD):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_BILLRECORD_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_BILLRECORD):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_BILLRECORD):
    case SUCCESS(ACTION_TYPES.UPDATE_BILLRECORD):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.LOAD_BILLRECORD_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.DELETE_BILLRECORD):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/pbill-records'; // 账单流水

// Actions
// 账单流水列表数据
export const getEntities: ICrudGetListAction<IBillRecord> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_BILLRECORD_LIST,
    payload: axios.get<IBillRecord>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    })
  };
};

export const loadEntities: ICrudGetListAction<IBillRecord> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.LOAD_BILLRECORD_LIST,
    payload: axios.get<IBillRecord>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};
// 账单流水详情
export const getEntity: ICrudGetAction<IBillRecord> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_BILLRECORD,
    payload: axios.get<IBillRecord>(requestUrl)
  };
};
// 创建账单流水
export const createEntity: ICrudPutAction<IBillRecord> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_BILLRECORD,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};
// 更新账单流水
export const updateEntity: ICrudPutAction<IBillRecord> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_BILLRECORD,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};
// 删除账单流水
export const deleteEntity: ICrudDeleteAction<IBillRecord> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_BILLRECORD,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

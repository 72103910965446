/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibied
 * Proprietary and confidential
 */

import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Role from './role-management';
import RoleDetail from './role-management-detail';
import RoleUpdate from './role-management-update';
import RoleDeleteDialog from './role-management-delete-dialog';
import PrivateRoute from 'app/shared/auth/private-route';

const Routes = ({ match }) => (
  <>
    <Switch>
      <PrivateRoute exact path={`${match.url}/new`} component={RoleUpdate} hasAnyAuthorities={['Role#create']} />
      <PrivateRoute exact path={`${match.url}/:id/edit`} component={RoleUpdate} hasAnyAuthorities={['Role#update']}/>
      <PrivateRoute exact path={`${match.url}/:id`} component={RoleDetail} hasAnyAuthorities={['Role#read']}/>
      <PrivateRoute path={match.url} component={Role} hasAnyAuthorities={['Role#read']} />
    </Switch>
    <PrivateRoute path={`${match.url}/:id/delete`} component={RoleDeleteDialog} hasAnyAuthorities={['Role#delete']}/>
  </>
);

export default Routes;

import React from 'react';
import { Card, Icon, Row, Col } from 'antd';
import { isEmpty } from 'lodash';

import { IUser, UserType } from 'app/shared/model/user.model';
import { Translate } from 'react-jhipster';
import { IHouseRoom } from 'app/shared/model/house-room.model';
import UserSearchModal from 'app/shared/components/user-search/modal';

export interface IProps {
  value?: IUser;
  onChange?: (user: IUser) => void;
  readonly?: boolean;
  loading?: boolean;
  type?: UserType[];
}

export interface IState {
  user: IUser;
  userSearchModalVisible?: boolean;
}

export default class UserInfoCard extends React.Component<IProps, IState> {
  static getDerivedStateFromProps(nextProps) {
    // Should be a controlled component.
    if ('value' in nextProps) {
      const user = nextProps.value || {};
      return {
        user: { ...user }
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      user: { ...props.value }
    };
  }

  triggerChange(user: IUser) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(user);
    }
    this.closeUserSearchModal();
  }

  openUserSearchModal = () => {
    const { readonly } = this.props;
    if (readonly) return;
    this.setState({
      userSearchModalVisible: true
    });
  };

  closeUserSearchModal = () => {
    this.setState({
      userSearchModalVisible: false
    });
  };

  selectUser = (user: IUser) => {
    this.setState({
      user
    });
    this.triggerChange(user);
  };

  renderDescription = (mobile, idCard) => (
    <Row>
      <Col>{mobile}</Col>
      <Col>{idCard}</Col>
    </Row>
  );

  renderCard() {
    const { user } = this.state;
    const { readonly, loading } = this.props;
    const cursor = readonly ? 'default' : 'pointer';
    if (loading) {
      return <Card loading />;
    }

    if (isEmpty(user)) {
      return (
        <Card style={{ borderStyle: 'dashed', cursor }} onClick={this.openUserSearchModal}>
          <Icon type="plus" /> <Translate contentKey={'global.placeholder.select'} />
        </Card>
      );
    }
    const idCard = user.idCard ? user.idCard : '暂无身份证';
    return (
      <Card style={{ cursor }} onClick={this.openUserSearchModal}>
        <Card.Meta title={user.name ? user.name : user.login} description={this.renderDescription(user.mobile, idCard)} />
      </Card>
    );
  }

  render() {
    return (
      <>
        {this.renderCard()}
        <UserSearchModal
          type={this.props.type}
          visible={this.state.userSearchModalVisible}
          onCancel={this.closeUserSearchModal}
          onOK={this.selectUser}
        />
      </>
    );
  }
}

import axios from 'axios';
import * as qs from 'qs';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction } from 'react-jhipster';
import { Moment } from 'moment';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { IPropertyRecords } from 'app/shared/model/property-records.model';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_Property_LIST: 'Property/FETCH_Property_LIST',
  LOAD_Property_LIST: 'Property/LOAD_Property_LIST'
};
// state定义
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPropertyRecords>,
  entity: [],
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type PropertyState = Readonly<typeof initialState>;

// Reducer

export default (state: PropertyState = initialState, action): PropertyState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_Property_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_Property_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case FAILURE(ACTION_TYPES.FETCH_Property_LIST):
    case FAILURE(ACTION_TYPES.LOAD_Property_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_Property_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_Property_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    default:
      return state;
  }
};

const apiUrl = '/api/rental-ledger';
// 搜索
export const getEntities: ICrudGetListAction<IPropertyRecords> = (page, size, searchForm) => {
  const requestUrl = `${apiUrl}/list?page=${page}&size=${size}`;

  return {
    type: ACTION_TYPES.FETCH_Property_LIST,
    payload: axios.get<IPropertyRecords>(`${requestUrl}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};
// 导出
export const loadEntities: ICrudGetListAction<IPropertyRecords> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.LOAD_Property_LIST,
    payload: axios.get<IPropertyRecords>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

import { Moment } from 'moment';
import { IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';

export interface IGuide {
  id?: number;
  createdAt?: Moment;
  createdBy?: string;
  updatedBy?: string;
  updatedAt?: Moment;
  attachment?: string;
}

export const defaultValue: Readonly<IGuide> = {};

export interface ISearchForm {
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
}

export const defaultSearchForm: Readonly<ISearchForm> = {
  createdBy: {
    contains: ''
  },
  createdAt: {
    lessOrEqualThan: '',
    greaterOrEqualThan: ''
  }
};

import axios from 'axios';
import * as qs from 'qs';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { defaultValue, IHydropowerRecord } from 'app/shared/model/hydropower_record';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_HYDROPOWERRECORD_LIST: 'hydropowerConfiguration/FETCH_HYDROPOWERRECORD_LIST', // 水电充值列表
  FETCH_HYDROPOWERRECORD_TOTAL: 'hydropowerConfiguration/FETCH_HYDROPOWERRECORD_TOTAL', // 统计
  FETCH_HYDROPOWERRECORDLOG_LIST: 'hydropowerConfiguration/FETCH_HYDROPOWERRECORDLOG_LIST', // 详情
  RESET: 'hydropowerConfiguration/RESET' // 重置
};
// state
const initialState = {
  loading: false, // 是否加载
  fetching: false, // 是否加载
  loadMore: false, // 是否加载更多
  errorMessage: null, // 错误信息
  entities: [] as ReadonlyArray<IHydropowerRecord>, // 列表内容
  mobileEntities: [] as ReadonlyArray<IHydropowerRecord>, // 列表内容
  useElectricityAmount: null, // 用电金额
  useWaterAmount: null, // 用水金额
  useElectricityMeter: null, // 用电度数
  useWaterMeter: null, // 用水度数
  totalFetching: false, // 统计loading
  logList: [] as ReadonlyArray<IHydropowerRecord>, // 日志内容
  entity: defaultValue, // 初始化内容
  updating: false, // 是否更新
  totalItems: 0, // 总条数
  updateSuccess: false // 更新是否成功
};

export type hydropowerRecordState = Readonly<typeof initialState>;

// Reducer
export default (state: hydropowerRecordState = initialState, action): hydropowerRecordState => {
  switch (action.type) {
    // 接口开始
    case REQUEST(ACTION_TYPES.FETCH_HYDROPOWERRECORD_LIST):
    case REQUEST(ACTION_TYPES.FETCH_HYDROPOWERRECORD_TOTAL):
    case REQUEST(ACTION_TYPES.FETCH_HYDROPOWERRECORDLOG_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true,
        logList: [],
        totalFetching: true
      };
    // 接口失败
    case FAILURE(ACTION_TYPES.FETCH_HYDROPOWERRECORD_LIST):
    case FAILURE(ACTION_TYPES.FETCH_HYDROPOWERRECORD_TOTAL):
    case FAILURE(ACTION_TYPES.FETCH_HYDROPOWERRECORDLOG_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
        logList: [],
        totalFetching: true
      };
    // 接口成功
    case SUCCESS(ACTION_TYPES.FETCH_HYDROPOWERRECORD_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data || [],
        mobileEntities: state.mobileEntities.concat(action.payload.data),

        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_HYDROPOWERRECORDLOG_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        logList: action.payload.data || [],
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_HYDROPOWERRECORD_TOTAL):
      return {
        ...state,
        useElectricityAmount: action.payload.data.useElectricityAmount,
        useWaterAmount: action.payload.data.useWaterAmount,
        useElectricityMeter: action.payload.data.useElectricityMeter,
        useWaterMeter: action.payload.data.useWaterMeter,
        totalFetching: false
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
// Actions
// 水电管理，列表
export const getEntities: ICrudGetListAction<IHydropowerRecord> = (page, size, sort, searchForm) => {
  const requestUrl = `api/house-room-water-electricity-use-list?page=${page}&size=${size}`;
  return {
    type: ACTION_TYPES.FETCH_HYDROPOWERRECORD_LIST,
    payload: axios.get<IHydropowerRecord>(`${requestUrl}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params)
    }),
    meta: { transformUserName: true }
  };
};
// 统计
export const getTotal = searchForm => ({
  type: ACTION_TYPES.FETCH_HYDROPOWERRECORD_TOTAL,
  payload: axios.get(`api/house-room-water-electricity-use-list-statistics`, {
    params: flattenKey({ ...searchForm, size: 999999 }),
    paramsSerializer: params => qs.stringify(params)
  })
});
// 搜索重置
// 水电管理详情
export const getLogEntity: ICrudGetListAction<IHydropowerRecord> = (page, size, sort, searchForm) => {
  const requestUrl = `api/water-electricity-controls-log-list?page=${page}&size=${size}`;
  return {
    type: ACTION_TYPES.FETCH_HYDROPOWERRECORDLOG_LIST,
    payload: axios.get<IHydropowerRecord>(`${requestUrl}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params)
    }),
    meta: { transformUserName: true }
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

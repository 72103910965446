import { IUser } from 'app/shared/model/user.model';

export interface IUserGroup {
  id?: number;
  name?: string;
  ancestry?: string;
  users?: IUser[];
}

export const defaultValue: Readonly<IUserGroup> = {};

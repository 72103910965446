import axios from 'axios';
import * as qs from 'qs';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, ISignSetting } from 'app/shared/model/sign-setting.model';
import { ICrudGetListAction } from 'app/shared/model/list';
import { IRenewal } from 'app/shared/model/renewal.model';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_SIGNSETTING_LIST: 'signSetting/FETCH_SIGNSETTING_LIST',
  LOAD_SIGNSETTING_LIST: 'signSetting/LOAD_SIGNSETTING_LIST',
  FETCH_SIGNSETTING: 'signSetting/FETCH_SIGNSETTING',
  CREATE_SIGNSETTING: 'signSetting/CREATE_SIGNSETTING',
  UPDATE_SIGNSETTING: 'signSetting/UPDATE_SIGNSETTING',
  DELETE_SIGNSETTING: 'signSetting/DELETE_SIGNSETTING',
  SET_BLOB: 'signSetting/SET_BLOB',
  RESET: 'signSetting/RESET'
};
// state
const initialState = {
  loading: false,
  loadMore: false,
  fetching: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ISignSetting>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0
};

export type SignSettingState = Readonly<typeof initialState>;

// Reducer

export default (state: SignSettingState = initialState, action): SignSettingState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SIGNSETTING_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_SIGNSETTING_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_SIGNSETTING):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_SIGNSETTING):
    case REQUEST(ACTION_TYPES.DELETE_SIGNSETTING):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case REQUEST(ACTION_TYPES.UPDATE_SIGNSETTING):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        entity: action.meta.entity
      };
    case FAILURE(ACTION_TYPES.FETCH_SIGNSETTING_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SIGNSETTING):
    case FAILURE(ACTION_TYPES.CREATE_SIGNSETTING):
    case FAILURE(ACTION_TYPES.UPDATE_SIGNSETTING):
    case FAILURE(ACTION_TYPES.DELETE_SIGNSETTING):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_SIGNSETTING_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_SIGNSETTING_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_SIGNSETTING):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_SIGNSETTING):
    case SUCCESS(ACTION_TYPES.UPDATE_SIGNSETTING):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_SIGNSETTING):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.SET_BLOB:
      const { name, data, contentType } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType
        }
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/sign-settings'; // 签约配置

// Actions
// 签约配置列表
export const getEntities: ICrudGetListAction<ISignSetting> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_SIGNSETTING_LIST,
    payload: axios.get<IRenewal>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const loadEntities: ICrudGetListAction<ISignSetting> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.LOAD_SIGNSETTING_LIST,
    payload: axios.get<IRenewal>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};
// 签约配置详情
export const getEntity: ICrudGetAction<ISignSetting> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SIGNSETTING,
    payload: axios.get<ISignSetting>(requestUrl)
  };
};
// 新增签约配置
export const createEntity: ICrudPutAction<ISignSetting> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SIGNSETTING,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};
// 更新签约配置
export const updateEntity: ICrudPutAction<ISignSetting> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SIGNSETTING,
    payload: axios.put(apiUrl, cleanEntity(entity)),
    meta: { entity: cleanEntity(entity) }
  });
  dispatch(getEntities());
  return result;
};
// 删除签约配置
export const deleteEntity: ICrudDeleteAction<ISignSetting> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SIGNSETTING,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType
  }
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

import axios from 'axios';
import * as qs from 'qs';
import { ICrudGetAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IHouseLayout, ISearchForm, defaultSearchForm } from 'app/shared/model/house-layout.model';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_HOUSELAYOUT_LIST: 'houseLayout/FETCH_HOUSELAYOUT_LIST',
  LOAD_HOUSELAYOUT_LIST: 'houseLayout/LOAD_HOUSELAYOUT_LIST',
  FETCH_HOUSELAYOUT: 'houseLayout/FETCH_HOUSELAYOUT',
  CREATE_HOUSELAYOUT: 'houseLayout/CREATE_HOUSELAYOUT',
  UPDATE_HOUSELAYOUT: 'houseLayout/UPDATE_HOUSELAYOUT',
  DELETE_HOUSELAYOUT: 'houseLayout/DELETE_HOUSELAYOUT',
  UPDATE_SERACH: 'houseLayout/UPDATE_SERACH',
  RESET: 'houseLayout/RESET',
  RESET_ENTITY: 'expenseGrade/RESET_ENTITY'
};
// state定义
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IHouseLayout>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  total: 0,
  searchData: {
    page: 0,
    size: 20,
    sort: 'createdAt,desc',
    searchForm: defaultSearchForm
  }
};

export type HouseLayoutState = Readonly<typeof initialState>;

// Reducer

export default (state: HouseLayoutState = initialState, action): HouseLayoutState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_HOUSELAYOUT_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_HOUSELAYOUT_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_HOUSELAYOUT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_HOUSELAYOUT):
    case REQUEST(ACTION_TYPES.UPDATE_HOUSELAYOUT):
    case REQUEST(ACTION_TYPES.DELETE_HOUSELAYOUT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_HOUSELAYOUT_LIST):
    case FAILURE(ACTION_TYPES.LOAD_HOUSELAYOUT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_HOUSELAYOUT):
    case FAILURE(ACTION_TYPES.CREATE_HOUSELAYOUT):
    case FAILURE(ACTION_TYPES.UPDATE_HOUSELAYOUT):
    case FAILURE(ACTION_TYPES.DELETE_HOUSELAYOUT):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_HOUSELAYOUT_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        total: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_HOUSELAYOUT_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        total: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_HOUSELAYOUT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_HOUSELAYOUT):
    case SUCCESS(ACTION_TYPES.UPDATE_HOUSELAYOUT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_HOUSELAYOUT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.UPDATE_SERACH:
      return {
        ...state,
        searchData: action.payload.data
      };
    case ACTION_TYPES.RESET_ENTITY:
      return {
        ...state,
        entity: defaultValue
      };
    default:
      return state;
  }
};

const apiUrl = 'api/house-layouts'; // 户型配置

// Actions
// 户型配置列表
export const getEntities = (page = 1, size = 2, sort = 'createdAt,desc', searchForm: ISearchForm) => dispatch => {
  dispatch({
    type: ACTION_TYPES.UPDATE_SERACH,
    payload: {
      searchData: {
        page,
        size,
        sort,
        searchForm
      }
    }
  });
  return dispatch({
    type: ACTION_TYPES.FETCH_HOUSELAYOUT_LIST,
    payload: axios.get<IHouseLayout>(
      `${apiUrl}${
        sort ? `?page=${page}&size=${size}&sort=${sort}&cacheBuster=${new Date().getTime()}` : `?cacheBuster=${new Date().getTime()}`
      }`,
      {
        params: flattenKey(searchForm),
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
      }
    )
  });
};

export const getEntityList = (searchForm: ISearchForm) => dispatch =>
  dispatch({
    type: ACTION_TYPES.FETCH_HOUSELAYOUT_LIST,
    payload: axios.get<IHouseLayout>(`${apiUrl}${`?page=0&size=100&sort=createdAt,desc&cacheBuster=${new Date().getTime()}`}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    })
  });

export const loadEntities = (page = 1, size = 2, sort = 'createdAt,desc', searchForm: ISearchForm) => dispatch => {
  dispatch({
    type: ACTION_TYPES.UPDATE_SERACH,
    payload: {
      searchData: {
        page,
        size,
        sort,
        searchForm
      }
    }
  });
  return dispatch({
    type: ACTION_TYPES.LOAD_HOUSELAYOUT_LIST,
    payload: axios.get<IHouseLayout>(
      `${apiUrl}${
        sort ? `?page=${page}&size=${size}&sort=${sort}&cacheBuster=${new Date().getTime()}` : `?cacheBuster=${new Date().getTime()}`
      }`,
      {
        params: flattenKey(searchForm),
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
      }
    )
  });
};

export const getEntity: ICrudGetAction<IHouseLayout> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_HOUSELAYOUT,
    payload: axios.get<IHouseLayout>(requestUrl)
  };
};
// 创建户型配置
export const createEntity = entity => async (dispatch, getState) => {
  const {
    houseLayout: { searchData }
  } = getState();
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_HOUSELAYOUT,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities(searchData.page, searchData.size, searchData.sort, searchData.searchForm));
  return result;
};
// 更新户型配置
export const updateEntity = entity => async (dispatch, getState) => {
  const {
    houseLayout: { searchData }
  } = getState();
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_HOUSELAYOUT,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities(searchData.page, searchData.size, searchData.sort, searchData.searchForm));
  return result;
};
// 更新户型配置
export const updateEntityWithRoom = (roomId, entity) => async (dispatch, getState) => {
  const {
    houseLayout: { searchData }
  } = getState();
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_HOUSELAYOUT,
    payload: axios.put(`api/house-layout-with-room/${roomId}`, cleanEntity(entity))
  });
  return result;
};
// 删除户型配置
export const deleteEntity = id => async (dispatch, getState) => {
  const {
    houseLayout: { searchData }
  } = getState();
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_HOUSELAYOUT,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities(searchData.page, searchData.size, searchData.sort, searchData.searchForm));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const resetEntity = () => ({
  type: ACTION_TYPES.RESET_ENTITY
});

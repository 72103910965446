/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import styles from './login.less';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Alert, Button, Checkbox, Form, Icon, Input, Spin } from 'antd';
import { Translate, translate } from 'react-jhipster';

import Captcha from '../captcha/captcha';

import { IRootState } from 'app/shared/reducers';
import { login, login_cap, getCaptcha } from 'app/shared/reducers/authentication';
import Result from 'app/shared/components/result';
import { getOnceCaptcha } from 'app/shared/common/captcha.reducer';

export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps<{}> {
  form: any;
}

export interface ILoginState {
  updateCaptcha: boolean;
  captcha?: {
    captchaCode?: string;
    captchaBase64Str?: string;
  };
}

const FormItem = Form.Item;

export class Login extends React.Component<ILoginProps, ILoginState> {
  constructor(props) {
    super(props);

    this.state = {
      updateCaptcha: false,
      captcha: {
        captchaCode: '',
        captchaBase64Str: ''
      }
    };
  }

  handleLogin = (username, password, captcha, rememberMe = false) => {
    this.props.login_cap(username, password, captcha, rememberMe);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { userName, password, captcha, remember } = values;
        this.setState(
          (state, props) => ({
            updateCaptcha: false
          }),
          () => {
            this.handleLogin(userName, password, captcha, remember);
          }
        );
      }
    });
  };

  componentDidUpdate() {
    // if (!this.state.updateCaptcha) {
    //   this.setState(
    //     (state, props) => ({
    //       updateCaptcha: true
    //     }),
    //     () => {
    //       this.handleCaptcha();
    //     }
    //   );
    // }
    if (!this.state.updateCaptcha) {
      this.handleCaptcha();
    }
  }

  handleCaptcha = () => {
    this.setState(
      {
        updateCaptcha: true
      },
      async () => {
        // this.props.getCaptcha();
        const res = await getOnceCaptcha();
        // console.log(res);
        if (res.status === 200) {
          this.setState({
            captcha: { ...res.data }
          });
        }
      }
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { location, isAuthenticated, loading } = this.props;
    const { from } = location.state || { from: { pathname: '/', search: window.location.search } };
    const { updateCaptcha } = this.state;
    if (loading) {
      return (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: '#fff',
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Spin />
        </div>
      );
    }
    if (isAuthenticated) {
      return <Redirect to={from} />;
    } else {
      if (!updateCaptcha) {
        this.handleCaptcha();
      }
    }
    return (
      <div className={styles.main}>
        <div className={styles.login}>
          <Form onSubmit={this.handleSubmit}>
            {this.props.loginError ? (
              <Alert message={translate('login.messages.error.authentication')} className={styles.alert} closable type="error" />
            ) : null}
            <FormItem>
              {getFieldDecorator('userName', {
                rules: [{ required: true, message: translate('login.form.username.require') }]
              })(
                <Input
                  size={'large'}
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder={translate('login.form.username.placeholder')}
                  className={styles.prefixIcon}
                  autoComplete={'off'}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: translate('login.form.username.require') }]
              })(
                <Input
                  size="large"
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder={translate('login.form.password.placeholder')}
                  className={styles.prefixIcon}
                  autoComplete={'off'}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('captcha', {
                rules: [{ required: true, message: translate('login.form.captcha.require') }]
              })(
                <div>
                  <div style={{ marginBottom: 16 }}>
                    <Input
                      size={'large'}
                      prefix={<Icon type="safety-certificate" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder={translate('login.form.captcha.placeholder')}
                      className={styles.prefixIcon}
                      autoComplete={'off'}
                      addonAfter={<Captcha src={this.state.captcha.captchaBase64Str} onClick={this.handleCaptcha} />}
                      defaultValue=""
                    />
                  </div>
                </div>
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('remember', {
                valuePropName: 'checked',
                initialValue: true
              })(
                <Checkbox>
                  <Translate contentKey={'login.form.rememberme'} />
                </Checkbox>
              )}
              <Button type="primary" htmlType="submit" size={'large'} className={styles.submit}>
                <Translate contentKey={'login.form.button'} />
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loginError: authentication.loginError,
  showModal: authentication.showModalLogin,
  loading: authentication.loading,
  captcha: authentication.captcha
});

const mapDispatchToProps = { login_cap, getCaptcha };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const WrappedNormalLoginForm = Form.create()(Login);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedNormalLoginForm);

import { omit } from 'lodash';

/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export function flattenKey(obj, parentKey = null) {
  const resultObj = {};
  if (!obj) {
    return resultObj;
  }
  Object.entries(obj).forEach(([key, val]) => {
    if (val === null || val === undefined || val === '') return;
    let fullKey = key;
    if (parentKey) {
      fullKey = `${parentKey}.${fullKey}`;
    }
    if (Array.isArray(val)) {
      resultObj[fullKey] = val;
      return;
    }
    if (typeof val === 'object') {
      Object.assign(resultObj, flattenKey(val, fullKey));
      return;
    }
    resultObj[fullKey] = val;
  });
  return resultObj;
}

export const flatten = obj => {
  const array = Array.isArray(obj) ? obj : [obj];
  return array.reduce((acc, value) => {
    acc.push(omit(value, 'routes'));
    if (value.routes) {
      acc = acc.concat(flatten(value.routes));
    }
    return acc;
  }, []);
};

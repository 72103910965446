import { Moment } from 'moment';
import { IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';

export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING'
}

export interface IChangeParking {
  id?: number; // 编号
  auitState?: AuditState;
  auditAt?: Moment; // 审核时间
  auditDescription?: string; // 审核备注
  createdAt?: Moment; // 创建时间
  createdBy?: string; // 创建人
  updatedBy?: string; // 更新人
  updatedAt?: Moment; // 更新时间
  parkingLotId?: number; // 车位id
  content?: string; // 备注
  houseRoomId?: number; // 房号id
  pcontractId?: number; // 车位合同id
  userId?: number; // 操作人id
  auditorId?: number; // 审核人id
  auditorName?: string; // 审核人名称
  auditState?: string; // 审核状态
}

export const defaultValue: Readonly<IChangeParking> = {};

export interface ISearchForm {
  parkingLotId?: IRangeFilter;
  userId?: IRangeFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
  houseId?: IRangeFilter;
}

export const defaultSearchForm: ISearchForm = {
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  houseId: {
    in: []
  },
  parkingLotId: {
    greaterThan: '0'
  },
  userId: {
    in: []
  }
};

import { SAVE_LOCAL, AUTH_TOKEN_KEY, AUTH_REFRESH_TOKEN_KEY } from 'app/config/constants';
import { getKey, removeKey, setKey } from './localstore-utils';

export const decodeJWT = token => {
  if (token) {
    const strings = token.split('.'); // 截取token，获取载体
    return JSON.parse(decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, '+').replace(/_/g, '/')))));
  } else {
    return {};
  }
};

// token 默认30分钟
// export const getTokenOvertime = () => new Date().valueOf() + 30 * 60 * 1000;
export const getTokenOvertime = token => {
  const userInfo = decodeJWT(token);
  return userInfo.exp ? userInfo.exp : new Date().valueOf() + 30 * 60 * 1000;
};

// refresh token 默认 30 天
// export const getRefreshTokenOvertime = () => new Date().valueOf() + 30 * 24 * 60 * 60 * 1000;
export const getRefreshTokenOvertime = refreshToken => {
  const userInfo = decodeJWT(refreshToken);
  return userInfo.exp ? userInfo.exp : new Date().valueOf() + 30 * 24 * 60 * 60 * 1000;
};

// 检测token是否过期
export const checkOverTime = token => {
  if (token) {
    const userInfo = decodeJWT(token);
    const nowTime = new Date().getTime() / 1000;
    const exp = userInfo.exp;
    return exp < nowTime;
  } else {
    return false;
  }
};

// 存储 token 和 refreshToken
export const setToken = (rememberMe, token, refreshToken) => {
  setKey(AUTH_REFRESH_TOKEN_KEY, refreshToken, 'LOCAL');
  if (rememberMe) {
    setKey(SAVE_LOCAL, true, 'LOCAL');
    setKey(AUTH_TOKEN_KEY, token, 'LOCAL');
  } else {
    setKey(SAVE_LOCAL, false, 'LOCAL');
    setKey(AUTH_TOKEN_KEY, token, 'COOKIE');
    setKey(AUTH_TOKEN_KEY, token, 'SESSION');
  }
};

// 获取token相关信息
export const getToken = () => ({
  token: getKey(AUTH_TOKEN_KEY, 'LOCAL') || getKey(AUTH_TOKEN_KEY, 'SESSION'),
  refreshToken: getKey(AUTH_REFRESH_TOKEN_KEY, 'LOCAL')
});

export const removeToken = () => {
  if (getKey(AUTH_TOKEN_KEY, 'LOCAL')) {
    removeKey(AUTH_TOKEN_KEY, 'LOCAL');
    removeKey(AUTH_REFRESH_TOKEN_KEY, 'LOCAL');
  }
  if (getKey(AUTH_TOKEN_KEY, 'SESSION')) {
    removeKey(AUTH_TOKEN_KEY, 'SESSION');
  }
  if (getKey(AUTH_TOKEN_KEY, 'COOKIE')) {
    removeKey(AUTH_TOKEN_KEY, 'COOKIE');
  }

  removeKey(SAVE_LOCAL);
};

import { Moment } from 'moment';
import { IOrders } from 'app/shared/model/orders.model';
import { IContractExpense } from 'app/shared/model/contract-expense.model';
import { IContractFacilities } from 'app/shared/model/contract-facilites.model';
import { ILiveInfo } from 'app/shared/model/live-info.model';
import { IUser } from 'app/shared/model/user.model';
import { IParkingRoom } from 'app/shared/model/parking-room.model';
import { IParking } from 'app/shared/model/parking.model';
import { IFilter, IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { IAttachment } from 'app/shared/model/attachment.model';
import { IHouseRoom } from 'app/shared/model/house-room.model';
import { IContractFacilitiesTree } from 'app/shared/model/facilities.model';

export const enum ContractState {
  WAITING = 'WAITING',
  RENT_OUT = 'RENT_OUT',
  END = 'END',
  NULLIFIED = 'NULLIFIED',
  PREREFUNDED = 'PREREFUNDED' // 已提前退租
}

export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING'
}

export const enum CalcExpense {
  FIXED = 'FIXED',
  NATRUE = 'NATRUE'
}

export const enum ContractCycle {
  REAL = 'REAL',
  NATURE = 'NATURE'
}

export const enum ContractPushStatus {
  UNPUSH = 'UNPUSH',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS'
}

export interface Itcontract {
  id?: number;
  code?: string;
}

export interface IContractExpenseDivert {
  deposit?: IContractExpense[];
  lifeFee?: IContractExpense[];
  inGrades?: IContractExpense[];
}

export interface IPakingContract {
  contractFacilities?: IContractFacilities[];
  id?: number;
  code?: string;
  paymentCycle?: number;
  cycle?: ContractCycle;
  signSettingId?: number;
  singleMonthPrice?: number;
  state?: ContractState;
  belatedInterest?: number;
  belatedCycle?: number;
  startAt?: Moment;
  endAt?: Moment;
  auditState?: AuditState;
  auditAt?: Moment;
  auditor?: IUser;
  signAt?: Moment;
  carType?: string; // 车型
  prefLicense?: string;
  suffixLicense?: string;
  carNoColor?: string; // 车牌颜色
  purchaseMonth?: number; // 月份
  auditDescription?: string;
  ammeter?: number;
  waterMeter?: number;
  houseRoom?: IHouseRoom;
  house?: IHouseRoom;
  gasMeter?: number;
  name?: string;
  idCard?: string;
  guidPrice?: string; // 指导价格
  idCardFront?: string;
  idCardBackend?: string;
  idCardWithHandle?: string;
  signImage?: string;
  lessorIdCard?: string;
  lessorIdCardFront?: string;
  lessorIdCardBackend?: string;
  lessorIdCardWithHandle?: string;
  lessorSignImage?: string;
  emergencyContact?: string;
  emergencyMobile?: string;
  extraDescription?: string;
  firstBillDay?: number;
  billDay?: number;
  houseRoomId?: number;
  parkingLotId?: number; // 车位id
  calcExpense?: CalcExpense;
  pcontractImage?: string; // 合同影像
  safetyCommitment?: string;
  supplementaryAgreement?: string;
  createdAt?: Moment;
  createdBy?: string;
  updatedBy?: string;
  updatedAt?: Moment;
  userId?: number;
  user?: IUser;
  houseId?: number;
  parking?: IParkingRoom;
  licensePlateNumber?: string; // 车牌号
  auditorId?: number;
  rentRangeAt?: [Moment, Moment];
  academicCert?: string;
  academicCert2?: string;
  academicCert3?: string;
  academicCerts?: IAttachment[];
  contractPushStatus?: ContractPushStatus;
  parkingLot?: IParkingRoom;
  noHouseCert?: string;
  socialInsurance?: string;
  prefundPcontractId?: number;
  contractExpenses?: IContractExpenseDivert;
  wqdjh?: string;
  badjh?: string;
  parmName?: string;
  parmValue?: string;
  remark?: string;
  showDownloadIcon?: boolean;
}

export const defaultValue: Readonly<IPakingContract> = {
  signSettingId: 2,
  user: undefined,
  paymentCycle: 1,
  cycle: ContractCycle.NATURE,
  firstBillDay: 2,
  contractFacilities: [],
  billDay: 15,
  belatedCycle: 1,
  belatedInterest: 0.1,
  contractPushStatus: ContractPushStatus.UNPUSH,
  singleMonthPrice: 0,
  code: ''
};

export interface ISearchForm {
  state?: IFilter;
  auditState?: IFilter;
  parkingLotId?: IRangeFilter;
  userId?: IRangeFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
  customerQuery?: IStringFilter;
  code?: IStringFilter;
  houseId?: IRangeFilter;
  startAt?: IRangeFilter;
  endAt?: IRangeFilter;
  parkingStatus?: IRangeFilter;
  licensePlateNumber?: IStringFilter;
}

export const defaultSearchForm: ISearchForm = {
  state: {
    in: []
  },
  auditState: {
    in: []
  },
  code: {
    contains: ''
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  startAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  endAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  houseId: {
    in: []
  },
  parkingLotId: {
    in: []
  },
  userId: {
    in: []
  },
  licensePlateNumber: {
    contains: ''
  }
};

import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction, IPayload } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import * as qs from 'qs';

import { IAds, defaultValue, ISearchForm } from 'app/shared/model/ads.model';
import { flattenKey } from 'app/shared/util/object-utils';

export const ACTION_TYPES = {
  FETCH_ADS_LIST: 'ads/FETCH_ADS_LIST',
  LOAD_ADS_LIST: 'ads/LOAD_ADS_LIST',
  FETCH_ADS: 'ads/FETCH_ADS',
  CREATE_ADS: 'ads/CREATE_ADS',
  UPDATE_ADS: 'ads/UPDATE_ADS',
  DELETE_ADS: 'ads/DELETE_ADS',
  RESET: 'ads/RESET'
};

const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IAds>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0
};

export type AdsState = Readonly<typeof initialState>;

// Reducer
// 请求，然后改变state值
export default (state: AdsState = initialState, action): AdsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ADS_LIST):
      return {
        ...state,
        errorMessage: null,
        fetching: true,
        loading: true
      };
    case REQUEST(ACTION_TYPES.LOAD_ADS_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_ADS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_ADS):
    case REQUEST(ACTION_TYPES.UPDATE_ADS):
    case REQUEST(ACTION_TYPES.DELETE_ADS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    // 请求失败
    case FAILURE(ACTION_TYPES.FETCH_ADS_LIST):
    case FAILURE(ACTION_TYPES.LOAD_ADS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ADS):
    case FAILURE(ACTION_TYPES.CREATE_ADS):
    case FAILURE(ACTION_TYPES.UPDATE_ADS):
    case FAILURE(ACTION_TYPES.DELETE_ADS):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    // 请求成功
    case SUCCESS(ACTION_TYPES.FETCH_ADS_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_ADS_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_ADS):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_ADS):
    case SUCCESS(ACTION_TYPES.UPDATE_ADS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_ADS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/ads'; // 广告管理

// Actions
export declare type ICrudGetListAction<T> = (
  page?: number,
  size?: number,
  sort?: string,
  searchForm?: ISearchForm
) => IPayload<T> | ((dispatch: any) => IPayload<T>);
export const getEntities: ICrudGetListAction<IAds> = (page, size, sort, searchForm) => ({
  type: ACTION_TYPES.FETCH_ADS_LIST,
  payload: axios.get<IAds>(`${apiUrl}?cacheBuster=${new Date().getTime()}`, {
    params: flattenKey(searchForm),
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
  })
});

export const loadEntities: ICrudGetListAction<IAds> = (page, size, sort, searchForm) => ({
  type: ACTION_TYPES.LOAD_ADS_LIST,
  payload: axios.get<IAds>(`${apiUrl}?cacheBuster=${new Date().getTime()}`, {
    params: flattenKey(searchForm),
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
  })
});

export const getEntity: ICrudGetAction<IAds> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ADS,
    payload: axios.get<IAds>(requestUrl)
  };
};
// 创建广告管理
export const createEntity: ICrudPutAction<IAds> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ADS,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};
// 更新广告管理
export const updateEntity: ICrudPutAction<IAds> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ADS,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};
// 删除广告管理
export const deleteEntity: ICrudDeleteAction<IAds> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ADS,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

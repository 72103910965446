import { IPayment } from 'app/shared/model/payment.model';

export const enum PaymentMethodType {
  WECHAT = 'WECHAT',
  ALIPAY = 'ALIPAY',
  OFFONLE = 'OFFONLE',
  UNIONPAY = 'UNIONPAY'
}

export interface IPaymentMethod {
  id?: number;
  name?: string;
  type?: PaymentMethodType;
  description?: string;
  activted?: boolean;
  preferences?: string;
  payments?: IPayment[];
}

export const defaultValue: Readonly<IPaymentMethod> = {
  activted: false
};

/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import DescriptionList from './DescriptionList';
import Description from './Description';

DescriptionList.Description = Description;
export default DescriptionList;

import { combineReducers } from 'redux';
import locale, { LocaleState } from './locale';
import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import roleManagement, { RoleManagementState } from 'app/modules/administration/roles/role-management.reducer';
// prettier-ignore
// prettier-ignore
import authorization, { AuthorizationState } from 'app/entities/authorization/authorization.reducer';
// prettier-ignore
import thirdPartyConfig, { ThirdPartyConfigState } from 'app/entities/third-party-config/third-party-config.reducer';
// prettier-ignore
import certification, { CertificationState } from 'app/entities/certification/certification.reducer';
// prettier-ignore
import company, { CompanyState } from 'app/entities/company/company.reducer';
// prettier-ignore
import userGroup, { UserGroupState } from 'app/entities/user-group/user-group.reducer';
// prettier-ignore
import blackList, { BlackListState } from 'app/entities/black-list/black-list.reducer';
// prettier-ignore
import houseLayout, { HouseLayoutState } from 'app/entities/house-layout/house-layout.reducer';
// prettier-ignore
import facilities, { FacilitiesState } from 'app/entities/facilities/facilities.reducer';
// prettier-ignore
import expenseGrade, { ExpenseGradeState } from 'app/entities/expense-grade/expense-grade.reducer';
// prettier-ignore
import setting, { SettingState } from 'app/entities/setting/setting.reducer';
// prettier-ignore
import house, { HouseState } from 'app/entities/house/house.reducer';
// prettier-ignore
// 车位管理
import parking, { ParkingSpacesState } from 'app/entities/parking-spaces/parking-spaces.reducer';
// prettier-ignore
// 车位合同
import parkingContract, { ParkingContractState } from 'app/entities/parking-contract/contract.reducer';
// prettier-ignore
import houseExpense, { HouseExpenseState } from 'app/entities/house-expense/house-expense.reducer';
// prettier-ignore
import regionalDivide, { RegionalDivideState } from 'app/entities/regional-divide/regional-divide.reducer';
// prettier-ignore
import orders, { OrdersState } from 'app/entities/orders/orders.reducer';
// prettier-ignore
import parkingFinancial, { ParkingFinancialState } from 'app/entities/parking-financial/parking-financial.reducer';
// prettier-ignore
import paymentMethod, { PaymentMethodState } from 'app/entities/payment-method/payment-method.reducer';
// prettier-ignore
import payment, { PaymentState } from 'app/entities/payment/payment.reducer';
// prettier-ignore
import lifePrice, { LifePriceState } from 'app/entities/life-price/life-price.reducer';
// prettier-ignore
import maintenance, { MaintenanceState } from 'app/entities/maintenance/maintenance.reducer';
// prettier-ignore
import favorite, { FavoriteState } from 'app/entities/favorite/favorite.reducer';
// prettier-ignore
import history, { HistoryState } from 'app/entities/history/history.reducer';
// prettier-ignore
import notice, { NoticeState } from 'app/entities/notice/notice.reducer';
// prettier-ignore
import messageContent, { MessageContentState } from 'app/entities/message-content/message-content.reducer';
// prettier-ignore
import message, { MessageState } from 'app/entities/message/message.reducer';
// prettier-ignore
import messageTemplate, { MessageTemplateState } from 'app/entities/message-template/message-template.reducer';
// prettier-ignore
import messageGroup, { MessageGroupState } from 'app/entities/message-group/message-group.reducer';
// prettier-ignore
import visit, { VisitState } from 'app/entities/visit/visit.reducer';
// prettier-ignore
import advertising, { AdvertisingState } from 'app/entities/advertising/advertising.reducer';
// prettier-ignore
import ads, { AdsState } from 'app/entities/ads/ads.reducer';
// prettier-ignore
import appointment, { AppointmentState } from 'app/entities/appointment/appointment.reducer';
// prettier-ignore
import contract, { ContractState } from 'app/entities/contract/contract.reducer';
// prettier-ignore
import shareLivePeople, { ShareLivePeopleState } from 'app/entities/share-live-people/share-live-people.reducer';
// prettier-ignore
import changeHouse, { ChangeHouseState } from 'app/entities/change-house/change-house.reducer';
// prettier-ignore
import changeParking, { ChangeParkingState } from 'app/entities/change-parking/change-parking.reducer';
// prettier-ignore
import entityAuditEvent, { EntityAuditEventState } from 'app/entities/entity-audit/entity-audit.reducer';
// prettier-ignore
import recommend, { RecommendState } from 'app/entities/recommend/recommend.reducer';
import marketing, { MarketingState } from 'app/entities/marketing/marketing-reducer';
// prettier-ignore
import signSetting, { SignSettingState } from 'app/entities/sign-setting/sign-setting.reducer';
import appointmentLog, { AppointmentLogState } from 'app/entities/appointment-log/appointment-log.reducer';
import liveInfo, { LiveInfoState } from 'app/entities/live-info/live-info.reducer';

import houseRoom, { HouseRoomState } from 'app/entities/house-room/house-room.reducer';
// prettier-ignore
import renewal, {
  RenewalState
} from 'app/entities/renewal/renewal.reducer';
// prettier-ignore
import parkingRenewal, { parkingRenewalState } from 'app/entities/renewal-parking/renewal.reducer';
// prettier-ignore
import parkingPay, { parkingPayState } from 'app/entities/pay-parking/pay.reducer';
// prettier-ignore
import parkingTemporary, { parkingTemporaryState } from 'app/entities/temporary-parking/temporary.reducer';
// prettier-ignore
import houseSurvey, { houseSurveyState } from 'app/entities/houseSurveyUpdate/houseSurvey.reducer';
// prettier-ignore
import hydropowerConfiguration, { hydropowerConfigurationState } from 'app/entities/hydropower/hydropower_configuration/configuration.reducer';
// prettier-ignore
import hydropowerAccount, { hydropowerAccountState } from 'app/entities/hydropower/hydropower_account/account.reducer';
// prettier-ignore
import hydropowerRecharge, { hydropowerRechargeState } from 'app/entities/hydropower/hydropower_recharge/recharge.reducer';
// prettier-ignore
import hydropowerRecord, { hydropowerRecordState } from 'app/entities/hydropower/hydropower_record/record.reducer';
import transaction, { transactionState } from 'app/entities/health/transaction_statistics/transaction.reducer';
import healthUse, { healthUseState } from 'app/entities/health/user_statistics/user.reducer';
// prettier-ignore
import callSurvey, { callSurveyState } from 'app/entities/callSurvey/callSurvey.reducer';
// prettier-ignore
import staySurvey, { staySurveyState } from 'app/entities/staySurvey/staySurvey.reducer';
// prettier-ignore
import refundRentalSurvey, { refundRentalSurveyState } from 'app/entities/refundRentalSurvey/refundRentalSurvey.reducer';
// prettier-ignore
import RefundRentalParking, { RefundRentalParkingState } from 'app/entities/refund-rental-parking/refund-rental.reducer';
// prettier-ignore
import refundRental, {
  RefundRentalState
} from 'app/entities/refund-rental/refund-rental.reducer';
import property, { PropertyState } from 'app/entities/property_records/property-records.reducer';
import propertyDetails, { PropertyDetailState } from 'app/entities/property_details_records/property-details-records.reducer';
import deductionItem, { DeductionItemState } from 'app/entities/deduction-item/deduction-item.reducer';
import billRecord, { BillRecordState } from 'app/entities/bill-record/bill-record.reducer';
import parkingBillRecord, { ParkingBillRecordState } from 'app/entities/parking-bill-record/bill-record.reducer';
import billRecordItem, { BillRecordItemState } from 'app/entities/bill-record-item/bill-record-item.reducer';
import feedback, { FeedbackState } from 'app/entities/feedback/feedback.reducer';
import orderStatistical, { StatisticalState } from 'app/entities/orders-statistical/statistical.reducer';
import statisticalYear, { StatisticalYearState } from 'app/entities/investment-statistics/statistics.reducer';
import allStatistical, { AllStatisticalState } from 'app/entities/month-statistics/statistics.reducer';
// prettier-ignore
import popularize, {
  PopularizeState
} from 'app/entities/popularize/popularize.reducer';
// prettier-ignore
import popularizeRecord, {
  PopularizeRecordState
} from 'app/entities/popularize-record/popularize-record.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */
import city, { CityState } from 'app/entities/city/city.reducer';
// prettier-ignore
import settle, {
  SettleState
} from 'app/entities/settle/settle.reducer';
import faceAuthRecord, { FaceAuthRecordState } from 'app/entities/face-auth-record/face-auth-record.reducer';
import guide, { GuideState } from 'app/entities/guide/guide.reducer';
// prettier-ignore
import news, {
  NewsState
} from 'app/entities/news/news.reducer';
import customTime, { CustomTimeState } from 'app/entities/custom-time/custom-time.reducer';
import tContract, { TContractState } from 'app/entities/t-contract/t-contract.reducer';

import home, { HomeState } from 'app/entities/home/home.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */
export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly home: HomeState;
  readonly locale: LocaleState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly roleManagement: RoleManagementState;
  readonly authorization: AuthorizationState;
  readonly thirdPartyConfig: ThirdPartyConfigState;
  readonly certification: CertificationState;
  readonly company: CompanyState;
  readonly userGroup: UserGroupState;
  readonly blackList: BlackListState;
  readonly houseLayout: HouseLayoutState;
  readonly facilities: FacilitiesState;
  readonly expenseGrade: ExpenseGradeState;
  readonly setting: SettingState;
  readonly house: HouseState;
  readonly houseExpense: HouseExpenseState;
  readonly regionalDivide: RegionalDivideState;
  readonly orders: OrdersState;
  readonly parkingFinancial: ParkingFinancialState;
  readonly paymentMethod: PaymentMethodState;
  readonly payment: PaymentState;
  readonly lifePrice: LifePriceState;
  readonly maintenance: MaintenanceState;
  readonly favorite: FavoriteState;
  readonly history: HistoryState;
  readonly notice: NoticeState;
  readonly messageContent: MessageContentState;
  readonly message: MessageState;
  readonly messageTemplate: MessageTemplateState;
  readonly messageGroup: MessageGroupState;
  readonly visit: VisitState;
  readonly advertising: AdvertisingState;
  readonly ads: AdsState;
  readonly appointment: AppointmentState;
  readonly contract: ContractState;
  readonly shareLivePeople: ShareLivePeopleState;
  readonly changeHouse: ChangeHouseState;
  readonly changeParking: ChangeParkingState;
  readonly entityAuditEvent: EntityAuditEventState;
  readonly signSetting: SignSettingState;
  readonly recommend: RecommendState;
  readonly marketing: MarketingState;
  readonly appointmentLog: AppointmentLogState;
  readonly liveInfo: LiveInfoState;
  readonly houseRoom: HouseRoomState;
  readonly parking: ParkingSpacesState;
  readonly parkingContract: ParkingContractState;
  readonly renewal: RenewalState;
  readonly parkingRenewal: parkingRenewalState;
  readonly parkingPay: parkingPayState;
  readonly parkingTemporary: parkingTemporaryState;
  readonly houseSurvey: houseSurveyState;
  readonly hydropowerConfiguration: hydropowerConfigurationState;
  readonly hydropowerAccount: hydropowerAccountState;
  readonly hydropowerRecharge: hydropowerRechargeState;
  readonly hydropowerRecord: hydropowerRecordState;
  readonly transaction: transactionState;
  readonly healthUse: healthUseState;
  readonly callSurvey: callSurveyState;
  readonly staySurvey: staySurveyState;
  readonly refundRentalSurvey: refundRentalSurveyState;
  readonly RefundRentalParking: RefundRentalParkingState;
  readonly refundRental: RefundRentalState;
  readonly property: PropertyState;
  readonly propertyDetails: PropertyDetailState;
  readonly deductionItem: DeductionItemState;
  readonly billRecord: BillRecordState;
  readonly parkingBillRecord: ParkingBillRecordState;
  readonly billRecordItem: BillRecordItemState;
  readonly feedback: FeedbackState;
  readonly orderStatistical: StatisticalState;
  readonly statisticalYear: StatisticalYearState;
  readonly allStatistical: AllStatisticalState;
  readonly popularize: PopularizeState;
  readonly popularizeRecord: PopularizeRecordState;
  readonly city: CityState;
  readonly settle: SettleState;
  readonly guide: GuideState;
  readonly faceAuthRecord: FaceAuthRecordState;
  readonly news: NewsState;
  readonly customTime: CustomTimeState;
  readonly tContract: TContractState;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  home,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  roleManagement,
  authorization,
  thirdPartyConfig,
  certification,
  company,
  userGroup,
  blackList,
  houseLayout,
  facilities,
  expenseGrade,
  setting,
  house,
  houseExpense,
  regionalDivide,
  orders,
  parkingFinancial,
  paymentMethod,
  payment,
  lifePrice,
  maintenance,
  favorite,
  history,
  notice,
  messageContent,
  message,
  messageTemplate,
  messageGroup,
  visit,
  advertising,
  ads,
  parkingRenewal,
  parkingPay,
  parkingTemporary,
  houseSurvey,
  hydropowerConfiguration,
  hydropowerAccount,
  hydropowerRecharge,
  hydropowerRecord,
  transaction,
  healthUse,
  callSurvey,
  staySurvey,
  refundRentalSurvey,
  RefundRentalParking,
  appointment,
  contract,
  shareLivePeople,
  changeHouse,
  changeParking,
  entityAuditEvent,
  signSetting,
  recommend,
  marketing,
  appointmentLog,
  liveInfo,
  houseRoom,
  parking,
  parkingContract,
  renewal,
  refundRental,
  property,
  propertyDetails,
  deductionItem,
  billRecord,
  parkingBillRecord,
  billRecordItem,
  feedback,
  orderStatistical,
  statisticalYear,
  allStatistical,
  popularize,
  popularizeRecord,
  city,
  settle,
  faceAuthRecord,
  guide,
  news,
  customTime,
  tContract
});

export default rootReducer;

import { Moment } from 'moment';
import { IUser } from 'app/shared/model/user.model';
import { IHouse } from 'app/shared/model/house.model';

export interface IRegionalDivide {
  id?: number;
  name?: string;
  ancestry?: string;
  createdAt?: Moment;
  createdBy?: string;
  updatedAt?: Moment;
  updatedBy?: string;
  users?: IUser[];
  houses?: IHouse[];
}

export const defaultValue: Readonly<IRegionalDivide> = {};

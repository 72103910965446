import React from 'react';
import { Button, Col, Form, Input, List, Modal, Row, Select } from 'antd';

import { WrappedFormUtils } from 'antd/lib/form/Form';
import { IPaginationBaseState, translate, Translate } from 'react-jhipster';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { defaultSearchForm, ISearchForm, IUser, UserType } from 'app/shared/model/user.model';
import { defaultsDeep } from 'lodash';
import { IRootState } from 'app/shared/reducers';
import {
  getMobile,
  getNatureName,
  getRoles,
  getUsersSimple as getUsers,
  loadUsersSimple as loadUsers
} from 'app/modules/administration/user-management/user-management.reducer';
import { connect } from 'react-redux';
import ListItemMetaTitle from 'app/shared/components/commons/list-item-meta-title';
import LoadMore from 'app/shared/components/commons/load-more';
import ExpandContainer from 'app/shared/components/expand-container';
import ResponsiveList from 'app/shared/components/responsive-list';

export interface IUserSearchModalProps extends StateProps, DispatchProps {
  onOK?: (user: IUser) => void;
  onCancel?: () => void;
  visible: boolean;
  type?: UserType[];
}

export interface IUserSearchModalState extends IPaginationBaseState {
  searchForm?: ISearchForm;
}

class UserSearchModal extends React.Component<IUserSearchModalProps, IUserSearchModalState> {
  state: IUserSearchModalState = {
    searchForm: defaultsDeep({}, defaultSearchForm),
    ...{
      itemsPerPage: ITEMS_PER_PAGE,
      sort: 'createdAt',
      order: 'desc',
      activePage: 1
    }
  };

  componentDidUpdate(prevProps: Readonly<IUserSearchModalProps>, prevState: Readonly<IUserSearchModalState>, snapshot?: any): void {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.getUsers();
      this.props.getRoles();
    }
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.getUsers());

  getUsers = () => {
    const { activePage, itemsPerPage, sort, order, searchForm } = this.state;
    const { type } = this.props;
    if (type) {
      searchForm['type'] = { in: type };
    }
    this.props.getUsers(activePage - 1, itemsPerPage, `${sort},${order}`, searchForm);
  };

  loadUsers = () => {
    const { activePage, itemsPerPage, sort, order, searchForm } = this.state;
    const { type } = this.props;
    if (type) {
      searchForm['type'] = { in: type };
    }
    this.props.loadUsers(activePage - 1, itemsPerPage, `${sort},${order}`, searchForm);
  };

  handleSearch = form => {
    form.validateFields((err, postValues) => {
      if (!err) {
        const { login, mobile, roles } = postValues;
        this.setState(
          {
            searchForm: defaultsDeep(
              {
                login: {
                  contains: login
                },
                mobile: {
                  contains: mobile
                },
                roles: {
                  in: roles
                }
              },
              defaultSearchForm
            ),
            activePage: 1
          },
          () => this.getUsers()
        );
      }
    });
  };

  handleFormReset = form => {
    form.resetFields();
    this.setState(
      {
        ...this.state,
        searchForm: { ...defaultSearchForm },
        activePage: 1
      },
      () => this.getUsers()
    );
  };

  triggerClick = record => {
    if (this.props.onOK) {
      this.props.onOK(record);
    }
  };

  handleClick = record => () => {
    this.triggerClick(record);
  };

  renderActions = record => (
    <Button.Group>
      <Button onClick={this.handleClick(record)} type="primary" icon="disconnect" ghost>
        <Translate contentKey={'entity.action.select'} />
      </Button>
    </Button.Group>
  );

  getDataColumns() {
    return [
      {
        title: translate('userManagement.login'),
        dataIndex: 'login',
        key: 'login'
      },
      {
        title: translate('userManagement.name'),
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: translate('userManagement.mobile'),
        dataIndex: 'mobile',
        key: 'mobile',
        width: 120
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => this.renderActions(record)
      }
    ];
  }

  renderAdvancedForm(form: WrappedFormUtils<ISearchForm>) {
    const { getFieldDecorator } = form;
    const { searchForm } = this.state;
    const { roles, type } = this.props;
    const rowGutter = {
      md: 12,
      lg: 24,
      xl: 48
    };
    const formItemLayout = {
      xs: 24,
      md: 12
    };
    const handleSearch = () => this.handleSearch(form);
    const handleFormReset = () => this.handleFormReset(form);
    return (
      <div className={'tableListForm'}>
        <Form>
          <Row gutter={rowGutter} type={'flex'}>
            <Col {...formItemLayout}>
              <Form.Item label={translate('userManagement.login')}>
                {getFieldDecorator('login', {
                  initialValue: searchForm.login.contains
                })(<Input placeholder={translate('global.placeholder.input')} />)}
              </Form.Item>
            </Col>
            <Col {...formItemLayout}>
              <Form.Item label={translate('userManagement.mobile')}>
                {getFieldDecorator('mobile', {
                  initialValue: searchForm.mobile.contains
                })(<Input placeholder={translate('global.placeholder.input')} />)}
              </Form.Item>
            </Col>
            {type && type.includes(UserType.BACKEND) && (
              <Col {...formItemLayout}>
                <Form.Item label={translate('userManagement.role')}>
                  {getFieldDecorator('roles', {
                    initialValue: searchForm.roles.in
                  })(
                    <Select mode={'multiple'} allowClear placeholder={translate('global.placeholder.select')}>
                      {roles.map(role => (
                        <Select.Option value={role} key={role}>
                          <Translate contentKey={`roles.${role}`} children={role} />
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            )}
          </Row>
          <div style={{ overflow: 'hidden' }}>
            <span style={{ float: 'right', marginBottom: 24 }}>
              <Button.Group>
                <Button onClick={handleFormReset}>
                  <Translate contentKey={'entity.action.reset'} />
                </Button>
                <Button type="primary" htmlType="button" onClick={handleSearch}>
                  <Translate contentKey={'entity.action.search'} />
                </Button>
              </Button.Group>
            </span>
          </div>
        </Form>
      </div>
    );
  }

  renderItem = record => {
    const title = () => <ListItemMetaTitle left={getNatureName(record)} />;
    return (
      <List.Item key={record.id}>
        <List.Item.Meta title={title()} />
        {getMobile(record)}
      </List.Item>
    );
  };

  onLoadMore = () => {
    const { activePage } = this.state;
    this.setState(
      {
        activePage: activePage + 1
      },
      () => this.loadUsers()
    );
  };

  renderLoadMore() {
    const { loadMore, totalItems, users } = this.props;
    return <LoadMore loadMore={loadMore} totalItems={totalItems} dataSize={users.length} onLoadMore={this.onLoadMore} />;
  }

  renderModal() {
    const { loading, users, totalItems, visible, onCancel, fetching } = this.props;
    const CustomForm = Form.create()(({ form }) => this.renderAdvancedForm(form));
    const handleCancel = () => {
      if (onCancel) {
        onCancel();
      }
    };
    return (
      <Modal visible={visible} onCancel={handleCancel} footer={null} title={'查找用户'}>
        <ExpandContainer>
          <CustomForm />
        </ExpandContainer>
        <ResponsiveList<IUser>
          tableProps={{
            rowKey: 'id',
            loading: loading as boolean,
            dataSource: users.map(d => d),
            columns: this.getDataColumns(),
            pagination: {
              defaultPageSize: this.state.itemsPerPage,
              total: totalItems,
              onChange: this.handlePagination,
              current: this.state.activePage
            }
          }}
          listProps={{
            itemClick: this.triggerClick,
            rowKey: 'id',
            itemLayout: 'vertical',
            loading: fetching,
            loadMore: this.renderLoadMore(),
            dataSource: users.map(d => d),
            renderItem: this.renderItem
          }}
        />
      </Modal>
    );
  }

  render() {
    return this.renderModal();
  }
}

const mapStateToProps = ({ userManagement }: IRootState) => ({
  users: userManagement.simpleUser,
  loading: userManagement.loading,
  fetching: userManagement.fetching,
  loadMore: userManagement.loadMore,
  totalItems: userManagement.totalItems,
  roles: userManagement.authorities
});

const mapDispatchToProps = {
  getUsers,
  getRoles,
  loadUsers
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSearchModal);

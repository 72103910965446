import React from 'react';
import { Modal } from 'antd';
import BlackListUpdate, { IClickHandler } from 'app/entities/black-list/black-list-update';

export interface IBlackDialogProps {
  login: string;
  visible: boolean;
  onClose?: () => void;
}

export interface IBlackDialogState {
  handler: IClickHandler;
}

export default class BlackDialog extends React.Component<IBlackDialogProps> {
  state: IBlackDialogState = {
    handler: null
  };

  submitEvent = () => {
    this.state.handler &&
      this.state.handler.onClick().then(result => {
        this.onCloseEvent();
      });
  };

  onCloseEvent = () => {
    this.props.onClose && this.props.onClose();
  };

  render() {
    const props = {
      login: this.props.login,
      clickEventHandler: (handler: IClickHandler) => {
        this.setState({ handler });
      }
    };
    return (
      <Modal title={'标题'} visible={this.props.visible} onOk={this.submitEvent} onCancel={this.onCloseEvent}>
        {this.props.visible && <BlackListUpdate {...props} />}
      </Modal>
    );
  }
}

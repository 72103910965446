import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ISetting, defaultValue } from 'app/shared/model/setting.model';
// 接口
export const ACTION_TYPES = {
  FETCH_SETTING_LIST: 'setting/FETCH_SETTING_LIST',
  FETCH_SETTING: 'setting/FETCH_SETTING',
  CREATE_SETTING: 'setting/CREATE_SETTING',
  UPDATE_SETTING: 'setting/UPDATE_SETTING',
  DELETE_SETTING: 'setting/DELETE_SETTING',
  RESET: 'setting/RESET'
};
// state
const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ISetting>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false
};

export type SettingState = Readonly<typeof initialState>;

// Reducer

export default (state: SettingState = initialState, action): SettingState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SETTING_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SETTING):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_SETTING):
    case REQUEST(ACTION_TYPES.DELETE_SETTING):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case REQUEST(ACTION_TYPES.UPDATE_SETTING):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        entity: action.meta.entity
      };
    case FAILURE(ACTION_TYPES.FETCH_SETTING_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SETTING):
    case FAILURE(ACTION_TYPES.CREATE_SETTING):
    case FAILURE(ACTION_TYPES.UPDATE_SETTING):
    case FAILURE(ACTION_TYPES.DELETE_SETTING):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_SETTING_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_SETTING):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_SETTING):
    case SUCCESS(ACTION_TYPES.UPDATE_SETTING):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_SETTING):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/settings'; // 计算设置

// Actions
// 计算设置列表
export const getEntities: ICrudGetAllAction<ISetting> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_SETTING_LIST,
  payload: axios.get<ISetting>(`${apiUrl}?cacheBuster=${new Date().getTime()}`)
});

export const getEntity: ICrudGetAction<ISetting> = id => {
  const requestUrl = `${apiUrl}`;
  return {
    type: ACTION_TYPES.FETCH_SETTING,
    payload: axios.get<ISetting>(requestUrl)
  };
};
// 新增计算设置
export const createEntity: ICrudPutAction<ISetting> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SETTING,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};
// 更新计算设置
export const updateEntity: ICrudPutAction<ISetting> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SETTING,
    payload: axios.put(apiUrl, cleanEntity(entity)),
    meta: { entity: cleanEntity(entity) }
  });
  return result;
};
// 删除计算设置
export const deleteEntity: ICrudDeleteAction<ISetting> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SETTING,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

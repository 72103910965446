import React from 'react';
import { Upload, Progress, notification } from 'antd';
import { upload } from 'app/shared/components/CustomerUpload/axios-upload.reducer';
import { UploadProps } from 'antd/es/upload/interface';
import { UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';
import { translate } from 'react-jhipster';
import { MULTITYPE } from 'app/shared/components/CustomerUpload/multimini';

export interface ICustomerUploadProps extends UploadProps {
  preUploadOnchange?: () => void;
  autoUpload?: boolean;
  masterUploadEvent?: (handler: IMasterEventHandler) => void;
  autoUploadAllFinished?: (finished: Array<Promise<any>>) => void;
  previewLocalImg?: IPreviewLocalImg;
  children?: React.ReactNode;
}

export interface IPreviewLocalImg {
  loadLocal?: boolean;
  onPreview?: (img: any) => void;
}

export interface IMasterEventHandler {
  onStart?: () => Array<Promise<any>>;
  key?: string;
}

export interface ICustomerUploadState {
  fileList: UploadFile[];
  uploading: boolean;
  percent?: any;
  key?: any;
}

class CustomerUpload extends React.Component<ICustomerUploadProps, ICustomerUploadState> {
  state: ICustomerUploadState = {
    fileList: [],
    uploading: false,
    percent: 0,
    key: moment().toLocaleString()
  };
  handler: IMasterEventHandler = {
    key: this.state.key,
    onStart: () => this.onStartUpload()
  };

  __uploadProps = {
    fileList: this.state.fileList,
    beforeUpload: file => {
      const { accept } = this.props;
      if (!!accept && !accept.includes(file.type)) {
        this.openNotification(translate(`error.validFileFormat`, { params: `${file.name}上传失败，` }));
        return false;
      }
      this.setState({
        // @ts-ignore
        fileList: this.props.multiple ? [...this.state.fileList, file] : [].concat(file)
      });
      return !!this.props.autoUpload;
    },
    onChange: info => {
      if (!info.file.status) {
        return;
      }
      if (info.file.status === 'error') {
        this.openNotification(info.file.name ? `${info.file.name} 文件上传失败.` : `${info.file.error.response.data.message}`);
        return;
      }
      if (info.file.response && info.file.response.url) {
        info.file.url = info.file.response.url;
        this.props.onPreview && this.props.onPreview(info.file);
      } else {
        if (this.props.previewLocalImg && this.props.previewLocalImg.onPreview) {
          this.getBase64(this.props.autoUpload ? info.file.originFileObj : info.file, imageUrl => {
            this.props.previewLocalImg.onPreview(imageUrl);
          });
        }
        if (this.props.onPreview) {
          this.getBase64(this.props.autoUpload ? info.file.originFileObj : info.file, imageUrl => {
            info.file.url = imageUrl;
            this.props.onPreview(info.file);
          });
        }
      }
      this.props.onChange && this.props.onChange(info);
    }
  };

  constructor(props, state) {
    super(props, state);
    props.masterUploadEvent && props.masterUploadEvent(this.handler);
  }

  openNotification = (message: any) => {
    const args = {
      message: '上传失败',
      description: message
    };
    notification['error'](args);
  };
  getBase64 = (img, callback) => {
    // 不要转base64，会影响提交数据 2021年12月8日
    // if (!!img) {
    //   const reader = new FileReader();
    //   reader.addEventListener('load', () => callback(reader.result));
    //   reader.readAsDataURL(img);
    // }
  };

  onStartUpload = () => {
    const map: Array<Promise<any>> = this.state.fileList.map(item => this.startSingleFileUpload(item, 'file'));
    this.props.autoUploadAllFinished && this.props.autoUploadAllFinished(map);
    return map;
  };

  startSingleFileUpload = (file, filename) => {
    const { action } = this.props;
    const onProgress = data => {
      const { percent } = data;
      if (Number(percent)) {
        this.setState({ percent });
      }
    };
    return new Promise((resolve, reject) => {
      const onSuccess = (response, data) => {
        resolve({ response, data });
      };
      const onError = error => {
        reject(error);
      };
      upload(action, {}, file, filename, {}, () => {}, onProgress, onSuccess, onError);
    });
  };

  customRequest = ({ onProgress, onError, onSuccess, data, filename, file, withCredentials, action, headers }) => {
    const handleProgress = response => {
      const { percent } = response;
      this.setState({ percent });
      onProgress({ event: { percent } }, file);
    };
    const handleError = error => {
      onError(error);
    };
    const handleSuccess = (response, responseData) => {
      onSuccess({ url: response.filename }, file);
    };
    upload(action, data, file, filename, headers, handleError, handleProgress, handleSuccess, withCredentials);
  };
  help = () => {
    if (this.props.accept) {
      return (
        '请上传' +
        this.props.accept
          .split(',')
          .map(item => MULTITYPE[`${item}`.replace(/\s/g, '')])
          .join('、') +
        '类型的文件'
      );
    }
    return '';
  };

  render() {
    const { autoUpload, preUploadOnchange, previewLocalImg, ...restProps } = this.props;
    const uploadFileProps = { ...restProps, ...this.__uploadProps };
    uploadFileProps.style = { ...uploadFileProps.style, ...{ position: 'relative' } };
    const { percent } = this.state;
    const numberPercent = Number(percent) ? Number(percent) : 0;
    return (
      <div style={{ color: 'red' }}>
        <Upload {...uploadFileProps} fileList={this.state.fileList} customRequest={this.customRequest}>
          {this.props.children}
          <div style={{ position: 'absolute', left: '50%', bottom: 0, width: '90%', transform: 'translateX(-50%)' }}>
            {numberPercent && this.state.percent > 0 && this.state.percent < 100 && (
              <Progress percent={numberPercent} strokeWidth={2} showInfo={false} />
            )}
          </div>
          <div>{this.help()}</div>
        </Upload>
      </div>
    );
  }
}

export default CustomerUpload;

import { IUser } from 'app/shared/model/user.model';

export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING'
}

export interface ICompany {
  id?: number;
  state?: AuditState;
  idCard?: string;
  idCardFronted?: string;
  idCardBakcend?: string;
  houseLicense?: string;
  users?: IUser[];
}

export const defaultValue: Readonly<ICompany> = {};

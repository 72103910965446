import axios from 'axios';
import * as qs from 'qs';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IPaymentMethod } from 'app/shared/model/payment-method.model';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_PAYMENTMETHOD_LIST: 'paymentMethod/FETCH_PAYMENTMETHOD_LIST',
  FETCH_PAYMENTMETHOD: 'paymentMethod/FETCH_PAYMENTMETHOD',
  CREATE_PAYMENTMETHOD: 'paymentMethod/CREATE_PAYMENTMETHOD',
  UPDATE_PAYMENTMETHOD: 'paymentMethod/UPDATE_PAYMENTMETHOD',
  DELETE_PAYMENTMETHOD: 'paymentMethod/DELETE_PAYMENTMETHOD',
  RESET: 'paymentMethod/RESET'
};
// state
const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPaymentMethod>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false
};

export type PaymentMethodState = Readonly<typeof initialState>;

// Reducer

export default (state: PaymentMethodState = initialState, action): PaymentMethodState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PAYMENTMETHOD_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PAYMENTMETHOD):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_PAYMENTMETHOD):
    case REQUEST(ACTION_TYPES.UPDATE_PAYMENTMETHOD):
    case REQUEST(ACTION_TYPES.DELETE_PAYMENTMETHOD):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_PAYMENTMETHOD_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PAYMENTMETHOD):
    case FAILURE(ACTION_TYPES.CREATE_PAYMENTMETHOD):
    case FAILURE(ACTION_TYPES.UPDATE_PAYMENTMETHOD):
    case FAILURE(ACTION_TYPES.DELETE_PAYMENTMETHOD):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_PAYMENTMETHOD_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_PAYMENTMETHOD):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_PAYMENTMETHOD):
    case SUCCESS(ACTION_TYPES.UPDATE_PAYMENTMETHOD):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_PAYMENTMETHOD):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/payment-methods';

// Actions

export const getEntities: ICrudGetListAction<IPaymentMethod> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_PAYMENTMETHOD_LIST,
    payload: axios.get<IPaymentMethod>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const getEntity: ICrudGetAction<IPaymentMethod> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PAYMENTMETHOD,
    payload: axios.get<IPaymentMethod>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IPaymentMethod> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PAYMENTMETHOD,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IPaymentMethod> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PAYMENTMETHOD,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IPaymentMethod> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PAYMENTMETHOD,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

import { Moment } from 'moment';
import { BankName } from 'app/shared/model/user.model';

export const enum PaymentMethodType {
  WECHAT = 'WECHAT',
  ALIPAY = 'ALIPAY',
  OFFONLE = 'OFFONLE',
  UNIONPAY = 'UNIONPAY'
}

export const enum PaymentState {
  WAITING = 'WAITING',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED'
}

export interface IPayment {
  id?: number;
  number?: string;
  amount?: number;
  type?: PaymentMethodType;
  bank?: BankName;
  state?: PaymentState;
  createdAt?: Moment;
  createdBy?: string;
  updatedBy?: string;
  updatedAt?: Moment;
  paymentMethodId?: number;
}

export const defaultValue: Readonly<IPayment> = {};

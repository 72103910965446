// 水电管理 定义
import { Moment } from 'moment';
import { IRangeFilter, IStringFilter, IFilter } from 'app/shared/model/filter.model';

// 返回状态定义
export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING'
}
export interface IHydropowerConfiguration {
  houseId?: number; // 房源id
  house?: string; // 房源
  waterPrice?: string; // 水费
  electricityPrice?: string; // 电费
  thresholdValue?: string; // 阈值
  controlsName?: string; // 操作者
  consumptionDate?: Date; // 操作时间
  overdraftAmountElectricity?: number; // 电费透支额度
  overdraftAmountWater?: number; // 水费透支额度
}

export const defaultValue: Readonly<IHydropowerConfiguration> = {};

export interface ISearchForm {
  houseId?: IFilter; // 房源
}

export const defaultSearchForm: ISearchForm = {
  houseId: { equals: null }
};

// 水电管理日志
export interface IHydropowerConfigurationLog {
  house?: string; // 所属小区
  oldWaterPrice?: number; // 水费价格（元
  oldElectricityPrice?: number; // 电费价格（元
  oldThresholdValue?: number; // 阈值（元）
  oldoverdraftAmount?: number; // 透支前的额度
  waterPrice?: number; // 水费
  electricityPrice?: number; // 电费
  thresholdValue?: number; // 阈值（元）
  overdraftAmount?: number; // 透支额度
}

import { Moment } from 'moment';
import { IRangeFilter, IStringFilter, IFilter } from 'app/shared/model/filter.model';

export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING'
}

export interface IRenewal {
  id?: number; // id
  auditState?: AuditState; // 审核状态
  auditAt?: Moment; // 审核日期
  auditDescription?: string; // 审核描述
  content?: string; // 审核内容
  diffDeposit?: number; // diffDeposit
  userName?: string; // 用户名
  mobile?: string; // 手机号
  roomName?: string; // 房间号
  createdAt?: Moment; // 创建日期
  createdBy?: string; // 创建者
  updatedBy?: string; // 更新者
  updatedAt?: Moment; // 更新日期
  houseRoomId?: number; // 房间号
  contractId?: number; // 普通合同id
  tcontractId?: number; // 团租合同id
  userId?: number; // userid
  auditorId?: number; // 审核id
  auditorName?: string; // 审核名称
  category?: string; // 类型
}

export const defaultValue: Readonly<IRenewal> = {};
export interface ISearchForm {
  houseRoomId?: IRangeFilter; // 房间号id
  userId?: IRangeFilter; // userid
  createdAt?: IRangeFilter; // 创建日期
  createdBy?: IStringFilter; // 创建者
  houseId?: IRangeFilter; // 房源
  auditState?: IStringFilter; // 审核状态
  auditAt?: IRangeFilter; // 审核日期
  category?: IFilter; // 类型
}

export const defaultSearchForm: ISearchForm = {
  createdAt: {
    // 创建日期
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' }, // 创建者
  houseId: {
    // 房源
    in: []
  },
  houseRoomId: {
    // 房间号
    in: []
  },
  userId: {
    // user id
    in: []
  },
  auditState: {
    // 审核状态
    in: []
  },
  auditAt: {
    // 审核日期
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  category: {
    // 类型
    in: ['CONTRACT', 'TCONTRACT']
  }
};

// 商业续租
export interface IMSearchForm {
  houseRoomId?: IRangeFilter; // 房间号
  userId?: IRangeFilter; // user id
  createdAt?: IRangeFilter; // 创建日期
  createdBy?: IStringFilter; // 创建者
  houseId?: IRangeFilter; // 房源
  auditState?: IStringFilter; // 审核状态
  auditAt?: IRangeFilter; // 审核日期
  category?: IFilter; // 类型
}

export const MdefaultSearchForm: IMSearchForm = {
  createdAt: {
    // 创建日期
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' }, // 创建者
  houseId: {
    // 房源
    in: []
  },
  houseRoomId: {
    // 房间号
    in: []
  },
  userId: {
    // user id
    in: []
  },
  auditState: {
    // 审核状态
    in: []
  },
  auditAt: {
    // 审核日期
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  category: {
    // 类型
    in: ['MCONTRACT']
  }
};

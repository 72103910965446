import { Moment } from 'moment';
import { IShareLivePeople } from 'app/shared/model/share-live-people.model';
import { IFilter, IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';

export interface ILiveInfo {
  id?: number;
  name?: string;
  mobile?: string;
  roomName?: string;
  idCard?: string;
  idCardFront?: string;
  idCardBackend?: string;
  ammeter?: number;
  waterMeter?: number;
  gasMeter?: number;
  createdAt?: Moment;
  createdBy?: string;
  updatedAt?: Moment;
  updatedBy?: string;
  contractId?: number;
  shareLivePeople?: IShareLivePeople[];
}

export const defaultValue: Readonly<ILiveInfo> = {};

export interface ISearchForm {
  houseRoomId?: IRangeFilter;
  userId?: IRangeFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
  houseId?: IRangeFilter;
}

export const defaultSearchForm: ISearchForm = {
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  houseId: {
    in: []
  },
  houseRoomId: {
    in: []
  },
  userId: {
    in: []
  }
};

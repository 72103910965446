import styles from './captcha.less';
import React, { Component } from 'react';

interface IProps {
  src: string;
  onClick: any;
}

export default class Captcha extends Component<IProps> {
  render() {
    return (
      <>
        <img className={styles.loginCaptcha} src={this.props.src} onClick={this.props.onClick} />
      </>
    );
  }
}

/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';
import { Card, Form, Icon, Tooltip, Divider, Button, Progress, Modal, message } from 'antd';
import { translate, Translate } from 'react-jhipster';
import { AxiosPromise } from 'axios';

export interface IProgress {
  progress?: number;
  processing?: boolean;
}

export interface IResetIndicesProgressProps {
  title?: any;
  help?: any;
  searchComponentLabel?: any;
  SearchComponent?: any;
  searchComponentProps?: any;
  statusHandle: () => AxiosPromise<IProgress>;
  resetHandle: (identities: number[]) => Promise<any>;
}

export interface IResetIndicesProgressState {
  btnDisabled?: boolean;
  progress?: IProgress;
  identities?: number[];
}

export default class ResetIndicesProgress extends React.Component<IResetIndicesProgressProps, IResetIndicesProgressState> {
  state: IResetIndicesProgressState = {
    btnDisabled: false,
    progress: {
      progress: 0,
      processing: false
    },
    identities: []
  };

  private timeId;
  // 组件卸载
  componentWillUnmount(): void {
    window.clearTimeout(this.timeId);
  }
  // 获取状态
  private getStatus(progress: IProgress): 'normal' | 'exception' | 'active' | 'success' {
    if (progress.progress > 0 && progress.progress < 100 && !progress.processing) {
      return 'exception';
    } else if (progress.progress < 100 && progress.processing) {
      return 'active';
    } else if (progress.progress === 100 && !progress.processing) {
      return 'success';
    } else {
      return 'normal';
    }
  }
  // 搜索
  searchComponentChange = value => {
    this.setState({ identities: value });
  };
  // 重置
  handleReset = () => {
    Modal.confirm({
      title: translate('indicesManagement.action.deleteConfirm.title'),
      okType: 'danger',
      onOk: () => {
        const { resetHandle } = this.props;
        const { identities } = this.state;
        this.setState({
          btnDisabled: true
        });
        return resetHandle(identities)
          .then(() => {
            this.handleStatus();
            return Promise.resolve();
          })
          .catch(() => {
            window.clearTimeout(this.timeId);
            return Promise.resolve();
          })
          .then(() => {
            this.setState({
              btnDisabled: false
            });
            return Promise.resolve();
          });
      }
    });
  };
  // status
  handleStatus = () => {
    const { statusHandle } = this.props;
    statusHandle()
      .then(response => {
        if (response.data.processing) {
          this.setState(
            {
              progress: {
                progress: Math.min(99, response.data.progress),
                processing: response.data.processing
              },
              btnDisabled: true
            },
            () => {
              this.timeId = setTimeout(this.handleStatus, 2000);
            }
          );
        } else {
          if (response.data.progress === 100) {
            message.success(translate('indicesManagement.resetSuccess'));
          }
          this.setState({
            progress: {
              progress: response.data.progress,
              processing: response.data.processing
            },
            btnDisabled: false
          });
        }
      })
      .catch(() => {
        message.error(translate('indicesManagement.resetError'));
      });
  };

  render(): React.ReactNode {
    const { title, help, searchComponentLabel, SearchComponent, searchComponentProps } = this.props;
    const { btnDisabled, progress } = this.state;
    return (
      <Card bordered={false} title={title} bodyStyle={{ textAlign: 'center' }}>
        <Form.Item
          label={
            <span>
              {searchComponentLabel}
              &nbsp;
              <Tooltip title={help}>
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          {SearchComponent && <SearchComponent {...searchComponentProps} onChange={this.searchComponentChange} style={{ width: '100%' }} />}
        </Form.Item>
        <Divider />
        <div>
          <Progress type="circle" percent={progress.progress} width={80} status={this.getStatus(progress)} />
          <div style={{ marginTop: 8 }}>
            <Button htmlType={'button'} onClick={this.handleReset} disabled={btnDisabled}>
              <Translate contentKey={'indicesManagement.action.reset'} />
            </Button>
          </div>
        </div>
      </Card>
    );
  }
}

import styles from './image.less';

import * as React from 'react';
import { Card, Icon, Modal, Tag } from 'antd';
import CustomerUpload from 'app/shared/components/CustomerUpload';

export interface IFormUploadProps {
  onChange?: (value: string) => void;
  value?: string;
}

export interface IFormUploadState {
  value?: string;
  previewVisible?: boolean;
}

export default class ImageUpload extends React.Component<IFormUploadProps, IFormUploadState> {
  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      return {
        value: nextProps.value
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      previewVisible: false
    };
  }

  triggerChange(value: string) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  }

  preview = (value: string) => {
    this.setState(
      {
        value
      },
      () => this.triggerChange(value)
    );
  };

  openPreview = () => {
    if (!this.state.value) {
      return;
    }
    this.setState({
      previewVisible: true
    });
  };

  closePreview = () => {
    this.setState({
      previewVisible: false
    });
  };

  render() {
    const { value } = this.state;
    // @ts-ignore
    const { accept } = this.props;
    const uploadButton = (
      <div className={'upload-button'}>
        <Icon type="plus" />
        <div className="ant-upload-text">上传</div>
      </div>
    );
    const onPreview = file => {
      this.preview(file.url);
    };
    return (
      <div>
        <Card
          className={styles.imageCard}
          cover={
            <CustomerUpload
              showUploadList={false}
              autoUpload
              listType="picture-card"
              accept={accept || 'image/png,image/jpeg,image/gif'}
              action={`/api/files`}
              onPreview={onPreview}
            >
              {value ? value.indexOf('.pdf') > -1 ? uploadButton : <img width={'100%'} src={value} alt={'预览'} /> : uploadButton}
            </CustomerUpload>
          }
          actions={[<Icon type="eye" key="eye" onClick={this.openPreview} />]}
        >
          <Modal title={'图片预览'} onCancel={this.closePreview} footer={null} visible={this.state.previewVisible}>
            <a href={value} target={'_blank'}>
              <img src={value} alt={'预览'} width={'100%'} />
            </a>
          </Modal>
        </Card>
        {value && value.indexOf('.pdf') > -1 && <Tag closable> {value} </Tag>}
      </div>
    );
  }
}

import React, { Component } from 'react';
import { Select } from 'antd';
import { isArray } from 'lodash';
import { BuildingType, IHouse } from 'app/shared/model/house.model';
import { SelectProps } from 'antd/lib/select';
import { getEntities } from 'app/entities/house/house.reducer';

const { Option } = Select;

interface IProps extends Omit<SelectProps<number>, 'onChange'> {
  buildingType?: BuildingType;
  onChange?: (d: any, value: any) => void;
  getSourceData?: any;
  disabled?: boolean;
  hasAllOpt?: boolean;
}

interface IStates {
  houses?: IHouse[];
  value?: string;
  loading?: boolean;
  timer?: any;
}

export default class HouseSelect extends Component<IProps, IStates> {
  state: IStates = {
    houses: [],
    loading: false,
    timer: null
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if ('value' in nextProps) {
      const value = nextProps.value || '';
      return {
        value
      };
    }
    return null;
  }

  componentDidMount(): void {
    this.handleSearch(null);
  }

  componentWillUnmount = () => {
    this.setState = () => false;
  };

  handleSearch = name => {
    clearTimeout(this.state.timer);
    this.setState({
      loading: true,
      timer: setTimeout(() => {
        (getEntities(0, 20, 'createdAt,desc', {
          name: { contains: name },
          buildingType: { equals: this.props.buildingType || '' }
        }) as any).payload
          .then(({ data }) => {
            this.setState({
              houses: data,
              loading: false
            });
          })
          .finally(() => {
            this.setState({
              loading: false
            });
          });
      }, 100)
    });
  };

  changeHandler = d => {
    if (!('value' in this.props)) {
      this.setState({ value: d });
    }
    this.triggerChange(d);
  };
  triggerChange = value => {
    if (this.props.onChange) {
      const valueFlag = this.state.houses.filter(item => item.id === value);
      // @ts-ignore
      this.props.onChange(value, valueFlag);
    }
    if (this.props.mode === 'multiple' && typeof this.props.getSourceData === 'function' && this.props.getSourceData) {
      const data: any = [];
      const { houses } = this.state;
      houses.map(house => {
        value.map(item => {
          if (house.id === item) {
            data.push(house);
          }
        });
      });
      this.props.getSourceData(data);
    }
  };

  render() {
    const { houses, loading } = this.state;
    const vs = this.state.value;
    const { value, onChange, hasAllOpt, ...restProps } = this.props;
    const realValue = hasAllOpt && !vs ? 'all' : isArray(vs) ? (vs as string[]).map(v => Number(v)) : vs;
    const allOpt = () =>
      hasAllOpt && (
        <Option value="all" key="all-houses" title={'all'}>
          全部
        </Option>
      );
    return (
      <Select
        {...restProps}
        value={realValue}
        showSearch
        onChange={this.changeHandler}
        defaultActiveFirstOption={false}
        filterOption={false}
        onSearch={this.handleSearch}
        loading={loading}
        // allowClear={false}
      >
        {allOpt()}
        {houses.map(house => (
          <Option value={house.id} key={`HouseSelect-${house.id}`} title={house.name}>
            {house.name}
          </Option>
        ))}
      </Select>
    );
  }
}

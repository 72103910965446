import styles from '../../home.less';

import React from 'react';
import { Row, Col, Dropdown, Statistic, Progress } from 'antd';

import customIcon from '../static/image/custom-icon.png';
import { isMobile } from 'app/shared/util/screen-utils';
import PieChart from 'app/shared/components/pie-chart';

interface ICustomerServiceProp {
  data: string[];
}

export default class CustomerService extends React.Component<ICustomerServiceProp, {}> {
  renderIcon(title) {
    return (
      <div>
        <i>
          <img src={customIcon} width={24} />
        </i>
        <span className={styles.icontitle}>{title}</span>
      </div>
    );
  }

  renderTitle() {
    return (
      <Row className={`${styles.rentTitle} ${styles.titleBorder}`}>
        <Col>{this.renderIcon('退租原因分析')}</Col>
      </Row>
    );
  }

  render() {
    const { data } = this.props;
    return (
      <div className={`${styles.rent} ${styles.mt16} ${!isMobile() ? styles.ml16 : styles.mt16} ${styles.pl10}`}>
        {this.renderTitle()}
        {data ? <PieChart data={this.props.data} /> : <div className={styles.circle}>0%</div>}
      </div>
    );
  }
}

import axios from 'axios';
import { Storage } from 'react-jhipster';
import Cookies from 'js-cookie';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { message as Message, notification as Notification } from 'antd';
import { setLocale } from 'app/shared/reducers/locale';

import { AUTH_TOKEN_KEY } from 'app/config/constants';
import { removeToken, setToken } from '../util/token-utils';
import { getToDo } from '../common/todo.reducer';

export const ACTION_TYPES = {
  LOGIN: 'authentication/LOGIN',
  GET_SESSION: 'authentication/GET_SESSION',
  LOGOUT: 'authentication/LOGOUT',
  CLEAR_AUTH: 'authentication/CLEAR_AUTH',
  ERROR_MESSAGE: 'authentication/ERROR_MESSAGE',
  CAPTCHA: 'authentication/CAPTCHA'
};

const initialState = {
  loading: false,
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  showModalLogin: false,
  account: {} as any,
  errorMessage: null as string, // Errors returned from server side
  redirectMessage: null as string,
  sessionHasBeenFetched: false,
  idToken: null as string,
  logoutUrl: null as string,
  captcha: {} as any
};

export type AuthenticationState = Readonly<typeof initialState>;

// Reducer

export default (state: AuthenticationState = initialState, action): AuthenticationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.LOGIN):
    case REQUEST(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: true
      };
    case FAILURE(ACTION_TYPES.LOGIN):
      return {
        ...initialState,
        errorMessage: action.payload,
        showModalLogin: true,
        loginError: true
      };
    case FAILURE(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        showModalLogin: true,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.LOGIN):
      return {
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true
      };
    case ACTION_TYPES.LOGOUT:
      return {
        ...initialState,
        showModalLogin: true
      };
    case SUCCESS(ACTION_TYPES.GET_SESSION): {
      const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
      return {
        ...state,
        isAuthenticated,
        loading: false,
        sessionHasBeenFetched: true,
        account: action.payload.data
      };
    }
    case ACTION_TYPES.ERROR_MESSAGE:
      return {
        ...initialState,
        showModalLogin: true,
        redirectMessage: action.message
      };
    case ACTION_TYPES.CLEAR_AUTH:
      return {
        ...state,
        loading: false,
        showModalLogin: true,
        isAuthenticated: false
      };
    case ACTION_TYPES.CAPTCHA:
      return {
        ...state,
        captcha: { ...action.payload }
      };
    default:
      return state;
  }
};

export const displayAuthError = message => ({ type: ACTION_TYPES.ERROR_MESSAGE, message });

export const getSession = () => async (dispatch, getState) => {
  await dispatch({
    type: ACTION_TYPES.GET_SESSION,
    payload: axios.get('api/account')
  });

  const { account } = getState().authentication;
  if (account && account.langKey) {
    const langKey = Storage.session.get('locale', account.langKey);
    await dispatch(setLocale(langKey));
  }
};

// 2021年9月28日：暂时丢弃
export const login = (username, password, rememberMe = false) => async (dispatch, getState) => {
  const result = await dispatch({
    type: ACTION_TYPES.LOGIN,
    payload: axios.post('api/authenticate', { username, password, rememberMe })
  });
  const bearerToken = result.value.headers.authorization;
  if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
    const jwt = bearerToken.slice(7, bearerToken.length);
    if (rememberMe) {
      Storage.local.set(AUTH_TOKEN_KEY, jwt);
    } else {
      Cookies.set(AUTH_TOKEN_KEY, jwt);
      Storage.session.set(AUTH_TOKEN_KEY, jwt);
    }
  }
  await dispatch(getSession());
};

// 2021年9月28日：使用验证码和刷新token的登录接口
export const login_cap = (username, password, captcha, rememberMe = false) => async (dispatch, getState) => {
  const result = await dispatch({
    type: ACTION_TYPES.LOGIN,
    payload: axios.post('api/authenticate_cap', { username, password, captcha, rememberMe, platform: 'Other' })
  });
  // console.log('login-cap', result, result.value.data);
  const loginData = result.value;
  // 获取refresh-token id_token
  const refreshToken = loginData.data.refresh_token;
  const idToken = loginData.data.id_token;
  // console.log(rememberMe);
  // 存token refreshToken
  if (idToken && refreshToken) {
    setToken(rememberMe, idToken, refreshToken);
    await dispatch(getSession());
    setTimeout(() => {
      getToDo();
      if (loginData.data.tips) {
        Notification.info({
          message: `待办事项`,
          description: loginData.data.tips,
          placement: 'bottomRight',
          duration: 10,
          onClick: () => {
            window.location.href = '/account/password';
          }
        });
      }
    }, 1000);
  } else {
    Message.error('请求失败！');
  }
};

export const getCaptcha = () => async (dispatch, getState) => {
  const res = await axios.get('api/login_captcha', {});
  let data = {
    captchaCode: '',
    captchaBase64Str: ''
  };
  if (res.status === 200) {
    data = res.data;
  }
  await dispatch({
    type: ACTION_TYPES.CAPTCHA,
    payload: data
  });
};

export const clearAuthToken = () => {
  removeToken();
};

export const logout = () => async dispatch => {
  await axios.post('/api/logout', {});
  clearAuthToken();
  window.location.href = '/user/login';
};

export const clearAuthentication = messageKey => (dispatch, getState) => {
  clearAuthToken();
  dispatch(displayAuthError(messageKey));
  dispatch({
    type: ACTION_TYPES.CLEAR_AUTH
  });
};
// house：默认id   auth: 权限  type:单选|双选  name:名字
export const getDefultHouseId = (houseId, authority, type, name) => {
  let house = type === 'multiple' ? { in: [houseId] } : houseId;
  // 没有房源 或者 全部房源
  if (!houseId || authority.indexOf('全局房源可见') > -1) {
    house = type === 'multiple' ? { in: [] } : null;
  }

  return house;
};

// 是否有房源权限
export const getHouseIdAuthority = (authority, houseId) => authority.indexOf('全局房源可见') > -1 || houseId;

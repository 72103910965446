import { Moment } from 'moment';
import { IFilter, IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { Direction } from 'app/shared/model/house.model';
import { IHouseRoom } from 'app/shared/model/house-room.model';
import { IAttachment } from 'app/shared/model/attachment.model';

export interface IHouseLayout {
  id?: number;
  roomNumber?: number;
  hallNumber?: number;
  kitchenNumber?: number;
  bathroomNumber?: number;
  attachments?: IAttachment[];
  direction?: Direction;
  activated?: boolean;
  facilities?: any[];
  specialFacilities?: any[];
  area?: any;
  houseId?: any;
  hasBase?: boolean;
  createdAt?: Moment;
  createdBy?: string;
  updatedAt?: Moment;
  updatedBy?: string;
  rooms?: IHouseRoom[];
  vrLink?: string; // vr地址
}

export const defaultValue: Readonly<IHouseLayout> = {
  activated: false,
  hasBase: false,
  houseId: null,
  kitchenNumber: 0,
  direction: Direction.S,
  facilities: [],
  specialFacilities: [],
  attachments: [],
  area: 0.0,
  rooms: [],
  hallNumber: 0,
  roomNumber: 0
};

export interface ISearchForm {
  roomNumber?: IRangeFilter;
  hallNumber?: IRangeFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
  updatedAt?: IRangeFilter;
  updatedBy?: IStringFilter;
  houseId?: IStringFilter;
  activated?: IFilter;
}

export const defaultSearchForm: Readonly<ISearchForm> = {
  roomNumber: {
    equals: ''
  },
  houseId: {
    in: []
  },
  hallNumber: {
    equals: ''
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  updatedAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  updatedBy: { contains: '' },
  activated: { equals: '' }
};

import { Moment } from 'moment';
import { IHouse } from 'app/shared/model/house.model';
import { IFilter, IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { IContractFacilities } from 'app/shared/model/contract-facilites.model';

export const enum FacilitiesType {
  BASIC = 'BASIC',
  FEATURE = 'FEATURE',
  VAS = 'VAS',
  COMPLEX = 'COMPLEX'
}

export interface IFacilities {
  id?: number;
  name?: string;
  type?: FacilitiesType;
  activated?: boolean;
  hasBase?: boolean;
  createdAt?: Moment;
  createdBy?: string;
  updatedAt?: Moment;
  updatedBy?: string;
  houses?: IHouse[];
}

export const defaultValue: Readonly<IFacilities> = {
  type: FacilitiesType.BASIC,
  activated: true,
  hasBase: false
};

export interface ISearchForm {
  name?: IStringFilter;
  type?: IStringFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
  updatedAt?: IRangeFilter;
  updatedBy?: IStringFilter;
  activated?: IFilter;
}

export const defaultSearchForm: Readonly<ISearchForm> = {
  name: {
    contains: ''
  },
  type: {
    equals: ''
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  updatedAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  updatedBy: { contains: '' },
  activated: { equals: '' }
};

export interface IContractFacilitiesTree {
  basics?: IContractFacilities[];
  features?: IContractFacilities[];
}

export interface IContractFacilitiesWithId {
  [feild: string]: IContractFacilities;
}

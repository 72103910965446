import { Moment } from 'moment';

export const enum FaceAuthState {
  WAITING = 'WAITING',
  SUCCESS = 'SUCCESS'
}

export const enum FaceAuthEvent {
  ID_CARD = 'ID_CARD',
  CONTRACT = 'CONTRACT'
}

export interface IFaceAuthRecord {
  id?: number;
  photo?: string;
  bizNo?: string;
  state?: FaceAuthState;
  similarity?: string;
  verifyResult?: string;
  authEvent?: FaceAuthEvent;
  createdAt?: Moment;
  updatedAt?: Moment;
  createdBy?: string;
  updatedBy?: string;
}

export const defaultValue: Readonly<IFaceAuthRecord> = {};

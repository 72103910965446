import { IFilter, IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';

export interface ISearchForm {
  name?: IStringFilter;
}

export const defaultSearchForm: ISearchForm = {
  name: {
    equals: ''
  }
};

export interface ICustomTime {
  id?: number;
  description?: string;
  name?: string;
  year?: number;
  month?: number;
  day?: number;
  activated?: boolean;
}

export const defaultValue: Readonly<ICustomTime> = {};

import axios from 'axios';
import * as qs from 'qs';
import { compact, omit, set, get, defaultsDeep } from 'lodash';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IUserGroup } from 'app/shared/model/user-group.model';
import { defaultSearchForm, IUser } from 'app/shared/model/user.model';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_USERGROUP_LIST: 'userGroup/FETCH_USERGROUP_LIST',
  FETCH_USERGROUP: 'userGroup/FETCH_USERGROUP',
  CREATE_USERGROUP: 'userGroup/CREATE_USERGROUP',
  UPDATE_USERGROUP: 'userGroup/UPDATE_USERGROUP',
  DELETE_USERGROUP: 'userGroup/DELETE_USERGROUP',
  RESET: 'userGroup/RESET',
  RESET_ENTITY: 'regionalDivide/RESET_ENTITY',
  SET_LOAD_KEYS: 'regionalDivide/SET_LOAD_KEYS',
  LINK_USERS: 'regionalDivide/LINK_USERS',
  LINK_USERS_WITHOUT: 'regionalDivide/LINK_USERS_WITHOUT',
  LINK_USER: 'regionalDivide/LINK_USER',
  UNLINK_USER: 'regionalDivide/UNLINK_USER'
};
// state
const initialState = {
  loading: false,
  userLoading: false,
  userWithoutLoading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IUserGroup>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  linkUsers: [] as ReadonlyArray<IUser>,
  linkUsersTotal: 0,
  linkUserWithout: [] as ReadonlyArray<IUser>,
  linkUsersWithoutTotal: 0,
  tree: {
    name: '公司',
    id: 'root',
    key: 'root'
  },
  loadKeys: [],
  linkUserLoading: false,
  searchForm: defaultSearchForm
};

export type UserGroupState = Readonly<typeof initialState>;

// Reducer

export default (state: UserGroupState = initialState, action): UserGroupState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_USERGROUP_LIST):
    case REQUEST(ACTION_TYPES.FETCH_USERGROUP):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.LINK_USERS_WITHOUT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        userWithoutLoading: true
      };
    case REQUEST(ACTION_TYPES.LINK_USERS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        userLoading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_USERGROUP):
    case REQUEST(ACTION_TYPES.UPDATE_USERGROUP):
    case REQUEST(ACTION_TYPES.DELETE_USERGROUP):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_USERGROUP_LIST):
    case FAILURE(ACTION_TYPES.FETCH_USERGROUP):
    case FAILURE(ACTION_TYPES.CREATE_USERGROUP):
    case FAILURE(ACTION_TYPES.UPDATE_USERGROUP):
    case FAILURE(ACTION_TYPES.DELETE_USERGROUP):
    case FAILURE(ACTION_TYPES.UNLINK_USER):
    case FAILURE(ACTION_TYPES.LINK_USER):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
        userLoading: false,
        userWithoutLoading: false,
        linkUserLoading: false
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERGROUP_LIST):
      const tree = { ...state.tree };
      const parentKey = action.meta.ancestry ? `children.${action.meta.ancestry.replace(/\//g, '.children.')}` : '';
      const entities = action.payload.data.reduce((result, regional) => {
        regional['key'] = compact([parentKey, 'children', regional.id]).join('.');
        const oldRegional = get(tree, regional.key);
        result[regional.id] = defaultsDeep(regional, oldRegional);
        result[regional.id] = regional;
        return result;
      }, {});
      set(tree, compact([parentKey, 'children']).join('.'), entities);
      return {
        ...state,
        loading: false,
        tree
      };
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERGROUP):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_USERGROUP):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.UPDATE_USERGROUP):
      const treeUpdate = { ...state.tree };
      const parentKeyUpdate = action.payload.data.ancestry ? `children.${action.payload.data.ancestry.replace(/\//g, '.children.')}` : '';
      set(treeUpdate, compact([parentKeyUpdate, 'children', action.payload.data.id, 'name']).join('.'), action.payload.data.name);
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
        tree: { ...treeUpdate }
      };
    case SUCCESS(ACTION_TYPES.DELETE_USERGROUP):
      const parentKeyDelete = action.payload.data.ancestry ? `children.${action.payload.data.ancestry.replace(/\//g, '.children.')}` : '';
      const s = compact([parentKeyDelete, 'children', action.payload.data.id]).join('.');
      const treeDelete = omit(state.tree, s);
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
        tree: treeDelete
      };
    case SUCCESS(ACTION_TYPES.LINK_USERS):
      return {
        ...state,
        userLoading: false,
        linkUsersTotal: parseInt(action.payload.headers['x-total-count'], 10),
        linkUsers: action.payload.data,
        searchForm: action.meta.searchForm
      };
    case SUCCESS(ACTION_TYPES.LINK_USERS_WITHOUT):
      return {
        ...state,
        userWithoutLoading: false,
        linkUsersWithoutTotal: parseInt(action.payload.headers['x-total-count'], 10),
        linkUserWithout: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.LINK_USER):
    case SUCCESS(ACTION_TYPES.UNLINK_USER):
      return {
        ...state,
        updateSuccess: true,
        linkUserLoading: false
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.RESET_ENTITY:
      return {
        ...state,
        entity: defaultValue
      };
    case ACTION_TYPES.SET_LOAD_KEYS:
      return {
        ...state,
        loadKeys: [...action.meta.loadKeys]
      };
    default:
      return state;
  }
};

const apiUrl = 'api/user-groups'; // 组织架构

// Actions

export const getEntities = (ancestry = '') => ({
  type: ACTION_TYPES.FETCH_USERGROUP_LIST,
  payload: axios.get<IUserGroup>(`${apiUrl}?cacheBuster=${new Date().getTime()}`, {
    params: flattenKey(ancestry ? { ancestry: { equals: ancestry } } : { ancestry: { specified: 'false' } }),
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
  }),
  meta: { ancestry }
});

export const getEntity: ICrudGetAction<IUserGroup> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_USERGROUP,
    payload: axios.get<IUserGroup>(requestUrl)
  };
};
// 创建组织架构
export const createEntity: ICrudPutAction<IUserGroup> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USERGROUP,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities(result.value.data.ancestry));
  return result;
};
// 更新组织架构
export const updateEntity: ICrudPutAction<IUserGroup> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_USERGROUP,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};
// 删除组织架构
export const deleteEntity: ICrudDeleteAction<IUserGroup> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_USERGROUP,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const resetEntity = () => ({
  type: ACTION_TYPES.RESET_ENTITY
});

export const setLoadKeys = loadKeys => ({
  type: ACTION_TYPES.SET_LOAD_KEYS,
  meta: { loadKeys }
});
// 获取用户
export const getUsers = (type, page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}/users${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type,
    payload: axios.get<IUser>(requestUrl, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true, searchForm }
  };
};

export const getLinkUsers = (page, size, sort = 'createdAt,desc', searchForm) =>
  getUsers(ACTION_TYPES.LINK_USERS, page, size, sort, searchForm);

export const getLinkUserWithout = (page, size, sort = 'createdAt,desc', searchForm) =>
  getUsers(ACTION_TYPES.LINK_USERS_WITHOUT, page, size, sort, searchForm);

export const linkUser = (regionalId, userIds) => async (dispatch, getState) => {
  const result = await dispatch({
    type: ACTION_TYPES.LINK_USER,
    payload: axios.put(`${apiUrl}/users`, flattenKey({ id: regionalId, userIds }), {
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    })
  });
  dispatch(getLinkUsers(0, 20, 'createdAt,desc', getState().regionalDivide.searchForm));
  return result;
};

export const unlinkUser = (regionalId, userIds) => ({
  type: ACTION_TYPES.UNLINK_USER,
  payload: axios.delete(`${apiUrl}/users`, {
    data: flattenKey({ id: regionalId, userIds }),
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
  })
});

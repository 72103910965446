import axios from 'axios';
import * as qs from 'qs';
import moment from 'moment';
import localforage from 'localforage';

import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction, Storage } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, ITContract } from 'app/shared/model/t-contract.model';
import { IContractExpense } from 'app/shared/model/contract-expense.model';
import { ExpenseType, IExpenseGradeTree } from 'app/shared/model/expense-grade.model';
import { FacilitiesType, IContractFacilitiesTree, IFacilities } from 'app/shared/model/facilities.model';
import { IContractFacilities } from 'app/shared/model/contract-facilites.model';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';
import { message } from 'antd';
import orders from '../orders/orders';
// 接口
export const ACTION_TYPES = {
  FETCH_T_CONTRACT_LIST: 'tContract/FETCH_T_CONTRACT_LIST',
  LOAD_T_CONTRACT_LIST: 'tContract/LOAD_T_CONTRACT_LIST',
  FETCH_T_CONTRACT: 'tContract/FETCH_T_CONTRACT',
  CREATE_T_CONTRACT: 'tContract/CREATE_T_CONTRACT',
  UPDATE_T_CONTRACT: 'tContract/UPDATE_T_CONTRACT',
  CANCEL_T_CONTRACT: 'tContract/CANCEL_T_CONTRACT',
  ENROLMENT_T_CONTRACT: 'tContract/ENROLMENT_T_CONTRACT',
  DELETE_T_CONTRACT: 'tContract/DELETE_T_CONTRACT',
  RESET: 'tContract/RESET',
  UPDATE_FORM_ENTITY: 'tContract/UPDATE_FORM_ENTITY',
  GET_FORM_ENTITY: 'tContract/GET_FORM_ENTITY',
  NEXT_STEP: 'tContract/NEXT_STEP',
  PREV_STEP: 'tContract/PREV_STEP',
  RESET_STEP: 'tContract/RESET_STEP',
  RESET_FORM_ENTITY: 'tContract/RESET_FORM_ENTITY',
  CHECK_ROOMS: 'house-rooms/canCreateOrUpdateTcontract',
  CLEARWATERELECTRICITY: 'tContract/CLEARWATERELECTRICITY'
};
// state
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITContract>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  formEntity: defaultValue,
  currentStep: 0,
  searchForm: {}
};

const store = localforage.createInstance({
  name: 'nameHere'
});

export type TContractState = Readonly<typeof initialState>;

// Reducer

export default (state: TContractState = initialState, action): TContractState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_T_CONTRACT_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_T_CONTRACT_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_T_CONTRACT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_T_CONTRACT):
    case REQUEST(ACTION_TYPES.UPDATE_T_CONTRACT):
    case REQUEST(ACTION_TYPES.CLEARWATERELECTRICITY):
    case REQUEST(ACTION_TYPES.CANCEL_T_CONTRACT):
    case REQUEST(ACTION_TYPES.ENROLMENT_T_CONTRACT):
    case REQUEST(ACTION_TYPES.DELETE_T_CONTRACT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_T_CONTRACT_LIST):
    case FAILURE(ACTION_TYPES.LOAD_T_CONTRACT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_T_CONTRACT):
    case FAILURE(ACTION_TYPES.CREATE_T_CONTRACT):
    case FAILURE(ACTION_TYPES.UPDATE_T_CONTRACT):
    case FAILURE(ACTION_TYPES.CLEARWATERELECTRICITY):
    case FAILURE(ACTION_TYPES.CANCEL_T_CONTRACT):
    case FAILURE(ACTION_TYPES.ENROLMENT_T_CONTRACT):
    case FAILURE(ACTION_TYPES.DELETE_T_CONTRACT):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_T_CONTRACT_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_T_CONTRACT_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_T_CONTRACT):
      const formEntity = { ...action.payload.data };
      formEntity.startAt = moment(formEntity.startAt);
      formEntity.endAt = moment(formEntity.endAt);
      return {
        ...state,
        loading: false,
        entity: formEntity,
        formEntity: { ...formEntity }
      };
    case SUCCESS(ACTION_TYPES.CREATE_T_CONTRACT):
    case SUCCESS(ACTION_TYPES.UPDATE_T_CONTRACT):
      store.removeItem('t-contract-form-entity');
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
        formEntity: defaultValue,
        currentStep: 0
      };
    case SUCCESS(ACTION_TYPES.CLEARWATERELECTRICITY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
        formEntity: defaultValue,
        currentStep: 0
      };
    case SUCCESS(ACTION_TYPES.DELETE_T_CONTRACT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case SUCCESS(ACTION_TYPES.CANCEL_T_CONTRACT):
      message.success('撤消合同操作成功');
      return state;
    case SUCCESS(ACTION_TYPES.ENROLMENT_T_CONTRACT):
      message.success('网签登记成功');
      return state;
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.UPDATE_FORM_ENTITY:
      store.setItem('t-contract-form-entity', action.payload);
      return {
        ...state,
        formEntity: { ...action.payload }
      };
    case ACTION_TYPES.CHECK_ROOMS:
      return {
        ...state
      };
    case ACTION_TYPES.GET_FORM_ENTITY:
      const getFormEntityData = { ...state.formEntity };
      getFormEntityData.startAt = getFormEntityData.startAt && moment(getFormEntityData.startAt);
      getFormEntityData.endAt = getFormEntityData.endAt && moment(getFormEntityData.endAt);

      return {
        ...state,
        formEntity: getFormEntityData
      };
    case ACTION_TYPES.NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1
      };
    case ACTION_TYPES.PREV_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1
      };
    case ACTION_TYPES.RESET_STEP:
      return {
        ...state,
        currentStep: 0
      };
    case ACTION_TYPES.RESET_FORM_ENTITY:
      Storage.local.remove('t-contract-form-entity');
      return {
        ...state,
        formEntity: defaultValue
      };
    default:
      return state;
  }
};

const apiUrl = 'api/tcontracts'; // 团租合同列表
const apiPrefix = 'api';
const initpage = 0;
const initsize = 20;

// Actions
// 团租合同列表
export const getEntities: ICrudGetListAction<ITContract> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  initialState.searchForm = { ...searchForm }; // 企业分权，记录最后一次搜索条件
  return {
    type: ACTION_TYPES.FETCH_T_CONTRACT_LIST,
    payload: axios.get<ITContract>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const loadEntities: ICrudGetListAction<ITContract> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.LOAD_T_CONTRACT_LIST,
    payload: axios.get<ITContract>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};
// 团组合同详情
export function fetchEntity(id) {
  const requestUrl = `${apiUrl}/${id}`;
  return axios.get<ITContract>(requestUrl).then(res => {
    const data = res.data;
    res.data.houseRoom = [];
    res.data.singleMonthPrices = [];
    data.tcontractItems &&
      data.tcontractItems.map(item => {
        res.data.houseRoom.push({
          ...item.houseRoom
        });
        // 每个月费用
        res.data.singleMonthPrices.push({
          ...item.houseRoom,
          singleMonthPrice: item.singleMonthPrice, // 租金
          deposit: item.deposit, // 押金
          propertyFee: item.propertyFee // 物业费
        });
      });
    if (data.tcontractItems) {
      const temp = { ...data.tcontractItems[0] };
      res.data.paymentCycle = temp.paymentCycle;
      res.data.belatedCycle = temp.belatedCycle;
      res.data.belatedInterest = temp.belatedInterest;
      res.data.deposit = temp.deposit;
      // res.data.hasDeposit = temp.hasDeposit;
      res.data.hasPropertyFee = temp.hasPropertyFee;
      res.data.propertyFee = temp.propertyFee;
    }
    return res;
  });
}

export const getEntity: ICrudGetAction<ITContract> = id => ({
  type: ACTION_TYPES.FETCH_T_CONTRACT,
  payload: fetchEntity(id)
});
// 创建团租合同
export const createEntity: ICrudPutAction<ITContract> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_T_CONTRACT,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};
// 更新团租合同税号
export const updateTaxidEntity: ICrudPutAction<ITContract> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_T_CONTRACT,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

// 团租房源水电费清零
export const updateWaterElectricity: ICrudPutAction<ITContract> = entity => async dispatch => {
  const clearUrl = 'api/tcontracts/batchClearTcontractWaterElectricity';
  return dispatch({
    type: ACTION_TYPES.CLEARWATERELECTRICITY,
    // @ts-ignore
    payload: axios.post(`${clearUrl}`, cleanEntity(entity))
  });
};
// 更新团租合同
export const updateEntity: ICrudPutAction<ITContract> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_T_CONTRACT,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};
// 取消团租合同
export const cancelEntity: ICrudPutAction<ITContract> = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CANCEL_T_CONTRACT,
    payload: axios.put(`${apiUrl}/${id}/cancel`)
  });
  dispatch(getEntities(initpage, initsize, '', initialState.searchForm));
  return result;
};
export const enrolmentEntity: ICrudPutAction<ITContract> = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.ENROLMENT_T_CONTRACT,
    payload: axios.put(`${apiUrl}/${id}/enrolment`)
  });
  dispatch(getEntities(initpage, initsize, 'createdAt,desc', initialState.searchForm));
  return result;
};

export const auditEntity: ICrudPutAction<ITContract> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_T_CONTRACT,
    payload: axios.put(`${apiUrl}/${entity.id}/audit`, cleanEntity(entity))
  });
  // dispatch(reset());
  dispatch(getEntities(initpage, initsize, 'createdAt,desc', initialState.searchForm));
  return result;
};

export const deleteEntity: ICrudDeleteAction<ITContract> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_T_CONTRACT,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities(initpage, initsize, 'createdAt,desc', initialState.searchForm));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const updateFormEntity = (formEntity: ITContract) => ({
  type: ACTION_TYPES.UPDATE_FORM_ENTITY,
  payload: formEntity
});
// 检查房间状态
// @ts-ignore
export const checkRooms = (ids, tcontractId) => async dispatch => {
  const apiUrl1 = 'house-rooms/canCreateOrUpdateTcontract?tcontractId=';
  return dispatch({
    type: ACTION_TYPES.CHECK_ROOMS,
    // @ts-ignore
    payload: axios.post(`${apiPrefix}/${apiUrl1}${tcontractId}`, { ids: [...ids] })
  });
};

export const getFormEntity = () => ({
  type: ACTION_TYPES.GET_FORM_ENTITY
});

export const nextStep = () => ({
  type: ACTION_TYPES.NEXT_STEP
});

export const prevStep = () => ({
  type: ACTION_TYPES.PREV_STEP
});

export const resetStep = () => ({
  type: ACTION_TYPES.RESET_STEP
});

export const resetFormEntity = () => ({
  type: ACTION_TYPES.RESET_FORM_ENTITY
});

export function reduceExpenses(expenses: IContractExpense[], expenseGradeTree: IExpenseGradeTree) {
  return expenses.reduce(
    (result, expense) => {
      const expenseGrade = expenseGradeTree.expenseGradesWithMap[expense.expenseGradeId];
      if (expenseGrade) {
        expense.expenseGrade = expenseGrade;
        if (expenseGrade.id === 1 || expenseGrade.id === 2) {
          return result;
        }
        expense.name = expenseGrade.name;
        if (expenseGrade.parentId && expenseGrade.parentId !== 1 && expenseGrade.parentId !== 2) {
          const parent = expenseGradeTree.expenseGradesWithMap[expenseGrade.parentId];
          if (parent) {
            expense.name = `${parent.name} / ${expense.name}`;
          }
        }
        if (expenseGrade.parentId === 1) {
          result.deposit.push(expense);
        } else if (expenseGrade.parentId === 2) {
          result.lifeFee.push(expense);
        } else if (expenseGrade.type === ExpenseType.IN) {
          result.inGrades.push(expense);
        }
      }
      return result;
    },
    { deposit: [], lifeFee: [], inGrades: [] }
  );
}

export function reduceContractFacilities(contractFacilities: IContractFacilities[], facilities: IFacilities[]): IContractFacilitiesTree {
  const contractFacilitiesWithId = contractFacilities.reduce((result, cf) => {
    result[cf.facilitiesId] = cf;
    return result;
  }, {});
  return facilities.reduce(
    (result, f) => {
      const facId = contractFacilitiesWithId[f.id];
      const fac = {
        total: facId ? facId.total : 0,
        facilitiesId: f.id,
        contractId: facId ? facId.contractId : null,
        facilities: f
      };
      if (f.type === FacilitiesType.BASIC) {
        result.basics.push(fac);
      } else if (f.type === FacilitiesType.FEATURE) {
        result.features.push(fac);
      }
      return result;
    },
    { basics: [], features: [] }
  );
}

export function existsRentedHouseRoom(id: number) {
  return axios.put<ITContract>(`${apiUrl}/${id}/existRentedHouseRoom`);
}

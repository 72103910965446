import axios from 'axios';
import * as qs from 'qs';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { defaultValue, IHydropowerAccount } from 'app/shared/model/hydropower_account';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_HYDROPOWERACCOUNT_LIST: 'hydropowerConfiguration/FETCH_HYDROPOWERACCOUNT_LIST', // 水电列表
  FETCH_HYDROPOWERACCOUNTLOG_LIST: 'hydropowerConfiguration/FETCH_HYDROPOWERACCOUNTLOG_LIST', // 水电配置日志列表
  DELETE_HYDROPOWERACCOUNT: 'hydropowerConfiguration/DELETE_HYDROPOWERACCOUNT', // 删除
  RESET: 'hydropowerConfiguration/RESET' // 重置
};
// state
const initialState = {
  loading: false, // loading
  fetching: false, // Loading
  loadMore: false, // 加载更多
  errorMessage: null, // 错误信息
  entities: [] as ReadonlyArray<IHydropowerAccount>, // 搜索列表
  logList: [] as ReadonlyArray<IHydropowerAccount>, // 日志列表
  entity: defaultValue, // 初始化
  updating: false, // 是否加载
  totalItems: 0, // 总条数
  updateSuccess: false // 是否更新成功
};

export type hydropowerAccountState = Readonly<typeof initialState>;

// Reducer
export default (state: hydropowerAccountState = initialState, action): hydropowerAccountState => {
  switch (action.type) {
    // 加载开始
    case REQUEST(ACTION_TYPES.DELETE_HYDROPOWERACCOUNT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case REQUEST(ACTION_TYPES.FETCH_HYDROPOWERACCOUNT_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.FETCH_HYDROPOWERACCOUNTLOG_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    // 接口失败
    case FAILURE(ACTION_TYPES.FETCH_HYDROPOWERACCOUNT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_HYDROPOWERACCOUNTLOG_LIST):
    case FAILURE(ACTION_TYPES.DELETE_HYDROPOWERACCOUNT):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.DELETE_HYDROPOWERACCOUNT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    // 接口成功
    case SUCCESS(ACTION_TYPES.FETCH_HYDROPOWERACCOUNT_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data || [],
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_HYDROPOWERACCOUNTLOG_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        logList: action.payload.data || [],
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/house-room-water-electricity'; // 水电管理
// Actions
// 水电管理，列表
export const getEntities: ICrudGetListAction<IHydropowerAccount> = (page, size, sort, searchForm) => {
  const requestUrl = `api/house-room-water-electricity-list?page=${page}&size=${size}`;
  return {
    type: ACTION_TYPES.FETCH_HYDROPOWERACCOUNT_LIST,
    payload: axios.get<IHydropowerAccount>(`${requestUrl}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params)
    }),
    meta: { transformUserName: true }
  };
};

// 获取水电日志列表数据
export const getLogEntity: ICrudGetListAction<IHydropowerAccount> = (page, size, sort, searchForm) => {
  const requestUrl = `api/house-room-water-electricity-clear-list?page=${page}&size=${size}`;
  return {
    type: ACTION_TYPES.FETCH_HYDROPOWERACCOUNTLOG_LIST,
    payload: axios.get<IHydropowerAccount>(`${requestUrl}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params)
    }),
    meta: { transformUserName: true }
  };
};

// 删除
export const deleteEntity = (id: number, type: string, userId: number) => async dispatch => {
  const requestUrl = `api/house-room-water-electricity-clear?houseRoomId=${id}&waterElectricityType=${type}&userId=${userId}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_HYDROPOWERACCOUNT,
    payload: axios.post(requestUrl)
  });
  return result;
};
// 搜索重置
export const reset = () => ({
  type: ACTION_TYPES.RESET
});

import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Modal } from 'antd';
import { translate, log } from 'react-jhipster';

import { clearUser, deleteUser, getUser } from './user-management.reducer';
import { IRootState } from 'app/shared/reducers';
import { deleteToList } from 'app/shared/util/url-utils';
import { IUser } from 'app/shared/model/user.model';

export interface IUserManagementDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ login: string }> {}

class UserManagementDeleteDialog extends React.Component<IUserManagementDeleteDialogProps> {
  componentDidMount(): void {
    const userPromise: Promise<IUser> = this.props.getUser(this.props.match.params.login) as any;
    userPromise.then(() => {
      const { history, match, user } = this.props;
      Modal.confirm({
        title: translate('entity.delete.title'),
        content: translate('userManagement.delete.question', { login: user.name || user.login }),
        okText: translate('entity.action.delete'),
        okType: 'danger',
        cancelText: translate('entity.action.cancel'),
        onOk: () => {
          const deletePromise: Promise<any> = this.props.deleteUser(user.login) as any;
          deletePromise.then(() => {
            history.replace(deleteToList(match.url));
            this.props.clearUser();
          });
          return deletePromise;
        },
        onCancel: () => {
          this.props.clearUser();
          history.replace(deleteToList(match.url));
        }
      });
    });
  }

  render() {
    return <></>;
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  user: storeState.userManagement.user
});

const mapDispatchToProps = { getUser, deleteUser, clearUser };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementDeleteDialog);

import { Moment } from 'moment';
import { IRangeFilter, IStringFilter, IFilter } from 'app/shared/model/filter.model';

export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING'
}
export interface ICallSurvey {
  name?: string; // 姓名
  phone?: string; // 电话
  sex?: string; // 性别
  age?: number; // 年龄
  answerBy?: number; // 接听人
  remark?: string; // 备注
  budget?: string; // 预算
  industry?: string; // 行业
  industry_other?: string; // 所属行业：其他
  source?: string; // 如何知道项目
  source_other?: string; // 如何知道项目其他
  satisfaction?: string; // 满意度
  satisfaction_other?: string; // 满意度: 其他
  opinion?: string; // 意见
  houseId?: number; // 房源
  roomType?: string; // 户型
  callDate?: Date; // 接听时间
  inputDate?: Date; // 录入日期
  answerName?: string; // 接听人
  houseName?: string; // 意向小区名
}

export const defaultValue: Readonly<ICallSurvey> = {};

export interface ISearchForm {
  name?: IStringFilter;
  roomType?: IFilter;
  sex?: IFilter;
  houseId?: IFilter;
  budget?: IFilter;
  answerBy?: IFilter;
  source?: IFilter; // 来源
  age?: IFilter;
  createdAt?: IRangeFilter; // 创建日期
  callDate?: IRangeFilter; // 签约日期
  inputDate?: IRangeFilter; // 录入日期
}

export const defaultSearchForm: ISearchForm = {
  name: { contains: '' },
  sex: { equals: '' },
  budget: { equals: '' },
  source: { equals: '' },
  age: { equals: '' },
  houseId: { in: null },
  answerBy: { in: [] },
  createdAt: {
    lessThan: '',
    greaterThan: ''
  },
  roomType: {
    equals: ''
  },
  callDate: {
    lessThan: '',
    greaterThan: ''
  },
  inputDate: {
    lessThan: '',
    greaterThan: ''
  }
};

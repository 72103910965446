import { Moment } from 'moment';
import { IFilter, IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { HouseState, IHouseRoom } from 'app/shared/model/house-room.model';
import { IHouse } from 'app/shared/model/house.model';

export const enum PopularizeSynchState {
  DOING = 'DOING',
  UNSYNCH = 'UNSYNCH',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

export interface IPopularize {
  id?: number;
  hasFiveEight?: boolean;
  hasJianRong?: boolean;
  createdAt?: Moment;
  updatedAt?: Moment;
  createdBy?: string;
  jianRongState?: PopularizeSynchState;
  fiveEightState?: PopularizeSynchState;
  updatedBy?: string;
  houseRoomId?: number;
  houseRoom?: IHouseRoom;
  house?: IHouse;
  houseId?: number;
}

export const defaultValue: Readonly<IPopularize> = {
  hasFiveEight: false,
  jianRongState: PopularizeSynchState.UNSYNCH,
  fiveEightState: PopularizeSynchState.UNSYNCH,
  hasJianRong: false
};

export interface ISearchForm {
  hasFiveEight?: IFilter;
  hasJianRong?: IFilter;
  createdAt?: IRangeFilter;
  state?: IStringFilter;
  houseRoomId?: IStringFilter;
  houseId?: IStringFilter;
  updatedAt?: IRangeFilter;
  createdBy?: IStringFilter;
  updatedBy?: IStringFilter;
}

export const defaultSearchForm: Readonly<ISearchForm> = {
  hasFiveEight: {
    equals: ''
  },
  houseId: { equals: '' },
  houseRoomId: { equals: '' },
  hasJianRong: { equals: '' },
  state: { in: [] },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { equals: '' },
  updatedBy: { equals: '' }
};

import { CategoryState } from 'app/shared/model/t-contract.model';
/*
 * @Author: luoziyan 365977508@qq.com
 * @Date: 2022-08-16 16:44:15
 * @LastEditors: luoziyan 365977508@qq.com
 * @LastEditTime: 2024-05-08 16:26:24
 * @FilePath: \lehome-central\src\main\webapp\app\shared\model\refund-rental.model.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Moment } from 'moment';
import { IAttachment } from 'app/shared/model/attachment.model';
import { IDeductionItem } from 'app/shared/model/deduction-item.model';
import { IContract, defaultValue as contractDefaultValue } from 'app/shared/model/contract.model';
import { IRangeFilter, IStringFilter, IFilter } from 'app/shared/model/filter.model';
import { ITContract, defaultValue as tContractDefaultValue } from './t-contract.model';
// 状态
export const enum RefundRentalState {
  WAITING = 'WAITING',
  VERIFIED = 'VERIFIED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}

export interface IRefundRental {
  id?: number; // id
  state?: RefundRentalState; // 状态
  verifiedAt?: Moment; // 日期
  verifiedContent?: string; // verifiedContent
  auditAt?: Moment; // 审核日期
  auditDescription?: string; // 审核描述
  auditorName?: string; // 审核名称
  content?: string; // 审核内容
  refundAmount?: number; // 金额
  userName?: string; // 用户姓名
  mobile?: string; // 手机号
  roomName?: string; // 房间号
  createdAt?: Moment; // 新建日期
  createdBy?: string; // 新增
  updatedBy?: string; // 更新
  updatedAt?: Moment; // 更新日期
  houseRoomId?: number; // 房间号id
  contractId?: number; // 合同id
  userId?: number; // 用户id
  auditorId?: number; // 审核人id
  validatorId?: number; // 检验人id
  attachments?: IAttachment[]; // attachments
  deductionItems?: IDeductionItem[]; // deductionItems
  contract?: IContract; // 合同
  tcontractId?: number; // 团租合同id
  tcontract?: ITContract; // 普通合同id
}

export const defaultValue: Readonly<IRefundRental> = {
  attachments: [], // attachments
  deductionItems: [], // deductionItems
  contract: contractDefaultValue, // 普通合同
  tcontract: tContractDefaultValue // 团租合同
};

export interface ISearchForm {
  houseRoomId?: IRangeFilter; // 房间号id
  userId?: IRangeFilter; // useid
  createdAt?: IRangeFilter; // 日期
  createdBy?: IStringFilter; // 创建
  houseId?: IRangeFilter; // 房源
  category?: IFilter; // category
}

export const defaultSearchForm: ISearchForm = {
  createdAt: {
    // 创建日期
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' }, // 创建人
  houseId: {
    // 房源
    in: []
  },
  houseRoomId: {
    // 房间号
    in: []
  },
  userId: {
    // 用户
    in: []
  },
  category: {
    // 类型
    in: ['CONTRACT', 'TCONTRACT'] // 普通、商务
  }
};

export interface IMSearchForm {
  houseRoomId?: IRangeFilter; // 房间号
  userId?: IRangeFilter; // 用户
  createdAt?: IRangeFilter; // 创建人
  createdBy?: IStringFilter; // 创建时间
  houseId?: IRangeFilter; // 房源
  category?: IFilter; // 类型
}

export const MdefaultSearchForm: IMSearchForm = {
  createdAt: {
    // 创建日期
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' }, // 创建者
  houseId: {
    // 房源
    in: []
  },
  houseRoomId: {
    // 房间号
    in: []
  },
  userId: {
    // useid
    in: []
  },
  category: {
    // 类型
    in: ['MCONTRACT']
  }
};

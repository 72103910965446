import axios from 'axios';
import * as qs from 'qs';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { defaultValue, IHydropowerRecharge } from 'app/shared/model/hydropower_recharge';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';

// 请求
export const ACTION_TYPES = {
  FETCH_HYDROPOWERRECHARGE_LIST: 'hydropowerConfiguration/FETCH_HYDROPOWERRECHARGE_LIST', // 水电列表
  FETCH_HYDROPOWERRECHARGE_TOTAl: 'hydropowerConfiguration/FETCH_HYDROPOWERRECHARGE_TOTAl', // 水电统计
  RESET: 'hydropowerConfiguration/RESET' // 重置
};
// state
const initialState = {
  loading: false, // 加载
  fetching: false, // 加载
  loadMore: false, // h5加载更多
  errorMessage: null, // 错误信息
  entities: [] as ReadonlyArray<IHydropowerRecharge>, // 列表
  logList: [] as ReadonlyArray<IHydropowerRecharge>, // log列表
  entity: defaultValue, // 加载初始值
  updating: false, // 是否更新
  totalItems: 0, // 列表总条数
  updateSuccess: false, // 是否更新成功
  // 统计
  totalFetching: false, // 统计接口是否返回
  rechargeWaterAmount: 0, // 水费
  rechargeElectricityAmount: 0 // 电费
};

export type hydropowerRechargeState = Readonly<typeof initialState>;

// Reducer
export default (state: hydropowerRechargeState = initialState, action): hydropowerRechargeState => {
  switch (action.type) {
    // 接口开始
    case REQUEST(ACTION_TYPES.FETCH_HYDROPOWERRECHARGE_TOTAl):
    case REQUEST(ACTION_TYPES.FETCH_HYDROPOWERRECHARGE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true,
        totalFetching: true,
        rechargeWaterAmount: 0, // 水费
        rechargeElectricityAmount: 0 // 电费
      };
    // 接口失败
    case FAILURE(ACTION_TYPES.FETCH_HYDROPOWERRECHARGE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_HYDROPOWERRECHARGE_TOTAl):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    // 接口成功
    case SUCCESS(ACTION_TYPES.FETCH_HYDROPOWERRECHARGE_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data || [],
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };

    case SUCCESS(ACTION_TYPES.FETCH_HYDROPOWERRECHARGE_TOTAl):
      return {
        ...state,
        totalFetching: false,
        rechargeWaterAmount: action.payload.data.rechargeWaterAmount, // 水费
        rechargeElectricityAmount: action.payload.data.rechargeElectricityAmount // 电费
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/housesView'; // 水电管理
// Actions
// 水电管理，列表
export const getEntities: ICrudGetListAction<IHydropowerRecharge> = (page, size, sort, searchForm) => {
  const requestUrl = `api/house-room-water-electricity-add-list?page=${page}&size=${size}`;
  return {
    type: ACTION_TYPES.FETCH_HYDROPOWERRECHARGE_LIST,
    payload: axios.get<IHydropowerRecharge>(`${requestUrl}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params)
    }),
    meta: { transformUserName: true }
  };
};

// 统计
export const getTotal = searchForm => ({
  type: ACTION_TYPES.FETCH_HYDROPOWERRECHARGE_TOTAl,
  payload: axios.get(`api/house-room-water-electricity-add-list-statistics`, {
    params: flattenKey({ ...searchForm, size: 999999 }),
    paramsSerializer: params => qs.stringify(params)
  })
});
// 重置
export const reset = () => ({
  type: ACTION_TYPES.RESET
});

// 用户统计 定义
import moment, { Moment } from 'moment';
import { IRangeFilter, IStringFilter, IFilter } from 'app/shared/model/filter.model';

// 返回状态定义
export interface IUse {
  name?: String; // 姓名
  mobile?: String; // 手机
  updatedAt?: String; // 日期
}

export const defaultValue: Readonly<IUse> = {};

export interface ISearchForm {
  name?: IStringFilter; // 姓名
  updatedAt?: String; // 时间
  endDate?: String; // 结束日期
  useDate?: IRangeFilter; // 使用日期
  firstInterviewMkTime?: IRangeFilter;
}

export const defaultSearchForm: ISearchForm = {
  name: { contains: null },
  firstInterviewMkTime: {
    greaterOrEqualThan: moment()
      .startOf('month')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    lessOrEqualThan: moment()
      .endOf('month')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  }
};

import { Moment } from 'moment';
import { IRangeFilter, IStringFilter, IFilter } from 'app/shared/model/filter.model';

export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING'
}
export interface IRefundRentalSurvey {
  name?: string; // 姓名
  phone?: string; // 电话
  sex?: string; // 性别
  age?: number; // 年龄
  enteredBy?: number;
  enterName?: number; // 录入人
  followBy?: number;
  followName?: string; // 跟进入
  remark?: string; // 备注
  budget?: string; // 预算
  industry?: string; // 行业
  industry_other?: string; // 所属行业：其他
  source?: string; // 如何知道项目
  source_other?: string; // 如何知道项目其他
  appraise?: string; // 满意度
  // satisfaction_other?: string;  // 满意度: 其他
  opinion?: string; // 意见
  buildingCode?: string;
  unitCode?: string;
  floor?: string;
  roomCode?: string;
  houseId?: IRangeFilter; // 房源
  roomName?: string;
  inputDate?: Date; // 录入日期
  houseName?: string;
  roomId?: IRangeFilter;
  reason?: string;
  reason_other?: string;
  state?: string;
  workplace?: string;
  sourceWay?: string;
  sourceWay_other?: string;
  appraise_other?: string;
  contractId?: number; // 合同
  leaseTerm?: string;
  service?: string;
  clean?: string;
  facilities?: string;
  repair?: string;
  safe?: string;
  enteredName?: string; // 录入人
}

export const defaultValue: Readonly<IRefundRentalSurvey> = {};

export interface ISearchForm {
  name?: IStringFilter;
  sex?: IFilter;
  houseId?: IFilter;
  budget?: IFilter;
  isSign?: IFilter;
  service?: IFilter;
  enteredBy?: IFilter;
  followBy?: IFilter;
  source?: IStringFilter; // 来源
  age?: IFilter;
  industry?: string;
  industry_other?: string; //
  source_other?: string; // 来源其他
  createdAt?: IRangeFilter; // 创建日期
  signAt?: IRangeFilter; // 签约日期
  roomId?: IFilter; // 房号
  inputDate?: IRangeFilter; // 录入日期
}

export const defaultSearchForm: ISearchForm = {
  name: { contains: '' },
  sex: { equals: '' },
  budget: { equals: '' },
  isSign: { equals: '' },
  service: { equals: '' },
  source: { contains: '' },
  age: { equals: '' },
  houseId: { in: [] },
  enteredBy: { in: [] },
  followBy: { in: [] },
  roomId: {
    equals: ''
  },
  createdAt: {
    lessThan: '',
    greaterThan: ''
  },
  signAt: {
    lessThan: '',
    greaterThan: ''
  },
  inputDate: {
    lessThan: '',
    greaterThan: ''
  }
};

import moment, { isMoment } from 'moment';

import {
  APP_DATE_FORMAT,
  APP_LOCAL_DATE_FORMAT,
  APP_LOCAL_MONTH_FORMAT,
  APP_LOCAL_DATETIME_FORMAT,
  APP_LOCAL_DATETIME_T_FORMAT
} from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = date => (date ? formatInstant(date) : null);

export const converMonthTimeToServer = date => (date ? formatInstantMonth(date) : null);

export const convertDateToServer = date => (date ? formatInstantDate(date) : null);

export const convertDateEndToServer = date => (date ? formatInstantDateEnd(date) : null);

// export const convertDateTime = date => (date ? formatInstant(date) : null);

export const stringToDate = stringDate => {
  const m = moment(stringDate);
  if (m.isValid()) return moment(stringDate);
  return null;
};

export const stringToLocalDate = stringDate => {
  const m = moment(stringDate);
  if (m.isValid()) return moment(stringDate).subtract(8, 'hours');
  return null;
};
export const formatLocalDate = date =>
  date
    ? moment(date)
        .subtract(8, 'hours')
        .format(APP_DATE_FORMAT)
    : date;

export const formatLocalDateFormat = date =>
  date
    ? moment(date)
        .subtract(8, 'hours')
        .format(APP_LOCAL_DATE_FORMAT)
    : date;
export const formatDate = date => (date ? moment(date).format(APP_DATE_FORMAT) : date); // 返回date格式
export const formatDateSuffix = (date, suffix = '') => (date ? moment(date).format(APP_DATE_FORMAT) + ' ' + suffix : date);
export const formatDateDay = date => {
  // 返回日期
  if (isMoment(date)) {
    return date.format(APP_LOCAL_DATE_FORMAT);
  }
  return date ? moment(date).format(APP_LOCAL_DATE_FORMAT) : date;
};

export const formatInstantMonth = date =>
  date
    ? moment(date)
        .utc()
        .format(APP_LOCAL_MONTH_FORMAT)
    : date;

export const formatInstant = date =>
  date
    ? moment(date)
        .utc()
        .format(APP_LOCAL_DATETIME_T_FORMAT)
    : date;

export const formatInstantDate = date =>
  date
    ? moment(date)
        .seconds(0)
        .hours(0)
        .minutes(0)
        .milliseconds(0)
        .utc()
        .format(APP_LOCAL_DATETIME_T_FORMAT)
    : date;

export const formatInstantDateEnd = date =>
  date
    ? moment(date)
        .seconds(0)
        .hours(0)
        .minutes(0)
        .milliseconds(0)
        .add(1, 'd')
        .subtract(1, 'ms')
        .utc()
        .format(APP_LOCAL_DATETIME_T_FORMAT)
    : date;

export const getCurrentTime = () => moment().format('X');

/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import styles from './index.less';

import React from 'react';
import { Switch } from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import Login from 'app/modules/login/login';
import GlobalFooter from 'app/shared/layout/footer/footer';
import { translate } from 'react-jhipster';

export interface IUserLayoutProps {
  match: any;
}

const logo = '/content/images/logo.svg';

export default class UserLayout extends React.Component<IUserLayoutProps> {
  render() {
    const { match } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.header}>
              <span className={styles.title}>{translate('global.title')}</span>
            </div>
            <div className={styles.desc} />
          </div>
          <Switch>
            <ErrorBoundaryRoute path={`${match.url}/login`} component={Login} />
          </Switch>
        </div>
        <GlobalFooter />
      </div>
    );
  }
}

import { Moment } from 'moment';
import { IPayment } from 'app/shared/model/payment.model';
import { IContract } from 'app/shared/model/contract.model';
import { IRangeFilter, IStringFilter, IFilter } from 'app/shared/model/filter.model';
import { ExpenseType } from 'app/shared/model/expense-grade.model';
import { IHouse } from './house.model';

export const enum OrderState {
  PAYMENT = 'PAYMENT',
  WAITING = 'WAITING',
  WAITING_CONFIRM = 'WAITING_CONFIRM',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  REFUNDED = 'REFUNDED',
  REFUNDED_FAILED = 'REFUNDED_FAILED',
  NULLIFIED = 'NULLIFIED'
}

export const enum LockBelatedType {
  TODAY = 'TODAY',
  FOREVER = 'FOREVER'
}

export const enum CategoryType {
  CONTRACT_ORDERS = 'CONTRACT_ORDERS',
  TCONTRACT_ORDERS = 'TCONTRACT_ORDERS'
}

export interface IOrderItems {
  id?: number;
  name?: string;
  amount?: number;
  amountPaid?: number;
  position?: number;
  type?: ExpenseType;
  orderItems?: any;
  roomName?: any; // 房间名称
}

export interface IAdjustOrderItemLogs {
  id?: number;
  orders?: any;
  orderItemName?: string;
  beforeAmount?: number;
  afterAmount?: number;
  createdAt?: string;
}

export interface IOrders {
  id?: number;
  number?: string;
  total?: number;
  belated?: number;
  name?: string;
  house?: IHouse;
  parkingLotName?: string; // 车位名称
  description?: string;
  state?: OrderState;
  completedAt?: Moment;
  canceledAt?: Moment;
  refundedAt?: Moment;
  payableAt?: Moment;
  createdAt?: Moment;
  createdBy?: string;
  confirmAt?: Moment;
  updatedBy?: string;
  updatedAt?: Moment;
  userId?: number;
  payments?: IPayment[];
  porderItems?: IOrderItems[];
  username?: string; // 用户名
  mobile?: string; // 手机
  pcontractId?: number;
  startAt?: Moment;
  endAt?: Moment;
  transferredRemark?: string;
  adjustOrderItemLogs?: IAdjustOrderItemLogs[];
  liquidationAmount?: Number;
  liquidationFee?: Number;
  liquidationDate?: string;
  liquidationStatus?: string;
  category?: string;
  totalActualSingleMonthPrice?: number; // 月实际租金
  totalDeposit?: number; // 总押金
  totalRental?: number; // 总房租
}

export const defaultValue: Readonly<IOrders> = {
  porderItems: [],
  payments: []
};

export interface ISearchForm {
  parkingLotId?: IRangeFilter;
  userId?: IRangeFilter;
  createdAt?: IRangeFilter;
  startAt?: IRangeFilter;
  createdBy?: IStringFilter;
  houseId?: IRangeFilter;
  state?: IStringFilter;
  category?: IFilter;
  payableAt?: IRangeFilter;
  completedAt?: IRangeFilter;
  username?: IStringFilter;
}
// 账单搜索表格初始化
export const defaultSearchForm: ISearchForm = {
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  startAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  payableAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  completedAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  houseId: {
    in: []
  },
  parkingLotId: {
    in: []
  },
  userId: {
    in: []
  },
  state: {
    equals: ''
  },
  category: {
    equals: 'PCONTRACT_ORDER'
  },
  username: {}
};

import { Axis, Chart, Geom, Tooltip } from 'bizcharts';
import React, { Component } from 'react';

import Debounce from 'lodash.debounce';
import autoHeight from '../commons/autoHeight';
import styles from './index.less';

export interface IBarLineProps {
  height?: number;
  data: string[];
}
export default class BarLine extends Component<
  IBarLineProps,
  {
    autoHideXLabels: boolean;
  }
> {
  state = {
    autoHideXLabels: false
  };

  root: HTMLDivElement | undefined = undefined;

  node: HTMLDivElement | undefined = undefined;

  resize = Debounce(() => {
    if (!this.node || !this.node.parentNode) {
      return;
    }
    const canvasWidth = (this.node.parentNode as HTMLDivElement).clientWidth;
    const { data = [] } = this.props;
    const minWidth = data.length * 30;
    const { autoHideXLabels } = this.state;

    if (canvasWidth <= minWidth) {
      if (!autoHideXLabels) {
        this.setState({
          autoHideXLabels: true
        });
      }
    } else if (autoHideXLabels) {
      this.setState({
        autoHideXLabels: false
      });
    }
  }, 500);

  componentDidMount() {
    window.addEventListener('resize', this.resize, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  handleRoot = (n: HTMLDivElement) => {
    this.root = n;
  };

  handleRef = (n: HTMLDivElement) => {
    this.node = n;
  };

  render() {
    const forceFit = true;
    const color = 'l (270) 1:rgba(231, 39, 47, 1) 0:rgba(230, 33, 41, 0)';
    const padding = null;
    const lineColor = 'rgba(245, 167, 171, 1)';
    const { height = 1, data } = this.props;
    const { autoHideXLabels } = this.state;

    if (!data) {
      return <></>;
    }

    const scale = {
      x: {
        type: 'cat'
      },
      y: {
        min: 0
      },
      出租率: {
        min: 0
      }
    };

    const tooltip: [string, (...args: any[]) => { name?: string; value: string }] = [
      'x*y',
      (x: string, y: string) => ({
        name: '出租数',
        value: y
      })
    ];

    const tooltipLine: [string, (...args: any[]) => { name?: string; value: string }] = [
      'x*rate',
      (x: string, y: string) => ({
        name: '出租率',
        value: y ? y + '%' : y
      })
    ];

    return (
      <div className={styles.chart} style={{ height }} ref={this.handleRoot}>
        <div ref={this.handleRef}>
          {/* 图标父组件 */}
          <Chart scale={scale} height={height} forceFit={forceFit} data={data} padding={padding || 'auto'}>
            {/* 坐标组件 */}
            <Axis name="x" title={false} label={autoHideXLabels ? undefined : {}} tickLine={autoHideXLabels ? undefined : {}} />
            <Axis name="y" min={0} />
            <Tooltip showTitle={false} crosshairs={false} />
            {/* 柱体 */}
            <Geom type="interval" position="x*y" size={30} color={color} tooltip={tooltip} />
            <Geom type="line" position="x*rate" size={2} color={lineColor} shape={'smooth'} tooltip={tooltipLine} />
          </Chart>
        </div>
      </div>
    );
  }
}

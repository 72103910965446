import styles from '../../home.less';

import React from 'react';
import { Row, Col } from 'antd';

import assetIcon from '../static/image/asset-icon.png';

interface IAsset {
  RENTED?: string; // 在租中
  IDLE?: string; // 空置
  ABOUT_TO_EXPIRE?: string; // 即将到期
  apartmentCount?: string; // 公寓数量
  commercialCount?: string; // 商品数量
  stationaryCount?: string; // 固定车位数量
  flowCount?: string; // 流动车位数量
}

interface IAssetProp {
  data: IAsset[];
}

export default class Asset extends React.Component<IAssetProp, {}> {
  renderIcon(title) {
    return (
      <div>
        <i>
          <img src={assetIcon} width={24} />
        </i>
        <span className={styles.icontitle}>{title}</span>
      </div>
    );
  }

  renderTitle() {
    return (
      <Row className={styles.rentTitle}>
        <Col>{this.renderIcon('资产类型')}</Col>
      </Row>
    );
  }

  rederContain() {
    const { data } = this.props;
    const type = [
      { value: 'apartmentCount', label: '公寓' },
      { value: 'commercialCount', label: '商铺' },
      { value: 'stationaryCount', label: '固定车位' },
      { value: 'flowCount', label: '流动车位' }
    ];
    return (
      <div>
        <Row className={styles.tTitle}>
          <Col span={4}>类型</Col>
          <Col span={5}>数量</Col>
          <Col span={5}>在租中</Col>
          <Col span={5}>空置中</Col>
          <Col span={5}>即将到期</Col>
        </Row>
        {data &&
          data.map((item, index) => (
            <Row className={styles.tRow} key={`${item}-${index}`}>
              <Col span={4} key={`${type}-label-${index}`}>
                {type[index]['label']}
              </Col>
              <Col span={5} key={`${type}-value-${index}`}>
                {item[type[index]['value']]}
              </Col>
              <Col span={5} key={`${type}-RENTED-${index}`}>
                {' '}
                {item.RENTED}
              </Col>
              <Col span={5} key={`${type}-IDLE-${index}`}>
                {item.IDLE}
              </Col>
              <Col span={5} key={`${type}-ABOUT_TO_EXPIRE-${index}`}>
                {item.ABOUT_TO_EXPIRE}
              </Col>
            </Row>
          ))}
      </div>
    );
  }

  render() {
    return (
      <div className={`${styles.rent} ${styles.mt16}`}>
        {this.renderTitle()}
        {this.rederContain()}
      </div>
    );
  }
}

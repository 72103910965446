/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';
import { Button, Card, Checkbox, Divider, Form, Icon, Input, Radio, Select, Spin, Switch } from 'antd';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { createUser, getRoles, getUser, reset, updateUser } from './user-management.reducer';
import { IRootState } from 'app/shared/reducers';

import PasswordStrengthInput from 'app/shared/components/password/password-strength-input';
import { editToList, newToList } from 'app/shared/util/url-utils';
import { LIVING_STATE } from 'app/config/constants';
import { livingStateToChinese } from 'app/modules/administration/user-management/user-utils';
import { BankName, UserType } from 'app/shared/model/user.model';

export interface IUserManagementUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ login: string }> {
  form: any;
}

export interface IUserManagementUpdateState {
  isNew: boolean;
  appKeyFetching: boolean;
  type?: UserType;
}

const FormItem = Form.Item;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;

export class UserManagementUpdate extends React.Component<IUserManagementUpdateProps, IUserManagementUpdateState> {
  state: IUserManagementUpdateState = {
    isNew: !this.props.match.params || !this.props.match.params.login,
    appKeyFetching: false
  };

  componentDidMount() {
    !this.state.isNew && this.props.getUser(this.props.match.params.login);
    this.props.getRoles();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  saveUser = event => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values['id'] = this.props.user.id;
        if (values.type !== UserType.BACKEND) {
          values['authorities'] = [];
        }
        const result: any = this.state.isNew ? this.props.createUser(values) : this.props.updateUser(values);
        result.then(() => {
          this.handleClose();
        });
      }
    });
  };

  handleTypeChange = ({ target: { value } }: any) => {
    this.setState({ type: value });
  };

  handleClose = () => {
    const { history, match } = this.props;
    const { isNew } = this.state;
    // history.replace(isNew ? newToList(match.url) : editToList(match.url));
    history.replace(isNew ? newToList(match.url) : '/system/user-management');
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && !/^[A-Za-z0-9]+$/.test(value)) {
      form.validateFields(['password'], { force: true });
    }
    callback();
  };

  isRoleAdmin = () => {
    const { account } = this.props;
    if (typeof account.roles === 'object' && account.roles.length) {
      for (const i of Object.keys(account.roles)) {
        const item = account.roles[i];
        if (item === 'ROLE_ADMIN') {
          return true;
        }
      }
    }
    return false;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { user, loading, updating, roles } = this.props;
    const { isNew, type } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      }
    };

    getFieldDecorator('langKey', {
      initialValue: 'zh-cn'
    });
    return (
      <PageHeaderWrapper title={translate(isNew ? 'userManagement.home.createLabel' : 'userManagement.home.createOrEditLabel')}>
        <Card bordered={false}>
          <Spin spinning={loading}>
            <Form onSubmit={this.saveUser}>
              <Divider orientation="left">
                <Icon type="login" />
                &nbsp;
                {translate('userManagement.section.one')}
              </Divider>

              <FormItem {...formItemLayout} label={translate('userManagement.login')}>
                {getFieldDecorator('login', {
                  rules: [
                    {
                      required: true,
                      message: translate('register.messages.validate.login.required')
                    },
                    {
                      pattern: '^[_.@A-Za-z0-9-]*$',
                      message: translate('register.messages.validate.login.pattern')
                    },
                    {
                      min: 1,
                      message: translate('register.messages.validate.login.minlength')
                    },
                    {
                      max: 50,
                      message: translate('register.messages.validate.login.maxlength')
                    }
                  ],
                  initialValue: user.login
                })(<Input disabled={!this.state.isNew} />)}
              </FormItem>
              <FormItem {...formItemLayout} label={translate('global.menu.account.password')}>
                {/* {getFieldDecorator('password', {
                  rules: [
                    {
                      required: this.state.isNew ? true : false,
                      message: translate('userManagement.messages.validate.password.required')
                    },
                    {
                      pattern: '[_.@A-Za-z0-9-]*$',
                      message: translate('userManagement.messages.validate.password.pattern')
                    },
                    {
                      min: 6,
                      message: translate('userManagement.messages.validate.password.minlength')
                    },
                    {
                      max: 60,
                      message: translate('userManagement.messages.validate.password.maxlength')
                    }
                  ],
                  initialValue: ''
                })(<PasswordStrengthInput placeholder={translate('global.form.newpassword.placeholder')} />)} */}
                {getFieldDecorator('password', {
                  rules: [
                    // {
                    //   required: true,
                    //   message: translate('global.messages.validate.newpassword.required')
                    // },
                    {
                      max: 100,
                      message: translate('global.messages.validate.newpassword.maxlength')
                    },
                    {
                      min: 8,
                      message: translate('global.messages.validate.newpassword.minlength')
                    },
                    {
                      validator: this.validateToNextPassword
                    }
                  ]
                })(<PasswordStrengthInput placeholder={translate('global.form.newpassword.placeholder')} />)}
              </FormItem>
              <FormItem {...formItemLayout} label={translate('userManagement.type')}>
                {getFieldDecorator('type', {
                  initialValue: user.type || UserType.LEASEHOLDER
                })(
                  <RadioGroup onChange={this.handleTypeChange} disabled={!this.isRoleAdmin()}>
                    {[UserType.LEASEHOLDER, UserType.LANDLORD, UserType.BACKEND].map(value => (
                      <RadioButton value={value} key={value}>
                        <Translate contentKey={`lehomeApp.UserType.${value}`} />
                      </RadioButton>
                    ))}
                  </RadioGroup>
                )}
              </FormItem>
              {(type || user.type) === UserType.BACKEND && (
                <FormItem {...formItemLayout} label={translate('userManagement.profiles')}>
                  {getFieldDecorator('authorities', {
                    initialValue: user.authorities
                  })(
                    <CheckboxGroup
                      disabled={!this.isRoleAdmin()}
                      options={roles.map(r => ({ label: translate(`roles.${r}`, {}, r), value: r }))}
                    />
                  )}
                </FormItem>
              )}
              <FormItem {...formItemLayout} label={translate('userManagement.activate')}>
                {getFieldDecorator('activated', {
                  valuePropName: 'checked',
                  initialValue: user.activated
                })(
                  <Switch
                    checkedChildren={translate('userManagement.activated')}
                    unCheckedChildren={translate('userManagement.deactivated')}
                  />
                )}
              </FormItem>

              <Divider orientation="left">
                <Icon type="user" />
                &nbsp;
                {translate('userManagement.section.two')}
              </Divider>

              <FormItem {...formItemLayout} label={translate('userManagement.name')}>
                {getFieldDecorator('name', {
                  rules: [
                    {
                      max: 50,
                      message: translate('entity.validation.maxlength')
                    }
                  ],
                  initialValue: user.name
                })(<Input />)}
              </FormItem>
              <FormItem {...formItemLayout} label={translate('userManagement.mobile')}>
                {getFieldDecorator('mobile', {
                  initialValue: user.mobile
                })(<Input />)}
              </FormItem>
              <FormItem {...formItemLayout} label={translate('userManagement.livingState')}>
                {getFieldDecorator('livingState', {
                  initialValue: user.livingState
                })(
                  <Radio.Group buttonStyle="solid">
                    {LIVING_STATE.filter(item => item !== '').map((item, index) => (
                      <Radio.Button value={item} key={index}>
                        {livingStateToChinese(item)}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label={translate('userManagement.graduateFrom')}>
                {getFieldDecorator('graduateFrom', {
                  initialValue: user.graduateFrom
                })(<Input />)}
              </FormItem>
              <FormItem {...formItemLayout} label={translate('userManagement.education')}>
                {getFieldDecorator('education', {
                  initialValue: user.education
                })(<Input />)}
              </FormItem>
              <FormItem {...formItemLayout} label={translate('userManagement.companyName')}>
                {getFieldDecorator('companyName', {
                  initialValue: user.companyName
                })(<Input />)}
              </FormItem>
              <FormItem {...formItemLayout} label={translate('userManagement.workPosition')}>
                {getFieldDecorator('workPosition', {
                  initialValue: user.workPosition
                })(<Input />)}
              </FormItem>
              <FormItem {...formItemLayout} label={translate('userManagement.bankName')}>
                {getFieldDecorator('bankName', {
                  initialValue: user.bankName
                })(
                  <Select>
                    {[BankName.BOCOM, BankName.CCB, BankName.ABC].map(txt => (
                      <Select.Option key={txt} value={txt}>
                        <Translate contentKey={`lehomeApp.BankName.${txt}`} />
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label={translate('userManagement.bankAccount')}>
                {getFieldDecorator('bankAccount', {
                  initialValue: user.bankAccount
                })(<Input />)}
              </FormItem>
              <FormItem {...formItemLayout} label={translate('userManagement.bankUsername')}>
                {getFieldDecorator('bankUsername', {
                  initialValue: user.bankUsername
                })(<Input />)}
              </FormItem>
              <Divider orientation="left">
                <Icon type="idcard" />
                &nbsp;
                {translate('userManagement.section.three')}
              </Divider>
              <FormItem {...formItemLayout} label={translate('userManagement.idCard')}>
                {getFieldDecorator('idCard', {
                  initialValue: user.idCard
                })(<Input />)}
              </FormItem>
              <FormItem className={'tc'}>
                <Button type="primary" htmlType="submit" size={'large'} loading={updating}>
                  <Translate contentKey={'entity.action.save'} />
                </Button>
              </FormItem>
            </Form>
          </Spin>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  user: storeState.userManagement.user,
  roles: storeState.userManagement.authorities,
  loading: storeState.userManagement.loading,
  updating: storeState.userManagement.updating,
  account: storeState.authentication.account
});

const mapDispatchToProps = { getUser, getRoles, updateUser, createUser, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const updateForm = Form.create()(UserManagementUpdate);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(updateForm);

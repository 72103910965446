import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction, IPayload } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import * as qs from 'qs';
import { INotice, defaultValue, ISearchForm } from 'app/shared/model/notice.model';
import { flattenKey } from 'app/shared/util/object-utils';
import { cloneDeepWith } from 'lodash';
import { sendAtToStatus } from './notice-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_NOTICE_LIST: 'notice/FETCH_NOTICE_LIST',
  FETCH_NOTICE: 'notice/FETCH_NOTICE',
  CREATE_NOTICE: 'notice/CREATE_NOTICE',
  UPDATE_NOTICE: 'notice/UPDATE_NOTICE',
  DELETE_NOTICE: 'notice/DELETE_NOTICE',
  CLEAR_NOTICE: 'notice/CLEAR_NOTICE',
  RESET: 'notice/RESET'
};
// state类型
export declare type ICrudGetListAction<T> = (
  page?: number,
  size?: number,
  sort?: string,
  searchForm?: ISearchForm
) => IPayload<T> | ((dispatch: any) => IPayload<T>);
const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<INotice>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type NoticeState = Readonly<typeof initialState>;

// Reducer

export default (state: NoticeState = initialState, action): NoticeState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_NOTICE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_NOTICE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_NOTICE):
    case REQUEST(ACTION_TYPES.UPDATE_NOTICE):
    case REQUEST(ACTION_TYPES.DELETE_NOTICE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_NOTICE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_NOTICE):
    case FAILURE(ACTION_TYPES.CREATE_NOTICE):
    case FAILURE(ACTION_TYPES.UPDATE_NOTICE):
    case FAILURE(ACTION_TYPES.DELETE_NOTICE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_NOTICE_LIST):
      return {
        ...state,
        loading: false,
        entities: sendAtToStatus(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_NOTICE):
      const notices = sendAtToStatus([action.payload.data]);
      return {
        ...state,
        loading: false,
        entity: notices.length > 0 ? notices[0] : {}
      };
    case SUCCESS(ACTION_TYPES.CREATE_NOTICE):
    case SUCCESS(ACTION_TYPES.UPDATE_NOTICE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_NOTICE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };

    case ACTION_TYPES.CLEAR_NOTICE:
      return {
        ...state,
        entity: defaultValue
      };
    default:
      return state;
  }
};

const apiUrl = 'api/notices'; // 通知管理

// Actions
// 通知管理详情
export const getEntities: ICrudGetListAction<INotice> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?cacheBuster=${new Date().getTime()}&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_NOTICE_LIST,
    payload: axios.get<INotice>(`${requestUrl}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const getEntity: ICrudGetAction<INotice> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_NOTICE,
    payload: axios.get<INotice>(requestUrl)
  };
};
// 创建通知管理
export const createEntity: ICrudPutAction<INotice> = entity => async dispatch => {
  const entityCopy = cloneDeepWith(entity);
  entityCopy.seniority = `${entityCopy['seniority']}` === `true` ? '1' : '0';

  const result = await dispatch({
    type: ACTION_TYPES.CREATE_NOTICE,
    payload: axios.post(apiUrl, cleanEntity(entityCopy))
  });
  dispatch(getEntities());
  return result;
};
// 更新通知管理
export const updateEntity: ICrudPutAction<INotice> = entity => async dispatch => {
  const entityCopy = cloneDeepWith(entity);
  entityCopy.seniority = `${entityCopy.seniority}` === 'true' ? '1' : '0';
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_NOTICE,
    payload: axios.put(apiUrl, cleanEntity(entityCopy))
  });
  dispatch(getEntities());
  return result;
};
// 清除通知管理
export const clearEntity = () => ({
  type: ACTION_TYPES.CLEAR_NOTICE
});
// 删除通知管理
export const deleteEntity: ICrudDeleteAction<INotice> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_NOTICE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

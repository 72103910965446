import { Moment } from 'moment';
import { IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { IUser } from 'app/shared/model/user.model';

export const enum SettleState {
  WAITING = 'WAITING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

export interface ISettle {
  id?: number;
  userName?: string;
  userMobile?: string;
  createdAt?: Moment;
  updatedAt?: Moment;
  createdBy?: string;
  remark?: string;
  state?: SettleState;
  idCard?: string;
  updatedBy?: string;
  user?: IUser;
  houseAttach?: string;
  idCardFronted?: string;
  idCardBackend?: string;
}

export const defaultValue: Readonly<ISettle> = {
  state: SettleState.WAITING,
  user: {}
};

export interface ISearchForm {
  userName?: IStringFilter;
  userMobile?: IStringFilter;
  createdAt?: IRangeFilter;
}

export const defaultSearchForm: ISearchForm = {
  userName: {
    contains: ''
  },
  userMobile: {
    contains: ''
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  }
};

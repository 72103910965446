// 首页
import styles from './home.less';

import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Spin } from 'antd';
import { IRootState } from 'app/shared/reducers';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import HouseSelect from 'app/shared/components/house-search/select';
import { defaultsDeep, isString, omit } from 'lodash';
import Rent from './component/rent';
import RentRate from './component/rent-rate';
import MonthRentRate from './component/month-rent-rate';
import CustomerService from './component/customer-service';
import Asset from './component/asset';
import Source from './component/source';
import Healty from './component/healthy';
import { Statistics } from './component/statistics';
import moment, { Moment } from 'moment';
import { isMobile } from 'app/shared/util/screen-utils';
import { APP_LOCAL_DATETIME_T_FORMAT } from 'app/config/constants';
import {
  getRent,
  getASSET,
  getHouseRentRate,
  getParkingRentRate,
  getMonthRentRate,
  getSource,
  getCustomerService,
  getHealth
} from './home.reducer';

interface IHomeProp extends StateProps, DispatchProps {}

export interface IHomeState {
  houseId?: number;
  houseName?: string;
  rangeDate?: [Moment, Moment];
  type?: string; // 类型
}

export class Home extends React.Component<IHomeProp, IHomeState> {
  constructor(props) {
    super(props);
    this.state = {
      type: 'month', // 类型
      houseId: null,
      houseName: '',
      rangeDate: [moment().subtract(12, 'months'), moment()]
    };
  }

  checkAuth = (action: string[] | string) => {
    const {
      account: { authorities }
    } = this.props;
    if (isString(action)) {
      action = [action];
    }
    return hasAnyAuthority(authorities, action);
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { houseId, type } = this.state;
    this.getRent(type);
    this.props.getASSET(houseId);
    this.props.getSource({ houseId });
    this.props.getCustomerService({ houseId });
    this.props.getHealth(); // 康明数据
  }
  // 请求当前租金接口
  getRent = type => {
    type !== this.state.type && this.setState({ type }); // 设置type的值
    const yearStartEndTime = this.getRentYearTime(); // 年的开始时间和结束时间
    const { houseId } = this.state;
    const searchForm = type === 'month' ? { houseId: { in: houseId } } : { houseId: { in: houseId }, ...yearStartEndTime };
    this.props.getRent(searchForm); // 请求
  };
  // 当前租金 年份时间处理
  getRentYearTime() {
    const yearStartTime = moment
      .utc(
        moment()
          .startOf('year')
          .format('YYYY/MM/DD HH:mm:ss')
      )
      .format(APP_LOCAL_DATETIME_T_FORMAT); // 开始时间
    const yearEndTime = moment
      .utc(
        moment()
          .endOf('year')
          .format('YYYY/MM/DD HH:mm:ss')
      )
      .format(APP_LOCAL_DATETIME_T_FORMAT); // 结束时间
    return { startAt: { greaterOrEqualThan: yearStartTime, lessOrEqualThan: yearEndTime } };
  }

  houseChagne = (value, valueFlag) => {
    this.setState(
      {
        houseId: value === 'all' ? null : value,
        houseName: value === 'all' ? null : valueFlag[0]['name']
      },
      () => {
        this.getData();
      }
    );
  };

  renderTopNav = () => (
    <Row>
      <Col span={16} className={styles.topTitle}>
        建鑫乐家租赁住房可视化看板
      </Col>
      <Col span={4} push={4}>
        <HouseSelect hasAllOpt placeholder="请选择房源" style={{ width: '100%' }} className={styles.bordered} onChange={this.houseChagne} />
      </Col>
    </Row>
  );

  render() {
    const { home } = this.props;
    return (
      <>
        {this.renderTopNav()}
        <Row>
          <Col span={!isMobile() ? 19 : 24}>
            <Spin spinning={this.props.rentloading}>
              {/* 租金收入概况 */}
              <Rent rent={home.rent} onTypeChange={type => this.getRent(type)} />
            </Spin>
            <Spin spinning={this.props.monthRentRateloading}>
              {/* 月出租率 */}
              <MonthRentRate houseName={this.state.houseName} />
            </Spin>
            <Spin spinning={this.props.assetloading}>
              {/* 资产类型 */}
              <Asset data={home.asset} />
            </Spin>
            {/* 招商统计 */}
            <Statistics houseId={this.state.houseId} />
          </Col>
          <Col span={!isMobile() ? 5 : 24}>
            <Spin spinning={this.props.rentRentloading}>
              {/* 出租率 */}
              <RentRate houseId={this.state.houseId} />
            </Spin>
            <Spin spinning={this.props.customerServiceloading}>
              {/* 退租原因分析 */}
              <CustomerService data={home.customerService} />
            </Spin>
            <Spin spinning={this.props.sourceloading}>
              {/* 客户来源 */}
              <Source data={home.source} />
            </Spin>
            {/* 智慧健康服务 */}
            <Healty data={home.health} />
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = ({ authentication, home }: IRootState) => ({
  account: authentication.account,
  home,
  rentloading: home.rentloading,
  rentRentloading: home.rentRentloading,
  monthRentRateloading: home.monthRentRateloading,
  customerServiceloading: home.customerServiceloading,
  health: home.health, // 康明
  assetloading: home.assetloading,
  sourceloading: home.sourceloading,
  statisticsloading: home.statisticsloading
});

const mapDispatchToProps = {
  getRent,
  getASSET,
  getHouseRentRate,
  getParkingRentRate,
  getMonthRentRate,
  getSource,
  getCustomerService,
  getHealth
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);

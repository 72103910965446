/*
 * @Author: luoziyan 365977508@qq.com
 * @Date: 2023-08-01 09:44:57
 * @LastEditors: luoziyan 365977508@qq.com
 * @LastEditTime: 2024-05-08 17:07:31
 * @FilePath: \lehome-central\src\main\webapp\app\shared\components\Investment-bar\index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import { G2, Chart, Geom, Axis, Tooltip, Legend } from 'bizcharts';
import DataSet from '@antv/data-set';

interface IInvestmentBarProps {
  data?: any[]; // 数据
  field?: string[];
}

export default class InvestmentBar extends React.Component<IInvestmentBarProps, {}> {
  render() {
    const { data, field } = this.props;
    if (!(data && field)) return <></>;
    const ds = new DataSet();
    const dv = ds.createView().source(data);
    dv.transform({
      type: 'fold',
      fields: field,
      // 展开字段集
      key: '月份',
      // key字段
      value: '数量' // value字段
    });
    return (
      <div>
        <Chart padding={[50, 20, 20, 30]} height={200} data={dv} forceFit style={{ zIndex: 100, top: '-25px', position: 'relative' }}>
          <Axis name="月份" />
          <Axis name="数量" />
          {/* 图例 */}
          <Legend position="top" offsetX={350} offsetY={-18} marker={'circle'} />
          <Tooltip
          // crosshairs={{
          //   type: 'y'
          // }}
          />
          <Geom
            type="interval"
            position="月份*数量"
            color={['name', ['#16E2FF', '#FF7176']]}
            adjust={[
              {
                type: 'dodge',
                marginRatio: 0
              }
            ]}
          />
        </Chart>
      </div>
    );
  }
}

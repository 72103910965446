import { INotice, NoticeType } from 'app/shared/model/notice.model';
import { NOTICE_STATUS_ARRAY } from 'app/config/constants';
import moment from 'moment';
import React from 'react';
import { Tag, Icon } from 'antd';
import { translate } from 'react-jhipster';

export const sendAtToStatus = (notices: [INotice]) => {
  const a = moment();
  const status = sendAt => {
    const b = sendAt && null !== sendAt && sendAt !== '' ? moment(sendAt) : a;
    return a.diff(b) > 0 ? NOTICE_STATUS_ARRAY[1] : NOTICE_STATUS_ARRAY[2];
  };
  return notices.map((item: INotice) => ({ ...item, status: status(item.sendAt) }));
};
// notice Status icon
export const noticeStatusToIconWithHtml = (notice: INotice) =>
  notice.status === NOTICE_STATUS_ARRAY[1] ? (
    <Tag color="#87d068" key={`status-${notice.id}`}>
      <Icon type="check-circle" />
      &nbsp;&nbsp;
      {translate(`lehomeApp.notice.statusValue.${notice.status}`)}
    </Tag>
  ) : (
    <Tag color="#FFC107" key={`status-${notice.id}`}>
      <Icon type="clock-circle" />
      &nbsp;&nbsp;
      {translate(`lehomeApp.notice.statusValue.${notice.status}`)}
    </Tag>
  );
// notice type Icon
export const noticeTypeToIcon = (notice: INotice) => {
  switch (notice.type) {
    case NoticeType.NOTICE:
      return <Icon type="notification" theme="twoTone" />;
    case NoticeType.EVENT:
      return <Icon type="heart" theme="twoTone" twoToneColor="#eb2f96" />;
    case NoticeType.NEWS:
      return <Icon type="file" theme="twoTone" twoToneColor="#9C27B0" />;
    default:
      return <Icon type="database" theme="twoTone" twoToneColor="#9E9E9E" />;
  }
};

import { IStringFilter, IRangeFilter, IFilter } from 'app/shared/model/filter.model';
import { IBase } from 'app/shared/model/base.model';
import { LIVING_STATE } from 'app/config/constants';
export const enum BankName {
  BOCOM = 'BOCOM',
  CCB = 'CCB',
  ABC = 'ABC'
}

export const enum UserType {
  LEASEHOLDER = 'LEASEHOLDER',
  LANDLORD = 'LANDLORD',
  BACKEND = 'BACKEND'
}

export interface IUser extends IBase {
  id?: any;
  login?: string;
  name?: string;
  mobile?: string;
  email?: string;
  activated?: boolean;
  imageUrl?: string;
  langKey?: string;
  authorities?: any[];
  password?: string;
  livingState?: string;
  graduateFrom?: string;
  education?: string;
  companyName?: string;
  workPosition?: string;
  idCard?: string;
  bankAccount?: string;
  bankName?: BankName;
  bankUsername?: string;
  type?: UserType;
}

export const enum CertStatus {
  EXPIRED = -1,
  EFFECTIVE = 1,
  REVOCATION = -2
}

export interface ICert {
  id?: any;
  userId?: number; // 用户id
  certId?: string; // 证书编号
  serialNumber?: number; // 不用管这个
  statusMsg?: string; // 状态描述 （-1 过期 1 有效 -2 吊销）
  status?: number; // 不用管这个
  issuerDN?: string; // 颁发机构
  revokedTime?: string; // 吊销日期，正常使用中的证书无此项内容
  startTime?: string; // 有效期开始时间
  stopTime?: string; // 有效期截止时间
  revokedReason?: string; // 吊销原因，正常使用中的证书无此项内容
  subjectDN?: string; // 证书主题，即该证书代表的个人或企业的名称等信息
}

export const defaultValue: Readonly<IUser> = {
  id: '',
  login: '',
  name: '',
  imageUrl: '',
  mobile: '',
  email: '',
  activated: false,
  langKey: '',
  authorities: [],
  createdBy: '',
  livingState: LIVING_STATE[0],
  createdAt: null,
  updatedBy: '',
  updatedAt: null,
  password: '',
  type: UserType.LEASEHOLDER
};
export const defaultCert: Readonly<ICert> = {};

export interface ISearchForm {
  id?: IStringFilter;
  login?: IStringFilter;
  name?: IStringFilter;
  mobile?: IStringFilter;
  roles?: IStringFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
  updatedAt?: IRangeFilter;
  updatedBy?: IStringFilter;
  type?: IFilter;
  activated?: IFilter;
  query?: IStringFilter;
}

export const defaultSearchForm: Readonly<ISearchForm> = {
  id: {
    in: []
  },
  login: {
    contains: ''
  },
  mobile: {
    contains: ''
  },
  name: {
    contains: ''
  },
  query: {
    contains: ''
  },
  roles: {
    in: []
  },
  type: {
    in: []
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  updatedAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  updatedBy: { contains: '' },
  activated: { equals: '' }
};

import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Modal } from 'antd';
import { translate } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { deleteEntity, getEntity } from './settle.reducer';
import { deleteToList } from 'app/shared/util/url-utils';

export interface ISettleDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class SettleDeleteDialog extends React.Component<ISettleDeleteDialogProps> {
  // 组件初始化
  componentDidMount() {
    const promise: Promise<any> = this.props.getEntity(this.props.match.params.id) as any;
    promise.then(({ value: { data } }) => {
      const { history, match } = this.props;
      Modal.confirm({
        title: translate('entity.delete.title'),
        content: translate('lehomeApp.settle.delete.question', { id: data.id }),
        okText: translate('entity.action.delete'),
        okType: 'danger',
        cancelText: translate('entity.action.cancel'),
        onOk: () => {
          const deletePromise: Promise<any> = this.props.deleteEntity(data.id) as any;
          deletePromise.then(() => {
            history.replace(deleteToList(match.url));
          });
          return deletePromise;
        },
        onCancel: () => {
          history.replace(deleteToList(match.url));
        }
      });
    });
  }

  render() {
    return <></>;
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  settleEntity: storeState.settle.entity
});

const mapDispatchToProps = {
  getEntity,
  deleteEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettleDeleteDialog);

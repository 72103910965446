import { Moment } from 'moment';

export interface IHistory {
  id?: number;
  createdAt?: Moment;
  houseId?: number;
  userId?: number;
}

export const defaultValue: Readonly<IHistory> = {};

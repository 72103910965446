import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Modal } from 'antd';
import { translate } from 'react-jhipster';

import { reset, deleteEntity, getEntity } from './black-list.reducer';
import { IRootState } from 'app/shared/reducers';
import { deleteToList } from 'app/shared/util/url-utils';
import { IBlackList } from 'app/shared/model/black-list.model';

export interface IBlackListDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

class BlackListDeleteDialog extends React.Component<IBlackListDeleteDialogProps> {
  // 确定弹出框
  componentDidMount(): void {
    const entityPromise: Promise<IBlackList> = this.props.getEntity(this.props.match.params.id) as any;
    entityPromise.then(() => {
      const { history, match, black } = this.props;
      Modal.confirm({
        title: translate('entity.delete.title'),
        content: translate('lehomeApp.blackList.delete.question', { id: black.id }),
        okText: translate('entity.action.delete'),
        okType: 'danger',
        cancelText: translate('entity.action.cancel'),
        onOk: () => {
          const deletePromise: Promise<any> = this.props.deleteEntity(black.id) as any;
          deletePromise.then(() => {
            history.replace(deleteToList(match.url));
            this.props.reset();
          });
          return deletePromise;
        },
        onCancel: () => {
          this.props.reset();
          history.replace(deleteToList(match.url));
        }
      });
    });
  }
  // 渲染
  render() {
    return <></>;
  }
}

const mapStateToProps = ({ blackList }: IRootState) => ({
  black: blackList.entity
});

const mapDispatchToProps = { getEntity, deleteEntity, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlackListDeleteDialog);

import moment, { Moment } from 'moment';
import { IRangeFilter, IStringFilter, IFilter } from 'app/shared/model/filter.model';

// 返回状态定义
export interface Itransaction {
  userId?: Number; // 租户用户id
  mobile?: String; // 购买人手机号（一般租户手机号）
  realname?: String; // 租户姓名
  projectname?: String; // 购买产品
  money?: Number; // 购买金额
  ordersn?: String; // 明康订单号
  buytime?: String; // 购买时间
}

export const defaultValue: Readonly<Itransaction> = {};

export interface ISearchForm {
  realname?: IStringFilter; // 姓名
  startDate?: String; // 时间
  endDate?: String; // 结束日期
  buytime?: IRangeFilter; // 购买日期
}

export const defaultSearchForm: ISearchForm = {
  realname: { contains: null }, // 姓名
  buytime: {
    // 时间
    greaterOrEqualThan: moment()
      .startOf('month')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    lessOrEqualThan: moment()
      .endOf('month')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  }
};

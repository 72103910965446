import { Moment } from 'moment';
import { IAds } from 'app/shared/model/ads.model';
import { IFilter, IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';

export interface IAdvertising {
  id?: number;
  name?: string;
  code?: string;
  createdBy?: string;
  createdAt?: Moment;
  updatedBy?: string;
  updatedAt?: Moment;
  ads?: IAds[];
}

export const defaultValue: Readonly<IAdvertising> = {};

export interface ISearchForm {
  name?: IStringFilter;
  createdAt?: IRangeFilter;
}

export const defaultSearchForm: ISearchForm = {
  name: {
    equals: ''
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  }
};

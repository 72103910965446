import { Moment } from 'moment';

export interface IFavorite {
  id?: number;
  createdAt?: Moment;
  houseId?: number;
  userId?: number;
}

export const defaultValue: Readonly<IFavorite> = {};

import { IFilter, IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { IUser, defaultValue as userDefaultValue } from 'app/shared/model/user.model';

export interface IFeedback {
  id?: number;
  mobile?: string;
  type?: string;
  content?: string;
  image?: string;
  user?: IUser;
}

export const defaultValue: Readonly<IFeedback> = {
  user: userDefaultValue
};

export interface ISearchForm {
  content?: IStringFilter;
  mobile?: IStringFilter;
  type?: IStringFilter;
  createdAt?: IRangeFilter;
}

export const defaultSearchForm: Readonly<ISearchForm> = {
  content: {
    contains: ''
  },
  mobile: {
    contains: ''
  },
  type: {
    in: []
  },
  createdAt: {
    lessOrEqualThan: '',
    greaterOrEqualThan: ''
  }
};

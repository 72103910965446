import styles from '../../home.less';

import React from 'react';
import { Row, Col, Select } from 'antd';
import rentIcon from '../static/image/rent-icon.png';
import rent1 from '../static/image/rent-1.png';
import rent2 from '../static/image/rent-2.png';
import rent3 from '../static/image/rent-3.png';
import { isMobile } from 'app/shared/util/screen-utils';
interface ISearchForm {
  total?: number;
  trulyTotal?: number;
  rate?: string;
}

interface IRentState {
  type?: string; // 租金类型
}
interface IRentProp {
  rent: ISearchForm;
  onTypeChange: Function;
}

export default class Rent extends React.Component<IRentProp, IRentState> {
  constructor(props) {
    super(props);
    this.state = {
      type: 'month'
    };
  }
  renderIcon(title) {
    return (
      <div>
        <i>
          <img src={rentIcon} width={24} />
        </i>
        <span className={styles.icontitle}>{title}</span>
      </div>
    );
  }

  renderTitle() {
    // 租金类型 按月 按年
    const list = [{ label: '按当月度', value: 'month' }, { label: '按本年度', value: 'year' }];
    return (
      <Row className={`${styles.rentTitle}`}>
        <Col span={8}>{this.renderIcon('租金收入概况')}</Col>
        <Col span={10} push={!isMobile() ? 13 : 8} className={styles.sborder}>
          <Select style={{ width: 110 }} onChange={this.typeChange} defaultValue={this.state.type}>
            {list.map(item => (
              <Select.Option value={item.value} key={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    );
  }
  // 改变租金状态
  typeChange = value => {
    this.setState(
      {
        type: value
      },
      () => {
        this.props.onTypeChange(value);
      }
    );
  };

  rederContain() {
    const valueStyle = {
      color: '#E62129',
      font: 'bold 24px',
      textAling: 'right'
    };
    const { rent } = this.props;

    if (!rent) {
      return <></>;
    }
    return (
      <Row type="flex" className={styles.rentContent}>
        <Col span={4}>
          <img src={rent1} width="100%" />
        </Col>
        <Col span={5}>
          <div className={!isMobile() ? styles.sTitle : styles.sTitleMobile}>应收</div>
          <div className={!isMobile() ? styles.content : styles.contentMobile}>￥ {rent.total || 0}</div>
        </Col>
        <Col span={4}>
          <img src={rent2} width="100%" />
        </Col>
        <Col span={5}>
          <div className={!isMobile() ? styles.sTitle : styles.sTitleMobile}>实收</div>
          <div className={!isMobile() ? styles.content : styles.contentMobile}>￥ {rent.trulyTotal || 0}</div>
        </Col>
        <Col span={4}>
          <img src={rent3} width="100%" />
        </Col>
        <Col span={2}>
          <div className={!isMobile() ? styles.sTitle : styles.sTitleMobile}>收缴率</div>
          <div className={!isMobile() ? styles.content : styles.contentMobile}>{rent.rate || 0}%</div>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <div className={styles.rent}>
        {this.renderTitle()}
        {this.rederContain()}
      </div>
    );
  }
}

// 水电账号管理 定义
import { Moment } from 'moment';
import { IRangeFilter, IStringFilter, IFilter } from 'app/shared/model/filter.model';

// 返回状态定义
export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING'
}
export interface IHydropowerAccount {
  houseId?: number; // 房源id
  house?: string; // 房源名称
  houseRoomId?: number; // 房号id
  roomCode?: string; // 房号
  customerId?: number; // 租户id
  customerName?: string; // 租户名称
  waterAmount?: number; // 房间水费余额
  electricityAmount?: number; // 房间电费余额
  waterPrice?: number; // 水费价格
  electricityPrice?: number; // 电费价格
  id?: number; // houseRoomId
  type?: string; // 类型
  userId: number; // 用户id
  thresholdvalue?: number;
}

export const defaultValue: Readonly<IHydropowerAccount> = {
  userId: null // 用户id
};

export interface ISearchForm {
  houseId?: IFilter; // 房源
  id?: IFilter; // 房号
  houseRoomCode?: IFilter; // 房号
  customerId?: IFilter; // 租户
  buildingType?: IFilter; // 集中式房源
  consumptionDate?: IRangeFilter; // 录入日期
  clearType?: IStringFilter; // 清零类型
  controlsName?: IStringFilter; // 操作人
  sort?: string; // 排序
  thresholdvalue?: number; // thresholdvalue
  state?: IFilter; // 房间状态
  electricityAmount?: IRangeFilter; // 电费小于
  waterAmount?: IRangeFilter; // 水费小于
}

export const defaultSearchForm: ISearchForm = {
  electricityAmount: { lessOrEqualThan: null },
  waterAmount: { lessOrEqualThan: null },
  state: { in: [] },
  houseId: { equals: 50 },
  id: {
    equals: null // id
  },
  houseRoomCode: {
    // 房间号
    equals: null
  },
  customerId: {
    // 客户id
    equals: null
  },
  buildingType: {
    // 集中式房源
    equals: 'CENTRALIZED'
  },
  consumptionDate: {
    // 日期
    lessThan: '',
    greaterThan: ''
  }
};
// 水电管理日志
export interface ISearchFormLog {
  houseId?: IRangeFilter; // 房源
  houseRoomId?: IRangeFilter; // 房号
  customerId?: IStringFilter; // 租户
  consumptionDate?: IRangeFilter; // 录入日期
  clearType?: IStringFilter; // 清零类型
  controlsName?: IStringFilter; // 操作人
  houseRoomCode?: IStringFilter; // 房号
  controlsId?: IStringFilter; // 客户id
  sort?: string; // 排序
  thresholdvalue?: number; // 值
}

export const defaultSearchFormLog: ISearchFormLog = {
  houseId: { equals: null }, // 房源
  houseRoomId: {
    // 房间号id
    equals: null
  },
  customerId: {
    // 客户id
    contains: ''
  },
  consumptionDate: {
    // 日期
    lessThan: '',
    greaterThan: ''
  },
  controlsId: {
    // 客户id
    equals: null
  },
  clearType: {
    // 类型
    equals: null
  },
  sort: 'consumptionDate,desc' // 初始化
};
export interface IHydropowerAccountLog {
  controlsId?: number; // 客户id
  id?: number; // id
  house?: string; // 房源名称
  houseId?: any; // 房源di
  houseRoomCode?: string; // 房间号
  houseRoomId?: number; // 房间号id
  customerName?: string; // 租户名称
  customerId?: number; // 租户id
  clearType?: string; // 清零类型
  meter?: string; // 清零时房间对应水/电表读数
  remainingAmount?: number; // 清零时房间对应水/电余额
  controlsName?: string; // 操作名称
  controlsRemark?: string; // 清零备注
  consumptionDate?: Date; // 清零时间
  thresholdvalue?: number; // thresholdvalue
}

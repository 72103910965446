import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { getSortState, IPaginationBaseState, Translate, translate } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { getEntities, reset, loadEntities } from './settle.reducer';
import { defaultSearchForm, ISearchForm, ISettle, SettleState } from 'app/shared/model/settle.model';
import { APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATETIME_T_FORMAT, ORDER_ALIAS } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { filterField } from 'app/modules/administration/user-management/user-management';
import { getDefaultSort, objectToParameters, searchToObject } from 'app/shared/util/url-utils';
import { defaultsDeep, isString, omit } from 'lodash';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import LinkButton from 'app/shared/components/link-button';
import { formatDate, stringToDate } from 'app/shared/util/date-utils';
import { ColumnProps } from 'antd/lib/table';
import { Button, Card, Col, DatePicker, Form, Input, Row, Table, List } from 'antd';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { isMobile } from 'app/shared/util/screen-utils';
import ExpandContainer from 'app/shared/components/expand-container';
import ResponsiveList from 'app/shared/components/responsive-list';
import ListItemMetaTitle from 'app/shared/components/commons/list-item-meta-title';
import SplitText from 'app/shared/components/commons/split-text';
import LoadMore from 'app/shared/components/commons/load-more';

export interface ISettleProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  form: WrappedFormUtils<ISearchForm>;
}

export interface ISettleState extends IPaginationBaseState {
  searchForm?: ISearchForm;
}

export class Settle extends React.Component<ISettleProps, ISettleState> {
  state: ISettleState = {
    searchForm: filterField(defaultsDeep(omit(searchToObject(this.props.location), ['page', 'sort']), defaultSearchForm)),
    ...getSortState(getDefaultSort(this.props.location), ITEMS_PER_PAGE)
  };
  // 组件初始化
  componentDidMount() {
    this.sortEntities();
  }
  // 组件卸载
  componentWillUnmount() {
    this.props.reset();
  }
  // 排序
  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };
  // 排序
  sortEntities() {
    this.getEntities();
    if (isMobile()) {
      return;
    }
    this.changeUrl();
  }
  // 页码
  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());
  // 请求
  getEntities = () => {
    const { activePage, itemsPerPage, sort, order, searchForm } = this.state;
    this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`, searchForm);
  };
  // 请求
  loadEntities = () => {
    const { activePage, itemsPerPage, sort, order, searchForm } = this.state;
    this.props.loadEntities(activePage - 1, itemsPerPage, `${sort},${order}`, searchForm);
  };
  // 请求
  changeUrl() {
    const { activePage, sort, order, searchForm } = this.state;
    const searchParams = [`page=${activePage}`, `sort=${sort},${order}`, objectToParameters(searchForm)];
    this.props.history.push(`${this.props.location.pathname}?${searchParams.filter(x => x).join('&')}`);
  }
  // table handle change
  tableHandleChange = (pagination, filters, sorter) => {
    this.setState(
      {
        order: sorter.order === 'ascend' ? 'asc' : 'desc',
        sort: sorter.order && sorter.field ? sorter.field : ''
      },
      () => this.sortEntities()
    );
  };
  // 权限
  checkAuth = (action: string[] | string) => {
    const {
      account: { authorities }
    } = this.props;
    if (isString(action)) {
      action = [action];
    }
    return hasAnyAuthority(authorities, [`Settle#${action}`]);
  };
  // 重置
  handleFormReset = (form: WrappedFormUtils<ISearchForm>) => e => {
    e.preventDefault();
    form.resetFields();
    this.setState(
      {
        ...this.state,
        searchForm: defaultSearchForm
      },
      () => this.sortEntities()
    );
  };
  // 搜索
  handleSearch = (form: WrappedFormUtils<ISearchForm>) => e => {
    e.preventDefault();
    form.validateFields((err, postValues) => {
      if (!err) {
        const { createdAt, userMobile, userName } = postValues;
        this.setState(
          {
            searchForm: defaultsDeep(
              {
                userMobile: {
                  contains: userMobile
                },
                userName: {
                  contains: userName
                },
                createdAt: {
                  greaterOrEqualThan: createdAt[0] ? createdAt[0].format(APP_LOCAL_DATETIME_T_FORMAT) : '',
                  lessOrEqualThan: createdAt[1] ? createdAt[1].format(APP_LOCAL_DATETIME_T_FORMAT) : ''
                }
              },
              defaultSearchForm
            )
          },
          () => this.sortEntities()
        );
      }
    });
  };
  // 渲染 actions
  renderActions = (text, record) => {
    const { match } = this.props;
    return (
      <Button.Group>
        {this.checkAuth('update') && record.state === SettleState.WAITING && (
          <LinkButton to={`${match.url}/${record.id}/edit`} title={translate('entity.action.edit')} type="primary" icon="form" />
        )}
        {this.checkAuth('delete') && (
          <LinkButton to={`${match.url}/${record.id}/delete`} title={translate('entity.action.delete')} type="danger" icon="delete" />
        )}
      </Button.Group>
    );
  };
  // 表格列数据
  getDataColumns(): Array<ColumnProps<ISettle>> {
    const { sort, order } = this.state;
    return [
      {
        title: 'ID',
        dataIndex: 'ID',
        key: 'id',
        render: (text, record) => record.id
      },
      {
        title: translate('lehomeApp.settle.userName'),
        dataIndex: 'userName',
        key: 'userName'
      },
      {
        title: translate('lehomeApp.settle.userMobile'),
        dataIndex: 'userMobile',
        key: 'userMobile'
      },
      {
        title: translate('lehomeApp.settle.state'),
        dataIndex: 'state',
        key: 'state',
        render: (text, record) => translate(`lehomeApp.SettleState.${text}`)
      },
      {
        title: translate('lehomeApp.settle.createdAt'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        sortOrder: sort === 'createdAt' ? ORDER_ALIAS[order] : false,
        render: formatDate
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: this.renderActions
      }
    ];
  }
  // 渲染搜索框
  renderSearchFrom(form: WrappedFormUtils<ISearchForm>) {
    const { getFieldDecorator } = form;
    const { searchForm } = this.state;
    const rowGutter = {
      md: 8,
      lg: 24,
      xl: 48
    };
    const formItemLayout = {
      xs: 24,
      md: 12
    };
    return (
      <div className={'tableListForm'}>
        <Form onSubmit={this.handleSearch(form)}>
          <Row gutter={rowGutter} type={'flex'}>
            <Col {...formItemLayout}>
              <Form.Item label={translate('lehomeApp.settle.userMobile')}>
                {getFieldDecorator('userMobile', {
                  initialValue: searchForm.userMobile.contains
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col {...formItemLayout}>
              <Form.Item label={translate('lehomeApp.settle.userName')}>
                {getFieldDecorator('userName', {
                  initialValue: searchForm.userName.contains
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col {...formItemLayout}>
              <Form.Item label={translate('lehomeApp.appointment.createdAt')}>
                {getFieldDecorator('createdAt', {
                  initialValue: [stringToDate(searchForm.createdAt.greaterOrEqualThan), stringToDate(searchForm.createdAt.lessOrEqualThan)]
                })(<DatePicker.RangePicker format={APP_LOCAL_DATE_FORMAT} />)}
              </Form.Item>
            </Col>
          </Row>
          <div style={{ overflow: 'hidden' }}>
            <span style={{ float: 'right', marginBottom: 24 }}>
              <Button.Group>
                <Button onClick={this.handleFormReset(form)}>
                  <Translate contentKey={'entity.action.reset'} />
                </Button>
                <Button type="primary" htmlType="submit">
                  <Translate contentKey={'entity.action.search'} />
                </Button>
              </Button.Group>
            </span>
          </div>
        </Form>
      </div>
    );
  }
  // 渲染item
  renderItem = record => {
    const { match } = this.props;
    const actions = [];
    if (this.checkAuth('update') && record.state === SettleState.WAITING) {
      actions.push(<LinkButton to={`${match.url}/${record.id}/edit`} type="link" size={'small'} icon="form" />);
    }
    if (this.checkAuth('delete')) {
      actions.push(<LinkButton to={`${match.url}/${record.id}/delete`} type="link" size={'small'} icon="delete" />);
    }
    const title = () => <ListItemMetaTitle left={record.userName} right={formatDate(record.createdAt)} />;
    return (
      <List.Item actions={actions} key={record.id}>
        <List.Item.Meta title={title()} description={<SplitText values={[translate(`lehomeApp.SettleState.${record.state}`)]} />} />
        {record.userMobile}
      </List.Item>
    );
  };
  // h5翻页
  onLoadMore = () => {
    const { activePage } = this.state;
    this.setState(
      {
        activePage: activePage + 1
      },
      () => this.loadEntities()
    );
  };
  // 翻页请求
  renderLoadMore() {
    const { loadMore, totalItems, settleList } = this.props;
    return <LoadMore loadMore={loadMore} totalItems={totalItems} dataSize={settleList.length} onLoadMore={this.onLoadMore} />;
  }

  render() {
    const { settleList, match, totalItems, form, loading, fetching } = this.props;
    return (
      <PageHeaderWrapper
        extra={
          this.checkAuth('create') ? (
            <LinkButton to={`${match.url}/new`} icon={'plus'} type={'primary'}>
              <Translate contentKey="lehomeApp.settle.home.createLabel">Create a new Settle</Translate>
            </LinkButton>
          ) : (
            <></>
          )
        }
      >
        <Card bordered={false}>
          <ExpandContainer>{this.renderSearchFrom(form)}</ExpandContainer>
          <ResponsiveList
            tableProps={{
              rowKey: 'id',
              loading: loading as boolean,
              dataSource: settleList.map(facility => facility),
              columns: this.getDataColumns(),
              onChange: this.tableHandleChange,
              pagination: {
                defaultPageSize: this.state.itemsPerPage,
                total: totalItems,
                onChange: this.handlePagination,
                current: this.state.activePage
              },
              scroll: { x: 1300 }
            }}
            listProps={{
              rowKey: 'id',
              itemLayout: 'vertical',
              loading: fetching,
              loadMore: this.renderLoadMore(),
              dataSource: settleList.map(d => d),
              renderItem: this.renderItem
            }}
          />
        </Card>
      </PageHeaderWrapper>
    );
  }
}

const mapStateToProps = ({ settle, authentication }: IRootState) => ({
  settleList: settle.entities,
  loading: settle.loading,
  fetching: settle.fetching,
  loadMore: settle.loadMore,
  totalItems: settle.totalItems,
  account: authentication.account
});

const mapDispatchToProps = {
  getEntities,
  reset,
  loadEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create<ISettleProps>()(Settle));

import { IHouse } from 'app/shared/model/house.model';
import { IFilter, IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { Moment } from 'moment';
import { IHouseRoom, defaultValue as defaultHouseRoom } from 'app/shared/model/house-room.model';

export interface IRecommend {
  id?: string;
  seniority?: string;
  createdAt?: string;
  createdBy?: Moment;
  updatedAt?: Moment;
  updatedBy?: string;
  houseRoom?: IHouseRoom;
  house?: IHouse;
}

export interface ISearchForm {
  roomId?: IFilter;
  houseId?: IStringFilter;
  type?: IFilter;
  createdAt?: IRangeFilter;
}

export const defaultValue: Readonly<IRecommend> = {
  id: '',
  house: {},
  houseRoom: { ...defaultHouseRoom },
  seniority: '',
  createdAt: '',
  updatedBy: ''
};

export const defaultSearchForm: Readonly<ISearchForm> = {
  roomId: {
    in: []
  },
  type: {
    in: []
  },
  houseId: {
    equals: ''
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  }
};

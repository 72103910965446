import axios from 'axios';
import * as qs from 'qs';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { defaultValue, Itransaction } from 'app/shared/model/transaction.modal';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_ITRANSACTION_LIST: 'hydropowerConfiguration/FETCH_ITRANSACTION_LIST', // 列表
  FETCH_ITRANSACTION_TOTAL: 'hydropowerConfiguration/FETCH_ITRANSACTION_TOTAL', // 统计
  RESET: 'hydropowerConfiguration/RESET' // 重置
};
// state
const initialState = {
  loading: false, // loading
  fetching: false, // loading
  loadMore: false, // 加载更多
  errorMessage: null, // 错误信息
  entities: [] as ReadonlyArray<Itransaction>, // 列表数据
  totalNum: 0, // 金额
  totalFetching: false, // 统计loading
  logList: [] as ReadonlyArray<Itransaction>, // 列表数据
  entity: defaultValue, // 初始化
  updating: false, // 更新
  totalItems: 0, // 总条数
  updateSuccess: false // 更新信息
};

export type transactionState = Readonly<typeof initialState>;

// Reducer
export default (state: transactionState = initialState, action): transactionState => {
  switch (action.type) {
    // 接口初始化
    case REQUEST(ACTION_TYPES.FETCH_ITRANSACTION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ITRANSACTION_TOTAL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true,
        totalFetching: true,
        totalNum: 0
      };
    // 接口失败
    case FAILURE(ACTION_TYPES.FETCH_ITRANSACTION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ITRANSACTION_TOTAL):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
        totalFetching: true,
        totalNum: 0
      };
    // 接口成功
    case SUCCESS(ACTION_TYPES.FETCH_ITRANSACTION_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data || [],
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_ITRANSACTION_TOTAL):
      return {
        ...state,
        totalFetching: false,
        totalNum: action.payload.data
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/buy_medicine_record-list'; // 明康成交统计
// Actions
// 明康成交统计
export const getEntities: ICrudGetListAction<Itransaction> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiUrl}?page=${page}&size=${size}&sort=${sort}`;

  return {
    type: ACTION_TYPES.FETCH_ITRANSACTION_LIST,
    payload: axios.get<Itransaction>(`${requestUrl}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params)
    }),
    meta: { transformUserName: true }
  };
};
// 明康金额统计
export const getTotal: ICrudGetListAction<Itransaction> = (page, size, sort, searchForm) => {
  const requestUrl = `api/buy_medicine_record-list-statistics?page=0&size=9999999`;

  return {
    type: ACTION_TYPES.FETCH_ITRANSACTION_TOTAL,
    payload: axios.get<Itransaction>(`${requestUrl}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params)
    }),
    meta: { transformUserName: true }
  };
};
// 重置
export const reset = () => ({
  type: ACTION_TYPES.RESET
});

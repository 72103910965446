/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
// 系统管理-用户管理
import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  Button,
  Card,
  Switch,
  Table,
  Tag,
  Form,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Badge,
  Modal,
  Descriptions,
  Popconfirm,
  Tooltip
} from 'antd';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import moment from 'moment';
import { getSortState, IPaginationBaseState, TextFormat, Translate, translate, log } from 'react-jhipster';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES, APP_LOCAL_DATETIME_T_FORMAT, USER } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { getRoles, getUsers, updateUser, confirmUnlock } from './user-management.reducer';
import { IRootState } from 'app/shared/reducers';
import LinkButton from 'app/shared/components/link-button';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { ISearchForm, defaultSearchForm, IUser, UserType } from 'app/shared/model/user.model';
import { defaultsDeep, omit, values, isString } from 'lodash';
import { getDefaultSort, searchToObject } from 'app/shared/util/url-utils';
import { WrappedFormInternalProps } from 'antd/lib/form/Form';
import { stringToDate } from 'app/shared/util/date-utils';
import { livingStateToChinese, wxStateToChinese } from 'app/modules/administration/user-management/user-utils';
import { getLatestCertifications } from 'app/entities/certification/certification.reducer';
import { AuditState } from 'app/shared/model/certification.model';
import { unLock } from 'app/entities/black-list/black-list.reducer';
import BlackDialog from 'app/modules/administration/user-management/black-dialog';

export const filterField = (searchForm: ISearchForm) => {
  if (searchForm && searchForm.activated && searchForm.activated.equals) {
    searchForm.activated.equals = ['true', 'false'].indexOf(searchForm.activated.equals.toString()) > -1 ? searchForm.activated.equals : '';
  }
  return searchForm;
};

export const canNotOperate = login => values(USER).includes(login);

export interface IUserManagementProps extends StateProps, DispatchProps, RouteComponentProps<{}>, WrappedFormInternalProps {}

export interface IUserManagementState extends IPaginationBaseState {
  searchForm?: ISearchForm;
  verifiedUserId?: number[];
  login?: string;
  dialogVisible?: boolean;
  isCertModalVisible?: boolean;
  cert?: any;
}

export class UserManagement extends React.Component<IUserManagementProps, IUserManagementState> {
  state: IUserManagementState = {
    verifiedUserId: [],
    login: '',
    dialogVisible: false,
    isCertModalVisible: false,
    cert: {},
    searchForm: filterField(defaultsDeep(omit(searchToObject(this.props.location), ['page', 'sort']), defaultSearchForm)),
    ...getSortState(getDefaultSort(this.props.location), ITEMS_PER_PAGE)
  };

  __Dialog = {
    open: login => {
      this.setState({
        login,
        dialogVisible: true
      });
    },
    unLock: login => {
      Modal.warning({
        title: translate('lehomeApp.blackList.delete.question', { id: login }),
        // title: translate('global.tips'),
        content: translate('entity.action.removeBlacklist'),
        onOk: () => {
          const unLockPromise = this.props.unLock(login) as any;
          unLockPromise.then(data => {
            this.sortUsers();
          });
        }
      });
    },
    close: () => {
      this.setState({
        dialogVisible: false
      });
      this.sortUsers();
    }
  };

  componentDidMount() {
    this.getUsers();
    this.props.getRoles();
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortUsers()
    );
  };

  sortUsers() {
    this.getUsers();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortUsers());

  tableHandleChange = (pagination, filters, sorter) => {
    this.setState(
      {
        order: sorter.order === 'ascend' ? 'asc' : 'desc',
        sort: sorter.field ? sorter.field : ''
      },
      () => this.sortUsers()
    );
  };

  getUsers = async () => {
    const { activePage, itemsPerPage, sort, order, searchForm } = this.state;
    const resultPromise = (await this.props.getUsers(activePage - 1, itemsPerPage, `${sort},${order}`, searchForm)) as any;
    const userIds = resultPromise.value.data.map(item => item.id);
    const data = this.props.getLatestCertifications(userIds, AuditState.SUCCESS) as any;
    data.then(cerDatas => {
      const verifiedUserId = cerDatas.value.data.map(item => item.userId);
      this.setState({
        verifiedUserId
      });
    });
  };

  handleSearch = e => {
    e && e.preventDefault();
    this.props.form.validateFields((err, postValues) => {
      if (!err) {
        const { login, mobile, createdAt, name, activated, roles, type } = postValues;
        this.setState(
          {
            searchForm: defaultsDeep(
              {
                login: {
                  contains: login
                },
                mobile: {
                  contains: mobile
                },
                name: {
                  contains: name
                },
                createdAt: {
                  greaterOrEqualThan: createdAt[0] ? createdAt[0].utc().format(APP_LOCAL_DATETIME_T_FORMAT) : '',
                  lessOrEqualThan: createdAt[1] ? createdAt[1].utc().format(APP_LOCAL_DATETIME_T_FORMAT) : ''
                },
                activated: {
                  equals: activated
                },
                roles: {
                  in: roles
                },
                type: {
                  in: type
                }
              },
              defaultSearchForm
            )
          },
          () => this.sortUsers()
        );
      }
    });
  };

  handleFormReset = () => {
    this.props.form.resetFields();
    this.setState(
      {
        ...this.state,
        searchForm: defaultSearchForm
      },
      () => this.sortUsers()
    );
  };

  userLink(text, user) {
    const { match, account } = this.props;
    if (account.login === user.login) {
      return text;
    } else {
      return <Link to={`${match.url}/${user.login}`}>{text}</Link>;
    }
  }

  formatDate(date) {
    return date ? moment(date).format(APP_DATE_FORMAT) : date;
  }

  checkAuth = (action: string[] | string) => {
    const {
      account: { authorities }
    } = this.props;
    if (isString(action)) {
      action = [action];
    }
    return hasAnyAuthority(authorities, [`User#${action}`]);
  };

  renderAdvancedForm() {
    const {
      form: { getFieldDecorator },
      roles
    } = this.props;
    const { searchForm } = this.state;
    const rowGutter = {
      md: 8,
      lg: 24,
      xl: 48
    };
    const formItemLayout = {
      xs: 24,
      md: 8
    };
    return (
      <div className={'tableListForm'}>
        <Form onSubmit={this.handleSearch}>
          <Row gutter={rowGutter} type={'flex'}>
            <Col {...formItemLayout}>
              <Form.Item label={translate('userManagement.login')}>
                {getFieldDecorator('login', {
                  initialValue: searchForm.login.contains
                })(<Input placeholder={translate('global.placeholder.input')} />)}
              </Form.Item>
            </Col>
            <Col {...formItemLayout}>
              <Form.Item label={translate('userManagement.name')}>
                {getFieldDecorator('name', {
                  initialValue: searchForm.name.contains
                })(<Input placeholder={translate('global.placeholder.input')} />)}
              </Form.Item>
            </Col>
            <Col {...formItemLayout}>
              <Form.Item label={translate('userManagement.mobile')}>
                {getFieldDecorator('mobile', {
                  initialValue: searchForm.mobile.contains
                })(<Input placeholder={translate('global.placeholder.input')} />)}
              </Form.Item>
            </Col>
            <Col {...formItemLayout}>
              <Form.Item label={translate('userManagement.activate')}>
                {getFieldDecorator('activated', {
                  initialValue: filterField(searchForm).activated.equals
                })(
                  <Select allowClear placeholder={translate('global.placeholder.select')}>
                    <Select.Option value={'true'}>{translate('userManagement.activated')}</Select.Option>
                    <Select.Option value={'false'}>{translate('userManagement.deactivated')}</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col {...formItemLayout}>
              <Form.Item label={translate('userManagement.type')}>
                {getFieldDecorator('type', {
                  initialValue: searchForm.type.in
                })(
                  <Select allowClear mode={'multiple'} placeholder={translate('global.placeholder.select')}>
                    {[UserType.LEASEHOLDER, UserType.LANDLORD, UserType.BACKEND].map(value => (
                      <Select.Option value={value} key={value}>
                        <Translate contentKey={`lehomeApp.UserType.${value}`} />
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col {...formItemLayout}>
              <Form.Item label={translate('userManagement.role')}>
                {getFieldDecorator('roles', {
                  initialValue: filterField(searchForm).roles.in
                })(
                  <Select mode={'multiple'} allowClear placeholder={translate('global.placeholder.select')}>
                    {roles.map(role => (
                      <Select.Option value={role} key={role}>
                        <Translate contentKey={`roles.${role}`} children={role} />
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col {...formItemLayout}>
              <Form.Item label={translate('userManagement.createdDate')}>
                {getFieldDecorator('createdAt', {
                  initialValue: [stringToDate(searchForm.createdAt.greaterOrEqualThan), stringToDate(searchForm.createdAt.lessOrEqualThan)]
                })(<DatePicker.RangePicker format={APP_LOCAL_DATE_FORMAT} />)}
              </Form.Item>
            </Col>
          </Row>
          <div style={{ overflow: 'hidden' }}>
            <span style={{ float: 'right', marginBottom: 24 }}>
              <Button.Group>
                <Button onClick={this.handleFormReset}>
                  <Translate contentKey={'entity.action.reset'} />
                </Button>
                <Button type="primary" htmlType="submit">
                  <Translate contentKey={'entity.action.search'} />
                </Button>
              </Button.Group>
            </span>
          </div>
        </Form>
      </div>
    );
  }

  confirmUnlock = record => async () => {
    this.props.confirmUnlock(record.id);
    this.getUsers();
  };

  renderActions = record => {
    const { match, account } = this.props;
    const { verifiedUserId } = this.state;
    const __openEvent = () => (record.activated ? this.__Dialog.open(record.login) : this.__Dialog.unLock(record.login));

    return account.login !== record.login && !canNotOperate(record.login) ? (
      <Button.Group>
        {this.checkAuth(record.activated ? 'toBlackList' : 'outOfBlackList') && (
          <Button
            onClick={__openEvent}
            title={record.activated ? translate('entity.action.blacklisting') : translate('entity.action.removeBlacklist')}
            type={record.activated ? 'danger' : 'default'}
            icon={record.activated ? 'lock' : 'unlock'}
          />
        )}
        {this.checkAuth('update') && (
          <LinkButton to={`${match.url}/${record.login}/edit`} title={translate('entity.action.edit')} type="primary" icon="form" />
        )}
        {this.checkAuth('delete') && (
          <LinkButton to={`${match.url}/${record.login}/delete`} title={translate('entity.action.delete')} icon="delete" type={'danger'} />
        )}
        {/* 微信解绑 */}
        {this.checkAuth('delete') && record.wxbinded && (
          <Popconfirm title={'确定要解绑当前微信号？'} onConfirm={this.confirmUnlock(record)}>
            <Tooltip title={'解绑微信'}>
              {/* <Button type="primary" icon="rollback" /> */}
              <Button title="微信解绑" icon="unlock" type={'danger'} />
            </Tooltip>
          </Popconfirm>
        )}
        {/* 修改手机号 */}
        {this.checkAuth('changeMobile') && (
          <LinkButton to={`${match.url}/${record.login}/pws/edit`} title={'修改手机号'} type="primary" icon="idcard" />
        )}
      </Button.Group>
    ) : null;
  };

  renderDetails = record => {
    const { match, account } = this.props;
    return record.cert ? (
      <div>
        {
          <Button size={'small'} title={translate('entity.action.view')} icon="eye" onClick={() => this.showCertDetail(record)}>
            {record.cert.statusMsg}
          </Button>
        }
      </div>
    ) : null;
  };

  showCertDetail = record => {
    this.setState({
      isCertModalVisible: true,
      cert: record.cert
    });
  };

  getDataColumns() {
    const orderMap = {
      desc: 'descend',
      asc: 'ascend'
    };
    const { account } = this.props;
    const { sort, order, verifiedUserId } = this.state;
    const columns = [
      {
        title: translate('userManagement.login'),
        dataIndex: 'login',
        key: 'login',
        render: (text, record) => this.userLink(text, record)
      },
      {
        title: translate('userManagement.name'),
        dataIndex: 'name',
        key: 'name',
        width: 120
      },
      {
        title: translate('userManagement.mobile'),
        dataIndex: 'mobile',
        key: 'mobile',
        width: 120
      },
      {
        title: translate('userManagement.certification.status'),
        dataIndex: 'none',
        key: 'none',
        width: 120,
        render: (text, record) =>
          verifiedUserId.includes(record.id) ? (
            <Badge status="success" text={translate('userManagement.certification.yes')} />
          ) : (
            <Badge status="default" text={translate('userManagement.certification.no')} />
          )
      },
      {
        title: translate('userManagement.activate'),
        dataIndex: 'activated',
        key: 'activated',
        render: (text, record) =>
          record.activated ? (
            <Badge status="success" text={translate('userManagement.activated')} />
          ) : (
            <Badge status="default" text={translate('userManagement.deactivated')} />
          )
      },
      {
        title: translate('userManagement.cancel'),
        dataIndex: 'canceled',
        key: 'canceled',
        render: (text, record) =>
          record.canceled ? (
            <Badge status="default" text={translate('userManagement.canceled')} />
          ) : (
            <Badge status="success" text={translate('userManagement.activated')} />
          )
      },
      {
        title: translate('userManagement.type'),
        dataIndex: 'type',
        key: 'type',
        render: text => translate(`lehomeApp.UserType.${text}`)
      },
      {
        title: translate('userManagement.livingState'),
        dataIndex: 'livingState',
        key: 'livingState',
        render: (text, record) => livingStateToChinese(text)
      },
      {
        title: translate('userManagement.cert.statusMsg'),
        dataIndex: 'cert.statusMsg',
        key: 'cert.statusMsg',
        render: (text, record) => this.renderDetails(record)
      },
      {
        title: translate('userManagement.profiles'),
        dataIndex: 'roles',
        key: 'roles',
        render: (text, record) =>
          record.roles.map(auth => (
            <Tag key={`${record.id}-${auth}`}>
              {' '}
              <Translate contentKey={`roles.${auth}`} children={auth} />{' '}
            </Tag>
          )),
        width: 80
      },
      {
        title: translate('userManagement.wxbinded'),
        dataIndex: 'wxbinded',
        key: 'wxbinded',
        // render: (text, record) =>record.wxbinded?'是':'否',
        render: (text, record) => wxStateToChinese(text),
        width: 80
      }
    ];
    const columes2 = [
      {
        title: translate('userManagement.createdDate'),
        dataIndex: 'createdDate',
        key: 'createdDate',
        sorter: true,
        sortOrder: sort === 'createdDate' ? orderMap[order] : false,
        render: text => this.formatDate(text)
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: 250,
        render: (text, record) => this.renderActions(record)
      }
    ];
    return columns.concat(columes2 as any[]);
  }

  CertModalVisibleCancel = () => {
    this.setState({
      isCertModalVisible: false,
      cert: {}
    });
    this.getUsers();
  };

  render() {
    const { users, match, totalItems, loading } = this.props;
    const dataSource = () => users.map(user => user);
    const { cert } = this.state;
    return (
      <PageHeaderWrapper
        extra={
          this.checkAuth('create') ? (
            <LinkButton to={`${match.url}/new`} icon={'plus'} type={'primary'}>
              <Translate contentKey="userManagement.home.createLabel">Create a new user</Translate>
            </LinkButton>
          ) : (
            <></>
          )
        }
      >
        <Card bordered={false}>
          {this.renderAdvancedForm()}
          <Table
            rowKey={'id'}
            loading={loading}
            dataSource={dataSource()}
            columns={this.getDataColumns()}
            onChange={this.tableHandleChange}
            pagination={{
              defaultPageSize: this.state.itemsPerPage,
              total: totalItems,
              onChange: this.handlePagination,
              current: this.state.activePage
            }}
          />
          <BlackDialog login={this.state.login} visible={this.state.dialogVisible} onClose={this.__Dialog.close} />
        </Card>
        <Modal
          title="证书详情"
          visible={this.state.isCertModalVisible}
          width={750}
          onCancel={this.CertModalVisibleCancel}
          onOk={this.CertModalVisibleCancel}
        >
          <Descriptions column={2}>
            <Descriptions.Item label={translate('userManagement.cert.serialNumber')}>{cert.serialNumber}</Descriptions.Item>
            <Descriptions.Item label={translate('userManagement.cert.statusMsg')}>{cert.statusMsg}</Descriptions.Item>
            <Descriptions.Item label={translate('userManagement.cert.startTime')}>{cert.startTime}</Descriptions.Item>
            <Descriptions.Item label={translate('userManagement.cert.endTime')}>{cert.stopTime}</Descriptions.Item>
          </Descriptions>
        </Modal>
      </PageHeaderWrapper>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  users: storeState.userManagement.users,
  totalItems: storeState.userManagement.totalItems,
  roles: storeState.userManagement.authorities,
  loading: storeState.userManagement.loading,
  account: storeState.authentication.account,
  certificationList: storeState.certification.entities
});

const mapDispatchToProps = { getUsers, updateUser, getRoles, getLatestCertifications, unLock, confirmUnlock };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(UserManagement));

import axios from 'axios';
import * as qs from 'qs';
import { ICrudGetAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { defaultValue, IHydropowerConfiguration } from 'app/shared/model/hydropower_configuration';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_HYDROPOWERCONFIGURATION_LIST: 'hydropowerConfiguration/FETCH_HYDROPOWERCONFIGURATION_LIST', // 水电列表
  FETCH_HYDROPOWERCONFIGURATIONLOG_LIST: 'hydropowerConfiguration/FETCH_HYDROPOWERCONFIGURATIONLOG_LIST', // 水电配置日志列表
  CREATE_HYDROPOWERCONFIGURATION: 'hydropowerConfiguration/CREATE_HYDROPOWERCONFIGURATION', // 创建
  FETCH_HYDROPOWERCONFIGURATION: 'hydropowerConfiguration/FETCH_HYDROPOWERCONFIGURATION', // 详情
  UPDATE_HYDROPOWERCONFIGURATION: 'hydropowerConfiguration/UPDATE_HYDROPOWERCONFIGURATION', // 更新
  DELETE_HYDROPOWERCONFIGURATION: 'hydropowerConfiguration/DELETE_HYDROPOWERCONFIGURATION', // 删除
  RESET: 'hydropowerConfiguration/RESET' // 重置
};
// state
const initialState = {
  loading: false, // 是否加载
  fetching: false, // 加载
  loadMore: false, // 加载更多
  errorMessage: null, // 错误信息
  entities: [] as ReadonlyArray<IHydropowerConfiguration>, // 列表返回数据
  logList: [] as ReadonlyArray<IHydropowerConfiguration>, // log列表返回数据
  entity: defaultValue, // 初始值
  updating: false, // 是否更新
  totalItems: 0, // 列表返回条数
  updateSuccess: false // 是否更新成功
};

export type hydropowerConfigurationState = Readonly<typeof initialState>;

// Reducer
export default (state: hydropowerConfigurationState = initialState, action): hydropowerConfigurationState => {
  switch (action.type) {
    // 接口初始化
    case REQUEST(ACTION_TYPES.FETCH_HYDROPOWERCONFIGURATION):
    case REQUEST(ACTION_TYPES.CREATE_HYDROPOWERCONFIGURATION):
    case REQUEST(ACTION_TYPES.UPDATE_HYDROPOWERCONFIGURATION):
    case REQUEST(ACTION_TYPES.DELETE_HYDROPOWERCONFIGURATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case REQUEST(ACTION_TYPES.FETCH_HYDROPOWERCONFIGURATION_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.FETCH_HYDROPOWERCONFIGURATIONLOG_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    // 接口失败
    case FAILURE(ACTION_TYPES.UPDATE_HYDROPOWERCONFIGURATION):
    case FAILURE(ACTION_TYPES.CREATE_HYDROPOWERCONFIGURATION):
    case FAILURE(ACTION_TYPES.FETCH_HYDROPOWERCONFIGURATION):
    case FAILURE(ACTION_TYPES.FETCH_HYDROPOWERCONFIGURATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_HYDROPOWERCONFIGURATIONLOG_LIST):
    case FAILURE(ACTION_TYPES.DELETE_HYDROPOWERCONFIGURATION):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    // 接口成功
    case SUCCESS(ACTION_TYPES.DELETE_HYDROPOWERCONFIGURATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case SUCCESS(ACTION_TYPES.FETCH_HYDROPOWERCONFIGURATION):
      return {
        ...state,
        updating: false,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_HYDROPOWERCONFIGURATION):
    case SUCCESS(ACTION_TYPES.UPDATE_HYDROPOWERCONFIGURATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_HYDROPOWERCONFIGURATION_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data || [],
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_HYDROPOWERCONFIGURATIONLOG_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        logList: action.payload.data || [],
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/water-electricity-config-list'; // 水电管理
// Actions
// 水电管理，列表
export const getEntities: ICrudGetListAction<IHydropowerConfiguration> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiUrl}?page=${page}&size=${size}`;
  return {
    type: ACTION_TYPES.FETCH_HYDROPOWERCONFIGURATION_LIST,
    payload: axios.get<IHydropowerConfiguration>(`${requestUrl}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params)
    }),
    meta: { transformUserName: true }
  };
};

// 获取水电日志列表数据
export const getLogEntity: ICrudGetListAction<IHydropowerConfiguration> = (page, size, id) => {
  const requestUrl = `api/water-electricity-config-log-list?page=${page}&size=${size}&houseId.equals=${id}&sort=consumptionDate,desc`;
  return {
    type: ACTION_TYPES.FETCH_HYDROPOWERCONFIGURATIONLOG_LIST,
    payload: axios.get<IHydropowerConfiguration>(`${requestUrl}`, {
      paramsSerializer: params => qs.stringify(params)
    }),
    meta: { transformUserName: true }
  };
};

// 获取水电详情
export const getEntity: ICrudGetAction<IHydropowerConfiguration> = id => {
  const requestUrl = `api/water-electricity-config-info?houseId=${id}`;
  return {
    type: ACTION_TYPES.FETCH_HYDROPOWERCONFIGURATION,
    payload: axios.get<IHydropowerConfiguration>(requestUrl)
  };
};

// 新增
export const createEntity = entity => async dispatch => {
  const requestUrl = `api/water-electricity-config-add?${Object.keys(entity)
    .map(key => key + '=' + entity[key])
    .join('&')}
  `;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_HYDROPOWERCONFIGURATION,
    payload: axios.post(requestUrl, cleanEntity(entity))
  });
  return result;
};

// 修改
export const updateEntity: ICrudPutAction<IHydropowerConfiguration> = entity => async dispatch => {
  const url = `api/water-electricity-config-update?${Object.keys(entity)
    .map(key => key + '=' + entity[key])
    .join('&')}`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_HYDROPOWERCONFIGURATION,
    payload: axios.post(url, cleanEntity(entity))
  });
  return result;
};
// 删除
export const deleteEntity: ICrudDeleteAction<IHydropowerConfiguration> = id => async dispatch => {
  const requestUrl = `api/water-electricity-config-delete?houseId=${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_HYDROPOWERCONFIGURATION,
    payload: axios.post(requestUrl)
  });
  return result;
};
// 搜索重置
export const reset = () => ({
  type: ACTION_TYPES.RESET
});

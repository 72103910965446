import { createStore, applyMiddleware, compose } from 'redux';
import { createPromise } from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import reducer, { IRootState } from 'app/shared/reducers';
import DevTools from './devtools';
import errorMiddleware from './error-middleware';
import notificationMiddleware from './notification-middleware';
import loggerMiddleware from './logger-middleware';
import transformUserName from './transform-user-name';
import { PROMISE_PENDING, PROMISE_DELIMITER, PROMISE_FULFILLED, PROMISE_REJECTED } from './constants';
// 定义中间件
const defaultMiddlewares = [
  thunkMiddleware,
  errorMiddleware,
  notificationMiddleware,
  transformUserName,
  createPromise({
    promiseTypeSuffixes: [PROMISE_PENDING, PROMISE_FULFILLED, PROMISE_REJECTED],
    promiseTypeDelimiter: PROMISE_DELIMITER
  }),
  loggerMiddleware
];
const composedMiddlewares = middlewares =>
  process.env.NODE_ENV === 'development'
    ? compose(
        applyMiddleware(...defaultMiddlewares, ...middlewares),
        DevTools.instrument()
      )
    : compose(applyMiddleware(...defaultMiddlewares, ...middlewares));

const initialize = (initialState?: IRootState, middlewares = []) => createStore(reducer, initialState, composedMiddlewares(middlewares));

export default initialize;

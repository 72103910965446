import { IAuthorization } from 'app/shared/model/authorization.model';

export const enum Provider {
  WECHAT = 'WECHAT',
  QQ = 'QQ'
}

export interface IThirdPartyConfig {
  id?: number;
  provider?: Provider;
  preferences?: string;
  authorizations?: IAuthorization[];
}

export const defaultValue: Readonly<IThirdPartyConfig> = {};

import { Moment } from 'moment';
import { IHouse } from 'app/shared/model/house.model';
import { IFilter, IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { IUser } from 'app/shared/model/user.model';
import house from 'app/entities/house/house';
import { IHouseRoom } from 'app/shared/model/house-room.model';

export const enum AppointmentState {
  WAITING = 'WAITING',
  ACCEPTED = 'ACCEPTED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED'
}

export interface IAppointment {
  id?: number;
  appointmentAt?: Moment;
  state?: AppointmentState;
  username?: string;
  phone?: string;
  description?: string;
  comment?: string;
  rate?: number;
  createdAt?: Moment;
  createdBy?: string;
  updatedBy?: string;
  updatedAt?: Moment;
  customerId?: number;
  customer?: IUser;
  managerId?: number;
  manager?: IUser;
  houses?: IHouse[];
  houseRooms?: IHouseRoom[];
}

export const defaultValue: Readonly<IAppointment> = {
  houses: [],
  houseRooms: []
};

export interface ISearchForm {
  appointmentAt?: IRangeFilter;
  state?: IFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
  customerQuery?: IStringFilter;
  username?: IStringFilter;
  phone?: IStringFilter;
  houseId?: IRangeFilter;
}

export const defaultSearchForm = {
  state: {
    equals: ''
  },
  appointmentAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  houseId: {
    in: []
  },
  customerQuery: {
    contains: ''
  },
  username: {
    contains: ''
  },
  phone: {
    contains: ''
  }
};

import styles from '../../home.less';

import React from 'react';
import { Row, Col, Dropdown, Statistic, Button } from 'antd';
import monthRentRateIcon from '../static/image/month-rent-rate-icon.png';
import InvestmentBar from 'app/shared/components/Investment-bar';
import { toNumber } from 'lodash';
// import { flattenKey } from 'app/shared/util/object-utils';
// import * as qs from 'qs';
import axios from 'axios';

const TYPESTATE = { week: 'time', year: 'month' };

interface IStatisticsProps {
  houseId?: number;
}

interface IStatisticsState {
  typeName?: string;
  data?: any[];
  field?: string[];
  house?: number;
}

export class Statistics extends React.Component<IStatisticsProps, IStatisticsState> {
  constructor(props) {
    super(props);
    this.state = {
      typeName: 'year',
      data: [],
      field: null,
      house: null
    };
  }

  componentDidMount(): void {
    this.getData(null);
  }

  shouldComponentUpdate(nextProps: Readonly<IStatisticsProps>, nextState: Readonly<IStatisticsState>, nextContext: any): boolean {
    if (nextProps.houseId !== this.props.houseId) {
      this.getData(nextProps.houseId);
      this.setState({ house: nextProps.houseId });
    }

    return true;
  }
  renderIcon(title) {
    return (
      <div>
        <i className="">
          <img src={monthRentRateIcon} width={24} />
        </i>
        <span className={styles.icontitle}>{title}</span>
      </div>
    );
  }

  btnClick = typeName => {
    this.setState(
      {
        typeName
      },
      () => {
        this.getData(this.state.house);
      }
    );
  };

  renderTitle() {
    const { typeName } = this.state;
    return (
      <Row className={styles.rentTitle}>
        <Col span={3}>{this.renderIcon('招商统计')}</Col>
        <Col span={6}>
          <Button type={typeName === 'year' ? 'primary' : 'default'} onClick={() => this.btnClick('year')} style={{ marginRight: '8px' }}>
            按月统计
          </Button>
          <Button type={typeName !== 'year' ? 'primary' : 'default'} onClick={() => this.btnClick('week')}>
            按周统计
          </Button>
        </Col>
      </Row>
    );
  }

  getData(houseId) {
    const { typeName } = this.state;
    axios
      .post(`/api/zsStats/count`, {
        type: typeName,
        houseId
      })
      .then(({ data }) => {
        const field = [];
        const rdata = [{ name: '来电' }, { name: '来访' }];
        const typeState = TYPESTATE[typeName];
        if (data && data.length > 0) {
          data.forEach(item => {
            rdata[0][item[typeState]] = toNumber(item.call);
            rdata[1][item[typeState]] = toNumber(item.view);
            field.push(item[typeState]);
          });
        }

        this.setState({ data: rdata, field });
      });
  }

  rederContain() {
    const { data, field } = this.state;
    return <InvestmentBar data={data} field={field} />;
  }

  render() {
    return (
      <div className={`${styles.rent} ${styles.mt16}`}>
        {this.renderTitle()}
        {this.rederContain()}
      </div>
    );
  }
}

import { Moment } from 'moment';
import { IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { BankName } from 'app/shared/model/user.model';

export const enum PaymentType {
  ONLINE = 'ONLINE',
  CASH = 'CASH',
  TRANSFER = 'TRANSFER'
}

export const enum Type {
  HOUSE = 'HOUSE',
  PARKING_LOT = 'PARKING_LOT'
}

export const enum SignSettingSource {
  PERSONAL = 'PERSONAL',
  OFFICIAL = 'OFFICIAL'
}

export interface ISignSetting {
  id?: number;
  name?: string;
  paymentType?: PaymentType;
  bank?: BankName;
  source?: SignSettingSource;
  contractTemplate?: any;
  createdAt?: Moment;
  createdBy?: string;
  type?: string;
  updatedBy?: string;
  updatedAt?: Moment;
  houseId?: Number;
}

export const defaultValue: Readonly<ISignSetting> = {};

export interface ISearchForm {
  source?: IStringFilter;
  name?: IStringFilter;
  createdAt?: IRangeFilter;
  type?: IStringFilter;
}

export const defaultSearchForm: ISearchForm = {
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  source: {
    equals: ''
  },
  name: {
    contains: ''
  },
  type: {
    equals: ''
  }
};

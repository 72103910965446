import { Moment } from 'moment';
import { IFilter, IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { IAttachment } from 'app/shared/model/attachment.model';
import { IHouseRoom } from 'app/shared/model/house-room.model';

export const enum FixedState {
  WAITING = 'WAITING',
  PROCESSING = 'PROCESSING',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED'
}

export interface IMaintenanceItem {
  id?: number;
  name?: string;
  amount?: number;
  maintenance?: IMaintenance;
}

export interface IMaintenance {
  id?: number;
  description?: string;
  content?: string;
  roomId?: number;
  fixedAt?: Moment;
  maintenanceItems?: IMaintenanceItem[];
  attachments?: IAttachment[];
  state?: FixedState;
  customerName?: string;
  customerMobile?: string;
  roomCode?: string;
  imageUrl?: string;
  createdAt?: Moment;
  createdBy?: string;
  updatedBy?: string;
  updatedAt?: Moment;
  customerId?: number;
  houseId?: number;
}

export const defaultValue: Readonly<IMaintenance> = {
  maintenanceItems: [],
  attachments: []
};

export interface ISearchForm {
  createdAt?: IRangeFilter;
  roomId?: IFilter;
  customerMobile?: IStringFilter;
  customerId?: IFilter;
  state?: IStringFilter;
}

export const defaultSearchForm: ISearchForm = {
  customerId: {
    equals: ''
  },
  customerMobile: {
    contains: ''
  },
  state: {
    in: []
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  roomId: {
    equals: ''
  }
};

import axios from 'axios';
import * as qs from 'qs';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, translate } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IHouseRoom } from 'app/shared/model/house-room.model';
import { flattenKey } from 'app/shared/util/object-utils';
import { ICrudGetListAction } from 'app/shared/model/list';
import { IHouse } from 'app/shared/model/house.model';

export const ACTION_TYPES = {
  SEARCH_HOUSEROOMS: 'houseRoom/SEARCH_HOUSEROOMS',
  FETCH_HOUSEROOM_LIST: 'houseRoom/FETCH_HOUSEROOM_LIST',
  FETCH_SIMPLE_HOUSEROOM_LIST: 'houseRoom/FETCH_SIMPLE_HOUSEROOM_LIST',
  LOAD_HOUSEROOM_LIST: 'houseRoom/LOAD_HOUSEROOM_LIST',
  FETCH_HOUSEROOM: 'houseRoom/FETCH_HOUSEROOM',
  CREATE_HOUSEROOM: 'houseRoom/CREATE_HOUSEROOM',
  UPDATE_HOUSEROOM: 'houseRoom/UPDATE_HOUSEROOM',
  AUTHENTICITY_HOUSEROOM: 'houseRoom/AUTHENTICITY_HOUSEROOM',
  DELETE_HOUSEROOM: 'houseRoom/DELETE_HOUSEROOM',
  RESET: 'houseRoom/RESET'
};
// state定义
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IHouseRoom>,
  simpleEntities: [] as ReadonlyArray<IHouseRoom>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type HouseRoomState = Readonly<typeof initialState>;

// Reducer
export function isFull(room: IHouseRoom) {}

export default (state: HouseRoomState = initialState, action): HouseRoomState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_HOUSEROOM_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SIMPLE_HOUSEROOM_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_HOUSEROOM_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.SEARCH_HOUSEROOMS):
    case REQUEST(ACTION_TYPES.FETCH_HOUSEROOM):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_HOUSEROOM):
    case REQUEST(ACTION_TYPES.UPDATE_HOUSEROOM):
    case REQUEST(ACTION_TYPES.DELETE_HOUSEROOM):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.SEARCH_HOUSEROOMS):
    case FAILURE(ACTION_TYPES.FETCH_HOUSEROOM_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SIMPLE_HOUSEROOM_LIST):
    case FAILURE(ACTION_TYPES.LOAD_HOUSEROOM_LIST):
    case FAILURE(ACTION_TYPES.FETCH_HOUSEROOM):
    case FAILURE(ACTION_TYPES.CREATE_HOUSEROOM):
    case FAILURE(ACTION_TYPES.UPDATE_HOUSEROOM):
    case FAILURE(ACTION_TYPES.DELETE_HOUSEROOM):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.SEARCH_HOUSEROOMS):
    case SUCCESS(ACTION_TYPES.FETCH_HOUSEROOM_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data.map(item => ({ ...item })),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_SIMPLE_HOUSEROOM_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        simpleEntities: action.payload.data.map(item => ({ ...item })),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_HOUSEROOM_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_HOUSEROOM):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_HOUSEROOM):
    case SUCCESS(ACTION_TYPES.UPDATE_HOUSEROOM):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_HOUSEROOM):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/house-rooms'; // 房源管理-集中式/分散式/团租式 form search
const apiSimpleUrl = 'api/house-rooms/simple'; // 房号select数据
const apiSearchUrl = 'api/_search/house-rooms'; // 房源select

// Actions

export const getSearchEntities: ICrudGetListAction<IHouseRoom> = (page, size, sort, searchForm) => ({
  type: ACTION_TYPES.SEARCH_HOUSEROOMS,
  payload: axios.get<IHouseRoom>(
    `${apiUrl}${
      sort ? `?page=${page}&size=${size}&sort=${sort}&cacheBuster=${new Date().getTime()}` : `?cacheBuster=${new Date().getTime()}`
    }`,
    {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }
  ),
  meta: { transformUserName: true }
});

export const getEntities: ICrudGetListAction<IHouseRoom> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiUrl}?page=${page}&size=${size}&sort=${sort}`;
  return {
    type: ACTION_TYPES.FETCH_HOUSEROOM_LIST,
    payload: axios.get<IHouseRoom>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    })
  };
};

export const getSimpleEntities: ICrudGetListAction<IHouseRoom> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiSimpleUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_SIMPLE_HOUSEROOM_LIST,
    payload: axios.get<IHouseRoom>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    })
  };
};

export const loadEntities: ICrudGetListAction<IHouseRoom> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.LOAD_HOUSEROOM_LIST,
    payload: axios.get<IHouseRoom>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    })
  };
};

export const getEntity: ICrudGetAction<IHouseRoom> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_HOUSEROOM,
    payload: axios.get<IHouseRoom>(requestUrl)
  };
};
export const getEntityByHouseId: ICrudGetAction<IHouseRoom> = houseId => {
  const requestUrl = `${apiUrl}/house/${houseId}`;
  return {
    type: ACTION_TYPES.FETCH_HOUSEROOM,
    payload: axios.get<IHouseRoom>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IHouseRoom> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_HOUSEROOM,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IHouseRoom> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_HOUSEROOM,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};
export const roomStateChange = (idArrs: Number[], houseState: 'up' | 'down' | 'publish') => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_HOUSEROOM,
    payload: axios.put(`${apiUrl}/actions/update-to-${houseState}`, { ids: idArrs })
  });
  // dispatch(getEntities());
  return result;
};
export const roomStateChangeWithNoneLoadList = (idArrs, houseState: 'up' | 'down' | 'publish') => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_HOUSEROOM,
    payload: axios.put(`${apiUrl}/actions/update-to-${houseState}`, { ids: idArrs })
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IHouseRoom> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_HOUSEROOM,
    payload: axios.delete(requestUrl)
  });
  return result;
};
export const deleteRoom: ICrudDeleteAction<IHouseRoom> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_HOUSEROOM,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const deleteBatch = (idArrs: Number[]) => async dispatch => {
  const requestUrl = `${apiUrl}/actions/delete-batch`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_HOUSEROOM,
    payload: axios.delete(requestUrl, { data: { ids: idArrs } })
  });
  return result;
};
export const authenticity = (idArrs, state: 'success' | 'failed') => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_HOUSEROOM,
    payload: axios.put(`${apiUrl}/actions/authenticity-judge/${state}`, { ids: idArrs })
  });
  return result;
};
export const reset = () => ({
  type: ACTION_TYPES.RESET
});
export const getHouseTypeCN = (house: IHouse) =>
  `${translate(`lehomeApp.HouseType.${house.type}`)}-${translate(`lehomeApp.house.hasEscalatorValue.${house.hasEscalator}`)}`;

export const getFullName = (houseRoom: IHouseRoom) => {
  if (!houseRoom || !houseRoom.house) {
    return '';
  }
  const suffix = ['', '栋', '单元', '楼', '室'];
  const contents = [houseRoom.house.name, houseRoom.buildingCode, houseRoom.unitCode, houseRoom.floor, houseRoom.roomCode];
  const name = contents
    .map((content, index) => {
      if (content) {
        return content + suffix[index];
      }
      return content;
    })
    .filter(content => content)
    .join('');
  return name;
};

export const getSingleMonthPrice = (houseRoom: IHouseRoom) => {
  if (!houseRoom) return '';
  return houseRoom.singleMonthPrice;
};

export const getPaymentCycle = (houseRoom: IHouseRoom) => {
  if (!houseRoom) return '';
  return houseRoom.paymentCycle;
};

export const getSingleMonthPriceWithUnit = (houseRoom: IHouseRoom) => {
  if (!houseRoom) return '';
  return `${getSingleMonthPrice(houseRoom)}元/月`;
};

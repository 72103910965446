// @ts-nocheck
import axios from 'axios';
import * as qs from 'qs';
import { ICrudGetAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { message } from 'antd';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IOrders as IParkingFinancial, defaultValue } from 'app/shared/model/parking-financial.model';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';

// 请求
export const ACTION_TYPES = {
  FETCH_ORDERS_LIST: 'orders/FETCH_ORDERS_LIST', // 表格
  LOAD_ORDERS_LIST: 'orders/LOAD_ORDERS_LIST', // 表格
  FETCH_ORDERS: 'orders/FETCH_ORDERS', // 表格
  REMIND_ORDER: 'orders/REMIND_ORDER', // 确认
  REMIND_ORDERS: 'orders/REMIND_ORDERS', // 批量确认
  CREATE_ORDERS: 'orders/CREATE_ORDERS', // 创建
  UPDATE_ORDERS: 'orders/UPDATE_ORDERS', // 更新
  CONFIRM_ORDERS: 'orders/CONFIRM_ORDERS', // 确认
  CONFIRM_CHECK_ORDERS: 'orders/CONFIRM_CHECK_ORDERS', // 确认
  LOCK_BELATED_TYPE: 'orders/LOCK_BELATED_TYPE', // 解锁违约金
  UNLOCK_BELATED_TYPE: 'orders/UNLOCK_BELATED_TYPE', // 锁定违约金
  CONFIRM_ROLL_BACK: 'orders/CONFIRM_ROLL_BACK', // 回退
  CONFIRM_RESTART: 'orders/CONFIRM_RESTART', // 重新发起
  CONFIRM_INVALID: 'orders/CONFIRM_INVALID', // 作废
  DELETE_ORDERS: 'orders/DELETE_ORDERS', // 删除账单
  BATCH_CONFIRM_ORDER: 'orders/BATCH_CONFIRM_ORDER', // 批量统一
  BATCH_CONFIRMS_ORDER: 'orders/BATCH_CONFIRMS_ORDER', // 批量确认
  BATCH_CHANGE_CHARGES: 'orders/BATCH_CHANGE_CHARGES', // 批量修改
  OPEN_MONTH_CART: 'orders/OPEN_MONTH_CART', // 手动开通月卡
  RESET: 'orders/RESET'
};

// state
const initialState = {
  loading: false, // loading
  fetching: false,
  loadMore: false, // 公众号加载更多
  errorMessage: null, // 报错信息
  updating: false, // 更新loading
  updateSuccess: false, // 更新成功
  entities: [] as ReadonlyArray<IParkingFinancial>, // 数据返回列表
  entity: defaultValue, // 数据返回列表
  totalItems: 0, // 总条数
  searchForm: {}
};

export type ParkingFinancialState = Readonly<typeof initialState>;

// Reducer
export default (state: ParkingFinancialState = initialState, action): ParkingFinancialState => {
  switch (action.type) {
    // 开始请求
    case REQUEST(ACTION_TYPES.BATCH_CONFIRM_ORDER):
    case REQUEST(ACTION_TYPES.BATCH_CONFIRMS_ORDER):
    case REQUEST(ACTION_TYPES.CONFIRM_ORDERS):
    case REQUEST(ACTION_TYPES.CONFIRM_CHECK_ORDERS):
    case REQUEST(ACTION_TYPES.LOCK_BELATED_TYPE):
    case REQUEST(ACTION_TYPES.UNLOCK_BELATED_TYPE):
    case REQUEST(ACTION_TYPES.BATCH_CHANGE_CHARGES):
    case REQUEST(ACTION_TYPES.CONFIRM_ROLL_BACK):
    case REQUEST(ACTION_TYPES.CONFIRM_RESTART):
    case REQUEST(ACTION_TYPES.CONFIRM_INVALID):
    case REQUEST(ACTION_TYPES.FETCH_ORDERS_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_ORDERS_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_ORDERS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_ORDERS):
    case REQUEST(ACTION_TYPES.UPDATE_ORDERS):
    case REQUEST(ACTION_TYPES.DELETE_ORDERS):
    case REQUEST(ACTION_TYPES.OPEN_MONTH_CART):
    case REQUEST(ACTION_TYPES.REMIND_ORDER):
    case REQUEST(ACTION_TYPES.REMIND_ORDERS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    // 请求失败
    case FAILURE(ACTION_TYPES.FETCH_ORDERS_LIST):
    case FAILURE(ACTION_TYPES.BATCH_CONFIRM_ORDER):
    case FAILURE(ACTION_TYPES.BATCH_CONFIRMS_ORDER):
    case FAILURE(ACTION_TYPES.LOAD_ORDERS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ORDERS):
    case FAILURE(ACTION_TYPES.CONFIRM_ORDERS):
    case FAILURE(ACTION_TYPES.CONFIRM_CHECK_ORDERS):
    case FAILURE(ACTION_TYPES.LOCK_BELATED_TYPE):
    case FAILURE(ACTION_TYPES.UNLOCK_BELATED_TYPE):
    case FAILURE(ACTION_TYPES.BATCH_CHANGE_CHARGES):
    case FAILURE(ACTION_TYPES.CONFIRM_ROLL_BACK):
    case FAILURE(ACTION_TYPES.CONFIRM_RESTART):
    case FAILURE(ACTION_TYPES.CONFIRM_INVALID):
    case FAILURE(ACTION_TYPES.CREATE_ORDERS):
    case FAILURE(ACTION_TYPES.UPDATE_ORDERS):
    case FAILURE(ACTION_TYPES.DELETE_ORDERS):
    case FAILURE(ACTION_TYPES.OPEN_MONTH_CART):
    case FAILURE(ACTION_TYPES.REMIND_ORDER):
    case FAILURE(ACTION_TYPES.REMIND_ORDERS):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    // 请求成功
    // 账单管理 table查询
    case SUCCESS(ACTION_TYPES.FETCH_ORDERS_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_ORDERS_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_ORDERS):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_ORDERS):
    case SUCCESS(ACTION_TYPES.UPDATE_ORDERS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.OPEN_MONTH_CART):
      message.error(action.payload.data);
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case SUCCESS(ACTION_TYPES.REMIND_ORDER):
    case SUCCESS(ACTION_TYPES.REMIND_ORDERS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case SUCCESS(ACTION_TYPES.DELETE_ORDERS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    // 批量确认账单
    case SUCCESS(ACTION_TYPES.BATCH_CONFIRM_ORDER):
      message.success('批量确认账单操作成功');
      return state;
    case SUCCESS(ACTION_TYPES.BATCH_CONFIRMS_ORDER):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CONFIRM_ORDERS):
      return {
        ...state,
        loading: false
      };
    case SUCCESS(ACTION_TYPES.CONFIRM_CHECK_ORDERS):
      return {
        ...state,
        loading: false
      };
    // 锁定违约金
    case SUCCESS(ACTION_TYPES.LOCK_BELATED_TYPE):
      message.success('锁定违约金操作成功');
      return state;
    // 解锁违约金
    case SUCCESS(ACTION_TYPES.UNLOCK_BELATED_TYPE):
      message.success('解除锁定违约金操作成功');
      return state;
    // 批量调整费项
    case SUCCESS(ACTION_TYPES.BATCH_CHANGE_CHARGES):
      message.success('批量调整费项操作成功');
      return state;
    // 撤销回退
    case SUCCESS(ACTION_TYPES.CONFIRM_ROLL_BACK):
      message.success('撤销回退操作成功');
      return state;
    // 重新发起账单
    case SUCCESS(ACTION_TYPES.CONFIRM_RESTART):
      message.success('重新发起账单操作成功');
      return state;
    // 作废账单
    case SUCCESS(ACTION_TYPES.CONFIRM_INVALID):
      message.success('作废账单操作成功');
      return state;
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/porders'; // 账务管理-账单管理 表单查询
const initpage = 0; // 初始页码
const initsize = 20; // 条数

// Actions
// 账单管理详情
export const getEntities: ICrudGetListAction<IParkingFinancial[]> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${
    sort ? `?page=${page}&size=${size}&sort=${sort}${sort.includes('roomName,') ? sort.replace('roomName,', '&roomCodeSort=') : ''}` : ''
  }`;
  initialState.searchForm = { ...searchForm };
  return {
    type: ACTION_TYPES.FETCH_ORDERS_LIST,
    payload: axios.get<IParkingFinancial[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

// 账单管理列表
export const loadEntities: ICrudGetListAction<IParkingFinancial[]> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.LOAD_ORDERS_LIST,
    payload: axios.get<IParkingFinancial[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};
// 批量确认
export const batchConfirm = (ids: any[]) => async dispatch => {
  const requestUrl = `${apiUrl}/confirms`;
  const result = await dispatch({
    type: ACTION_TYPES.BATCH_CONFIRM_ORDER,
    payload: axios.put<IParkingFinancial>(
      requestUrl,
      { ids },
      { paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' }) }
    )
  });
  return result;
};
// 锁定违约金
export const lockBelatedType = (id, data) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/lock-belated`;
  const result = await dispatch({
    type: ACTION_TYPES.LOCK_BELATED_TYPE,
    payload: axios.put(requestUrl, data)
  });
  return result;
};
// 解锁违约金
export const unlockBelatedType = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/unlock-belated`;
  const result = await dispatch({
    type: ACTION_TYPES.UNLOCK_BELATED_TYPE,
    payload: axios.put(requestUrl, {})
  });
  return result;
};

export const getEntity: ICrudGetAction<IParkingFinancial> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ORDERS,
    payload: axios.get<IParkingFinancial>(requestUrl)
  };
};

// 普通订单
export const checkConfirm = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/confirm`;
  const result = await dispatch({
    type: ACTION_TYPES.CONFIRM_CHECK_ORDERS,
    payload: axios.put<IParkingFinancial>(requestUrl)
  });
  return result;
};
// 回滚
export const confirmRollBack = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/confirm-roll-back`;
  const result = await dispatch({
    type: ACTION_TYPES.CONFIRM_ROLL_BACK,
    payload: axios.put<IParkingFinancial>(requestUrl)
  });
  // dispatch(getEntities());
  return result;
};
// 重新发起
export const confirmRestart = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/restart`;
  const result = await dispatch({
    type: ACTION_TYPES.CONFIRM_RESTART,
    payload: axios.put<IParkingFinancial>(requestUrl)
  });
  // dispatch(getEntities());
  return result;
};
// 作废
export const confirmInvalid = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/invalid`;
  const result = await dispatch({
    type: ACTION_TYPES.CONFIRM_INVALID,
    payload: axios.put<IParkingFinancial>(requestUrl)
  });
  // dispatch(getEntities());
  return result;
};

export const transferred = (id, paymentMethodId, type, transferredRemark) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/transferred`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ORDERS,
    payload: axios.put<IParkingFinancial>(requestUrl, null, { params: { paymentMethodId, type, transferredRemark } })
  });
  // dispatch(getEntities());
  return result;
};
// 新建
export const createEntity: ICrudPutAction<IParkingFinancial> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ORDERS,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};
// 更新
export const updateEntity: ICrudPutAction<IParkingFinancial> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ORDERS,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};

export const remind = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.REMIND_ORDER,
    payload: axios.put(`/api/porders/${id}/remind`)
  });
  return result;
};
// 开通月租卡
export const getOpenMonthCart = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.OPEN_MONTH_CART,
    payload: axios.post(`/api/porders/${id}/openNowMonthCard`)
  });
  return result;
};

export const reminds = ids => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.REMIND_ORDERS,
    payload: axios.put(`/api/porders/reminds`, { ids })
  });
  // dispatch(getEntities());
  return result;
};
// 删除
export const deleteEntity: ICrudDeleteAction<IParkingFinancial> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ORDERS,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities(initpage, initsize, '', initialState.searchForm));
  return result;
};
// 表单查询重置
export const reset = () => ({
  type: ACTION_TYPES.RESET
});
// 所有
export const loadTotal = searchForm =>
  axios.get<{ total: number; trulyTotal: number; orderItemsTotalMap: any }>(`${apiUrl}/total`, {
    params: flattenKey(searchForm),
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
  });

import axios from 'axios';
import * as qs from 'qs';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IRefundRental } from 'app/shared/model/refund-rental-parking.model';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_REFUNDRENTAL_LIST: 'refundRental/FETCH_REFUNDRENTAL_LIST',
  LOAD_REFUNDRENTAL_LIST: 'refundRental/LOAD_REFUNDRENTAL_LIST',
  FETCH_REFUNDRENTAL: 'refundRental/FETCH_REFUNDRENTAL',
  CREATE_REFUNDRENTAL: 'refundRental/CREATE_REFUNDRENTAL',
  UPDATE_REFUNDRENTAL: 'refundRental/UPDATE_REFUNDRENTAL',
  DELETE_REFUNDRENTAL: 'refundRental/DELETE_REFUNDRENTAL',
  RESET: 'refundRental/RESET'
};
// state定义
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IRefundRental>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  searchForm: {}
};

export type RefundRentalParkingState = Readonly<typeof initialState>;

// Reducer

export default (state: RefundRentalParkingState = initialState, action): RefundRentalParkingState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_REFUNDRENTAL_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_REFUNDRENTAL_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_REFUNDRENTAL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_REFUNDRENTAL):
    case REQUEST(ACTION_TYPES.UPDATE_REFUNDRENTAL):
    case REQUEST(ACTION_TYPES.DELETE_REFUNDRENTAL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_REFUNDRENTAL_LIST):
    case FAILURE(ACTION_TYPES.LOAD_REFUNDRENTAL_LIST):
    case FAILURE(ACTION_TYPES.FETCH_REFUNDRENTAL):
    case FAILURE(ACTION_TYPES.CREATE_REFUNDRENTAL):
    case FAILURE(ACTION_TYPES.UPDATE_REFUNDRENTAL):
    case FAILURE(ACTION_TYPES.DELETE_REFUNDRENTAL):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_REFUNDRENTAL_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_REFUNDRENTAL_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_REFUNDRENTAL):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_REFUNDRENTAL):
    case SUCCESS(ACTION_TYPES.UPDATE_REFUNDRENTAL):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_REFUNDRENTAL):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/prefund-rentals'; // 退房申请
const initpage = 0; // 初始页码
const initsize = 20; // 条数

// Actions
// 退房申请列表
export const getEntities: ICrudGetListAction<IRefundRental> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  initialState.searchForm = { ...searchForm };

  return {
    type: ACTION_TYPES.FETCH_REFUNDRENTAL_LIST,
    payload: axios.get<IRefundRental>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const loadEntities: ICrudGetListAction<IRefundRental> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.LOAD_REFUNDRENTAL_LIST,
    payload: axios.get<IRefundRental>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};
// 退房申请详情
export const getEntity: ICrudGetAction<IRefundRental> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_REFUNDRENTAL,
    payload: axios.get<IRefundRental>(requestUrl)
  };
};
// 创建退房申请
export const createEntity: ICrudPutAction<IRefundRental> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_REFUNDRENTAL,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  // dispatch(reset());
  // dispatch(getEntities());
  return result;
};
// 更新退房申请
export const updateEntity: ICrudPutAction<IRefundRental> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_REFUNDRENTAL,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  // dispatch(reset());
  // dispatch(getEntities());
  return result;
};
// 验证退房申请
export const validateEntity: ICrudPutAction<IRefundRental> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_REFUNDRENTAL,
    payload: axios.put(`${apiUrl}/validate`, cleanEntity(entity))
  });
  // dispatch(reset());
  // dispatch(getEntities());
  return result;
};
// 审批退房申请
export const auditEntity: ICrudPutAction<IRefundRental> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_REFUNDRENTAL,
    payload: axios.put(`${apiUrl}/audit`, cleanEntity(entity))
  });
  // dispatch(reset());
  // dispatch(getEntities());
  return result;
};
// 删除退房申请
export const deleteEntity: ICrudDeleteAction<IRefundRental> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_REFUNDRENTAL,
    payload: axios.delete(requestUrl)
  });
  // dispatch(reset());
  dispatch(getEntities(initpage, initsize, '', initialState.searchForm));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

import moment, { Moment } from 'moment';
import { IFilter, IRangeFilter, IStringFilter } from './filter.model';
import { IHouse } from './house.model';

export enum MarketingState {
  UNPUBLISHED = 'UNPUBLISHED',
  PUBLISHED = 'PUBLISHED',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED'
}

export enum DecreaseType {
  ORDERS_DECREASE = 'ORDERS_DECREASE'
}

export interface IMarketingItem {
  name?: string;
  singleMonthDecreaseAmount?: number;
}

export interface IMarketing {
  id?: number;
  title?: string;
  state?: MarketingState;
  description?: any;
  startAt?: Moment;
  endAt?: Moment;
  decreaseMonth?: number;
  decreaseType?: DecreaseType;
  image1?: string;
  image2?: string;
  image3?: string;
  image4?: string;
  marketingItems?: IMarketingItem[];
  houses?: IHouse[];
  houseIds?: number[];
  marketingRoomRent?: number;
  marketingPropertyCharges?: number;
  rangeAt?: [Moment, Moment];
}

export const defaultValue: Readonly<IMarketing> = {
  title: '',
  state: MarketingState.UNPUBLISHED,
  image1: '',
  image2: '',
  image3: '',
  image4: '',
  decreaseMonth: 3,
  decreaseType: DecreaseType.ORDERS_DECREASE,
  marketingItems: [],
  houses: [],
  houseIds: []
};

export interface ISearchForm {
  title?: IStringFilter;
  state?: IStringFilter;
  houseId?: IFilter;
  startAt?: IRangeFilter;
  endAt?: IRangeFilter;
  page?: number;
  sort?: number;
}

export const defaultSearchForm: Readonly<ISearchForm> = {
  title: {
    contains: ''
  },
  state: {
    equals: ''
  },
  houseId: {
    equals: ''
  },
  startAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  endAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  }
};

import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
// tslint:disable-next-line:no-unused-variable
import { getSortState, IPaginationBaseState, Translate, translate } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { deleteEntity, getEntities, updateEntity } from './black-list.reducer';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATETIME_T_FORMAT } from 'app/config/constants';
import { deleteToList, getDefaultSort, objectToParameters, searchToObject } from 'app/shared/util/url-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { defaultsDeep, isString, omit } from 'lodash';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import moment from 'moment';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { Badge, Button, Card, Col, DatePicker, Form, Input, Row, Select, Table, Tag, Modal } from 'antd';
import LinkButton from 'app/shared/components/link-button';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { stringToDate } from 'app/shared/util/date-utils';
import { defaultSearchForm, ISearchForm } from 'app/shared/model/user.model';
import { getRoles, getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { filterField } from 'app/modules/administration/user-management/user-management';

export interface IBlackListProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IBlackListState extends IPaginationBaseState {
  searchForm?: ISearchForm;
  blackList: {};
}

export class BlackList extends React.Component<IBlackListProps, IBlackListState> {
  state: IBlackListState = {
    blackList: {},
    searchForm: defaultsDeep(omit(searchToObject(this.props.location), ['page', 'sort']), defaultSearchForm),
    ...getSortState(getDefaultSort(this.props.location), ITEMS_PER_PAGE)
  };
  // 初始化
  componentDidMount() {
    this.sortEntities();
    this.props.getRoles();
  }
  // 请求
  getEntities = async () => {
    const { activePage, itemsPerPage, sort, order, searchForm } = this.state;
    const blackListResult = (await this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`, { ...searchForm })) as any;
    return blackListResult;
    // const userIds = blackListResult.value.data.map(item => item.userId);
    // await this.props.getUsers(0, itemsPerPage, `${sort},${order}`, {
    //   ...searchForm,
    //   id: { in: userIds || [] }
    // });
    // return blackListResult.value.data.reduce((pre, cur) => {
    //   pre[cur.userId] = cur;
    //   return pre;
    // }, {});
  };
  // 排序
  sortEntities() {
    const blackList = this.getEntities();
    blackList.then(result => {
      this.setState({
        blackList: result
      });
    });
    this.changeUrl();
  }
  // 翻页
  changeUrl() {
    const { activePage, sort, order, searchForm } = this.state;
    const searchParams = [`page=${activePage}`, `sort=${sort},${order}`, objectToParameters(searchForm)];
    this.props.history.push(`${this.props.location.pathname}?${searchParams.filter(x => x).join('&')}`);
  }
  // change 页码
  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());
  // table change
  tableHandleChange = (pagination, filters, sorter) => {
    this.setState(
      {
        order: sorter.order === 'ascend' ? 'asc' : 'desc',
        sort: sorter.order && sorter.field ? sorter.field : ''
      },
      () => this.sortEntities()
    );
  };
  // delete
  deleteBlack = black => {
    Modal.confirm({
      title: translate('entity.delete.title'),
      content: translate('lehomeApp.blackList.delete.question', { id: black.id }),
      okText: translate('entity.action.delete'),
      okType: 'danger',
      cancelText: translate('entity.action.cancel'),
      onOk: () => {
        const deletePromise: Promise<any> = this.props.deleteEntity(black.id) as any;
        deletePromise.then(() => {
          this.sortEntities();
        });
        return deletePromise;
      },
      onCancel: () => {
        this.sortEntities();
      }
    });
  };
  // 格式化日期
  formatDate(date) {
    return date ? moment(date).format(APP_DATE_FORMAT) : date;
  }
  // check auth
  checkAuth = (action: string[] | string) => {
    const {
      account: { authorities }
    } = this.props;
    if (isString(action)) {
      action = [action];
    }
    return hasAnyAuthority(authorities, [`BlackList#${action}`]);
  };
  // form reset
  handleFormReset = (form: WrappedFormUtils<ISearchForm>) => {
    form.resetFields();
    this.setState(
      {
        ...this.state,
        searchForm: defaultSearchForm,
        activePage: 1
      },
      () => this.sortEntities()
    );
  };
  // 搜索
  handleSearch = (form: WrappedFormUtils<ISearchForm>) => {
    form.validateFields((err, postValues) => {
      if (!err) {
        const { login, mobile, createdAt, activated, roles } = postValues;

        this.setState(
          {
            searchForm: defaultsDeep(
              {
                login: {
                  contains: login
                },
                mobile: {
                  contains: mobile
                },
                createdAt: {
                  greaterOrEqualThan: createdAt[0] ? createdAt[0].utc().format(APP_LOCAL_DATETIME_T_FORMAT) : '',
                  lessOrEqualThan: createdAt[1] ? createdAt[1].utc().format(APP_LOCAL_DATETIME_T_FORMAT) : ''
                },
                activated: {
                  equals: activated
                },
                roles: {
                  in: roles
                }
              },
              defaultSearchForm
            ),
            activePage: 1
          },
          () => this.sortEntities()
        );
      }
    });
  };
  // 渲染按钮
  renderActions = (text, record) => {
    const { match } = this.props;
    const __delete = () => {
      this.deleteBlack(record);
    };
    return (
      <Button.Group>
        {this.checkAuth('delete') && <Button title={translate('entity.action.delete')} type="danger" icon="delete" onClick={__delete} />}
      </Button.Group>
    );
  };
  // 获取数据
  getDataResource = () => (this.props.users.length === Object.keys(this.state.blackList).length ? this.props.users.map(user => user) : []);
  // 渲染列数据
  getDataColumns() {
    const orderMap = {
      desc: 'descend',
      asc: 'ascend'
    };
    const { account } = this.props;
    const { sort, order, blackList } = this.state;
    const columns = [
      {
        title: translate('userManagement.login'),
        dataIndex: 'login',
        key: 'login',
        width: 120
      },
      {
        title: translate('userManagement.name'),
        dataIndex: 'name',
        key: 'name',
        width: 120
      },
      {
        title: translate('userManagement.mobile'),
        dataIndex: 'mobile',
        key: 'mobile',
        width: 120
      },
      {
        title: translate('lehomeApp.blackList.description'),
        dataIndex: 'description',
        key: 'description'
      }
      // {
      //   title: translate('userManagement.activate'),
      //   dataIndex: 'activated',
      //   key: 'activated',
      //   render: (text, record) =>
      //     record.activated ? (
      //       <Badge status="success" text={translate('userManagement.activated')} />
      //     ) : (
      //       <Badge status="default" text={translate('userManagement.deactivated')} />
      //     )
      // },
      // {
      //   title: translate('userManagement.profiles'),
      //   dataIndex: 'user.roles',
      //   key: 'user.roles',
      //   render: (text, record) => record.roles.map(auth => <Tag key={`${record.id}-${auth}`}>{auth}</Tag>),
      //   width: 116
      // }
    ];
    const columes2 = [
      {
        title: translate('lehomeApp.blackList.operator'),
        dataIndex: 'operator',
        key: 'operator',
        width: 200
      },
      {
        title: translate('userManagement.createdDate'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        width: 200,
        sortOrder: sort === 'createdAt' ? orderMap[order] : false,
        render: text => this.formatDate(text)
      }
      // {
      //   title: '',
      //   dataIndex: 'action',
      //   key: 'action',
      //   render: (text, record) => this.renderActions(text, blackList[record.id])
      // }
    ];
    return columns.concat(columes2 as any[]);
  }
  // 渲染查询表单
  renderSearchForm(form: WrappedFormUtils<ISearchForm>) {
    const { getFieldDecorator } = form;
    const { searchForm } = this.state;
    const { roles } = this.props;
    const rowGutter = {
      md: 8,
      lg: 24,
      xl: 48
    };
    const formItemLayout = {
      xs: 24,
      md: 8
    };
    const __onSubmit = () => this.handleSearch(form);
    const __onReset = () => this.handleFormReset(form);
    return (
      <div className={'tableListForm'}>
        <Form onSubmit={__onSubmit}>
          <Row gutter={rowGutter} type={'flex'}>
            <Col {...formItemLayout}>
              <Form.Item label={translate('userManagement.login')}>
                {getFieldDecorator('login', {
                  initialValue: searchForm.login.contains
                })(<Input placeholder={translate('global.placeholder.input')} />)}
              </Form.Item>
            </Col>
            <Col {...formItemLayout}>
              <Form.Item label={translate('userManagement.mobile')}>
                {getFieldDecorator('mobile', {
                  initialValue: searchForm.mobile.contains
                })(<Input placeholder={translate('global.placeholder.input')} />)}
              </Form.Item>
            </Col>
            {/* <Col {...formItemLayout}>
              <Form.Item label={translate('userManagement.activate')}>
                {getFieldDecorator('activated', {
                  initialValue: filterField(searchForm).activated.equals
                })(
                  <Select allowClear placeholder={translate('global.placeholder.select')}>
                    <Select.Option value={'true'}>{translate('userManagement.activated')}</Select.Option>
                    <Select.Option value={'false'}>{translate('userManagement.deactivated')}</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col {...formItemLayout}>
              <Form.Item label={translate('userManagement.role')}>
                {getFieldDecorator('roles', {
                  initialValue: filterField(searchForm).roles.in
                })(
                  <Select mode={'multiple'} allowClear placeholder={translate('global.placeholder.select')}>
                    {roles.map(role => (
                      <Select.Option value={role} key={role}>
                        {role}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col> */}
            <Col {...formItemLayout}>
              <Form.Item label={translate('userManagement.createdDate')}>
                {getFieldDecorator('createdAt', {
                  initialValue: [stringToDate(searchForm.createdAt.greaterOrEqualThan), stringToDate(searchForm.createdAt.lessOrEqualThan)]
                })(<DatePicker.RangePicker format={APP_LOCAL_DATE_FORMAT} />)}
              </Form.Item>
            </Col>
          </Row>
          <div style={{ overflow: 'hidden' }}>
            <span style={{ float: 'right', marginBottom: 24 }}>
              <Button.Group>
                <Button onClick={__onReset}>
                  <Translate contentKey={'entity.action.reset'} />
                </Button>
                <Button type="primary" htmlType="submit">
                  <Translate contentKey={'entity.action.search'} />
                </Button>
              </Button.Group>
            </span>
          </div>
        </Form>
      </div>
    );
  }

  render() {
    const { loading, totalItems, match, users, blackList } = this.props;
    const dataSource = () => (blackList.length ? blackList.map(item => item) : []);
    const CustomForm = Form.create()(({ form }) => this.renderSearchForm(form));
    return (
      <PageHeaderWrapper>
        <Card bordered={false}>
          <CustomForm />
          <Table
            rowKey={'id'}
            loading={loading}
            dataSource={dataSource()}
            columns={this.getDataColumns()}
            onChange={this.tableHandleChange}
            pagination={{
              defaultPageSize: this.state.itemsPerPage,
              total: totalItems,
              onChange: this.handlePagination,
              current: this.state.activePage
            }}
          />
        </Card>
      </PageHeaderWrapper>
    );
  }
}

const mapStateToProps = ({ userManagement, blackList, authentication }: IRootState) => ({
  blackList: blackList.entities,
  updating: blackList.updating,
  totalItems: blackList.totalItems,
  loading: blackList.loading,
  roles: userManagement.authorities,
  users: userManagement.users,
  account: authentication.account
});

const mapDispatchToProps = {
  getEntities,
  deleteEntity,
  getUsers,
  getRoles,
  updateEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlackList);

import { IHouseExpense } from 'app/shared/model/house-expense.model';
import { IFacilities } from 'app/shared/model/facilities.model';
import { ILifePrice } from 'app/shared/model/life-price.model';
import { IMaintenance } from 'app/shared/model/maintenance.model';
import { IFavorite } from 'app/shared/model/favorite.model';
import { IHistory } from 'app/shared/model/history.model';
import { IFilter, IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { IHouseRoom } from 'app/shared/model/house-room.model';
import { IUser } from 'app/shared/model/user.model';
import { ICity } from 'app/shared/model/city.model';

export const enum BuildingType {
  DISTRIBUTED = 'DISTRIBUTED',
  CENTRALIZED = 'CENTRALIZED',
  GROUPED = 'GROUPED'
}

export const enum HourseSourceType {
  PERSONAL = 'PERSONAL',
  JIANXING = 'JIANXING'
}

export const enum HouseType {
  APARTMENT = 'APARTMENT',
  COMMERCIAL = 'COMMERCIAL',
  FULL_RENT = 'FULL_RENT',
  SHARED_RENT = 'SHARED_RENT'
}

export const enum Direction {
  S = 'S',
  WS = 'WS',
  ES = 'ES',
  N = 'N',
  WN = 'WN',
  EN = 'EN',
  W = 'W',
  E = 'E'
}

export interface IMerchantType {
  label?: string;
  value?: string;
}

export const enum PaymentType {
  ONLINE = 'ONLINE',
  CASH = 'CASH',
  TRANSFER = 'TRANSFER'
}

export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING',
  APPLY = 'APPLY'
}

export interface IHouse {
  parkingLotDisplay?: any;
  id?: number;
  name?: string;
  ydLoading?: boolean;
  communityName?: string;
  province?: string;
  surrounding?: string;
  bus?: string;
  subway?: string;
  city?: string;
  region?: string;
  address?: string;
  longitude?: number;
  latitude?: number;
  type?: HouseType;
  hasEscalator?: boolean;
  contractCode?: string;
  complexDescription?: string;
  floor?: number;
  flowSingleMonthPrice?: number;
  buildingType?: BuildingType;
  town?: ICity;
  developer?: string;
  investmentObject?: string;
  merchantType?: string;
  recentMode?: string;
  flowCount?: number; // 流动车位总数，非必填，不填服务器默认为0
  flowLimit?: number; // 流动车位数量限制，非必填，不填服务器默认为0
  status?: string;
  agentName?: string;
  propertyInfo?: string;
  toogleFloor?: number;
  buildingCode?: string;
  unitCode?: string;
  roomCode?: string;
  roomNumber?: string;
  hallNumber?: number;
  area?: number;
  direction?: Direction;
  houseDescription?: string;
  singleMonthPrice?: number;
  paymentCycle?: number;
  depositNumber?: number;
  extraDeposit?: number;
  paymentType?: PaymentType;
  authState?: AuditState;
  houseExpenses?: IHouseExpense[];
  manager?: IUser;
  ownerId?: number;
  regionalDivideId?: number;
  facilities?: IFacilities[];
  lifePrices?: ILifePrice[];
  source?: HourseSourceType;
  maintenances?: IMaintenance[];
  favorites?: IFavorite[];
  histories?: IHistory[];
  rooms?: IHouseRoom;
}

export const defaultValue: Readonly<IHouse> = {
  hasEscalator: false,
  name: '',
  communityName: '',
  surrounding: '',
  bus: '',
  subway: '',
  source: HourseSourceType.JIANXING,
  direction: Direction.S,
  buildingType: BuildingType.CENTRALIZED,
  authState: AuditState.SUCCESS,
  facilities: []
};

export interface ISearchForm {
  name?: IStringFilter;
  buildingCode?: IStringFilter;
  buildingType?: IStringFilter;
  unitCode?: IStringFilter;
  floor?: IRangeFilter;
  state?: IFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
  updatedAt?: IRangeFilter;
  updatedBy?: IStringFilter;
}

export const defaultSearchForm: Readonly<ISearchForm> = {
  buildingCode: {
    contains: ''
  },
  unitCode: {
    contains: ''
  },
  name: {
    contains: ''
  },
  floor: {
    equals: ''
  },
  buildingType: {
    equals: ''
  },
  state: {
    equals: ''
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  updatedAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  updatedBy: { contains: '' }
};

// 本地存储数据的通用方法
import { Storage } from 'react-jhipster';
import Cookies from 'js-cookie';

export const getKey = (key, type = '') => {
  if (type) {
    switch (type) {
      case 'LOCAL':
        return Storage.local.get(key);
      case 'COOKIE':
        return Cookies.get(key);
      case 'SESSION':
        return Storage.session.get(key);
      default:
        return false;
    }
  } else {
    return Storage.local.get(key) || Storage.session.get(key) || Cookies.get(key) || false;
  }
};

export const setKey = (key, value, type = '') => {
  if (type) {
    switch (type) {
      case 'LOCAL':
        Storage.local.set(key, value);
        break;
      case 'COOKIE':
        Cookies.set(key, value);
        break;
      case 'SESSION':
        Storage.session.set(key, value);
        break;
      default:
        Storage.local.set(key, value);
        Cookies.set(key, value);
        Storage.session.set(key, value);
    }
    return true;
  } else {
    Storage.local.set(key, value);
    Cookies.set(key, value);
    Storage.session.set(key, value);
    return true;
  }
};

export const removeKey = (key, type = '') => {
  if (type) {
    switch (type) {
      case 'LOCAL':
        Storage.local.remove(key);
        return true;
      case 'COOKIE':
        Cookies.remove(key);
        return true;
      case 'SESSION':
        Storage.session.remove(key);
        return true;
      default:
        return false;
    }
  } else {
    Storage.local.remove(key);
    Storage.session.remove(key);
    Cookies.remove(key);
    return true;
  }
};

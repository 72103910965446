import { Moment } from 'moment';

export const enum AppointmentState {
  WAITING = 'WAITING',
  ACCEPTED = 'ACCEPTED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED'
}

export interface IAppointmentLog {
  id?: number;
  state?: AppointmentState;
  createdAt?: Moment;
  createdBy?: string;
  appointmentId?: number;
}

export const defaultValue: Readonly<IAppointmentLog> = {};

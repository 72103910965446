import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IRecommend, defaultValue, ISearchForm } from 'app/shared/model/recommend.model';
import { flattenKey } from 'app/shared/util/object-utils';
import * as qs from 'qs';
// 接口
export const ACTION_TYPES = {
  FETCH_RECOMMEND_LIST: 'recommend/FETCH_RECOMMEND_LIST',
  FETCH_RECOMMEND: 'recommend/FETCH_RECOMMEND',
  CREATE_RECOMMEND: 'recommend/CREATE_RECOMMEND',
  UPDATE_RECOMMEND: 'recommend/UPDATE_RECOMMEND',
  DELETE_RECOMMEND: 'recommend/DELETE_RECOMMEND',
  RESET: 'recommend/RESET'
};
// state
const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IRecommend>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type RecommendState = Readonly<typeof initialState>;

// Reducer

export default (state: RecommendState = initialState, action): RecommendState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_RECOMMEND_LIST):
    case REQUEST(ACTION_TYPES.FETCH_RECOMMEND):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_RECOMMEND):
    case REQUEST(ACTION_TYPES.UPDATE_RECOMMEND):
    case REQUEST(ACTION_TYPES.DELETE_RECOMMEND):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_RECOMMEND_LIST):
    case FAILURE(ACTION_TYPES.FETCH_RECOMMEND):
    case FAILURE(ACTION_TYPES.CREATE_RECOMMEND):
    case FAILURE(ACTION_TYPES.UPDATE_RECOMMEND):
    case FAILURE(ACTION_TYPES.DELETE_RECOMMEND):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_RECOMMEND_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_RECOMMEND):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_RECOMMEND):
    case SUCCESS(ACTION_TYPES.UPDATE_RECOMMEND):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_RECOMMEND):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/recommends'; // 房源推荐

// Actions
// 房源推荐列表
export const getEntities = (page?: any, size?: any, sort?: any, searchForm?: ISearchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_RECOMMEND_LIST,
    payload: axios.get<IRecommend>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    })
  };
};

export const getEntity: ICrudGetAction<IRecommend> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_RECOMMEND,
    payload: axios.get<IRecommend>(requestUrl)
  };
};
// 新增房源推荐
export const createEntity: ICrudPutAction<IRecommend> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_RECOMMEND,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};
// 新增房源推荐s
export const createEntitys = entities => async dispatch => {
  const datas = entities.map(item => cleanEntity(item));
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_RECOMMEND,
    payload: axios.post(`${apiUrl}/createBatch`, datas)
  });
  return result;
};
// 更新房源推荐
export const updateEntity: ICrudPutAction<IRecommend> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_RECOMMEND,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};
// 删除房源推荐
export const deleteEntity: ICrudDeleteAction<IRecommend> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_RECOMMEND,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';
import { Button, Card, Checkbox, Divider, Form, Icon, Input, Radio, Select, Spin, Switch } from 'antd';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { createUser, getRoles, getUser, reset, updatePhone } from './user-management.reducer';
import { IRootState } from 'app/shared/reducers';

import PasswordStrengthInput from 'app/shared/components/password/password-strength-input';
import { editToList, newToList } from 'app/shared/util/url-utils';
import { LIVING_STATE } from 'app/config/constants';
import { livingStateToChinese } from 'app/modules/administration/user-management/user-utils';
import { BankName, UserType } from 'app/shared/model/user.model';

export interface IUserManagementUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ login: string }> {
  form: any;
}

export interface IUserManagementUpdateState {
  isNew: boolean;
  appKeyFetching: boolean;
  type?: UserType;
}

const FormItem = Form.Item;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;

export class UserManagementUpdate extends React.Component<IUserManagementUpdateProps, IUserManagementUpdateState> {
  state: IUserManagementUpdateState = {
    isNew: !this.props.match.params || !this.props.match.params.login,
    appKeyFetching: false
  };

  componentDidMount() {
    !this.state.isNew && this.props.getUser(this.props.match.params.login);
    this.props.getRoles();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  saveUser = event => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { user } = this.props;
        const fuck = {}; // 用户信息
        values = { ...user, ...values };
        values['id'] = this.props.user.id;
        fuck['id'] = this.props.user.id;

        if (values.type !== UserType.BACKEND) {
          values['authorities'] = [];
        }
        // @ts-ignore
        fuck.login = values.login;
        // @ts-ignore
        fuck.mobile = values.mobile;
        const result: any = this.state.isNew ? this.props.updatePhone(fuck) : this.props.updatePhone(fuck);
        result.then(() => {
          this.handleClose();
        });
      }
    });
  };

  handleTypeChange = ({ target: { value } }: any) => {
    this.setState({ type: value });
  };

  handleClose = () => {
    const { history, match } = this.props;
    const { isNew } = this.state;
    history.replace('/system/user-management');
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && !/^[A-Za-z0-9]+$/.test(value)) {
      form.validateFields(['password'], { force: true });
    }
    callback();
  };

  isRoleAdmin = () => {
    const { account } = this.props;
    if (typeof account.roles === 'object' && account.roles.length) {
      for (const i of Object.keys(account.roles)) {
        const item = account.roles[i];
        if (item === 'ROLE_ADMIN') {
          return true;
        }
      }
    }
    return false;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { user, loading, updating, roles } = this.props;
    const { isNew, type } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      }
    };

    getFieldDecorator('langKey', {
      initialValue: 'zh-cn'
    });
    return (
      <PageHeaderWrapper title={'修改手机号'}>
        <Card bordered={false}>
          <Spin spinning={loading}>
            <Form onSubmit={this.saveUser}>
              {/* 登录 */}
              <FormItem {...formItemLayout} label={translate('userManagement.login')}>
                {getFieldDecorator('login', {
                  rules: [
                    {
                      required: true,
                      message: translate('register.messages.validate.login.required')
                    },
                    {
                      pattern: '^[_.@A-Za-z0-9-]*$',
                      message: translate('register.messages.validate.login.pattern')
                    },
                    {
                      min: 1,
                      message: translate('register.messages.validate.login.minlength')
                    },
                    {
                      max: 50,
                      message: translate('register.messages.validate.login.maxlength')
                    }
                  ],
                  initialValue: user.login
                })(<Input disabled={!this.state.isNew} />)}
              </FormItem>
              {/* 手机 */}
              <FormItem {...formItemLayout} label={translate('userManagement.mobile')}>
                {getFieldDecorator('mobile', {
                  initialValue: user.mobile
                })(<Input />)}
              </FormItem>
              <FormItem className={'tc'}>
                <Button type="primary" htmlType="submit" size={'large'} loading={updating}>
                  <Translate contentKey={'entity.action.save'} />
                </Button>
              </FormItem>
            </Form>
          </Spin>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  user: storeState.userManagement.user,
  roles: storeState.userManagement.authorities,
  loading: storeState.userManagement.loading,
  updating: storeState.userManagement.updating,
  account: storeState.authentication.account
});

const mapDispatchToProps = { getUser, getRoles, updatePhone, createUser, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const updateForm = Form.create()(UserManagementUpdate);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(updateForm);

import axios from 'axios';
import * as qs from 'qs';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction, IPayload } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IFacilities, defaultValue, ISearchForm, FacilitiesType } from 'app/shared/model/facilities.model';
import { flattenKey } from 'app/shared/util/object-utils';
import { ILiveInfo } from 'app/shared/model/live-info.model';

export declare type ICrudGetListAction<T> = (
  page?: number,
  size?: number,
  sort?: string,
  searchForm?: ISearchForm
) => IPayload<T> | ((dispatch: any) => IPayload<T>);
// 请求
export const ACTION_TYPES = {
  FETCH_FACILITIES_LIST: 'facilities/FETCH_FACILITIES_LIST',
  LOAD_FACILITIES_LIST: 'facilities/LOAD_FACILITIES_LIST',
  FETCH_FACILITIES: 'facilities/FETCH_FACILITIES',
  CREATE_FACILITIES: 'facilities/CREATE_FACILITIES',
  UPDATE_FACILITIES: 'facilities/UPDATE_FACILITIES',
  DELETE_FACILITIES: 'facilities/DELETE_FACILITIES',
  RESET: 'facilities/RESET'
};
// state
const initialState = {
  loading: false,
  loadMore: false,
  fetching: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IFacilities>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0
};

export type FacilitiesState = Readonly<typeof initialState>;

// Reducer

export default (state: FacilitiesState = initialState, action): FacilitiesState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_FACILITIES_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_FACILITIES_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_FACILITIES):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_FACILITIES):
    case REQUEST(ACTION_TYPES.UPDATE_FACILITIES):
    case REQUEST(ACTION_TYPES.DELETE_FACILITIES):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_FACILITIES_LIST):
    case FAILURE(ACTION_TYPES.LOAD_FACILITIES_LIST):
    case FAILURE(ACTION_TYPES.FETCH_FACILITIES):
    case FAILURE(ACTION_TYPES.CREATE_FACILITIES):
    case FAILURE(ACTION_TYPES.UPDATE_FACILITIES):
    case FAILURE(ACTION_TYPES.DELETE_FACILITIES):
      return {
        ...state,
        loading: false,
        updating: false,
        fetching: false,
        loadMore: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_FACILITIES_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_FACILITIES_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_FACILITIES):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_FACILITIES):
    case SUCCESS(ACTION_TYPES.UPDATE_FACILITIES):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_FACILITIES):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/facilities'; // 配套设施

// Actions
// 配套设施列表
export const getEntities: ICrudGetListAction<IFacilities> = (page, size, sort = 'createdAt,desc', searchForm) => ({
  type: ACTION_TYPES.FETCH_FACILITIES_LIST,
  payload: axios.get<IFacilities>(
    `${apiUrl}${
      sort ? `?page=${page}&size=${size}&sort=${sort}&cacheBuster=${new Date().getTime()}` : `?cacheBuster=${new Date().getTime()}`
    }`,
    {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }
  ),
  meta: { transformUserName: true }
});

export const loadEntities: ICrudGetListAction<IFacilities> = (page, size, sort = 'createdAt', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.LOAD_FACILITIES_LIST,
    payload: axios.get<IFacilities>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};
// 配套设施详情
export const getEntity: ICrudGetAction<IFacilities> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_FACILITIES,
    payload: axios.get<IFacilities>(requestUrl)
  };
};
// 创建配套设施
export const createEntity: ICrudPutAction<IFacilities> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_FACILITIES,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};
// 更新配套设施
export const updateEntity: ICrudPutAction<IFacilities> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_FACILITIES,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};
// 删除配套设施
export const deleteEntity: ICrudDeleteAction<IFacilities> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_FACILITIES,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const getEntitiesWithActivated = searchForm =>
  axios.get<IFacilities[]>(`${apiUrl}/all?cacheBuster=${new Date().getTime()}`, {
    params: flattenKey(searchForm),
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
  });
export const getBaseEntitiesWithActivated = searchForm =>
  getEntitiesWithActivated({
    ...searchForm,
    type: { equals: FacilitiesType.BASIC }
  });
export const getFeatureEntitiesWithActivated = searchForm =>
  getEntitiesWithActivated({
    ...searchForm,
    type: { equals: FacilitiesType.FEATURE }
  });
export const getComplexEntitiesWithActivated = searchForm =>
  getEntitiesWithActivated({
    ...searchForm,
    type: { equals: FacilitiesType.COMPLEX }
  });

import React from 'react';
import { NavLink as Link, RouteComponentProps, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';
import ProLayout, { MenuDataItem } from '@ant-design/pro-layout';
import { Route } from '@ant-design/pro-layout/lib/typings';
import { translate, TranslatorContext } from 'react-jhipster';

import Home from 'app/modules/home/home';
import PrivateRoute, { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import { AUTHORITIES } from 'app/config/constants';
import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { setLocale } from 'app/shared/reducers/locale';
import Login from 'app/modules/login/login';
import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import PageNotFound from 'app/shared/error/page-not-found';
import RightHeader from './right-header';
import Logout from 'app/modules/login/logout';
import { Card, Skeleton, Icon } from 'antd';
import GlobalFooter from 'app/shared/layout/footer/footer';
import routesData, { flattenRoutes } from './routes';
import { ResetIndices } from 'app/entities/indices-management/reset-indices';
import { isMobile } from 'app/shared/util/screen-utils';
import UserManagement from 'app/modules/administration/user-management';
import Role from 'app/modules/administration/roles';
import BlockList from 'app/entities/black-list';
import Settle from 'app/entities/settle';

const defaultFilterMenuData = (menuData: MenuDataItem[] = [], checkAuthorize): MenuDataItem[] =>
  menuData
    .filter(item => item && !item.hideInMenu && (!isMobile() || (isMobile() && !item.hideMobile)) && checkAuthorize(item.authority))
    .map(item => {
      item.name = translate(item.locale, {}, item.name);
      if (item.routes && Array.isArray(item.routes)) {
        const routes = defaultFilterMenuData(item.routes, checkAuthorize);
        if (routes.length > 0) return { ...item, routes };
        return undefined;
      }
      return { ...item, children: undefined };
    })
    .filter(item => item);

const renderMenuData = (menuData, checkAuthorize) => defaultFilterMenuData(menuData, checkAuthorize);

const defaultName = data =>
  data
    .filter(item => item)
    .map(item => {
      item.name = item.name || item.locale;
      if (item.routes && Array.isArray(item.routes)) {
        const children = defaultName(item.routes);
        return { ...item, children };
      }
      return item;
    });

const menuDataFuc = checkAuthorize => {
  const menuData1 = defaultName(routesData);
  const menuData = renderMenuData(menuData1, checkAuthorize);
  return { menuData };
};

const renderMenu = (menuData: MenuDataItem) => (
  <Link to={menuData.path}>
    {menuData.icon && <Icon type={menuData.icon} />}
    <span>{menuData.name}</span>
  </Link>
);

const breadcrumbItemRender = (route: Route, params, routes, paths) =>
  route.isUrl !== false ? <Link to={route.path}>{route.breadcrumbName}</Link> : route.breadcrumbName;

const breadcrumbRender = routes =>
  routes.map(r => {
    r.isUrl = !flattenRoutes[r.path] || flattenRoutes[r.path].isUrl;
    return r;
  });

const Account = Loadable({
  loader: () => import('app/modules/account'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});

const Admin = Loadable({
  loader: () => import('app/modules/administration'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});
const Notice = Loadable({
  loader: () => import('app/entities/notice'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});
const EntityAudit = Loadable({
  loader: () => import('app/entities/entity-audit'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});
const Recommend = Loadable({
  loader: () => import('app/entities/recommend'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});
const Marketing = Loadable({
  loader: () => import('app/entities/marketing'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});
// 设置
const Setting = Loadable({
  loader: () => import('app/modules/setting'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});

const OrgManagement = Loadable({
  loader: () => import('app/modules/org-management'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});
// 集中式
const House = Loadable({
  loader: () => import('app/entities/house'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});
const DistributedHouse = Loadable({
  loader: () => import('app/entities/house/distributed'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});
const GroupedHouse = Loadable({
  loader: () => import('app/entities/house-grouped'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});
// 合同管理
const RentalManagement = Loadable({
  loader: () => import('app/modules/rental'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});
// 商业合同管理
const CommerceRentalManagement = Loadable({
  loader: () => import('app/modules/commerceRental'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});

// 车位月保
const ParkingManagement = Loadable({
  loader: () => import('app/modules/parking'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});

// 财务管理
const FinancialManagement = Loadable({
  loader: () => import('app/modules/financial'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});

// 水电管理
const hydropowerManagement = Loadable({
  loader: () => import('app/modules/hydropower'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});

// 招商管理
const InvestmentManagement = Loadable({
  loader: () => import('app/modules/investment'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});

// 智慧健康管理
const healthManagement = Loadable({
  loader: () => import('app/modules/health'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});

// 汇总统计
const monthStatisticsManagement = Loadable({
  loader: () => import('app/modules/monthStatistics'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});
const Feedback = Loadable({
  loader: () => import('app/entities/feedback'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});
// 集中抄表
const LifePrice = Loadable({
  loader: () => import('app/entities/life-price'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});

// 报修管理
const Maintenance = Loadable({
  loader: () => import('app/entities/maintenance'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});

const Popularize = Loadable({
  loader: () => import('app/entities/popularize'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});
const Ads = Loadable({
  loader: () => import('app/entities/ads'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});
const Guide = Loadable({
  loader: () => import('app/entities/guide'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});
const News = Loadable({
  loader: () => import('app/entities/news'),
  loading: () => (
    <Card bordered={false}>
      <Skeleton active loading />
    </Card>
  )
});

export interface IBackendLayoutProps extends StateProps, DispatchProps, RouteComponentProps {}

export interface IBackendLayoutState {
  collapsed?: boolean;
  menuData: MenuDataItem[];
}

export class BackendLayout extends React.Component<IBackendLayoutProps, IBackendLayoutState> {
  state = {
    collapsed: isMobile(),
    menuData: []
  };

  componentDidMount(): void {
    this.getMenuDataFuc();
  }

  shouldComponentUpdate(nextProps: Readonly<IBackendLayoutProps>, nextState: Readonly<IBackendLayoutState>, nextContext: any): boolean {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      return true;
    }
    if ((nextProps.loaded !== this.props.loaded || nextProps.authorities !== this.props.authorities) && nextProps.loaded) {
      return true;
    }
    return nextState !== this.state;
  }

  private getMenuDataFuc() {
    const { menuData } = menuDataFuc(hasAnyAuthorities => {
      if (!hasAnyAuthorities || hasAnyAuthorities.length === 0) return true;
      return hasAnyAuthority(this.props.authorities, hasAnyAuthorities);
    });
    this.setState({
      // @ts-ignore
      menuData: [...menuData]
    });
  }

  componentDidUpdate(prevProps: Readonly<IBackendLayoutProps>, prevState: Readonly<IBackendLayoutState>, snapshot?: any): void {
    if (prevProps.location.pathname !== this.props.location.pathname && !this.state.collapsed && isMobile()) {
      this.setState({
        collapsed: true
      });
    }
    if ((prevProps.loaded !== this.props.loaded || prevProps.authorities !== this.props.authorities) && this.props.loaded) {
      this.getMenuDataFuc();
    }
  }

  rightContentRender() {
    return <RightHeader />;
  }

  handleCollapse = collapsed => this.setState({ collapsed });

  render() {
    function rendFooter() {
      return <GlobalFooter />;
    }

    const { menuData } = this.state;
    const renderMenuDataFuc = () => menuData;
    console.count();
    return (
      <ProLayout
        location={this.props.location}
        menuDataRender={renderMenuDataFuc}
        menuItemRender={renderMenu}
        collapsed={this.state.collapsed}
        onCollapse={this.handleCollapse}
        route={{ path: '/', routes: [...routesData] }}
        title={'建鑫乐家租赁平台'}
        logo={'/content/images/logo.svg'}
        rightContentRender={this.rightContentRender}
        breadcrumbRender={breadcrumbRender}
        itemRender={breadcrumbItemRender}
        footerRender={rendFooter}
      >
        <Switch>
          {/* 登录 */}
          <ErrorBoundaryRoute path="/login" component={Login} />
          {/* 登出 */}
          <ErrorBoundaryRoute path="/logout" component={Logout} />
          {/* 重置密码 */}
          <ErrorBoundaryRoute path="/register" component={Register} />
          <ErrorBoundaryRoute path="/activate/:key?" component={Activate} />
          <ErrorBoundaryRoute path="/reset/request" component={PasswordResetInit} />
          <ErrorBoundaryRoute path="/reset/finish/:key?" component={PasswordResetFinish} />
          <PrivateRoute path="/admin" component={Admin} />
          {/* 设置 */}
          <PrivateRoute path="/setting" component={Setting} />
          {/* 房源管理 */}
          <PrivateRoute path="/house/centralized" component={House} />
          <PrivateRoute path="/house/distributed" component={DistributedHouse} />
          <PrivateRoute path="/house/grouped" component={GroupedHouse} />
          <PrivateRoute path="/information/recommend" component={Recommend} />
          <PrivateRoute path="/information/marketing" component={Marketing} />
          <PrivateRoute path="/information/popularize" component={Popularize} />
          <PrivateRoute path="/information/ads" component={Ads} />
          <PrivateRoute path="/information/news" component={News} hasAnyAuthorities={[AUTHORITIES.ADMIN, 'News#read']} />
          <PrivateRoute path="/system/user-management" component={UserManagement} hasAnyAuthorities={['User#read']} />
          <PrivateRoute path="/system/role" component={Role} hasAnyAuthorities={['Role#read']} />
          <PrivateRoute path="/system/black" component={BlockList} hasAnyAuthorities={['Block#read']} />
          <PrivateRoute path="/system/settle" component={Settle} hasAnyAuthorities={['Settle#read']} />
          <PrivateRoute path="/system/notice" component={Notice} />
          <PrivateRoute path="/system/audit/entity" component={EntityAudit} />
          <PrivateRoute path="/system/indices" component={ResetIndices} />
          <PrivateRoute path="/system/guide" component={Guide} />
          {/* 用户管理 */}
          <PrivateRoute path="/org-management" component={OrgManagement} />
          <PrivateRoute path="/life-management/life-prices" component={LifePrice} />
          <PrivateRoute path="/life-management/maintenances" component={Maintenance} />
          <PrivateRoute path="/life-management/feedback" component={Feedback} />
          <PrivateRoute path="/rental" component={RentalManagement} />
          {/* 商业合同管理 */}
          <PrivateRoute path="/commerce-rental" component={CommerceRentalManagement} />
          {/* 车位月保 */}
          <PrivateRoute path="/parking" component={ParkingManagement} />
          {/* 财务管理 */}
          <PrivateRoute path="/financial" component={FinancialManagement} />
          {/* 水电管理 */}
          <PrivateRoute path="/hydropower" component={hydropowerManagement} />
          {/* 招商管理 */}
          <PrivateRoute path="/investment" component={InvestmentManagement} />
          {/* 智慧健康管理 */}
          <PrivateRoute path="/health" component={healthManagement} />
          {/* 月度统计 */}
          <PrivateRoute path="/statistics/month/all" component={monthStatisticsManagement} />
          <ErrorBoundaryRoute path="/account" component={Account} />
          <PrivateRoute path="/" exact component={Home} />
          {/* 404 */}
          <ErrorBoundaryRoute component={PageNotFound} />
        </Switch>
      </ProLayout>
    );
  }
}

const mapStateToProps = ({ authentication, applicationProfile, locale }: IRootState) => ({
  loaded: locale.loaded,
  authorities: authentication.account.authorities
});

const mapDispatchToProps = { setLocale, getSession, getProfile, hasAnyAuthority };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackendLayout);

/*
 *  Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */
import styles from './right-header.less';

import React from 'react';
import { Avatar, Dropdown, Icon, Menu } from 'antd';
import { NavLink as Link } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { setLocale } from 'app/shared/reducers/locale';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { connect } from 'react-redux';
import { locales } from 'app/config/translation';

interface IProps extends StateProps, DispatchProps {}
class RightHeader extends React.Component<IProps> {
  renderMenu() {
    const { isAuthenticated } = this.props;
    let { currentAccount = {} } = this.props;

    if (currentAccount == null) currentAccount = {};

    if (isAuthenticated) {
      const menu = (
        <Menu className={styles.menu} selectedKeys={[]}>
          <Menu.Item>
            <Link to={'/account/settings'}>
              <Icon type="user" />
              {translate('global.menu.account.main') + translate('global.menu.account.settings')}
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to={'/account/password'}>
              <Icon type="lock" />
              {translate('global.menu.account.main') + translate('global.menu.account.password')}
            </Link>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="logout">
            <Link to={'/logout'}>
              <Icon type="logout" />
              {translate('global.menu.account.logout') + translate('global.menu.account.login')}
            </Link>
          </Menu.Item>
        </Menu>
      );
      return (
        <Dropdown overlay={menu}>
          <span className={`action account`}>
            <Avatar size="small" className={'avatar'} src={'/content/images/avatar.png'} />
            <span className={'name'}>{currentAccount.name}</span>
          </span>
        </Dropdown>
      );
    } else {
      return <></>;
    }
  }

  // renderVerson() {
  //   return (
  //     <a href="https://unl.fsjxzf.com/建鑫三期-V1.1版本更新文档.pdf" target="view_window">
  //       V1.0版本更新说明
  //     </a>
  //   );
  // }

  renderLocaleMenus() {
    const { currentLocale } = this.props;
    if (!currentLocale) return <></>;
    const langMap = new Map([['zh-cn', '简体中文'], ['zh-tw', '繁体中文'], ['en', 'English']]);
    const menus = locales.map(lang => {
      const localChange = () => {
        this.props.setLocale(lang);
      };
      return (
        <Menu.Item key={lang}>
          <a href={'javascript:;'} onClick={localChange}>
            {langMap.get(lang.toLowerCase())}
          </a>
        </Menu.Item>
      );
    });
    const menu = <Menu selectedKeys={[currentLocale]}>{menus}</Menu>;
    return (
      <Dropdown overlay={menu}>
        <span className={`action account`}>
          <span className={'name'}>{langMap.get(currentLocale.toLowerCase())}</span>
        </span>
      </Dropdown>
    );
  }
  render() {
    return (
      <div className={styles.right}>
        {/* {this.renderVerson()} */}
        {this.renderMenu()}
      </div>
    );
  }
}
const mapStateToProps = ({ authentication, locale }: IRootState) => ({
  currentLocale: locale.currentLocale,
  isAuthenticated: authentication.isAuthenticated,
  currentAccount: authentication.account,
  authorities: authentication.account.authorities
});

const mapDispatchToProps = { setLocale, getSession, getProfile, hasAnyAuthority };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RightHeader);

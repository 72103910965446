/*
 * @Author: luoziyan 365977508@qq.com
 * @Date: 2022-08-19 14:24:19
 * @LastEditors: luoziyan 365977508@qq.com
 * @LastEditTime: 2024-04-22 16:03:40
 * @FilePath: \lehome-central\src\main\webapp\app\app.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import './app.scss';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { ConfigProvider } from 'antd';

import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { getFeedbackType, getProfile } from 'app/shared/reducers/application-profile';
import { getEntities } from 'app/entities/house/house.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import enUS from 'antd/lib/locale-provider/en_US';
import moment from 'moment';
import AppRoutes from 'app/routes';

const baseHref = document
  .querySelector('base')
  .getAttribute('href')
  .replace(/\/$/, '');

export interface IAppProps extends StateProps, DispatchProps {}

export const App = (props: IAppProps) => {
  useEffect(() => {
    props.getSession(); // 权限
    props.getEntities(0, 20, '', { buildingType: { equals: 'CENTRALIZED' } }); // 请求集中式房源
    // props.getProfile();
    // props.getFeedbackType();
  }, []);

  let currentLocale: any = zhCN;
  moment.locale('zh-cn');
  if (props.currentLocale === 'en') {
    currentLocale = enUS;
    moment.locale('en');
  }

  return (
    <ConfigProvider locale={currentLocale}>
      <Router basename={baseHref}>
        <ErrorBoundary>
          <AppRoutes />
        </ErrorBoundary>
      </Router>
    </ConfigProvider>
  );
};

const mapStateToProps = ({ locale, authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  currentLocale: locale.currentLocale
});

const mapDispatchToProps = { getSession, getEntities, getProfile, getFeedbackType, hasAnyAuthority };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(hot(module)(App));

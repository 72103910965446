// @ts-nocheck
import { G2, Chart, Geom, Axis, Tooltip, Coord, Label, Legend, View, Guide, Shape, Facet, Util } from 'bizcharts';
import React, { Component } from 'react';
import DataSet from '@antv/data-set';
import Debounce from 'lodash.debounce';

export interface IRadarProps {
  data: string[];
  autoLabel?: boolean;
}

export default class Radar extends Component<
  IRadarProps,
  {
    autoHideXLabels: boolean;
  }
> {
  state = {
    autoHideXLabels: false
  };

  root: HTMLDivElement | undefined = undefined;

  node: HTMLDivElement | undefined = undefined;

  resize = Debounce(() => {
    if (!this.node || !this.node.parentNode) {
      return;
    }
    const canvasWidth = (this.node.parentNode as HTMLDivElement).clientWidth;
    const { data = [], autoLabel = true } = this.props;
    if (!autoLabel) {
      return;
    }
    const minWidth = data.length * 30;
    const { autoHideXLabels } = this.state;

    if (canvasWidth <= minWidth) {
      if (!autoHideXLabels) {
        this.setState({
          autoHideXLabels: true
        });
      }
    } else if (autoHideXLabels) {
      this.setState({
        autoHideXLabels: false
      });
    }
  }, 500);

  componentDidMount() {
    window.addEventListener('resize', this.resize, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  handleRoot = (n: HTMLDivElement) => {
    this.root = n;
  };

  handleRef = (n: HTMLDivElement) => {
    this.node = n;
  };

  render() {
    const { DataView } = DataSet;
    const { data } = this.props;
    if (!data) {
      return <></>;
    }
    const dv = new DataView().source(data);
    dv.transform({
      type: 'fold',
      fields: ['a'],
      // 展开字段集
      key: 'user',
      // key字段
      value: 'score' // value字段
    });
    const cols = {
      score: {
        min: 0,
        max: 80
      }
    };
    const lineColor = 'rgba(245, 167, 171, 1)';
    const lineColor1 = 'rgba(24, 144, 255, 0.5)';
    const tooltip: [string, (...args: any[]) => { name?: string; value: string }] = [
      'month*money',
      (month: string, money: string) => ({
        name: '金额',
        value: `${money}元`
      })
    ];
    const tooltip1: [string, (...args: any[]) => { name?: string; value: string }] = [
      'month*count',
      (month: string, count: string) => ({
        name: '访问人数',
        value: `${count}`
      })
    ];
    return (
      <Chart data={dv} padding={[20, 25, 20, 40]} width={220} scale={cols} forceFit height={222}>
        <Legend />
        <Axis name="month" />
        <Tooltip crosshairs={{ type: 'y' }} />
        <Geom type="line" position="month*money" color={lineColor} shape={'smooth'} size={2} tooltip={tooltip} />
        <Geom type="line" position="month*count" color={lineColor1} shape={'smooth'} size={2} tooltip={tooltip1} />
      </Chart>
    );
  }
}

import axios from 'axios';
import * as qs from 'qs';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, translate } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IParkingRoom } from 'app/shared/model/parking-room.model';
import { flattenKey } from 'app/shared/util/object-utils';
import { ICrudGetListAction } from 'app/shared/model/list';
import { IParking } from 'app/shared/model/parking.model';

export const ACTION_TYPES = {
  SEARCH_PARKINGROOMS: 'parkingRoom/SEARCH_PARKINGROOMS',
  FETCH_PARKINGROOM_LIST: 'parkingRoom/FETCH_PARKINGROOM_LIST',
  FETCH_SIMPLE_PARKINGROOM_LIST: 'parkingRoom/FETCH_SIMPLE_PARKINGROOM_LIST',
  LOAD_PARKINGROOM_LIST: 'parkingRoom/LOAD_PARKINGROOM_LIST',
  LOAD_PARKINGROOM_SIMPLE_LIST: 'parkingRoom/LOAD_PARKINGROOM_SIMPLE_LIST',
  FETCH_PARKINGROOM: 'parkingRoom/FETCH_PARKINGROOM',
  CREATE_PARKINGROOM: 'parkingRoom/CREATE_PARKINGROOM',
  UPDATE_PARKINGROOM: 'parkingRoom/UPDATE_PARKINGROOM',
  AUTHENTICITY_PARKINGSPACES: 'parkingRoom/AUTHENTICITY_PARKINGSPACES',
  DELETE_PARKINGSPACE: 'parkingRoom/DELETE_PARKINGSPACE',
  RESET: 'parkingRoom/RESET'
};
// state定义
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IParkingRoom>,
  simpleEntities: [] as ReadonlyArray<IParkingRoom>,
  simpleEntitiesData: [] as ReadonlyArray<IParkingRoom>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  simpleTotalItems: 0,
  updateSuccess: false
};

export type ParkingSpacesState = Readonly<typeof initialState>;

// Reducer
export function isFull(room: IParkingRoom) {}

export default (state: ParkingSpacesState = initialState, action): ParkingSpacesState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PARKINGROOM_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SIMPLE_PARKINGROOM_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_PARKINGROOM_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.LOAD_PARKINGROOM_SIMPLE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.SEARCH_PARKINGROOMS):
    case REQUEST(ACTION_TYPES.FETCH_PARKINGROOM):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_PARKINGROOM):
    case REQUEST(ACTION_TYPES.UPDATE_PARKINGROOM):
    case REQUEST(ACTION_TYPES.DELETE_PARKINGSPACE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.SEARCH_PARKINGROOMS):
    case FAILURE(ACTION_TYPES.FETCH_PARKINGROOM_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SIMPLE_PARKINGROOM_LIST):
    case FAILURE(ACTION_TYPES.LOAD_PARKINGROOM_LIST):
    case FAILURE(ACTION_TYPES.LOAD_PARKINGROOM_SIMPLE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PARKINGROOM):
    case FAILURE(ACTION_TYPES.CREATE_PARKINGROOM):
    case FAILURE(ACTION_TYPES.UPDATE_PARKINGROOM):
    case FAILURE(ACTION_TYPES.DELETE_PARKINGSPACE):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.SEARCH_PARKINGROOMS):
    case SUCCESS(ACTION_TYPES.FETCH_PARKINGROOM_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data.map(item => ({ ...item })),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_SIMPLE_PARKINGROOM_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        simpleEntities: action.payload.data.map(item => ({ ...item })),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_PARKINGROOM_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_PARKINGROOM_SIMPLE_LIST):
      return {
        ...state,
        loadMore: false,
        simpleEntitiesData: action.payload.data.map(item => ({ ...item })),
        simpleTotalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_PARKINGROOM):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_PARKINGROOM):
    case SUCCESS(ACTION_TYPES.UPDATE_PARKINGROOM):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_PARKINGSPACE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/parking-lots'; // 车位管理-集中式
// const apiSimpleUrl = 'api/house-rooms/simple'; // 车位select数据
const apiSearchUrl = 'api/_search/house-rooms'; // 房源select

// Actions
export const getSearchEntities: ICrudGetListAction<IParkingRoom> = (page, size, sort, searchForm) => ({
  type: ACTION_TYPES.SEARCH_PARKINGROOMS,
  payload: axios.get<IParkingRoom>(
    `${apiUrl}${
      sort ? `?page=${page}&size=${size}&sort=${sort}&cacheBuster=${new Date().getTime()}` : `?cacheBuster=${new Date().getTime()}`
    }`,
    {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }
  ),
  meta: { transformUserName: true }
});

export const getEntities: ICrudGetListAction<IParkingRoom> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_PARKINGROOM_LIST,
    payload: axios.get<IParkingRoom>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey({ ...searchForm, page, size }),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    })
  };
};
// 车位select
export const getSimpleEntities: ICrudGetListAction<IParkingRoom> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiUrl}/simple?${sort ? `page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_SIMPLE_PARKINGROOM_LIST,
    payload: axios.get<IParkingRoom>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    })
  };
};

export const getFullName = record =>
  record && record.fullName
    ? record.fullName
    : `${record && record.house ? record.house.name : ''} ${record.floor ? record.floor + '层' : ''} ${
        record.district ? record.district : ''
      } `;

export const loadEntities: ICrudGetListAction<IParkingRoom> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.LOAD_PARKINGROOM_LIST,
    payload: axios.get<IParkingRoom>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    })
  };
};
// 车位搜索列表
export const loadSimpleEntities: ICrudGetListAction<IParkingRoom> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiUrl}/simple${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.LOAD_PARKINGROOM_SIMPLE_LIST,
    payload: axios.get<IParkingRoom>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    })
  };
};

export const getEntity: ICrudGetAction<IParkingRoom> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PARKINGROOM,
    payload: axios.get<IParkingRoom>(requestUrl)
  };
};
// export const getEntityByHouseId: ICrudGetAction<IParkingRoom> = houseId => {
//   const requestUrl = `${apiUrl}/house/${houseId}`;
//   return {
//     type: ACTION_TYPES.FETCH_PARKINGROOM,
//     payload: axios.get<IParkingRoom>(requestUrl)
//   };
// };
// 创建车位
export const createEntity: ICrudPutAction<IParkingRoom> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PARKINGROOM,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};
// 更新车位
export const updateEntity: ICrudPutAction<IParkingRoom> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PARKINGROOM,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};
// 上架|下架|发布车位
export const parkingStateChange = (idArrs: Number[], parkingState: 'up' | 'down' | 'publish') => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PARKINGROOM,
    payload: axios.put(`${apiUrl}/update-to-${parkingState}`, { ids: idArrs })
  });
  return result;
};
// 批量上架|下架|发布车位
export const parkingStateChangeWithNoneLoadList = (idArrs, parkingState: 'up' | 'down' | 'publish') => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PARKINGROOM,
    payload: axios.put(`${apiUrl}/update-to-${parkingState}`, { ids: idArrs })
  });
  dispatch(getEntities());
  return result;
};
// 删除车位
export const deleteEntity: ICrudDeleteAction<IParkingRoom> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PARKINGSPACE,
    payload: axios.delete(requestUrl)
  });
  return result;
};
// 批量删除车位
export const deleteBatch = (idArrs: Number[]) => async dispatch => {
  const requestUrl = `${apiUrl}/batch-delete`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PARKINGSPACE,
    payload: axios.delete(requestUrl, { data: { ids: idArrs } })
  });
  return result;
};
// 车位：通过验真
export const authenticity = (idArrs, state: 'success' | 'failed') => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PARKINGROOM,
    payload: axios.put(`${apiUrl}/authenticity/${state}`, { ids: idArrs })
  });
  return result;
};
export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const getSingleMonthPrice = (houseRoom: IParkingRoom) => {
  if (!houseRoom) return '';
  return houseRoom.singleMonthPrice;
};

export const getPaymentCycle = (houseRoom: IParkingRoom) => {
  if (!houseRoom) return '';
  return houseRoom.paymentCycle;
};

export const getSingleMonthPriceWithUnit = (houseRoom: IParkingRoom) => {
  if (!houseRoom) return '';
  return `${getSingleMonthPrice(houseRoom)}元/月`;
};

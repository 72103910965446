import axios from 'axios';
import * as qs from 'qs';
import { set } from 'lodash';

import { ICrudGetAction, ICrudPutAction, ICrudDeleteAction, IPayload } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ICustomTime, defaultValue, ISearchForm } from 'app/shared/model/custom-time.model';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_CUSTOMTIME_LIST: 'customTime/FETCH_CUSTOMTIME_LIST',
  FETCH_CUSTOMTIME: 'customTime/FETCH_CUSTOMTIME',
  UPDATE_CUSTOMTIME: 'customTime/UPDATE_CUSTOMTIME',
  CREATE_CUSTOMTIME: 'customTime/CREATE_CUSTOMTIME',
  DELETE_CUSTOMTIME: 'customTime/DELETE_CUSTOMTIME',
  RESET_ENTITY: 'customTime/RESET_ENTITY',
  RESET: 'customTime/REST'
};
// state定义
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  entity: defaultValue,
  entities: [] as ReadonlyArray<ICustomTime>,
  errorMessage: null,
  updating: false,
  updateSuccess: false,
  totalItems: 0
};

export type CustomTimeState = Readonly<typeof initialState>;

export declare type ICrudGetListAction<T> = (
  page?: number,
  size?: number,
  searchForm?: ISearchForm
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

// Reducer

export default (state: CustomTimeState = initialState, action): CustomTimeState => {
  switch (action.type) {
    // REQUEST
    case REQUEST(ACTION_TYPES.FETCH_CUSTOMTIME_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.FETCH_CUSTOMTIME):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_CUSTOMTIME):
    case REQUEST(ACTION_TYPES.UPDATE_CUSTOMTIME):
    case REQUEST(ACTION_TYPES.DELETE_CUSTOMTIME):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    // FAILURE
    case FAILURE(ACTION_TYPES.FETCH_CUSTOMTIME_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CUSTOMTIME):
    case FAILURE(ACTION_TYPES.CREATE_CUSTOMTIME):
    case FAILURE(ACTION_TYPES.UPDATE_CUSTOMTIME):
    case FAILURE(ACTION_TYPES.DELETE_CUSTOMTIME):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    // SUCCESS
    case SUCCESS(ACTION_TYPES.FETCH_CUSTOMTIME_LIST):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: true,
        fetching: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_CUSTOMTIME):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_CUSTOMTIME):
    case SUCCESS(ACTION_TYPES.UPDATE_CUSTOMTIME):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_CUSTOMTIME):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET_ENTITY:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

// Actions
const apiUrl = '/api/custom-time'; // 自定义时间配置
// 自定义时间配置列表
export const getEntities: ICrudGetListAction<ICustomTime> = (page, size, searchForm) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}&page=${page}&size=${size}`;
  return {
    type: ACTION_TYPES.FETCH_CUSTOMTIME_LIST,
    payload: axios.get<ICustomTime>(`${requestUrl}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const getEntity: ICrudGetAction<ICustomTime> = id => ({
  type: ACTION_TYPES.FETCH_CUSTOMTIME,
  payload: axios.get<ICustomTime>(`${apiUrl}/${id}`)
});
// 创建自定义时间配置
export const createEntity: ICrudPutAction<ICustomTime> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CUSTOMTIME,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(resetEntity());
  dispatch(getEntities());
  return result;
};
// 更新自定义时间配置
export const updateEntity = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CUSTOMTIME,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};
// 删除自定义时间配置
export const deleteEntity: ICrudDeleteAction<ICustomTime> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CUSTOMTIME,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

// 重置
export const resetEntity = () => ({
  type: ACTION_TYPES.RESET_ENTITY
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

import * as qs from 'qs';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IChangeParking } from 'app/shared/model/change-parking.model';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_CHANGEPARKING_LIST: 'changeHouse/FETCH_CHANGEPARKING_LIST',
  LOAD_CHANGEPARKING_LIST: 'changeHouse/LOAD_CHANGEPARKING_LIST',
  FETCH_CHANGEPARKING: 'changeHouse/FETCH_CHANGEPARKING',
  AUDIT_CHANGEPARKING: 'changeHouse/AUDIT_CHANGEPARKING',
  CREATE_CHANGEPARKING: 'changeHouse/CREATE_CHANGEPARKING',
  UPDATE_CHANGEPARKING: 'changeHouse/UPDATE_CHANGEPARKING',
  DELETE_CHANGEPARKING: 'changeHouse/DELETE_CHANGEPARKING',
  RESET: 'changeHouse/RESET'
};
// state类型
const initialState = {
  loading: false, // loading
  fetching: false,
  loadMore: false, // 加载更多
  errorMessage: null, // 错误信息
  entities: [] as ReadonlyArray<IChangeParking>, // 表格数据
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
  searchForm: {}
};

export type ChangeParkingState = Readonly<typeof initialState>;

// Reducer

export default (state: ChangeParkingState = initialState, action): ChangeParkingState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CHANGEPARKING_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_CHANGEPARKING_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_CHANGEPARKING):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_CHANGEPARKING):
    case REQUEST(ACTION_TYPES.UPDATE_CHANGEPARKING):
    case REQUEST(ACTION_TYPES.DELETE_CHANGEPARKING):
    case REQUEST(ACTION_TYPES.AUDIT_CHANGEPARKING):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_CHANGEPARKING_LIST):
    case FAILURE(ACTION_TYPES.LOAD_CHANGEPARKING_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CHANGEPARKING):
    case FAILURE(ACTION_TYPES.CREATE_CHANGEPARKING):
    case FAILURE(ACTION_TYPES.UPDATE_CHANGEPARKING):
    case FAILURE(ACTION_TYPES.DELETE_CHANGEPARKING):
    case FAILURE(ACTION_TYPES.AUDIT_CHANGEPARKING):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_CHANGEPARKING_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_CHANGEPARKING_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_CHANGEPARKING):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_CHANGEPARKING):
    case SUCCESS(ACTION_TYPES.UPDATE_CHANGEPARKING):
    case SUCCESS(ACTION_TYPES.AUDIT_CHANGEPARKING):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_CHANGEPARKING):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/pchanges'; // 换车位申请
const initpage = 0; // 初始页码
const initsize = 20; // 条数

// Actions
// 换车位请列表
export const getEntities: ICrudGetListAction<IChangeParking> = (page, size, sort = 'createdAt', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  initialState.searchForm = { ...searchForm };
  return {
    type: ACTION_TYPES.FETCH_CHANGEPARKING_LIST,
    payload: axios.get<IChangeParking>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const loadEntities: ICrudGetListAction<IChangeParking> = (page, size, sort = 'createdAt', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;

  return {
    type: ACTION_TYPES.LOAD_CHANGEPARKING_LIST,
    payload: axios.get<IChangeParking>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};
// 换车申请详情
export const getEntity: ICrudGetAction<IChangeParking> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CHANGEPARKING,
    payload: axios.get<IChangeParking>(requestUrl)
  };
};
// 创建换车申请
export const createEntity: ICrudPutAction<IChangeParking> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CHANGEPARKING,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  // dispatch(reset());
  // dispatch(getEntities());
  return result;
};
// 更新换车申请
export const updateEntity: ICrudPutAction<IChangeParking> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CHANGEPARKING,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  // dispatch(reset());
  // dispatch(getEntities());
  return result;
};
// 审核换车申请
export const auditEntity: ICrudPutAction<IChangeParking> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CHANGEPARKING,
    payload: axios.put(`${apiUrl}/audit`, cleanEntity(entity))
  });
  // dispatch(reset());
  // dispatch(getEntities());
  return result;
};
// 删除换车申请
export const deleteEntity: ICrudDeleteAction<IChangeParking> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CHANGEPARKING,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities(initpage, initsize, '', initialState.searchForm));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

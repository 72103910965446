import axios from 'axios';
import * as qs from 'qs';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { defaultValue, IUse } from 'app/shared/model/user.modal';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_USE_LIST: 'hydropowerConfiguration/FETCH_USE_LIST',
  RESET: 'hydropowerConfiguration/RESET' // 重置
};
// state
const initialState = {
  loading: false, // loading
  fetching: false, // loading
  loadMore: false, // 加载更多
  errorMessage: null, // 错误信息
  entities: [] as ReadonlyArray<IUse>, // 列表数据
  totalFetching: false, // 统计loading
  entity: defaultValue, // 初始化数据
  updating: false, // 更新
  totalItems: 0, // 总条数
  updateSuccess: false // 更新状态
};

export type healthUseState = Readonly<typeof initialState>;

// Reducer
export default (state: healthUseState = initialState, action): healthUseState => {
  switch (action.type) {
    // 接口初始化
    case REQUEST(ACTION_TYPES.FETCH_USE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true,
        totalFetching: true
      };
    // 接口失败
    case FAILURE(ACTION_TYPES.FETCH_USE_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
        totalFetching: true
      };
    // 接口成功
    case SUCCESS(ACTION_TYPES.FETCH_USE_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data || [],
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/users'; // 用户统计
// Actions
// 用户统计，列表
export const getEntities: ICrudGetListAction<IUse> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiUrl}?page=${page}&size=${size}&sort=${sort}`;

  return {
    type: ACTION_TYPES.FETCH_USE_LIST,
    payload: axios.get<IUse>(`${requestUrl}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params)
    }),
    meta: { transformUserName: true }
  };
};
// 重置
export const reset = () => ({
  type: ACTION_TYPES.RESET
});

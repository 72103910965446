import { Moment } from 'moment';
import { IOrders } from 'app/shared/model/orders.model';
import { IContractExpense } from 'app/shared/model/contract-expense.model';
import { IContractFacilities } from 'app/shared/model/contract-facilites.model';
import { ILiveInfo } from 'app/shared/model/live-info.model';
import { IUser } from 'app/shared/model/user.model';
import { IHouseRoom } from 'app/shared/model/house-room.model';
import { IFilter, IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
// 合同状态
export const enum TContractState {
  WAITING = 'WAITING',
  RENT_OUT = 'RENT_OUT',
  END = 'END',
  NULLIFIED = 'NULLIFIED'
}
// 类型
export const CategoryState = [{ value: 'TCONTRACT', label: '团租合同' }, { value: 'MCONTRACT', label: '商业合同' }];
// 审核状态
export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING'
}

export const enum CalcExpense {
  FIXED = 'FIXED',
  NATRUE = 'NATRUE'
}
// 团租状态
export const enum TContractCycle {
  REAL = 'REAL',
  NATURE = 'NATURE'
}
// 团租状态
export const enum TContractPushStatus {
  UNPUSH = 'UNPUSH',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS'
}

export interface ITContractItem {
  paymentCycle?: number; // paymentCycle
  singleMonthPrice?: number; // 每个月费用
  deposit?: number; // 金额
  hasDeposit?: boolean; // hasDeposit
  propertyFee?: any; // 物业费
  hasPropertyFee?: boolean; // 是否有物业费
  belatedInterest?: number; // belatedInterest
  belatedCycle?: number; // belatedCycle
  houseRoom?: IHouseRoom; // 房间号
}

export interface ITContract {
  id?: number;
  cycle?: TContractCycle;
  firstBillDay?: number;
  billDay?: number;
  code?: string;
  startAt?: Moment;
  name?: string; // 企业信息
  endAt?: Moment;
  calcExpense?: CalcExpense;
  contractImage?: string;
  businessLicense?: string;
  safetyCommitment?: string;
  supplementaryAgreement?: string;
  tcontractExpenses?: IContractExpense[];
  tcontractFacilities?: IContractFacilities[];
  tcontractItems?: ITContractItem[]; // 团租房间详情
  tcontractItem?: ITContractItem;
  houseRoom?: IHouseRoom[]; // 房源
  singleMonthPrices?: IHouseRoom[]; // 弃用
  rentRangeAt?: [Moment, Moment]; // 时间
  belatedInterest?: number; // belatedInterest
  belatedCycle?: number; // belatedCycle
  paymentCycle?: number; // paymentCycle
  deposit?: number; // 弃用
  hasDeposit?: boolean; // 是否有押金
  depositDif?: number;
  hasDepositDif?: boolean;
  propertyFee?: any; // 物业费
  hasPropertyFee?: boolean; // 是否有物业费
  auditState?: AuditState;
  auditAt?: Moment; // 审核时间
  auditor?: IUser; // 审核者
  auditorId?: number; // 审核者id
  auditDescription?: string; // 审核描述
  totalSignSingleMonthPrice?: number; // 总房费
  totalDeposit?: number; // 总押金
  oldtcontractItems?: ITContractItem[];
  category?: string; // 团租类型： 团租、商业
  source?: string; // 来源
  managementSpecification?: string;
  houseRentalAgreement?: string;
  taxid?: string; // 发票
}

export const defaultValue: Readonly<ITContract> = {
  cycle: TContractCycle.NATURE,
  firstBillDay: 2,
  billDay: 15,
  code: '',
  belatedCycle: 1,
  belatedInterest: 0.1,
  paymentCycle: 1,
  deposit: 0,
  hasDeposit: true,
  depositDif: 0,
  hasDepositDif: false,
  propertyFee: 0,
  hasPropertyFee: true,
  tcontractFacilities: [],
  tcontractExpenses: [],
  tcontractItem: {
    paymentCycle: 1,
    singleMonthPrice: 0,
    deposit: 0,
    hasDeposit: true,
    propertyFee: 0,
    hasPropertyFee: true,
    belatedInterest: 0.1,
    belatedCycle: 1,
    houseRoom: {}
  }
};

export interface ISearchForm {
  taxid?: string; // 发票id
  state?: IFilter; // 类型
  auditState?: IFilter; // 审核状态
  houseRoomId?: IRangeFilter; // 房间号
  userId?: IRangeFilter; // User id
  createdAt?: IRangeFilter; // 创建者
  createdBy?: IStringFilter; // 创建日期
  customerQuery?: IStringFilter; // 搜索
  code?: IStringFilter; // code
  houseId?: IRangeFilter; // 房源
  startAt?: IRangeFilter; // 开始日期
  endAt?: IRangeFilter; // 结束日期
  name?: IStringFilter; // Name
  category?: IFilter; // 类型
  source?: IFilter; // 来源
}

export const defaultSearchForm: ISearchForm = {
  category: {
    // 类型
    equals: 'TCONTRACT'
  },
  state: {
    // 状态
    in: []
  },
  auditState: {
    // 审核状态
    in: []
  },
  code: {
    // code
    contains: ''
  },
  createdAt: {
    // 创建日期
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  startAt: {
    // 开始日期
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  endAt: {
    // 结束日期
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  source: {
    // 来源
    equals: ''
  },
  createdBy: { contains: '' }, // 创建者
  houseId: {
    // 房源
    in: []
  },
  houseRoomId: {
    // 房间号
    in: []
  },
  userId: {
    // User id
    in: []
  },
  name: {
    // name
    contains: ''
  }
};
export interface IMSearchForm {
  state?: IFilter; // 状态
  auditState?: IFilter; // 审核状态
  houseRoomId?: IRangeFilter; // 房间号
  userId?: IRangeFilter; // userid
  createdAt?: IRangeFilter; // 创建日期
  createdBy?: IStringFilter; // 创建者
  customerQuery?: IStringFilter; // 搜索
  code?: IStringFilter; // code
  houseId?: IRangeFilter; // 房源
  startAt?: IRangeFilter; // 开始日期
  endAt?: IRangeFilter; // 结束日期
  name?: IStringFilter; // Name
  category?: IFilter; // 类型
  source?: IFilter; // 来源
}

export const MdefaultSearchForm: IMSearchForm = {
  category: {
    // 类型
    in: ['MCONTRACT']
  },
  state: {
    // 状态
    in: []
  },
  auditState: {
    // 审核状态
    in: []
  },
  code: {
    // code
    contains: ''
  },
  createdAt: {
    // 创建日期
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  startAt: {
    // 开始日期
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  endAt: {
    // 结束日期
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  source: {
    // 来源
    equals: ''
  },
  createdBy: { contains: '' }, // 创建者
  houseId: {
    // 房源
    in: []
  },
  houseRoomId: {
    // 房间号
    in: []
  },
  userId: {
    // user id
    in: []
  },
  name: {
    // name
    contains: ''
  }
};

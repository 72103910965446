import axios from 'axios';
import * as qs from 'qs';

import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction, IPayload } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IAppointment, defaultValue, ISearchForm } from 'app/shared/model/appointment.model';
import { flattenKey } from 'app/shared/util/object-utils';
import { message } from 'antd';

export declare type ICrudGetListAction<T> = (
  page?: number,
  size?: number,
  sort?: string,
  searchForm?: ISearchForm
) => IPayload<T> | ((dispatch: any) => IPayload<T>);
// 请求
export const ACTION_TYPES = {
  FETCH_APPOINTMENT_LIST: 'appointment/FETCH_APPOINTMENT_LIST',
  LOAD_APPOINTMENT_LIST: 'appointment/LOAD_APPOINTMENT_LIST', // load appointment
  FETCH_APPOINTMENT: 'appointment/FETCH_APPOINTMENT', // fetch appointment
  CREATE_APPOINTMENT: 'appointment/CREATE_APPOINTMENT', // create appointment
  UPDATE_APPOINTMENT: 'appointment/UPDATE_APPOINTMENT', // update appointment
  DELETE_APPOINTMENT: 'appointment/DELETE_APPOINTMENT',
  RESET: 'appointment/RESET', // reset
  RESET_ENTITY: 'appointment/RESET_ENTITY', // reset netity
  UPDATE_APPOINTMENT_STATE: 'appointment/UPDATE_APPOINTMENT_STATE' // update appointment state
};
// state定义类型
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IAppointment>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0
};

export type AppointmentState = Readonly<typeof initialState>;

// Reducer
// 请求设置
export default (state: AppointmentState = initialState, action): AppointmentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_APPOINTMENT_LIST):
      return {
        ...state,
        errorMessage: null,
        fetching: true,
        loading: true
      };
    case REQUEST(ACTION_TYPES.LOAD_APPOINTMENT_LIST):
      return {
        ...state,
        errorMessage: null,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_APPOINTMENT):
    case REQUEST(ACTION_TYPES.UPDATE_APPOINTMENT_STATE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_APPOINTMENT):
    case REQUEST(ACTION_TYPES.UPDATE_APPOINTMENT):
    case REQUEST(ACTION_TYPES.DELETE_APPOINTMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_APPOINTMENT_LIST):
    case FAILURE(ACTION_TYPES.LOAD_APPOINTMENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_APPOINTMENT):
    case FAILURE(ACTION_TYPES.CREATE_APPOINTMENT):
    case FAILURE(ACTION_TYPES.UPDATE_APPOINTMENT):
    case FAILURE(ACTION_TYPES.UPDATE_APPOINTMENT_STATE):
    case FAILURE(ACTION_TYPES.DELETE_APPOINTMENT):
      return {
        ...state,
        loading: false,
        loadMore: false,
        fetching: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_APPOINTMENT_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_APPOINTMENT_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_APPOINTMENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_APPOINTMENT):
    case SUCCESS(ACTION_TYPES.UPDATE_APPOINTMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.UPDATE_APPOINTMENT_STATE):
    case SUCCESS(ACTION_TYPES.DELETE_APPOINTMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/appointments'; // 租务管理-预约管理
// Actions
// 搜索
function fetchList(sort: string, page: number, size: number, searchForm: ISearchForm) {
  return axios.get<IAppointment>(
    `${apiUrl}${
      sort ? `?page=${page}&size=${size}&sort=${sort}&cacheBuster=${new Date().getTime()}` : `?cacheBuster=${new Date().getTime()}`
    }`,
    {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }
  );
}

export const getEntities: ICrudGetListAction<IAppointment> = (page, size, sort = 'createdAt,desc', searchForm) => ({
  type: ACTION_TYPES.FETCH_APPOINTMENT_LIST,
  payload: fetchList(sort, page, size, searchForm),
  meta: { transformUserName: true }
});

export const loadEntities: ICrudGetListAction<IAppointment> = (page, size, sort = 'createdAt,desc', searchForm) => ({
  type: ACTION_TYPES.LOAD_APPOINTMENT_LIST,
  payload: fetchList(sort, page, size, searchForm),
  meta: { transformUserName: true }
});
// 租务管理-预约管理 预约详情
export const getEntity: ICrudGetAction<IAppointment> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_APPOINTMENT,
    payload: axios.get<IAppointment>(requestUrl)
  };
};
// 租务管理-预约管理 预约创建
export const createEntity: ICrudPutAction<IAppointment> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_APPOINTMENT,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};
// 租务管理-预约管理 预约更新
export const updateEntity: ICrudPutAction<IAppointment> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_APPOINTMENT,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};
// 租务管理-预约管理 预约删除
export const deleteEntity: ICrudDeleteAction<IAppointment> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_APPOINTMENT,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const updateEntityState: ICrudPutAction<IAppointment> = entity => async dispatch => {
  const displayState = {
    ACCEPTED: '接单操作成功',
    PROCESSING: '前往开启操作成功',
    COMPLETED: '完成看房操作成功',
    CANCELED: '取消看房操作成功'
  };
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_APPOINTMENT,
    payload: axios.post(`${apiUrl}/${entity.id}/${entity.state}`).then(resp => {
      message.success(displayState[entity.state]);
      return Promise.resolve(resp);
    })
  });
  dispatch(getEntities());
  return result;
};

import * as qs from 'qs';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IChangeHouse } from 'app/shared/model/change-house.model';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_CHANGEHOUSE_LIST: 'changeHouse/FETCH_CHANGEHOUSE_LIST',
  LOAD_CHANGEHOUSE_LIST: 'changeHouse/LOAD_CHANGEHOUSE_LIST',
  FETCH_CHANGEHOUSE: 'changeHouse/FETCH_CHANGEHOUSE',
  AUDIT_CHANGEHOUSE: 'changeHouse/AUDIT_CHANGEHOUSE',
  CREATE_CHANGEHOUSE: 'changeHouse/CREATE_CHANGEHOUSE',
  UPDATE_CHANGEHOUSE: 'changeHouse/UPDATE_CHANGEHOUSE',
  DELETE_CHANGEHOUSE: 'changeHouse/DELETE_CHANGEHOUSE',
  RESET: 'changeHouse/RESET'
};
// state类型
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IChangeHouse>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
  searchForm: {}
};

export type ChangeHouseState = Readonly<typeof initialState>;

// Reducer

export default (state: ChangeHouseState = initialState, action): ChangeHouseState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CHANGEHOUSE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_CHANGEHOUSE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_CHANGEHOUSE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_CHANGEHOUSE):
    case REQUEST(ACTION_TYPES.UPDATE_CHANGEHOUSE):
    case REQUEST(ACTION_TYPES.DELETE_CHANGEHOUSE):
    case REQUEST(ACTION_TYPES.AUDIT_CHANGEHOUSE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_CHANGEHOUSE_LIST):
    case FAILURE(ACTION_TYPES.LOAD_CHANGEHOUSE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CHANGEHOUSE):
    case FAILURE(ACTION_TYPES.CREATE_CHANGEHOUSE):
    case FAILURE(ACTION_TYPES.UPDATE_CHANGEHOUSE):
    case FAILURE(ACTION_TYPES.DELETE_CHANGEHOUSE):
    case FAILURE(ACTION_TYPES.AUDIT_CHANGEHOUSE):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_CHANGEHOUSE_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_CHANGEHOUSE_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_CHANGEHOUSE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_CHANGEHOUSE):
    case SUCCESS(ACTION_TYPES.UPDATE_CHANGEHOUSE):
    case SUCCESS(ACTION_TYPES.AUDIT_CHANGEHOUSE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_CHANGEHOUSE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/change-houses'; // 换房申请
const initpage = 0;
const initsize = 20;

// Actions
// 换房申请列表
export const getEntities: ICrudGetListAction<IChangeHouse> = (page, size, sort = 'createdAt', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CHANGEHOUSE_LIST,
    payload: axios.get<IChangeHouse>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const loadEntities: ICrudGetListAction<IChangeHouse> = (page, size, sort = 'createdAt', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.LOAD_CHANGEHOUSE_LIST,
    payload: axios.get<IChangeHouse>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};
// 换房申请详情
export const getEntity: ICrudGetAction<IChangeHouse> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CHANGEHOUSE,
    payload: axios.get<IChangeHouse>(requestUrl)
  };
};
// 创建换房申请
export const createEntity: ICrudPutAction<IChangeHouse> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CHANGEHOUSE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  // dispatch(reset());
  // dispatch(getEntities());
  return result;
};
// 更新换房申请
export const updateEntity: ICrudPutAction<IChangeHouse> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CHANGEHOUSE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  // dispatch(reset());
  // dispatch(getEntities());
  return result;
};
// 审核换房申请
export const auditEntity: ICrudPutAction<IChangeHouse> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CHANGEHOUSE,
    payload: axios.put(`${apiUrl}/audit`, cleanEntity(entity))
  });
  // dispatch(reset());
  // dispatch(getEntities());
  return result;
};
// 删除换房申请
export const deleteEntity: ICrudDeleteAction<IChangeHouse> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CHANGEHOUSE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities(initpage, initsize, '', initialState.searchForm));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

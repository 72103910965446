import React from 'react';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

import Home from 'app/entities/home/home';
// rental routes
const Routes = ({ match }) => (
  <div>
    {/* 首页 */}
    <PrivateRoute path={match.url} component={Home} />
  </div>
);

export default Routes;

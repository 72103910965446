import styles from './style.less';
import React from 'react';
import { Icon } from 'antd';
import { isMobile } from 'app/shared/util/screen-utils';

export interface IExpandContainerProps {
  expandIcon?: string;
  collapsedIcon?: string;
}

export interface IExpandContainerStates {
  expand?: boolean;
}

export default class ExpandContainer extends React.Component<IExpandContainerProps, IExpandContainerStates> {
  static defaultProps = {
    expandIcon: 'search',
    collapsedIcon: 'caret-up'
  };
  state: IExpandContainerStates = {
    expand: !isMobile()
  };

  toggleExpand = () => {
    this.setState({
      expand: !this.state.expand
    });
  };

  render() {
    const { expandIcon, collapsedIcon } = this.props;
    const { expand } = this.state;
    return (
      <div className={styles.expandContainer}>
        <div style={{ display: expand ? 'block' : 'none' }}>{this.props.children}</div>
        <div className={styles.expand} onClick={this.toggleExpand}>
          <Icon type={expand ? collapsedIcon : expandIcon} />
        </div>
      </div>
    );
  }
}

import moment, { Moment } from 'moment';
import { IRangeFilter, IStringFilter, IFilter } from 'app/shared/model/filter.model';
import { convertDateToServer, formatLocalDate } from 'app/shared/util/date-utils';
// 返回状态定义
export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING'
}
export interface IHydropowerRecord {
  id?: number; // id
  house?: string; // 房源名称
  houseId?: number; // 房源
  houseRoomCode?: string; // 房号
  houseRoomId?: number; // 房号id
  customerName?: string; // 租户
  customerId?: number; // 租户id
  waterPrice?: number; // 房源水价
  electricityPrice?: number; // 房源电价
  waterMeter?: number; // 当前水表读数
  oldWaterMeter?: number; // 上次水表读数
  useWaterMeter?: number; // 使用吨数
  electricityMeter?: number; // 当前电表读数
  oldElectricityMeter?: number; // 上次电表读数
  useElectricityMeter?: number; // 使用度数
  waterAmount?: number; // 使用水费
  electricityAmount?: number; // 使用电费
  useDate?: Date; // 使用时间
  useElectricityAmount?: number; // 电费
  useWaterAmount?: number; // 水费
}

export const defaultValue: Readonly<IHydropowerRecord> = {};

export interface ISearchForm {
  houseId?: IStringFilter; // 房源
  houseRoomId?: IStringFilter; // 房号
  customerId?: IStringFilter; // 租户
  useDate?: IRangeFilter; // 充值时间
  useElectricityMeter?: IRangeFilter; // 用电异常
  useWaterMeter?: IRangeFilter; // 用水异常
  sort?: any; // 排序
}

export const defaultSearchForm: ISearchForm = {
  houseId: { equals: 50 }, // 房源
  houseRoomId: {
    // 房间
    equals: null
  },
  useElectricityMeter: {
    greaterOrEqualThan: null
  },
  useWaterMeter: {
    greaterOrEqualThan: null
  },
  customerId: {
    // 用户
    equals: null
  },
  useDate: {
    // 使用日期
    lessOrEqualThan: convertDateToServer(
      moment()
        .add(1, 'day')
        .endOf('day')
    ),
    greaterOrEqualThan: convertDateToServer(moment().startOf('day'))
  },
  sort: 'useDate,desc' // 排序
};

import { G2, Chart, Geom, Axis, Tooltip, Coord, Label, Legend, View, Guide, Shape, Facet, Util } from 'bizcharts';
import React, { Component } from 'react';
import DataSet from '@antv/data-set';
import { floor } from 'lodash';

export interface IPieChartProps {
  data?: string[];
}

export default class PieChart extends Component<
  IPieChartProps,
  {
    autoHideXLabels: boolean;
  }
> {
  state = {
    autoHideXLabels: false
  };

  root: HTMLDivElement | undefined = undefined;

  node: HTMLDivElement | undefined = undefined;

  componentDidMount() {}

  componentWillUnmount() {}

  handleRoot = (n: HTMLDivElement) => {
    this.root = n;
  };

  handleRef = (n: HTMLDivElement) => {
    this.node = n;
  };

  render() {
    const { DataView } = DataSet;
    const { data } = this.props;
    const { autoHideXLabels } = this.state;

    if (!data) {
      return <></>;
    }
    const dv = new DataView();
    dv.source(data).transform({
      type: 'percent',
      field: 'count',
      dimension: 'item',
      as: 'percent'
    });
    const cols = {
      percent: {
        formatter: val => {
          val = (val * 100).toFixed(2) + '%';
          return val;
        }
      }
    };
    return (
      <div>
        <Chart height={200} width={280} data={dv} scale={cols} padding={[0, 130, 0, 0]} forceFit={false}>
          <Coord type={'theta'} radius={0.8} innerRadius={0.8} />
          <Axis name="percent" label={autoHideXLabels ? undefined : {}} tickLine={autoHideXLabels ? undefined : {}} />
          <Legend layout="vertical" position="right" offsetX={5} offsetY={-25} />
          <Tooltip showTitle={false} useHtml />
          <Geom
            type="intervalStack"
            position="percent"
            color="item"
            tooltip={[
              'item*percent',
              (item, percent) => {
                percent = (percent * 100).toFixed(2) + '%';
                return {
                  name: item,
                  value: percent
                };
              }
            ]}
            style={{
              lineWidth: 1,
              stroke: '#fff'
            }}
          />
        </Chart>
      </div>
    );
  }
}

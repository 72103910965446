import axios from 'axios';
import * as qs from 'qs';
import { ICrudGetAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { defaultValue, IRefundRentalSurvey } from 'app/shared/model/refund-rental-survey';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_REFUNDRENTALSURVEY_LIST: 'refundRentalSurvey/FETCH_REFUNDRENTALSURVEY_LIST', // 列表
  CREATE_REFUNDRENTALSURVEY: 'refundRentalSurvey/CREATE_REFUNDRENTALSURVEY', // 创建
  FETCH_REFUNDRENTALSURVEY: 'refundRentalSurvey/FETCH_REFUNDRENTALSURVEY', // 详情
  UPDATE_REFUNDRENTALSURVEY: 'refundRentalSurvey/UPDATE_REFUNDRENTALSURVEY', // 更新
  DELETE_REFUNDRENTALSURVEY: 'refundRentalSurvey/DELETE_REFUNDRENTALSURVEY', // 删除
  RESET: 'refundRentalSurvey/RESET' // 重置
};
// state
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IRefundRentalSurvey>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  searchForm: {}
};

export type refundRentalSurveyState = Readonly<typeof initialState>;

// Reducer
export default (state: refundRentalSurveyState = initialState, action): refundRentalSurveyState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_REFUNDRENTALSURVEY):
    case REQUEST(ACTION_TYPES.CREATE_REFUNDRENTALSURVEY):
    case REQUEST(ACTION_TYPES.UPDATE_REFUNDRENTALSURVEY):
    case REQUEST(ACTION_TYPES.DELETE_REFUNDRENTALSURVEY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case REQUEST(ACTION_TYPES.FETCH_REFUNDRENTALSURVEY_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case FAILURE(ACTION_TYPES.UPDATE_REFUNDRENTALSURVEY):
    case FAILURE(ACTION_TYPES.CREATE_REFUNDRENTALSURVEY):
    case FAILURE(ACTION_TYPES.FETCH_REFUNDRENTALSURVEY):
    case FAILURE(ACTION_TYPES.FETCH_REFUNDRENTALSURVEY_LIST):
    case FAILURE(ACTION_TYPES.DELETE_REFUNDRENTALSURVEY):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.DELETE_REFUNDRENTALSURVEY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case SUCCESS(ACTION_TYPES.FETCH_REFUNDRENTALSURVEY):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.UPDATE_REFUNDRENTALSURVEY):
    case SUCCESS(ACTION_TYPES.CREATE_REFUNDRENTALSURVEY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_REFUNDRENTALSURVEY_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data || [],
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/houseOut'; // 退房记录
const initpage = 0; // 初始页码
const initsize = 20; // 条数
// Actions
// 退房记录，列表
export const getEntities: ICrudGetListAction<IRefundRentalSurvey> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiUrl}/page?page=${page}&size=${size}`;
  initialState.searchForm = { ...searchForm };
  return {
    type: ACTION_TYPES.FETCH_REFUNDRENTALSURVEY_LIST,
    payload: axios.get<IRefundRentalSurvey>(
      `${requestUrl}${sort ? `&sort=${sort}&cacheBuster=${new Date().getTime()}` : `?cacheBuster=${new Date().getTime()}`}`,
      {
        params: flattenKey(searchForm),
        paramsSerializer: params => qs.stringify(params)
      }
    ),
    meta: { transformUserName: true }
  };
};
// 新增退房记录
export const createEntity = entity => async (dispatch, getState) => {
  const {
    expenseGrade: { searchData }
  } = getState();
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_REFUNDRENTALSURVEY,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities(initpage, initsize, '', initialState.searchForm));
  return result;
};
// 获取退房记录数据
export const getEntity: ICrudGetAction<IRefundRentalSurvey> = id => {
  const requestUrl = `${apiUrl}/detail/${id}`;
  return {
    type: ACTION_TYPES.FETCH_REFUNDRENTALSURVEY,
    payload: axios.get<IRefundRentalSurvey>(requestUrl)
  };
};
export const updateEntity: ICrudPutAction<IRefundRentalSurvey> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_REFUNDRENTALSURVEY,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};
// 删除
export const deleteEntity: ICrudDeleteAction<IRefundRentalSurvey> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_REFUNDRENTALSURVEY,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

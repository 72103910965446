import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
// tslint:disable-next-line:no-unused-variable
import { translate } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './settle.reducer';
// tslint:disable-next-line:no-unused-variable
import { formatDate } from 'app/shared/util/date-utils';
import { Descriptions, Card } from 'antd';
import ImagesPreview from 'app/shared/components/commons/images-preview';
import { PageHeaderWrapper } from '@ant-design/pro-layout';

export interface ISettleDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class SettleDetail extends React.Component<ISettleDetailProps> {
  // 组件初始化
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { settleEntity } = this.props;
    return (
      <PageHeaderWrapper title={translate('lehomeApp.settle.detail.title')}>
        <Card bordered={false} loading={this.props.loading}>
          <Descriptions bordered>
            <Descriptions.Item label={translate('lehomeApp.settle.userName')}>{settleEntity.userName}</Descriptions.Item>
            <Descriptions.Item label={translate('lehomeApp.settle.userMobile')}>{settleEntity.userMobile}</Descriptions.Item>
            <Descriptions.Item label={translate('lehomeApp.settle.createdAt')}>{formatDate(settleEntity.createdAt)}</Descriptions.Item>
            <Descriptions.Item label={translate('lehomeApp.settle.houseAttach')} span={3}>
              <a href={settleEntity.houseAttach} target={'_blank'}>
                <img src={settleEntity.houseAttach + '!large'} width={375} alt={'房产'} />
              </a>
            </Descriptions.Item>
            <Descriptions.Item label={translate('lehomeApp.settle.idCardFronted')} span={3}>
              <a href={settleEntity.idCardFronted} target={'_blank'}>
                <img src={settleEntity.idCardFronted + '!large'} width={375} alt={'身份证人像面'} />
              </a>
            </Descriptions.Item>
            <Descriptions.Item label={translate('lehomeApp.settle.idCardBackend')} span={3}>
              <a href={settleEntity.idCardBackend} target={'_blank'}>
                <img src={settleEntity.idCardBackend + '!large'} width={375} alt={'身份证国徽面'} />
              </a>
            </Descriptions.Item>
            <Descriptions.Item label="备注" span={3}>
              {settleEntity.remark}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

const mapStateToProps = ({ settle }: IRootState) => ({
  settleEntity: settle.entity,
  loading: settle.loading
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettleDetail);

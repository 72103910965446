import moment, { Moment } from 'moment';
import { INotice, NoticeType } from 'app/shared/model/notice.model';

export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING'
}

export interface ICertification {
  id?: string;
  state?: AuditState;
  idCardFronted?: string;
  idCardBackend?: string;
  noHouseCert?: string;
  socialInsurance?: string;
  education?: string;
  createdBy?: string;
  createdAt?: Moment;
  updatedBy?: string;
  updatedAt?: Moment;
  userId?: string;
}

export const defaultValue: Readonly<ICertification> = {
  id: '',
  state: AuditState.WAITING,
  idCardFronted: '',
  idCardBackend: '',
  noHouseCert: '',
  socialInsurance: '',
  education: '',
  createdBy: '',
  createdAt: moment(),
  updatedBy: '',
  updatedAt: moment(),
  userId: ''
};

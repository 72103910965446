import { Moment } from 'moment';

export interface ICity {
  id?: number;
  code?: string;
  name?: string;
  ancestry?: string;
  createdAt?: Moment;
  updatedAt?: Moment;
  createdBy?: string;
  updatedBy?: string;
}

export const defaultValue: Readonly<ICity> = {};

import styles from '../../home.less';

import React from 'react';
import { Row, Col, Select } from 'antd';
import monthRentRateIcon from '../static/image/month-rent-rate-icon.png';
import BarLine from 'app/shared/components/BarLine';
import axios from 'axios';
import moment, { Moment } from 'moment';
import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import { flattenKey } from 'app/shared/util/object-utils';
import * as qs from 'qs';
import { isMobile } from 'app/shared/util/screen-utils';

interface IMonthRentRateProps {
  houseName?: string;
}

interface IMonthRentRateState {
  data?: any;
  rangeDate?: [Moment, Moment];
  type?: string;
  name?: string;
}

export default class MonthRentRate extends React.Component<IMonthRentRateProps, IMonthRentRateState> {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      rangeDate: [moment().subtract(12, 'months'), moment()],
      type: 'HOUSE_ROOM'
    };
  }

  componentDidMount(): void {
    this.getMonthRentRateData(null);
  }

  shouldComponentUpdate(nextProps: Readonly<IMonthRentRateProps>, nextState: Readonly<IMonthRentRateState>, nextContext: any): boolean {
    if (nextProps.houseName !== this.props.houseName) {
      this.getMonthRentRateData(nextProps.houseName);
      this.setState({ name: nextProps.houseName });
    }

    return true;
  }

  getMonthRentRateData(houseName) {
    const { rangeDate, type } = this.state;
    const searchFrom = {
      assetType: type,
      houseName,
      start: convertDateTimeToServer(rangeDate[0]),
      end: convertDateTimeToServer(rangeDate[1])
    };

    axios
      .get(`/api/statistics/rental-rate`, {
        params: flattenKey(searchFrom),
        paramsSerializer: params => qs.stringify(params)
      })
      .then(({ data }) => {
        const rdata =
          data && data.length > 0
            ? data.map(item => {
                return { x: item.name, y: item.total, rate: item.rate };
              })
            : [];
        this.setState({ data: rdata });
      });
  }

  renderIcon(title) {
    return (
      <div>
        <i className="">
          <img src={monthRentRateIcon} width={24} />
        </i>
        <span className={styles.icontitle}>{title}</span>
      </div>
    );
  }

  typeChange = value => {
    this.setState(
      {
        type: value
      },
      () => {
        this.getMonthRentRateData(this.state.name);
      }
    );
  };

  renderTitle() {
    const list = [{ label: '门店', value: 'HOUSE_ROOM' }, { label: '车位', value: 'PARKING_LOT' }];

    return (
      <Row className={styles.rentTitle}>
        <Col span={8}>{this.renderIcon('月出租率')}</Col>
        <Col span={10} push={!isMobile() ? 13 : 8} className={styles.sborder}>
          <Select style={{ width: 110 }} onChange={this.typeChange} defaultValue={this.state.type}>
            {list.map(item => (
              <Select.Option value={item.value} key={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    );
  }

  rederContain() {
    const { data } = this.state;
    return <BarLine data={data} height={265} />;
  }

  render() {
    return (
      <div className={`${styles.rent} ${styles.mt16}`}>
        {this.renderTitle()}
        {this.rederContain()}
      </div>
    );
  }
}

/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import * as qs from 'qs';

import { Col, Row, Button, Card } from 'antd';
import { translate } from 'react-jhipster';
import ResetIndicesProgress, { IProgress } from 'app/entities/indices-management/reset-indices-progress';
import HouseSelect from 'app/shared/components/house-search/select';
import { PageHeaderWrapper } from '@ant-design/pro-layout';

interface IProps extends StateProps, DispatchProps {}

export class ResetIndices extends React.Component<IProps> {
  resetHandle = (url, ids) =>
    axios.post(
      url,
      { ids },
      {
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
      }
    );

  statusHandle = url => axios.get<IProgress>(url);
  // housesResetHandler
  housesResetHandler = identities => this.resetHandle('/api/indices/houses/reset', identities);
  // housesResetStatus
  housesResetStatus = () => this.statusHandle('/api/indices/houses/status');

  render(): React.ReactNode {
    const title = translate('global.menu.system.indicesManagement.main');
    const layout = {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 6,
      style: { marginBottom: 24 }
    };
    const __click = () => {};
    return (
      <PageHeaderWrapper title={'索引管理'} extra={<Button icon={'arrow-left'} onClick={__click} />}>
        <Card>
          <Row gutter={16}>
            <Col {...layout}>
              <ResetIndicesProgress
                title={'房源索引'}
                help={translate('indicesManagement.question.common')}
                searchComponentLabel={'房源'}
                resetHandle={this.housesResetHandler}
                statusHandle={this.housesResetStatus}
                SearchComponent={HouseSelect}
                searchComponentProps={{ multiple: true }}
              />
            </Col>
          </Row>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetIndices);

import axios from 'axios';
import { ICrudGetAction, ICrudPutAction, ICrudDeleteAction, IPayload } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import * as qs from 'qs';

import { IBlackList, defaultValue } from 'app/shared/model/black-list.model';
import { ISearchForm } from 'app/shared/model/facilities.model';
import { flattenKey } from 'app/shared/util/object-utils';

export declare type ICrudGetListAction<T> = (
  page?: number,
  size?: number,
  sort?: string,
  searchForm?: ISearchForm
) => IPayload<T> | ((dispatch: any) => IPayload<T>);
export const ACTION_TYPES = {
  FETCH_BLACKLIST_LIST: 'blackList/FETCH_BLACKLIST_LIST',
  FETCH_BLACKLIST: 'blackList/FETCH_BLACKLIST',
  CREATE_BLACKLIST: 'blackList/CREATE_BLACKLIST',
  UPDATE_BLACKLIST: 'blackList/UPDATE_BLACKLIST',
  DELETE_BLACKLIST: 'blackList/DELETE_BLACKLIST',
  RESET: 'blackList/RESET'
};
// 定义state类型
const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IBlackList>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type BlackListState = Readonly<typeof initialState>;

// Reducer

export default (state: BlackListState = initialState, action): BlackListState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_BLACKLIST_LIST):
    case REQUEST(ACTION_TYPES.FETCH_BLACKLIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_BLACKLIST):
    case REQUEST(ACTION_TYPES.UPDATE_BLACKLIST):
    case REQUEST(ACTION_TYPES.DELETE_BLACKLIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_BLACKLIST_LIST):
    case FAILURE(ACTION_TYPES.FETCH_BLACKLIST):
    case FAILURE(ACTION_TYPES.CREATE_BLACKLIST):
    case FAILURE(ACTION_TYPES.UPDATE_BLACKLIST):
    case FAILURE(ACTION_TYPES.DELETE_BLACKLIST):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_BLACKLIST_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_BLACKLIST):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_BLACKLIST):
    case SUCCESS(ACTION_TYPES.UPDATE_BLACKLIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_BLACKLIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/black-lists'; // 黑名单

// Actions
// 黑名单列表
export const getEntities: ICrudGetListAction<IBlackList> = (page, size, sort, searchForm) => ({
  type: ACTION_TYPES.FETCH_BLACKLIST_LIST,
  payload: axios.get<IBlackList>(`${apiUrl}?cacheBuster=${new Date().getTime()}`, {
    params: flattenKey(searchForm),
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
  })
});

export const getEntity: ICrudGetAction<IBlackList> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_BLACKLIST,
    payload: axios.get<IBlackList>(requestUrl)
  };
};
// 创建黑名单
export const createEntity: ICrudPutAction<IBlackList> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_BLACKLIST,
    payload: axios.post(`${apiUrl}`, cleanEntity(entity))
  });
  return result;
};
// 更新黑名单
export const updateEntity: ICrudPutAction<IBlackList> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_BLACKLIST,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};
// 锁死黑名单
export const unLock = login => async dispatch => {
  const requestUrl = `${apiUrl}/user/${login}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_BLACKLIST,
    payload: axios.delete(requestUrl)
  });
  return result;
};
// 删除黑名单
export const deleteEntity: ICrudDeleteAction<IBlackList> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_BLACKLIST,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

import 'react-pdf/dist/Page/AnnotationLayer.css';
import styles from './print.less';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import { Storage } from 'react-jhipster';
import Cookies from 'js-cookie';
import { Button, Skeleton } from 'antd';
import printJs from 'print-js';

pdfjs.GlobalWorkerOptions.workerSrc = `/js/pdf.worker.js`;

export interface IContractPrintStates {
  loading?: boolean;
  numPages?: number;
  file?: any;
  scale?: number;
}

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true
};
export default class ContractPrint extends React.Component<RouteComponentProps<{ id: string }>, IContractPrintStates> {
  width = 595;
  height = 842;

  constructor(props: Readonly<RouteComponentProps<{ id: string }>>) {
    super(props);
    const token =
      Storage.local.get('jhi-authenticationToken') ||
      Storage.session.get('jhi-authenticationToken') ||
      Cookies.get('jhi-authenticationToken');
    const headers = {};
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    this.state = {
      loading: true,
      numPages: null,
      file: { url: `/api/contracts/${this.props.match.params.id}/pdf`, httpHeaders: headers },
      scale: this.calcScale()
    };
  }
  // real width
  realWidth() {
    return window.outerWidth - 10;
  }
  // calc scale
  calcScale(): number {
    const realWith = this.realWidth();
    return realWith > this.width ? 1 : Number((realWith / this.width).toFixed(2));
  }
  // zoom in
  zoomIn = () => {
    const { scale } = this.state;
    this.setState({
      scale: Number(Math.max(0.1, scale - 0.2).toFixed(2))
    });
  };
  // zoom out
  zoomOut = () => {
    const { scale } = this.state;
    const number = Math.min(2, scale + 0.2);
    const value = number.toFixed(2);
    const scale1 = Number(value);
    this.setState({
      scale: scale1
    });
  };
  // scale with
  scaleWith = () => {
    this.setState({
      scale: this.calcScale()
    });
  };
  // 打印
  handlePrint = () => {
    const imgs = [];
    // @ts-ignore
    document.querySelectorAll('.react-pdf__Page__canvas').forEach(elm => imgs.push(elm.toDataURL('image/png')));
    printJs({ printable: imgs, type: 'image' });
  };
  // 加载成功
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages, loading: false });
  };
  // 渲染
  render() {
    const { numPages, scale } = this.state;
    return (
      <div className={styles.print}>
        <div className={'tool-bar fixed'}>
          <div />
          <div className={'center'}>
            <Button icon={'fullscreen'} style={{ opacity: 0 }} />
            <Button icon={'minus'} onClick={this.zoomIn} disabled={scale <= 0.1} />
            <div className={'rate-text'}>{(scale * 100).toFixed()}%</div>
            <Button icon={'plus'} onClick={this.zoomOut} disabled={scale >= 2} />
            <Button icon={'fullscreen'} style={{ marginRight: 16, marginLeft: 16 }} onClick={this.scaleWith} />
          </div>
          <div>
            <Button onClick={this.handlePrint} className={'print-btn'}>
              打印
            </Button>
          </div>
        </div>
        <div className={'tool-bar op0'}>
          <Button>打印</Button>
        </div>
        <Document
          loading={
            <div
              style={{
                width: 595,
                height: 842,
                margin: '0 auto',
                background: '#fff',
                padding: 30,
                marginTop: 20
              }}
            >
              <Skeleton active />
            </div>
          }
          onLoadSuccess={this.onDocumentLoadSuccess}
          file={this.state.file}
          options={options}
          className={'document'}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <div className={styles.page} key={index}>
              <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} />
            </div>
          ))}
        </Document>
      </div>
    );
  }
}

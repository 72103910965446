import { IPermission } from 'app/shared/model/permission.model';
import { IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { IBase } from 'app/shared/model/base.model';

export interface IRole extends IBase {
  id?: number;
  name?: string;
  permissions?: IPermission[];
}

export const defaultValue: Readonly<IRole> = {};

export interface ISearchForm {
  identity?: IStringFilter;
  name?: IStringFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
  updatedAt?: IRangeFilter;
  updatedBy?: IStringFilter;
}

export const defaultSearchForm: Readonly<ISearchForm> = {
  identity: {
    contains: ''
  },
  name: {
    contains: ''
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  updatedAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  updatedBy: { contains: '' }
};

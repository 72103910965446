import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, Form, Input, Spin } from 'antd';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { getUser } from 'app/modules/administration/user-management/user-management.reducer';

import { createEntity, getEntity, reset, updateEntity } from './black-list.reducer';
import { IBlackList } from 'app/shared/model/black-list.model';

// tslint:disable-next-line:no-unused-variable
// state类型
export interface IBlackListUpdateProps extends StateProps, DispatchProps {
  form: any;
  login?: string;
  onClose?: () => void;
  onOK?: (blackList?: IBlackList) => void;
  clickEventHandler?: (handler: IClickHandler) => void;
}

const FormItem = Form.Item;

export interface IClickHandler {
  onClick: () => Promise<any>;
}

export interface IBlackListUpdateState {
  login: string;
}

const { TextArea } = Input;

export class BlackListUpdate extends React.Component<IBlackListUpdateProps, IBlackListUpdateState> {
  state: IBlackListUpdateState = {
    login: this.props.login
  };
  // 点击
  clickEventHandler: IClickHandler = {
    onClick: () => this.saveEntity()
  };
  // 更新
  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }
  // 组件卸载
  componentWillUnmount() {
    this.props.reset();
  }
  // 初始化
  componentDidMount() {
    this.props.clickEventHandler && this.props.clickEventHandler(this.clickEventHandler);
    this.props.getUser(this.state.login);
  }
  // 保存
  saveEntity = () =>
    new Promise((resolve, reject) => {
      this.props.form.validateFieldsAndScroll((error, values) => {
        if (!error) {
          // const { user } = this.props;
          const entity = {
            // userId: user.id,
            ...values
          };
          const result = this.props.createEntity(entity) as any;
          result.then(data => {
            resolve(data.value.data);
          });
        }
      });
    });
  // 关闭
  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };
  // 渲染表单
  renderForm = () => {
    const {
      form: { getFieldDecorator }
    } = this.props;
    const { user, blackListEntity, loading } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      }
    };
    return (
      <Form onSubmit={this.saveEntity}>
        <FormItem {...formItemLayout} label={translate('userManagement.login')}>
          {getFieldDecorator('login', {
            initialValue: user.login
          })(<Input disabled />)}
        </FormItem>
        {/* <FormItem {...formItemLayout} label={translate('userManagement.name')}>
          {getFieldDecorator('name', {
            initialValue: user.name
          })(<Input disabled />)}
        </FormItem> */}
        <FormItem {...formItemLayout} label={translate('lehomeApp.blackList.description')}>
          {getFieldDecorator('description', {
            initialValue: blackListEntity.description,
            rules: [{ required: true, message: translate('lehomeApp.blackList.descriptionRule') }]
          })(<TextArea rows={4} />)}
        </FormItem>
        {!this.props.clickEventHandler && (
          <FormItem className={'tc'}>
            <Button type="primary" htmlType="submit" size={'large'} loading={loading}>
              <Translate contentKey={'entity.action.sent'} />
            </Button>
          </FormItem>
        )}
      </Form>
    );
  };

  render() {
    const { loading } = this.props;
    return (
      <Card bordered={false}>
        <Spin spinning={loading}>{this.renderForm()}</Spin>
      </Card>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  user: storeState.userManagement.user,
  blackListEntity: storeState.blackList.entity,
  loading: storeState.blackList.loading,
  updating: storeState.blackList.updating,
  updateSuccess: storeState.blackList.updateSuccess
});

const mapDispatchToProps = {
  getUser,
  getEntity,
  updateEntity,
  createEntity,
  reset
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const blackListUpdate = Form.create<IBlackListUpdateProps>()(BlackListUpdate);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(blackListUpdate);

import axios from 'axios';
import { ICrudGetAction, ICrudPutAction, ICrudDeleteAction, IPayload } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import * as qs from 'qs';

import { IHouse, defaultValue, ISearchForm } from 'app/shared/model/house.model';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_HOUSE_LIST: 'house/FETCH_HOUSE_LIST',
  FETCH_HOUSE: 'house/FETCH_HOUSE',
  CREATE_HOUSE: 'house/CREATE_HOUSE',
  UPDATE_HOUSE: 'house/UPDATE_HOUSE',
  DELETE_HOUSE: 'house/DELETE_HOUSE',
  YUNDING_HOUSE: 'house/YUNDING_HOUSE',
  IMPORT: 'house/IMPORT',
  RESET: 'house/RESET'
};
// state
const initialState = {
  loading: false,
  importLoading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IHouse>,
  entity: defaultValue,
  defaultHouseId: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  ydLoading: false
};
export declare type ICrudGetListAction<T> = (
  page?: number,
  size?: number,
  sort?: string,
  searchForm?: ISearchForm
) => IPayload<T> | ((dispatch: any) => IPayload<T>);
export type HouseState = Readonly<typeof initialState>;

// Reducer
export default (state: HouseState = initialState, action): HouseState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.IMPORT):
      return {
        ...state,
        importLoading: true,
        loading: true
      };
    case REQUEST(ACTION_TYPES.FETCH_HOUSE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_HOUSE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_HOUSE):
    case REQUEST(ACTION_TYPES.UPDATE_HOUSE):
    case REQUEST(ACTION_TYPES.DELETE_HOUSE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false
      };
    case REQUEST(ACTION_TYPES.YUNDING_HOUSE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        ydLoading: true
      };
    case FAILURE(ACTION_TYPES.IMPORT):
      return {
        ...state,
        importLoading: false,
        loading: false
      };
    case FAILURE(ACTION_TYPES.FETCH_HOUSE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_HOUSE):
    case FAILURE(ACTION_TYPES.CREATE_HOUSE):
    case FAILURE(ACTION_TYPES.UPDATE_HOUSE):
    case FAILURE(ACTION_TYPES.DELETE_HOUSE):
    case FAILURE(ACTION_TYPES.YUNDING_HOUSE):
      return {
        ...state,
        loading: false,
        ydLoading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.IMPORT):
      return {
        ...state,
        importLoading: false,
        loading: false
      };
    case SUCCESS(ACTION_TYPES.FETCH_HOUSE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        defaultHouseId: action.payload.data && action.payload.data.length > 0 ? action.payload.data[0]['id'] : '',
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.YUNDING_HOUSE):
      return {
        ...state,
        loading: false,
        ydLoading: false
      };
    case SUCCESS(ACTION_TYPES.FETCH_HOUSE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.CREATE_HOUSE):
    case SUCCESS(ACTION_TYPES.UPDATE_HOUSE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_HOUSE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/houses'; // 引索重建

// Actions
// 引索重建详情
export const getEntities: ICrudGetListAction<IHouse> = (page, size, sort, searchForm) => ({
  type: ACTION_TYPES.FETCH_HOUSE_LIST,
  payload: axios.get<IHouse>(`api/houses?cacheBuster=${new Date().getTime()}`, {
    params: flattenKey(searchForm),
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
  })
});
export const importCentered = (page, size, sort, searchForm) => ({
  type: ACTION_TYPES.FETCH_HOUSE_LIST,
  payload: axios.get<IHouse>(`api/house-room/centralized/import?cacheBuster=${new Date().getTime()}`, {
    params: flattenKey(searchForm),
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
  })
});

export const getEntity: ICrudGetAction<IHouse> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_HOUSE,
    payload: axios.get<IHouse>(requestUrl)
  };
};

export const getYunDingHouseData: ICrudGetAction<IHouse> = id => {
  const requestUrl = `/api/houses/${id}/pullAllFromYunDing/false`;
  return {
    type: ACTION_TYPES.YUNDING_HOUSE,
    payload: axios.put<IHouse>(requestUrl)
  };
};
// 创建引索重建
export const createEntity = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_HOUSE,
    payload: axios.post(`api/houses-with-config`, cleanEntity(entity))
  });
  return result;
};
// 更新引索重建
export const updateDistributedEntity = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_HOUSE,
    payload: axios.put(`api/houses-with-config`, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IHouse> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_HOUSE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IHouse> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_HOUSE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

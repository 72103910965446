/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { getSortState, IPaginationBaseState, Translate, translate } from 'react-jhipster';
import { Button, Card, Col, DatePicker, Form, Icon, Input, Row, Table } from 'antd';
import moment from 'moment';
import { defaultsDeep, omit, values } from 'lodash';

import { IRootState } from 'app/shared/reducers';
import { ISearchForm } from 'app/shared/model/role.model';
import { objectToParameters, searchToObject } from 'app/shared/util/url-utils';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATETIME_T_FORMAT, AUTHORITIES } from 'app/config/constants';
import { defaultSearchForm } from 'app/shared/model/user.model';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { filterField } from 'app/modules/administration/user-management/user-management';
import { deleteRole, getMRoles } from 'app/modules/administration/roles/role-management.reducer';
import { stringToDate } from 'app/shared/util/date-utils';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import LinkButton from 'app/shared/components/link-button';
import { PageHeaderWrapper } from '@ant-design/pro-layout';

interface IRoleManagementProps extends StateProps, DispatchProps, RouteComponentProps<{}> {
  form: any;
}

interface IRoleManagementState extends IPaginationBaseState {
  searchForm?: ISearchForm;
}

export const canNotOperate = name => values(AUTHORITIES).includes(name);

export class RoleManagement extends React.Component<IRoleManagementProps> {
  state: IRoleManagementState = {
    searchForm: defaultsDeep(omit(searchToObject(this.props.location), ['page', 'sort']), defaultSearchForm),
    ...getSortState(this.props.location, ITEMS_PER_PAGE),
    sort: 'createdAt',
    order: 'desc'
  };

  componentDidMount() {
    this.getRoles();
  }

  sortRoles() {
    this.getRoles();
    this.changeUrl();
  }

  getRoles = () => {
    const { activePage, itemsPerPage, sort, order, searchForm } = this.state;
    this.props.getMRoles(activePage - 1, itemsPerPage, `${sort},${order}`, filterField(searchForm));
  };

  checkAuth = (action: string) => {
    const {
      account: { authorities }
    } = this.props;
    return hasAnyAuthority(authorities, [`Role#${action}`]);
  };

  changeUrl() {
    const { activePage, sort, order, searchForm } = this.state;
    const searchParams = [`page=${activePage}`, `sort=${sort},${order}`, objectToParameters(searchForm)];
    this.props.history.push(`${this.props.location.pathname}?${searchParams.filter(x => x).join('&')}`);
  }

  formatDate(date) {
    return date ? moment(date).format(APP_DATE_FORMAT) : date;
  }

  tableHandleChange = (pagination, filters, sorter) => {
    this.setState(
      {
        order: sorter.order === 'ascend' ? 'asc' : 'desc',
        sort: sorter.field ? sorter.field : ''
      },
      () => this.sortRoles()
    );
  };

  pageHandleChange = (page: number) => {
    this.setState(
      {
        activePage: page
      },
      () => this.sortRoles()
    );
  };

  handleSearch = e => {
    e && e.preventDefault();
    this.props.form.validateFields((err, postValues) => {
      if (!err) {
        const { name, createdAt } = postValues;
        this.setState(
          {
            searchForm: defaultsDeep(
              {
                name: {
                  contains: name
                },
                createdAt: {
                  greaterOrEqualThan: createdAt[0] ? createdAt[0].utc().format(APP_LOCAL_DATETIME_T_FORMAT) : '',
                  lessOrEqualThan: createdAt[1] ? createdAt[1].utc().format(APP_LOCAL_DATETIME_T_FORMAT) : ''
                }
              },
              defaultSearchForm
            )
          },
          () => this.sortRoles()
        );
      }
    });
  };

  renderAdvancedForm() {
    const {
      form: { getFieldDecorator }
    } = this.props;
    const { searchForm } = this.props;
    const rowGutter = {
      md: 8,
      lg: 24,
      xl: 48
    };
    const formItemLayout = {
      xs: 24,
      md: 8
    };
    return (
      <div className={'tableListForm'}>
        <Form onSubmit={this.handleSearch}>
          <Row gutter={rowGutter} type={'flex'}>
            <Col {...formItemLayout}>
              <Form.Item label={translate('roles.name')}>
                {getFieldDecorator('name', {
                  initialValue: searchForm.name.contains
                })(<Input placeholder={translate('global.placeholder.input')} />)}
              </Form.Item>
            </Col>
            <Col {...formItemLayout}>
              <Form.Item label={translate('roles.createdAt')}>
                {getFieldDecorator('createdAt', {
                  initialValue: [stringToDate(searchForm.createdAt.greaterOrEqualThan), stringToDate(searchForm.createdAt.lessOrEqualThan)]
                })(<DatePicker.RangePicker format={APP_LOCAL_DATE_FORMAT} />)}
              </Form.Item>
            </Col>
          </Row>
          <div style={{ overflow: 'hidden' }}>
            <span style={{ float: 'right', marginBottom: 24 }}>
              <Button type="primary" htmlType="submit" icon={'search'}>
                <Translate contentKey={'entity.action.search'} />
              </Button>
            </span>
          </div>
        </Form>
      </div>
    );
  }

  viewLink(text, role) {
    const { match } = this.props;
    return (
      <Link to={`${match.url}/${role.id}`}>
        <Translate contentKey={`roles.${text}`} children={text} />
      </Link>
    );
  }

  renderActions = record => {
    const { match } = this.props;
    return !canNotOperate(record.name) ? (
      <Button.Group>
        {this.checkAuth('update') ? (
          <LinkButton to={`${match.url}/${record.id}/edit`} title={translate('entity.action.edit')} type="primary" icon="form" />
        ) : null}
        {this.checkAuth('delete') ? (
          <LinkButton to={`${match.url}/${record.id}/delete`} title={translate('entity.action.delete')} icon="delete" type={'danger'} />
        ) : null}
      </Button.Group>
    ) : null;
  };

  getDataColumns() {
    const orderMap = {
      desc: 'descend',
      asc: 'ascend'
    };

    const { account } = this.props;
    const { sort, order } = this.state;

    return [
      {
        title: translate('global.field.id'),
        dataIndex: 'id',
        key: 'id',
        sorter: true,
        sortOrder: sort === 'id' ? orderMap[order] : false
      },
      {
        title: translate('roles.name'),
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => this.viewLink(text, record),
        width: 160
      },
      {
        title: translate('roles.createdAt'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        sortOrder: sort === 'createdAt' ? orderMap[order] : false,
        render: text => this.formatDate(text),
        width: 160
      },
      {
        title: translate('roles.updatedBy'),
        dataIndex: 'updatedBy',
        key: 'updatedBy'
      },
      {
        title: translate('roles.updatedAt'),
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sorter: true,
        sortOrder: sort === 'updatedAt' ? orderMap[order] : false,
        render: text => this.formatDate(text),
        width: 160
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: 160,
        render: (text, record) => this.renderActions(record)
      }
    ];
  }

  action() {
    const { match } = this.props;
    return this.checkAuth('create') ? (
      <Button.Group>
        <LinkButton type={'primary'} htmlType={'button'} to={`${match.url}/new`}>
          <Icon type={'plus'} /> <Translate contentKey="roles.home.createLabel">Create a new role</Translate>
        </LinkButton>
      </Button.Group>
    ) : (
      <></>
    );
  }

  render() {
    const { totalItems, loading, roles } = this.props;

    return (
      <PageHeaderWrapper extra={this.action()}>
        <Card bordered={false}>
          {this.renderAdvancedForm()}
          <Table
            loading={loading}
            rowKey={'id'}
            dataSource={roles.map(r => r)}
            columns={this.getDataColumns()}
            onChange={this.tableHandleChange}
            pagination={{
              defaultPageSize: this.state.itemsPerPage,
              total: totalItems,
              onChange: this.pageHandleChange,
              current: this.state.activePage
            }}
          />
        </Card>
      </PageHeaderWrapper>
    );
  }
}

const mapStateTopProps = ({ authentication, roleManagement }: IRootState) => ({
  account: authentication.account,
  loading: roleManagement.loading,
  roles: roleManagement.roles,
  searchForm: roleManagement.searchForm,
  totalItems: roleManagement.totalItems
});

const mapDispatchToProps = { getMRoles, deleteRole };

type StateProps = ReturnType<typeof mapStateTopProps>;
type DispatchProps = typeof mapDispatchToProps;

const formManagementWrapper = Form.create()(RoleManagement);

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(formManagementWrapper);

import { Moment } from 'moment';
import { IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';

export interface INews {
  id?: number;
  title?: string;
  desc?: string;
  content?: any;
  createdAt?: Moment;
  createdBy?: string;
  updatedBy?: string;
  updatedAt?: Moment;
}

export const defaultValue: Readonly<INews> = {};

export interface ISearchForm {
  title?: IStringFilter;
  desc?: IStringFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
}

export const defaultSearchForm: ISearchForm = {
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  title: { contains: '' },
  desc: { contains: '' }
};

import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { createEntity, getEntity, getLatestEntity, updateEntity } from './certification.reducer';
// tslint:disable-next-line:no-unused-variable
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { Button, Card, Descriptions, Divider, Icon, Skeleton, Spin } from 'antd';
import LinkButton from 'app/shared/components/link-button';
import { detailsToEdit } from 'app/shared/util/url-utils';
import { getUser } from 'app/modules/administration/user-management/user-management.reducer';
import Meta from 'antd/lib/card/Meta';
import { IMasterEventHandler } from 'app/shared/components/CustomerUpload';
import { AuditState, defaultValue, ICertification } from 'app/shared/model/certification.model';
import ImageUpload from 'app/shared/components/form-upload/image';

export interface ICertificationEditProps extends StateProps, DispatchProps, RouteComponentProps<{ login: string }> {}
// 定义state类型
export interface ICertificationEditState {
  handlers: IMasterEventHandler[];
  localPreviewFiles: any[];
  certification: ICertification;
  uploading: boolean;
}

export class CertificationEdit extends React.Component<ICertificationEditProps, ICertificationEditState> {
  state: ICertificationEditState = {
    handlers: [],
    uploading: false,
    certification: { ...defaultValue },
    localPreviewFiles: []
  };
  // 初始化
  componentWillMount() {
    this.props.getUser(this.props.match.params.login);
    const result = this.props.getLatestEntity(this.props.match.params.login) as any;
    const { localPreviewFiles } = this.state;
    result.then(data => {
      this.setState({
        certification: data.value.data || { ...defaultValue }
      });
    });
  }
  // 渲染按钮
  action() {
    const { certificationEntity, match, loading } = this.props;
    return loading ? null : (
      <Button.Group>
        <LinkButton type={'primary'} htmlType={'button'} to={detailsToEdit(match.url, certificationEntity.id)}>
          <Icon type={'edit'} /> <Translate contentKey="userManagement.home.editLabel">Edit</Translate>
        </LinkButton>
      </Button.Group>
    );
  }
  // 图片
  setImage = key => value => {
    this.state.certification[key] = value;
    this.setState({
      certification: { ...this.state.certification }
    });
  };

  renderUpload = key => {
    const imageUrl = this.state.certification && this.state.certification[key] ? this.state.certification[key] : '';
    return (
      <div style={{ width: 337, maxHeight: 213, overflow: 'hidden', position: 'relative' }}>
        <ImageUpload onChange={this.setImage(key)} value={imageUrl !== 'null' && imageUrl !== null ? imageUrl : ''} />
      </div>
    );
  };

  render() {
    const { certificationEntity, loading, user } = this.props;
    const styleProps = data => {
      if (data && data === '') {
        return { style: { width: 337 } };
      } else {
        return {};
      }
    };
    const uploadFile = img => 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png';
    return (
      <PageHeaderWrapper
        title={
          user.name
            ? translate('lehomeApp.certification.home.paramsTitle', { name: user.name })
            : translate('lehomeApp.certification.home.title')
        }
      >
        <Spin size={'large'} spinning={this.props.loading || this.state.uploading || this.props.updating}>
          <Card bordered={false}>
            <Descriptions
              title={
                <div>
                  <Divider orientation="left">
                    <Icon type="idcard" />
                    &nbsp;
                    {translate('lehomeApp.certification.tips.idCard')}
                  </Divider>
                </div>
              }
            >
              <Descriptions.Item>
                <Card hoverable {...styleProps('d')} cover={this.renderUpload('idCardFronted')}>
                  <Meta title={translate('lehomeApp.certification.help.idCardFronted')} />
                </Card>
              </Descriptions.Item>
              <Descriptions.Item>
                <Card
                  hoverable
                  cover={
                    <Skeleton loading={false} avatar active>
                      {this.renderUpload('idCardBackend')}
                    </Skeleton>
                  }
                >
                  <Meta title={translate('lehomeApp.certification.help.idCardBackend')} />
                </Card>
              </Descriptions.Item>
            </Descriptions>
            <Descriptions
              title={
                <Divider orientation="left">
                  <Icon type="home" />
                  &nbsp;
                  {translate('lehomeApp.certification.tips.house')}
                </Divider>
              }
            >
              <Descriptions.Item>
                <Card hoverable cover={this.renderUpload('noHouseCert')}>
                  <Meta title={translate('lehomeApp.certification.help.noHouseCert')} />
                </Card>
              </Descriptions.Item>
            </Descriptions>
            <Descriptions
              title={
                <Divider orientation="left">
                  <Icon type="insurance" />
                  &nbsp;
                  {translate('lehomeApp.certification.tips.social')}
                </Divider>
              }
            >
              <Descriptions.Item>
                <Card hoverable cover={this.renderUpload('socialInsurance')}>
                  <Meta title={translate('lehomeApp.certification.help.socialInsurance')} />
                </Card>
              </Descriptions.Item>
            </Descriptions>
            <Descriptions
              title={
                <Divider orientation="left">
                  <Icon type="reconciliation" />
                  {translate('lehomeApp.certification.tips.education')}
                </Divider>
              }
            >
              <Descriptions.Item>
                <Card hoverable cover={this.renderUpload('education')}>
                  <Meta title={translate('lehomeApp.certification.help.education')} />
                </Card>
              </Descriptions.Item>
            </Descriptions>
            <Button
              onClick={this.startUpload}
              type={'primary'}
              disabled={this.props.loading || this.state.uploading}
              loading={this.props.loading || this.state.uploading}
            >
              {translate('entity.action.save')}
            </Button>
            {this.props.certificationEntity && this.props.certificationEntity.state === AuditState.WAITING && (
              <Button onClick={this.startUpload} type={'primary'}>
                {translate('entity.action.passJudge')}
              </Button>
            )}
          </Card>
        </Spin>
      </PageHeaderWrapper>
    );
  }

  startUpload = () => {
    const { certificationEntity, user } = this.props;
    const { certification } = this.state;
    this.setState({ uploading: true });
    const { history, match } = this.props;
    const form = { ...certificationEntity, ...certification };
    const excutePromise: any =
      this.props.certificationEntity && this.props.certificationEntity.id
        ? this.props.updateEntity({
            ...form,
            userId: user.id
          })
        : this.props.createEntity({
            ...form,
            userId: user.id
          });
    excutePromise.then(data => {
      history.replace(`${match.url.replace('/edit', '')}`);
    });
  };
}

const mapStateToProps = ({ certification, userManagement }: IRootState) => ({
  certificationEntity: certification.entity,
  user: userManagement.user,
  loading: certification.loading,
  updateSuccess: certification.updateSuccess,
  updating: certification.updating
});

const mapDispatchToProps = { getEntity, getUser, getLatestEntity, createEntity, updateEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificationEdit);

import axios from 'axios';
import * as qs from 'qs';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IAllStatistical } from 'app/shared/model/allStatistical.model';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_GET_TOTAL: 'statistical/FETCH_GET_TOTAL'
};
// state
const initialState = {
  loading: false,
  entities: [] as IAllStatistical[]
};

export type AllStatisticalState = Readonly<typeof initialState>;

export default (state: AllStatisticalState = initialState, action): AllStatisticalState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_GET_TOTAL):
      return {
        ...state,
        loading: true
      };
    case FAILURE(ACTION_TYPES.FETCH_GET_TOTAL):
      return {
        ...state,
        loading: false
      };
    case SUCCESS(ACTION_TYPES.FETCH_GET_TOTAL):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    default:
      return state;
  }
};

export const getTotal = (houseName, month) => ({
  type: ACTION_TYPES.FETCH_GET_TOTAL,
  payload: axios.get(`api/statistics/rental-stat-histories`, {
    params: { month, houseName }
  })
});

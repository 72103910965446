import { Moment } from 'moment';

export interface IMessage {
  id?: number;
  read?: Moment;
  createdAt?: Moment;
  recipientId?: number;
  messageContentId?: number;
}

export const defaultValue: Readonly<IMessage> = {};

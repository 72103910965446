import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { Alert } from 'antd';

class PageNotFound extends React.Component {
  render() {
    return (
      <div>
        <Alert type="error" message={translate('error.http.404')}/>
      </div>
    );
  }
}

export default PageNotFound;

/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Badge, Button, Card, Descriptions, Icon, Skeleton, Tag, Avatar } from 'antd';
import { TextFormat, Translate, translate } from 'react-jhipster';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { deleteUser, getUser, reset, getCert } from './user-management.reducer';
import { IRootState } from 'app/shared/reducers';
import DescriptionList from 'app/shared/layout/content/components/DescriptionList';
import LinkButton from 'app/shared/components/link-button';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { detailsToEdit } from 'app/shared/util/url-utils';
import { canNotOperate } from 'app/modules/administration/user-management/user-management';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { isString } from 'lodash';
import { livingStateToChinese } from 'app/modules/administration/user-management/user-utils';
import { CertStatus } from 'app/shared/model/user.model';

const { Description } = DescriptionList;

export interface IUserManagementDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ login: string }> {}

export class UserManagementDetail extends React.Component<IUserManagementDetailProps> {
  async componentDidMount() {
    await this.props.getUser(this.props.match.params.login);
    this.props.getCert(this.props.user.id);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  checkAuth = (action: string[] | string) => {
    const {
      account: { authorities }
    } = this.props;
    if (isString(action)) {
      action = [action];
    }
    return hasAnyAuthority(authorities, [`User#${action}`]);
  };

  action() {
    const { user, match, loading } = this.props;
    return loading || canNotOperate(user.login) ? null : (
      <Button.Group>
        <LinkButton type={'primary'} htmlType={'button'} to={detailsToEdit(match.url, user.login)}>
          <Icon type={'edit'} /> <Translate contentKey="userManagement.home.editLabel">Edit</Translate>
        </LinkButton>
      </Button.Group>
    );
  }

  render() {
    const { user, cert } = this.props;
    return (
      <PageHeaderWrapper extra={this.action()}>
        <Card bordered={false}>
          <Skeleton loading={this.props.loading} active>
            <Descriptions
              title={
                <div>
                  <Icon type="login" />
                  {translate('userManagement.section.one')}
                </div>
              }
            >
              <Descriptions.Item label={translate('userManagement.imageUrl')}>
                {user.imageUrl && user.imageUrl !== '' ? (
                  <Avatar shape="square" size={64} src={user.imageUrl} />
                ) : (
                  <Avatar style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }} shape="square" size={64}>
                    {user.login}
                  </Avatar>
                )}
              </Descriptions.Item>
              <Descriptions.Item label={translate('userManagement.login')}>{user.login}</Descriptions.Item>
              <Descriptions.Item label={translate('userManagement.name')}>{user.name}</Descriptions.Item>
              <Descriptions.Item label={translate('userManagement.activate')}>
                {user.activated ? (
                  <Badge status="success" text={translate('userManagement.activated')} />
                ) : (
                  <Badge status="error" text={translate('userManagement.deactivated')} />
                )}
              </Descriptions.Item>
              <Descriptions.Item label={translate('userManagement.langKey')}>{user.langKey}</Descriptions.Item>
              <Descriptions.Item label={translate('userManagement.createdBy')}>{user.createdBy}</Descriptions.Item>
              <Descriptions.Item label={translate('userManagement.createdDate')}>
                <TextFormat value={user.createdAt} type="date" format={APP_DATE_FORMAT} blankOnInvalid />
              </Descriptions.Item>
              <Descriptions.Item label={translate('userManagement.lastModifiedBy')}>{user.updatedBy}</Descriptions.Item>
              <Descriptions.Item label={translate('userManagement.lastModifiedDate')}>
                <TextFormat value={user.updatedAt} type="date" format={APP_DATE_FORMAT} blankOnInvalid />
              </Descriptions.Item>
              <Descriptions.Item label={translate('userManagement.profiles')}>
                {(user.authorities || []).map(auth => (
                  <Tag>{auth}</Tag>
                ))}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions
              title={
                <div>
                  <Icon type="user" />
                  {translate('userManagement.section.two')}
                </div>
              }
            >
              <Descriptions.Item label={translate('userManagement.name')}>{user.name}</Descriptions.Item>
              <Descriptions.Item label={translate('userManagement.mobile')}>{user.mobile}</Descriptions.Item>
              <Descriptions.Item label={translate('userManagement.livingState')}>
                {livingStateToChinese(user.livingState)}
              </Descriptions.Item>
              <Descriptions.Item label={translate('userManagement.graduateFrom')}>{user.graduateFrom}</Descriptions.Item>
              <Descriptions.Item label={translate('userManagement.education')}>{user.education}</Descriptions.Item>
              <Descriptions.Item label={translate('userManagement.companyName')}>{user.companyName}</Descriptions.Item>
              <Descriptions.Item label={translate('userManagement.workPosition')}>{user.workPosition}</Descriptions.Item>
            </Descriptions>
            <Descriptions
              title={
                <div>
                  <Icon type="idcard" />
                  {translate('userManagement.section.three')}
                </div>
              }
            >
              <Descriptions.Item label={translate('userManagement.idCard')}>{user.idCard}</Descriptions.Item>
            </Descriptions>
          </Skeleton>
        </Card>
        {cert.id && (
          <Card bordered={false}>
            <Skeleton loading={this.props.loading} active>
              <Descriptions title={'上上签证书'}>
                <Descriptions.Item label={`证书编号`}>{cert.certId}</Descriptions.Item>
                <Descriptions.Item label={`证书状态`}>{cert.statusMsg}</Descriptions.Item>
                <Descriptions.Item label={`有效时间`}>{`${cert.startTime}~${cert.stopTime}`}</Descriptions.Item>
                {cert.status === -2 && <Descriptions.Item label={`吊销原因`}>{`${cert.revokedReason}`}</Descriptions.Item>}
              </Descriptions>
            </Skeleton>
          </Card>
        )}
      </PageHeaderWrapper>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  user: storeState.userManagement.user,
  cert: storeState.userManagement.cert,
  account: storeState.authentication.account,
  loading: storeState.userManagement.loading
});

const mapDispatchToProps = { getUser, getCert, deleteUser, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementDetail);

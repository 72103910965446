import axios from 'axios';
import * as qs from 'qs';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IRenewal } from 'app/shared/model/renewal.model';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';
import { IChangeHouse } from 'app/shared/model/change-house.model';
// 请求
export const ACTION_TYPES = {
  FETCH_RENEWAL_LIST: 'renewal/FETCH_RENEWAL_LIST',
  LOAD_RENEWAL_LIST: 'renewal/LOAD_RENEWAL_LIST',
  FETCH_RENEWAL: 'renewal/FETCH_RENEWAL',
  CREATE_RENEWAL: 'renewal/CREATE_RENEWAL',
  UPDATE_RENEWAL: 'renewal/UPDATE_RENEWAL',
  DELETE_RENEWAL: 'renewal/DELETE_RENEWAL',
  RESET: 'renewal/RESET'
};
// state
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IRenewal>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  searchForm: {}
};

export type RenewalState = Readonly<typeof initialState>;

// Reducer

export default (state: RenewalState = initialState, action): RenewalState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_RENEWAL_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_RENEWAL_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_RENEWAL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_RENEWAL):
    case REQUEST(ACTION_TYPES.UPDATE_RENEWAL):
    case REQUEST(ACTION_TYPES.DELETE_RENEWAL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_RENEWAL_LIST):
    case FAILURE(ACTION_TYPES.LOAD_RENEWAL_LIST):
    case FAILURE(ACTION_TYPES.FETCH_RENEWAL):
    case FAILURE(ACTION_TYPES.CREATE_RENEWAL):
    case FAILURE(ACTION_TYPES.UPDATE_RENEWAL):
    case FAILURE(ACTION_TYPES.DELETE_RENEWAL):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_RENEWAL_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_RENEWAL_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_RENEWAL):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_RENEWAL):
    case SUCCESS(ACTION_TYPES.UPDATE_RENEWAL):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_RENEWAL):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/renewals'; // 续租申请
const initpage = 0;
const initsize = 20;

// Actions
// 续租申请列表
export const getEntities: ICrudGetListAction<IRenewal> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  initialState.searchForm = { ...searchForm };

  return {
    type: ACTION_TYPES.FETCH_RENEWAL_LIST,
    payload: axios.get<IRenewal>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const loadEntities: ICrudGetListAction<IRenewal> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.LOAD_RENEWAL_LIST,
    payload: axios.get<IRenewal>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};
// 续租申请详情
export const getEntity: ICrudGetAction<IRenewal> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_RENEWAL,
    payload: axios.get<IRenewal>(requestUrl)
  };
};
// 创建续租申请
export const createEntity: ICrudPutAction<IRenewal> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_RENEWAL,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  // dispatch(reset());
  // dispatch(getEntities());
  return result;
};
// 更新续租申请
export const updateEntity: ICrudPutAction<IRenewal> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_RENEWAL,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  // dispatch(reset());
  // dispatch(getEntities());
  return result;
};
// 审批续租申请
export const auditEntity: ICrudPutAction<IChangeHouse> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_RENEWAL,
    payload: axios.put(`${apiUrl}/audit`, cleanEntity(entity))
  });
  // dispatch(reset());
  // dispatch(getEntities());
  return result;
};
// 删除续租申请
export const deleteEntity: ICrudDeleteAction<IRenewal> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_RENEWAL,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities(initpage, initsize, '', initialState.searchForm));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

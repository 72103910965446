import React from 'react';
import { Tooltip, Typography, Skeleton, Button } from 'antd';

const LoadMore = ({ loadMore, totalItems = 0, dataSize = 0, onLoadMore = () => {} }) => {
  if (dataSize <= totalItems) {
    return (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px'
        }}
      >
        没有更多数据
      </div>
    );
  }

  if (loadMore) {
    return <Skeleton active />;
  }
  return (
    <div
      style={{
        textAlign: 'center',
        marginTop: 12,
        height: 32,
        lineHeight: '32px'
      }}
    >
      <Button onClick={onLoadMore}>点击加载更多</Button>
    </div>
  );
};
export default LoadMore;

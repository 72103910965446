import axios from 'axios';
import * as qs from 'qs';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { IPropertyDetailsRecords } from 'app/shared/model/property-details-records.model';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_Property_LIST: 'Property/FETCH_Property_LIST'
};
// state定义
const initialState = {
  loading: false, // loading
  fetching: false, // loading
  loadMore: false, // 加载更多loading
  errorMessage: null, // 返回错误信息
  entities: [], // 列表返回数据
  entity: [], // 详情返回
  updating: false, // 接口返回
  totalItems: 0, // 总条数
  updateSuccess: false // 接口是否返回成功
};

export type PropertyDetailState = Readonly<typeof initialState>;

// Reducer

export default (state: PropertyDetailState = initialState, action): PropertyDetailState => {
  switch (action.type) {
    // 请求接口
    case REQUEST(ACTION_TYPES.FETCH_Property_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    // 接口失败
    case FAILURE(ACTION_TYPES.FETCH_Property_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    // 接口返回成功
    case SUCCESS(ACTION_TYPES.FETCH_Property_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    default:
      return state;
  }
};
// 接口连接
const apiUrl = '/api/statistics/tenement_houseroom_statistics';
// 列表
export const getEntities: ICrudGetListAction<IPropertyDetailsRecords> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiUrl}?page=${page}&size=${size}`;

  return {
    type: ACTION_TYPES.FETCH_Property_LIST,
    payload: axios.get<IPropertyDetailsRecords>(`${requestUrl}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

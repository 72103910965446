/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import styles from './index.less';

import React from 'react';
import classNames from 'classnames';
import { Row } from 'antd';
import Description from './Description';

export interface IDescriptionListProps {
  layout?: 'horizontal' | 'vertical';
  col?: number;
  title: React.ReactNode;
  gutter?: number;
  size?: 'large' | 'small';
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

class DescriptionList extends React.Component<IDescriptionListProps> {
  public static Description: typeof Description;

  static defaultProps: IDescriptionListProps = {
    col: 3,
    layout: 'horizontal',
    gutter: 32,
    title: ''
  };

  render() {
    const { className, title, col, layout, gutter, children, size, ...restProps } = this.props;
    const clsString = classNames(styles.descriptionList, styles[layout], className, {
      [styles.small]: size === 'small',
      [styles.large]: size === 'large'
    });
    const column = col > 4 ? 4 : col;
    return (
      <div className={clsString} {...restProps}>
        {title ? <div className={styles.title}>{title}</div> : null}
        <Row gutter={gutter}>{React.Children.map(children, (child: any) => (child ? React.cloneElement(child, { column }) : child))}</Row>
      </div>
    );
  }
}

export default DescriptionList;

import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { ICrudGetListAction } from 'app/shared/model/list';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import * as qs from 'qs';

import { ISettle, defaultValue } from 'app/shared/model/settle.model';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_SETTLE_LIST: 'settle/FETCH_SETTLE_LIST',
  LOAD_SETTLE_LIST: 'settle/LOAD_SETTLE_LIST',
  FETCH_SETTLE: 'settle/FETCH_SETTLE',
  CREATE_SETTLE: 'settle/CREATE_SETTLE',
  UPDATE_SETTLE: 'settle/UPDATE_SETTLE',
  DELETE_SETTLE: 'settle/DELETE_SETTLE',
  RESET: 'settle/RESET'
};
// state
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ISettle>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type SettleState = Readonly<typeof initialState>;

// Reducer

export default (state: SettleState = initialState, action): SettleState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SETTLE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_SETTLE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_SETTLE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_SETTLE):
    case REQUEST(ACTION_TYPES.UPDATE_SETTLE):
    case REQUEST(ACTION_TYPES.DELETE_SETTLE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_SETTLE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SETTLE):
    case FAILURE(ACTION_TYPES.CREATE_SETTLE):
    case FAILURE(ACTION_TYPES.UPDATE_SETTLE):
    case FAILURE(ACTION_TYPES.DELETE_SETTLE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_SETTLE_LIST):
      return {
        ...state,
        fetching: false,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_SETTLE_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_SETTLE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_SETTLE):
    case SUCCESS(ACTION_TYPES.UPDATE_SETTLE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_SETTLE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/settles'; // 入住申请

// Actions

export const getEntities: ICrudGetListAction<ISettle> = (page = 0, size = 20, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_SETTLE_LIST,
    payload: axios.get<ISettle>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const loadEntities: ICrudGetListAction<ISettle> = (page = 0, size = 20, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_SETTLE_LIST,
    payload: axios.get<ISettle>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const getEntity: ICrudGetAction<ISettle> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SETTLE,
    payload: axios.get<ISettle>(requestUrl)
  };
};
// 创建入住申请
export const createEntity: ICrudPutAction<ISettle> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SETTLE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};
// 更新入住申请
export const updateEntity: ICrudPutAction<ISettle> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SETTLE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};
// 删除入住申请
export const deleteEntity: ICrudDeleteAction<ISettle> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SETTLE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import styles from './footer.less';

import React, { useState } from 'react';
import { Layout, Typography, Modal, Button } from 'antd';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';

const Agreement = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="link" onClick={showModal}>
        数据安全与个人信息保护制度
      </Button>
      <Modal title="数据安全与个人信息保护制度" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={'800px'}>
        <h2 style={{ textAlign: 'center' }}>数据安全与个人信息保护制度</h2>
        <h3>&nbsp;&nbsp;&nbsp;&nbsp;第1章 数据安全管理制度</h3>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;1.1 总则</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第一条
          为保证建鑫乐家平台数据安全，维护数据所有者权利，明确利益相关者的责任与义务，按照分类管理、分级保护、授权使用的原则，根据国家网络安全等级保护、《中华人民共和国网络安全法》等有关要求
          ，特制订本规定。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第二条
          本规定所管理的数据均为非涉密的数据，建鑫乐家平台已标识密级的文件或已声明密级的数据不纳入本规定管理范畴。
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;1.2 术语定义</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第三条
          本规定所称数据所有者是指，对所管理业务领域内的信息或信息系统，有权获取、创建、维护和授权的业务主管。
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第四条 本规定所称利益相关者包括数据创建者、数据所有者、数据管理者、数据使用者及信息安全管理人员。</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第五条
          本规定所管理的数据涵盖以纸质、电子等形式存在的文件和非文件形式的信息及其衍生物。其中，非文件形式的数据包括数据库及配置文件中的数据、配置信息等。
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;1.3 职责定义</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第六条 数据创建者负责针对其所创建数据的内容和价值提出分类分级建议，提交对应级别数据所有者确认。</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第七条 来自建鑫乐家平台以外的数据，数据承接者视同创建者行使提出分级分类建议的责任和义务。</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第八条
          数据所有者具有确认数据类别和敏感级别、确认销毁、提出技术保障需求、审查自检和审计报告、做出安全事件处置决定等权利和义务。其中，建鑫乐家平台业务数据的所有者为建鑫乐家平台信息部门。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第九条 各类数据的责任部门是该类数据的管理者。
          数据管理者作为数据所有者的代理者，代理数据所有者从事数据管理工作，负责其所管辖范围内数据的安全管理工作。
          数据管理者的职责包括但不限于：数据类别和敏感级别的标识与声明，根据级别进行管理与保护，数据使用培训，执行销毁操作并声明，定期自查提交报告，配合数据安全违规调查等。
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;1.3.1 分类与分级</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第十条 数据按其内容和用途不同分为技术类数据、行政管理类数据、业务类数据以及安全运行类数据。</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第十一条 数据分级应根据其价值、内容的敏感程度、影响及发放范围进行确定。</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第十二条
          数据管理者负责制定其分管领域内数据敏感等级的划分规则，并制定和发布本部门或本领域的数据敏感等级目录。
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;1.3.2 标识与声明</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第十三条
          数据的分类分级标识、声明是数据不可分割的一部分，自其标识、声明之日起，数据及其标识、声明不得分离，数据管理者和数据使用者均须按照标识、声明的类别和级别安全管理和使用数据。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第十四条
          对于文件形式的数据，须由数据管理者在文件明显位置标识其类别、敏感级别、失效日期等，且文件和标识不能分开。标识应该醒目，标识格式应统一，标识方法应便于审计。对于非文件形式的高敏感级别数据，如无法标识，须进行声明。
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第十五条 失效日期指数据敏感级别的有效性截止日期。到达失效日期后，应对数据进行重定级。</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第十六条
          对于敏感级别高的数据，须由管理者对数据名称、类别、敏感级别、失效日期等以声明文件的形式进行声明，声明文件须由数据所有者审批签字。声明文件须跟随数据一起保管和传递。（声明文件模版详见附件）
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第十七条 多个不同敏感级别的数据合并在一起时，按最高敏感级别给混合数据进行标识和声明。</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;1.3.3 保管与保护</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第十八条 数据管理者须按照数据的敏感级别实施对应的保管与保护措施，并确保满足数据所有者对数据的安全要求。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第十九条 数据管理者在日常管理中，须对数据按敏感级别分级防护，采取对应的存储、归档、设定保存期限等措施。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第二十条
          敏感级别高的数据纸质文件须参照建鑫乐家平台信息直通秘密级文件安全管理规定进行保管和保护。敏感级别高的电子数据须采用必要的访问控制措施。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第二十一条
          数据管理工作应该首选技术手段加管理要求实现。必须加强针对数据管理工作的技术手段的研究、选型、部署、维护等。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第二十二条
          敏感级别高的数据自产生之日起，所有者提出的技术保障需求应同步到位。如技术上无法达到，技术部门应持续跟踪技术进展，逐步使技术手段能够满足各项管理要求；对于已成熟并可采纳的技术手段，技术部门应验证其功能可靠性，逐步引入，持续优化技术保障工作。
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;1.3.4 数据使用</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第二十三条
          数据使用者在使用数据时，须注意识别数据的敏感级别，按照其敏感级规范数据操作使用行为；使用中发现问题及时反映，发现违规行为及时举报，并积极配合违规事件的调查；自觉遵守数据管理规定。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第二十 四条 数据使用者须保证其所使用数据来源的合法性，不私自拷贝、使用、传播、保存与自己工作无关的数据。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第二十五条
          数据使用者和数据管理者无权未经所有者批准向发布范围以外的单位或个人提供中心数据或其衍生物，否则视为违规；如因工作原因需要对外提供的，应经数据所有者确认，并记录、备案、备查。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第二十六条
          建鑫乐家平台业务数据须按主管业务部门授权或管理规定要求对外提供，否则视为违规，并需做好数据提供记录，备案、备查。
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第二十七条 数据管理者要对数据使用情况进行掌控和审计，发现违规行为及时制止，并依本规定进行处置。</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;1.3.5 数据销毁</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第二十八条 数据管理者有按照数据所有者要求清理和销毁原始数据的义务。</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第二十九条
          数据使用者应具有数据安全清理和销毁的意识，具有按照数据管理者的要求清理和销毁本地临时数据、中间文件、过程文件的责任和义务。
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第三十条 数据管理者和数据使用者具有按照规定记录清理和销毁数据过程，并接受安全管理人员审计的义务。</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第三十一条 数据管理者和数据使用者在清除和销毁电子数据时，须保证清除和销毁的彻底性。</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;1.3.6 安全检查与审计要求</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第三十二条
          数据管理者有义务监督数据的安全使用，负责所管理数据的自查工作。周期性地检查数据安全使用和管理情况，更新过期的标识或声明信息，向数据所有者汇报。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第三十三条
          信息安全领导机构根据信息安全组织授权，独立开展高敏感级别数据的第三方审计工作。检查和审计数据所有者、数据管理者数据管理要求的执行情况，向信息安全组织汇报。
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;1.3.7 数据保护</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第三十四条
          数据保护应遵循适度保护、积极防范、突出重点、分级管理的原则，通过数据的分类分级进行区别保护、动态保护。
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第三十五条 建鑫乐家平台信息安全组织，应根据信息安全工作的实际需要，制定数据保护的具体技术和管理措施。</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第三十六条 在数据的采集、决策分析、共享发布、存储和废弃的生命周期各阶段，对数据的相应级别进行防护和处理。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第三十七条
          应能够检测到系统管理数据、鉴别信息和重要业务数据在传输和存储过程中完整性受到破坏，并在检测到完整性错误时采取必要的恢复措施。
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第三十八条 数据在存储和传输过程中须根据数据的级别采用加密或其他手段确保其存储安全。</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第三十九条 数据在使用过程中，须根据数据的级别进行控制，严禁非授权访问、传输和修改。</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第四十条 数据交换过程中如涉及交换对象为非海关系统，应确保对端保护力度不低于海关系统数据保护力度。</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第四十一条 数据的销毁须严格按照建鑫乐家平台相关标准、规范要求及国家的法律法规要求进行处理。</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;1.4 附 则</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第四十二条 本规定由建鑫乐家平台技术部负责解释。</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第四十三条 本规定自发布之日起执行。</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;附件1 分类分级说明</p>
        <img src={'/content/images/agreements/agreement01.jpg'} alt="" style={{ width: '500px' }} />
        <h3>第2章 个人信息保护制度</h3>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第一条
          为了保护建鑫乐家平台用户的合法权益，维护网络信息安全，依据《中华人民共和国电信条例》《电信和互联网用户个人信息保护规定》，制订本制度。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第二条
          建鑫乐家平台提供网络信息服务过程中，有收集、使用用户个人信息的网络应用平台（以下简称各平台），适用于本制度。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第三条
          本制度所称用户个人信息，是指各系统平台在提供服务过程中收集的用户姓名、出生日期、身份证件号码、住址、电话号码、账号和密码等能够单独或者与其他信息结合识别用户的信息，以及用户使用服务的时间、地点
          等信息。
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第四条 各系统平台在提供信息服务过程中，收集、使用用户个人信息，应当遵循合法、正当、必要的原则。</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第五条
          各系统平台对收集、使用的用户个人信息的安全负责，应明确和落实相关人员安全管理责任，对工作人员实行权限管理，对批量导出、复制、销毁信息实行审查，并采取防泄密措施。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第六条
          各系统平台未经用户同意，不得收集、使用用户个人信息，如确需用户提供个人信息，应明确告知用户使用的目的、方式、范围和救济渠道，并告知拒绝提供信息的后果。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第七条
          各系统平台收集、使用用户个人信息应当严格保密，不得泄露、篡改或者毁损，不得出售或者非法向他人提供，不得与他人谈论用户个人信息内容。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第八条
          建鑫乐家平台对用户个人信息保护工作实施监督管理，各系统平台保管的用户个人信息发生泄露、毁损或者丢失，造成或者可能造成严重后果的，应及时通报领导小组办公室进行调查和应急处置。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;第九 条
          各系统平台保管的用户个人信息因泄露、篡改、毁损或者丢失，对建鑫乐家平台带来不利影响的，根据相关规定进行办理。
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第十条 各系统平台应积极接受公民、法人和其他社会组织的批评、意见和建议。</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第十一条 本制度建鑫乐家平台负责解释。</p>
      </Modal>
    </>
  );
};

const { Footer } = Layout;
const GlobalFooter = props => (
  <Footer className={styles.footer} {...props}>
    <Typography.Paragraph>
      <Typography.Text type={'secondary'}>{props.version}</Typography.Text>
    </Typography.Paragraph>
    <a href="https://beian.miit.gov.cn" target="_blank" className={styles.footerText}>
      佛山市建鑫住房租赁有限公司（粤ICP备17051109号） copyright ©2019 <Agreement />
    </a>
  </Footer>
);

const mapStateTopProps = ({ roleManagement, authentication, applicationProfile }: IRootState) => ({
  version: applicationProfile.version
});

const mapDispatchTopProps = {};

type StateProps = ReturnType<typeof mapStateTopProps>;
type DispatchProps = typeof mapDispatchTopProps;

export default connect(
  mapStateTopProps,
  mapDispatchTopProps
)(GlobalFooter);

/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { isPromise, log } from 'react-jhipster';
import * as qs from 'qs';
import { isArray, uniq, each } from 'lodash';
import axios from 'axios';

import { PROMISE_FULFILLED, PROMISE_DELIMITER } from './constants';
import { flattenKey } from 'app/shared/util/object-utils';

const apiUrl = '/api/users/names';

const transformUserCache = {};

const hasFulfilled = type => {
  if (!type) {
    return false;
  }
  const fullFulfilled = PROMISE_DELIMITER + PROMISE_FULFILLED;
  return type.indexOf(fullFulfilled) + fullFulfilled.length === type.length;
};

export default () => next => action => {
  // If a promise, continue on
  if (!isPromise(action.payload)) {
    return next(action);
  }
  if (!action.payload) {
    return next(action);
  }

  if (!action.meta || (action.meta && !action.meta.transformUserName && !hasFulfilled(action.type))) {
    return next(action);
  }
  const { payload, ...reset } = action;

  const promise = new Promise(resolve => {
    payload.then(response => {
      const data = response.data;
      let datas = [data];
      if (isArray(data)) {
        datas = data;
      }

      const unTransformUserName = [];
      datas.forEach(d => {
        if (d.createdBy && !transformUserCache[d.createdBy]) {
          unTransformUserName.push(d.createdBy);
        }
        if (d.updatedBy && !transformUserCache[d.updatedBy]) {
          unTransformUserName.push(d.updatedBy);
        }
      });
      const uniqUnTransformUserName = uniq(unTransformUserName);
      if (uniqUnTransformUserName.length > 0) {
        axios
          .get(apiUrl, {
            params: flattenKey({ login: { in: uniqUnTransformUserName } }),
            paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
          })
          .then(nameWithLoginResponse => {
            each(nameWithLoginResponse.data, (value, key) => {
              transformUserCache[key] = value;
            });
            log(transformUserCache);
            datas.forEach(d => {
              if (d.createdBy) d.createdBy = transformUserCache[d.createdBy] || d.createdBy;
              if (d.updatedBy) d.updatedBy = transformUserCache[d.updatedBy] || d.updatedBy;
            });
            resolve(response);
          })
          .then(() => {
            resolve(response);
          });
      } else {
        datas.forEach(d => {
          if (d.createdBy) d.createdBy = transformUserCache[d.createdBy];
          if (d.updatedBy) d.updatedBy = transformUserCache[d.updatedBy];
        });
        resolve(response);
      }
    });
  });
  return next({ payload: promise, ...reset });
};

import moment, { Moment } from 'moment';
import { IPayment } from 'app/shared/model/payment.model';
import { IContract } from 'app/shared/model/contract.model';
import { IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { ExpenseType } from 'app/shared/model/expense-grade.model';
import { APP_LOCAL_DATETIME_T_FORMAT } from 'app/config/constants'; // 时间格式

export const enum OrderState {
  PAYMENT = 'PAYMENT',
  WAITING = 'WAITING',
  WAITING_CONFIRM = 'WAITING_CONFIRM',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  REFUNDED = 'REFUNDED',
  REFUNDED_FAILED = 'REFUNDED_FAILED'
}

export const enum LockBelatedType {
  TODAY = 'TODAY',
  FOREVER = 'FOREVER'
}

export const enum CategoryType {
  CONTRACT_ORDERS = 'CONTRACT_ORDERS',
  TCONTRACT_ORDERS = 'TCONTRACT_ORDERS',
  MCONTRACT_ORDERS = 'MCONTRACT_ORDERS'
}

export interface IOrderItems {
  id?: number;
  name?: string;
  amount?: number;
  amountPaid?: number;
  position?: number;
  type?: ExpenseType;
  state?: OrderState;
  orderItems?: any;
  roomName?: any; // 房间名称
}

export interface IAdjustOrderItemLogs {
  id?: number;
  orders?: any;
  orderItemName?: string;
  beforeAmount?: number;
  afterAmount?: number;
  createdAt?: string;
}

export interface IOrders {
  id?: number;
  number?: string;
  total?: number;
  belated?: number;
  name?: string;
  description?: string;
  state?: OrderState;
  completedAt?: Moment;
  canceledAt?: Moment;
  refundedAt?: Moment;
  payableAt?: Moment;
  createdAt?: Moment;
  createdBy?: string;
  confirmAt?: Moment;
  updatedBy?: string;
  updatedAt?: Moment;
  userId?: number;
  payments?: IPayment[];
  orderItems?: IOrderItems[];
  username?: string; // 用户名
  mobile?: string; // 手机
  roomName?: any; // 房间号
  orderItem?: any; // 收费项
  contractId?: number;
  startAt?: Moment;
  endAt?: Moment;
  houseRoomId?: number;
  transferredRemark?: string;
  adjustOrderItemLogs?: IAdjustOrderItemLogs[];
  liquidationAmount?: Number;
  liquidationFee?: Number;
  liquidationDate?: string;
  liquidationStatus?: string;
  category?: string;
  tcontractItemId?: number; // 团租ID
  children?: IOrders[];
  totalActualSingleMonthPrice?: number; // 月实际租金
  totalDeposit?: number; // 总押金
  totalRental?: number; // 总房租
  miniOrders?: any; // 团租子订单集合
  type?: string;
}

export const defaultValue: Readonly<IOrders> = {
  orderItems: [],
  payments: []
};

export interface ISearchForm {
  houseRoomId?: IRangeFilter;
  userId?: IRangeFilter;
  createdAt?: IRangeFilter;
  startAt?: IRangeFilter;
  createdBy?: IStringFilter;
  houseId?: IRangeFilter;
  state?: IStringFilter;
  category?: IStringFilter;
  payableAt?: IRangeFilter;
  completedAt?: IRangeFilter;
  username?: IStringFilter;
}
// 账单搜索表格初始化
export const defaultSearchForm: ISearchForm = {
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  startAt: {
    greaterOrEqualThan: moment
      .utc(
        moment()
          .subtract(2, 'months')
          .endOf('month')
      )
      .format(APP_LOCAL_DATETIME_T_FORMAT), // 开始日期 默认三个月前
    lessOrEqualThan: moment
      .utc(
        moment()
          .add(1, 'month')
          .endOf('month')
      )
      .format(APP_LOCAL_DATETIME_T_FORMAT), // 结束日期
    lessThan: '',
    greaterThan: ''
  },
  payableAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  completedAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  houseId: {
    in: []
  },
  houseRoomId: {
    in: []
  },
  userId: {
    in: []
  },
  state: {
    equals: ''
  },
  category: {
    in: ['TCONTRACT_ORDERS', 'CONTRACT_ORDERS']
  },
  username: {}
};

// 账单搜索表格初始化
export const defaultMcontractSearchForm: ISearchForm = {
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  startAt: {
    greaterOrEqualThan: moment
      .utc(
        moment()
          .subtract(2, 'months')
          .endOf('month')
      )
      .format(APP_LOCAL_DATETIME_T_FORMAT), // 开始日期 默认三个月前
    lessOrEqualThan: moment
      .utc(
        moment()
          .add(1, 'month')
          .endOf('month')
      )
      .format(APP_LOCAL_DATETIME_T_FORMAT), // 结束日期
    lessThan: '',
    greaterThan: ''
  },
  payableAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  completedAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  houseId: {
    in: []
  },
  houseRoomId: {
    in: []
  },
  userId: {
    in: []
  },
  state: {
    equals: ''
  },
  category: {
    in: ['MCONTRACT_ORDERS']
  },
  username: {}
};

import styles from './list-item-meta-title.less';

import React from 'react';

const ListItemMetaTitle = props => {
  const { left, right } = props;
  return (
    <div className={styles.metaTitle}>
      <div className={styles.left}>{left}</div>
      <div className={styles.right}>{right}</div>
    </div>
  );
};
export default ListItemMetaTitle;

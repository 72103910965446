/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios';

export const ACTION_TYPES = {
  UPLOADING_FILE: 'axios-upload/UPLOADING_FILE'
};
const initialState = {};
export type AxiosUploadState = Readonly<typeof initialState>;
export default (state: AxiosUploadState = initialState, action): AxiosUploadState => {
  switch (action.type) {
    default:
      return state;
  }
};

export const upload = (action, data, file, filename, headers, onError, onProgress, onSuccess, withCredentials) => {
  const formData = new FormData();
  if (data) {
    Object.keys(data).map(key => {
      formData.append(key, data[key]);
    });
  }
  formData.append(filename, file);
  return axios
    .post(action, formData, {
      withCredentials,
      onUploadProgress: ({ total, loaded }) => {
        onProgress({ percent: (Math.round((loaded / total) * 100) - 0.1).toFixed(2) }, file);
      }
    })
    .then(({ data: response }) => {
      onProgress(100);
      onSuccess(response, file);
      return Promise.resolve(response);
    })
    .catch(error => {
      onError(error);
      return Promise.reject(error);
    });
};

import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IAppointmentLog, defaultValue } from 'app/shared/model/appointment-log.model';
// 请求
export const ACTION_TYPES = {
  FETCH_APPOINTMENTLOG_LIST: 'appointmentLog/FETCH_APPOINTMENTLOG_LIST',
  FETCH_APPOINTMENTLOG: 'appointmentLog/FETCH_APPOINTMENTLOG',
  CREATE_APPOINTMENTLOG: 'appointmentLog/CREATE_APPOINTMENTLOG',
  UPDATE_APPOINTMENTLOG: 'appointmentLog/UPDATE_APPOINTMENTLOG',
  DELETE_APPOINTMENTLOG: 'appointmentLog/DELETE_APPOINTMENTLOG',
  RESET: 'appointmentLog/RESET'
};
// 定义state类型
const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IAppointmentLog>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false
};

export type AppointmentLogState = Readonly<typeof initialState>;

// Reducer

export default (state: AppointmentLogState = initialState, action): AppointmentLogState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_APPOINTMENTLOG_LIST):
    case REQUEST(ACTION_TYPES.FETCH_APPOINTMENTLOG):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_APPOINTMENTLOG):
    case REQUEST(ACTION_TYPES.UPDATE_APPOINTMENTLOG):
    case REQUEST(ACTION_TYPES.DELETE_APPOINTMENTLOG):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_APPOINTMENTLOG_LIST):
    case FAILURE(ACTION_TYPES.FETCH_APPOINTMENTLOG):
    case FAILURE(ACTION_TYPES.CREATE_APPOINTMENTLOG):
    case FAILURE(ACTION_TYPES.UPDATE_APPOINTMENTLOG):
    case FAILURE(ACTION_TYPES.DELETE_APPOINTMENTLOG):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_APPOINTMENTLOG_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_APPOINTMENTLOG):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_APPOINTMENTLOG):
    case SUCCESS(ACTION_TYPES.UPDATE_APPOINTMENTLOG):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_APPOINTMENTLOG):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

// Actions

export const getEntities = appointmentId => ({
  type: ACTION_TYPES.FETCH_APPOINTMENTLOG_LIST,
  payload: axios.get<IAppointmentLog>(`/api/appointments/${appointmentId}/logs?cacheBuster=${new Date().getTime()}`),
  meta: { transformUserName: true }
});

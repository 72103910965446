import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import * as qs from 'qs';

import { ICertification, defaultValue } from 'app/shared/model/certification.model';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_CERTIFICATION_LIST: 'certification/FETCH_CERTIFICATION_LIST',
  FETCH_CERTIFICATION: 'certification/FETCH_CERTIFICATION',
  FETCH_CERTIFICATION_WAITING: 'certification/FETCH_CERTIFICATION_WAITING',
  CREATE_CERTIFICATION: 'certification/CREATE_CERTIFICATION',
  UPDATE_CERTIFICATION: 'certification/UPDATE_CERTIFICATION',
  FETCH_LATEST_CERTIFICATION: 'certification/FETCH_LATEST_CERTIFICATION',
  DELETE_CERTIFICATION: 'certification/DELETE_CERTIFICATION',
  RESET: 'certification/RESET'
};
// state 类型
const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ICertification>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false
};

export type CertificationState = Readonly<typeof initialState>;

// Reducer

export default (state: CertificationState = initialState, action): CertificationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_LATEST_CERTIFICATION):
    case REQUEST(ACTION_TYPES.FETCH_CERTIFICATION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CERTIFICATION_WAITING):
    case REQUEST(ACTION_TYPES.FETCH_CERTIFICATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_CERTIFICATION):
    case REQUEST(ACTION_TYPES.UPDATE_CERTIFICATION):
    case REQUEST(ACTION_TYPES.DELETE_CERTIFICATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_LATEST_CERTIFICATION):
    case FAILURE(ACTION_TYPES.FETCH_CERTIFICATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CERTIFICATION):
    case FAILURE(ACTION_TYPES.FETCH_CERTIFICATION_WAITING):
    case FAILURE(ACTION_TYPES.CREATE_CERTIFICATION):
    case FAILURE(ACTION_TYPES.UPDATE_CERTIFICATION):
    case FAILURE(ACTION_TYPES.DELETE_CERTIFICATION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_LATEST_CERTIFICATION):
    case SUCCESS(ACTION_TYPES.FETCH_CERTIFICATION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_CERTIFICATION_WAITING):
    case SUCCESS(ACTION_TYPES.FETCH_CERTIFICATION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_CERTIFICATION):
    case SUCCESS(ACTION_TYPES.UPDATE_CERTIFICATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_CERTIFICATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/certifications';

// Actions

export const getEntities: ICrudGetAllAction<ICertification> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_CERTIFICATION_LIST,
  payload: axios.get<ICertification>(`${apiUrl}?cacheBuster=${new Date().getTime()}`)
});

export const getEntity: ICrudGetAction<ICertification> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CERTIFICATION,
    payload: axios.get<ICertification>(requestUrl)
  };
};
export const getLatestEntity = userId => async dispatch => {
  const requestUrl = `${apiUrl}/${userId}/latest`;
  return dispatch({
    type: ACTION_TYPES.FETCH_CERTIFICATION_WAITING,
    payload: axios.get<ICertification>(requestUrl)
  });
};

export const createEntity: ICrudPutAction<ICertification> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CERTIFICATION,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const getLatestCertifications = (userIds, state) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_LATEST_CERTIFICATION,
    payload: axios.get(`${apiUrl}/${state.toString().toLowerCase()}/user`, {
      params: { userIds: [].concat(userIds) }
    })
  });
  return result;
};

export const updateEntity: ICrudPutAction<ICertification> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CERTIFICATION,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ICertification> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CERTIFICATION,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

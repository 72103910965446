import { IExpenseGrade } from 'app/shared/model/expense-grade.model';

export interface IHouseExpense {
  id?: number;
  price?: number;
  expenseGradeId?: number;
  houseId?: number;
  roomId?: number;
  name?: string;
  expenseGrade?: IExpenseGrade;
}

export const defaultValue: Readonly<IHouseExpense> = {
  price: 0.0,
  name: ''
};

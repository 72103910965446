import axios from 'axios';
import * as qs from 'qs';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ISearchForm, IStatistical } from 'app/shared/model/statistical.model';
// 请求
export const ACTION_TYPES = {
  FETCH_BY_HOUSE_LIST: 'statistical/FETCH_BY_HOUSE_LIST',
  FETCH_BY_DATE_LIST: 'statistical/FETCH_BY_HOUSE_LIST',
  FETCH_TOTAL: 'statistical/FETCH_TOTAL',
  FETCH_EXPIRED_COUNT: 'statistical/FETCH_EXPIRED_COUNT'
};
// state
const initialState = {
  loading: false,
  totalLoading: false,
  entities: [] as IStatistical[],
  reality: 0,
  outstandingLoan: 0,
  expiredCount: 0,
  uncollected: 0
};

export type StatisticalState = Readonly<typeof initialState>;

export default (state: StatisticalState = initialState, action): StatisticalState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_BY_DATE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_BY_HOUSE_LIST):
      return {
        ...state,
        loading: true
      };
    case REQUEST(ACTION_TYPES.FETCH_TOTAL):
      return {
        ...state,
        totalLoading: true
      };
    case FAILURE(ACTION_TYPES.FETCH_BY_DATE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_BY_HOUSE_LIST):
      return {
        ...state,
        loading: false
      };
    case FAILURE(ACTION_TYPES.FETCH_TOTAL):
      return {
        ...state,
        totalLoading: false
      };
    case SUCCESS(ACTION_TYPES.FETCH_BY_DATE_LIST):
    case SUCCESS(ACTION_TYPES.FETCH_BY_HOUSE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_TOTAL):
      return {
        ...state,
        totalLoading: false,
        outstandingLoan: action.payload.data.outstandingLoan,
        reality: action.payload.data.reality,
        uncollected: action.payload.data.uncollected
      };
    case SUCCESS(ACTION_TYPES.FETCH_EXPIRED_COUNT):
      return {
        ...state,
        expiredCount: action.payload.data
      };
    default:
      return state;
  }
};

const apiUrl = 'api/order-statistical'; // 财务报表
// 财务报表 type=house(按房源统计)|cacheBuster(按日期统计)
export const search = (searchFrom: ISearchForm, type: string) => ({
  type: ACTION_TYPES.FETCH_BY_HOUSE_LIST,
  payload: axios.get<IStatistical[]>(`${apiUrl}/${type}`, {
    params: searchFrom,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
  })
});

export const byHouse = (searchFrom: ISearchForm) => search(searchFrom, 'house');

export const byDate = (searchFrom: ISearchForm) => search(searchFrom, 'date');
// 财务报表全部数据
export const getTotal = month => ({
  type: ACTION_TYPES.FETCH_TOTAL,
  payload: axios.get(`${apiUrl}/total`, {
    params: { month }
  })
});

export const getExpiredCount = () => ({
  type: ACTION_TYPES.FETCH_EXPIRED_COUNT,
  payload: axios.get(`${apiUrl}/expired-count`)
});

import axios from 'axios';
import * as qs from 'qs';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { defaultValue, ITemporary } from 'app/shared/model/parking-temporary.model';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_RENEWAL_LIST: 'renewal/FETCH_RENEWAL_LIST'
};
// state
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITemporary>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type parkingTemporaryState = Readonly<typeof initialState>;

// Reducer
export default (state: parkingTemporaryState = initialState, action): parkingTemporaryState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_RENEWAL_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case FAILURE(ACTION_TYPES.FETCH_RENEWAL_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_RENEWAL_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    default:
      return state;
  }
};

const apiUrl = 'api/jie-shun/precords'; // 临时车位
const apiUrlCount = 'api/jie-shun/precordsCount'; // 临时车位统计
// Actions
// 临时车位列表
export const getEntities: ICrudGetListAction<ITemporary> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiUrl}?page=${page}&size=${size}`;
  return {
    type: ACTION_TYPES.FETCH_RENEWAL_LIST,
    payload: axios.get<ITemporary>(requestUrl, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params)
    }),
    meta: { transformUserName: true }
  };
};

export const getEntitiesCunt = searchForm =>
  axios.get<{ total: number }>(`${apiUrlCount}`, {
    params: flattenKey(searchForm),
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
  });

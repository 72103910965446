import styles from '../../home.less';

import React from 'react';
import { Row, Col, Select, Statistic } from 'antd';
import * as qs from 'qs';
import axios from 'axios';
import { flattenKey } from 'app/shared/util/object-utils';
import rentRateIcon from '../static/image/rent-rate-icon.png';
import rentRate1 from '../static/image/rent-rate-1.png';
import rentRate2 from '../static/image/rent-rate-2.png';
import rentRate3 from '../static/image/rent-rate-3.png';
import { isMobile } from 'app/shared/util/screen-utils';
interface IRentRateProp {
  houseId: number;
}

interface IRentRateState {
  data: any;
  type: string;
  house: number;
}

export default class RentRate extends React.Component<IRentRateProp, IRentRateState> {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      type: 'house-rental-ratio',
      house: null
    };
  }

  componentDidMount(): void {
    this.getData(null);
  }

  shouldComponentUpdate(nextProps: Readonly<IRentRateProp>, nextState: Readonly<IRentRateState>, nextContext: any): boolean {
    if (nextProps.houseId !== this.props.houseId) {
      this.setState({
        house: nextProps.houseId
      });
      this.getData(nextProps.houseId);
    }

    return true;
  }

  getData(houseId) {
    const { type } = this.state;
    axios
      .get(`/api/statistics/${type}`, {
        params: flattenKey({ houseId }),
        paramsSerializer: params => qs.stringify(params)
      })
      .then(({ data }) => {
        this.setState({ data });
      });
  }

  renderIcon(title) {
    return (
      <div>
        <i>
          <img src={rentRateIcon} width={24} />
        </i>
        <span className={styles.icontitle}>{title}</span>
      </div>
    );
  }

  typeChange = value => {
    this.setState(
      {
        type: value
      },
      () => {
        this.getData(this.state.house);
      }
    );
  };

  renderTitle() {
    // '房产', '车位'
    const list = [{ label: '门店', value: 'house-rental-ratio' }, { label: '车位', value: 'parking-lot-rental-ratio' }];
    return (
      <Row className={`${styles.rentTitle} ${styles.titleBorder}`}>
        <Col span={15}>{this.renderIcon('出租率')}</Col>
        <Col span={6} className={styles.sborder}>
          <Select style={{ width: 80, height: 24 }} onChange={this.typeChange} defaultValue={this.state.type}>
            {list.map(item => (
              <Select.Option value={item.value} key={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    );
  }

  rederContain() {
    const { data, type } = this.state;
    const pre = type === 'house-rental-ratio' ? '' : 'p';

    return (
      <Row type="flex" className={styles.rateContent}>
        <Col span={18}>
          <img src={rentRate1} width={'100%'} />
        </Col>
        <Col span={6} className={styles.rateContetn}>
          出租率
          <div className={styles.rate}>
            {data[`${pre + 'rate'}`]}
            <span className={styles.rateSymbol}>%</span>
          </div>
        </Col>
        <Col span={18} className={`${styles.rateImg}`}>
          <img src={rentRate2} width={'100%'} />
        </Col>
        <Col span={6} className={`${styles.rateContetn} ${styles.rateImg}`}>
          续租率
          <div className={styles.rate}>
            {data[`${pre + 'renewalRate'}`]}
            <span className={styles.rateSymbol}>%</span>
          </div>
        </Col>
        <Col span={18}>
          <img src={rentRate3} width={'100%'} />
        </Col>
        <Col span={6} className={styles.rateContetn}>
          退租率
          <div className={styles.rate}>
            {data[`${pre + 'refundRate'}`]}
            <span className={styles.rateSymbol}>%</span>
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <div className={`${styles.rent} ${!isMobile() ? styles.ml16 : styles.mt16}`}>
        {this.renderTitle()}
        {this.rederContain()}
      </div>
    );
  }
}

import React from 'react';
import { Switch } from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import BackendLayout from 'app/shared/layout/backend';
import UserLayout from 'app/shared/layout/user';
import PrivateRoute from 'app/shared/auth/private-route';
import ContractPrint from 'app/entities/contract/contract-print';
import ContractDepositPrint from 'app/entities/contract/contract-depoist-print';
import ParkingContractPrint from 'app/entities/parking-contract/contract-print';
import ParkingContractDepositPrint from 'app/entities/parking-contract/contract-depoist-print';
import TContractPrint from 'app/entities/t-contract/t-contract-print';
import TContractDepositPrint from 'app/entities/t-contract/t-contract-depoist-print';

const Routes = () => (
  <>
    <Switch>
      <ErrorBoundaryRoute path="/user" component={UserLayout} />
      <PrivateRoute path={`/rental/contract/contract/:id/print`} exact component={ContractPrint} hasAnyAuthorities={['Contract#read']} />
      <PrivateRoute
        path={`/rental/contract/contract/:id/deposit`}
        exact
        component={ContractDepositPrint}
        hasAnyAuthorities={['Contract#read']}
      />

      <PrivateRoute
        path={`/rental/contract/t-contract/:id/print`}
        exact
        component={TContractPrint}
        hasAnyAuthorities={['Tcontract#read']}
      />
      <PrivateRoute
        path={`/rental/contract/t-contract/:id/deposit`}
        exact
        component={TContractDepositPrint}
        hasAnyAuthorities={['Tcontract#read']}
      />

      <PrivateRoute
        path={`/parking/parking-contract/:id/deposit`}
        exact
        component={ParkingContractDepositPrint}
        hasAnyAuthorities={['Contract#read']}
      />

      <PrivateRoute
        path={`/parking/parking-contract/:id/print`}
        exact
        component={ParkingContractPrint}
        hasAnyAuthorities={['Tcontract#read']}
      />
      <ErrorBoundaryRoute path="/" component={BackendLayout} />
    </Switch>
  </>
);

export default Routes;

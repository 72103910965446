import styles from './style.less';
import React from 'react';
import { List, Table } from 'antd';
import { TableProps } from 'antd/lib/table/interface';
import { RouteContext } from '@ant-design/pro-layout';
import SelectionList, { ISelectionListProps } from 'app/shared/components/selection-list';
import { translate } from 'react-jhipster';

export interface IResponsiveListProps<T> {
  tableProps?: TableProps<T>;
  listProps?: ISelectionListProps<T>;
  hideBlock?: boolean;
}

export const showTotal = total => translate('global.showTotal', { total });
export default class ResponsiveList<T> extends React.Component<IResponsiveListProps<T>> {
  renderContent(isMobile) {
    const { tableProps, listProps, hideBlock } = this.props;
    if (isMobile) {
      return (
        <div>
          {!hideBlock && <div className={styles.block} />}
          <SelectionList {...listProps} />
        </div>
      );
    }
    const { pagination } = tableProps;
    if (pagination && pagination['total']) {
      if (!pagination['showTotal']) {
        pagination['showTotal'] = showTotal;
      }
    }
    return <Table {...tableProps} key={'responsiveList'} />;
  }
  render() {
    return <RouteContext.Consumer>{({ isMobile }) => this.renderContent(isMobile)}</RouteContext.Consumer>;
  }
}

export const enum Provider {
  WECHAT = 'WECHAT',
  QQ = 'QQ'
}

export interface IAuthorization {
  id?: number;
  appId?: string;
  provider?: Provider;
  info?: string;
  userId?: number;
  thirdPartyConfigId?: number;
}

export const defaultValue: Readonly<IAuthorization> = {};

import axios from 'axios';
import styles from './deposit-print.less';
import { Moment } from 'moment';
import * as numberConverter from 'number-to-chinese-words';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Skeleton, message } from 'antd';
import printJs from 'print-js';
import logo from './logo.png';
import { PaymentMethodType } from 'app/shared/model/payment.model';
import { formatDateDay } from 'app/shared/util/date-utils';
import orgMark from './org_mark.png';
// 数字大写
numberConverter.default.labels = {
  ...numberConverter.default.labels,
  digits: ['零', '壹', '貳', '參', '肆', '伍', '陸', '柒', '捌', '玖'],
  units: ['', '拾', '佰', '仟', '萬', '拾', '佰', '仟', '億', '拾', '佰', '仟', '兆', '拾', '佰', '仟', '京', '拾', '佰', '仟', '垓']
};
// 付款方式
const paymentTypes = {
  [PaymentMethodType.ALIPAY]: '支付宝',
  [PaymentMethodType.WECHAT]: '微信',
  [PaymentMethodType.UNIONPAY]: '云闪付',
  [PaymentMethodType.OFFONLE]: '线下支付'
};

window.console.log(paymentTypes);
// state定义
export interface IContractPrintStates {
  loading?: boolean;
  errorMsg?: string;
  entity?: {
    houseName: string;
    deposit: number;
    roomName: string;
    paymentNumber: string;
    paymentAt: Moment;
    type: PaymentMethodType;
    total: number;
    operator: string;
    username: string;
  };
}

export default class TContractPrint extends React.Component<RouteComponentProps<{ id: string }>, IContractPrintStates> {
  state: IContractPrintStates = {
    loading: false,
    errorMsg: null,
    entity: {
      houseName: '',
      deposit: 0,
      roomName: '',
      paymentNumber: '',
      paymentAt: null,
      type: null,
      username: '',
      total: 0,
      operator: ''
    }
  };
  // 打印
  componentDidMount() {
    this.setState({ loading: true });
    // 打印押金凭条
    axios
      .get(`/api/contracts/${this.props.match.params.id}/deposit`)
      .then(({ data }) => {
        this.setState({ entity: data, loading: false });
      })
      .catch(() => {
        const errorMsg = '没有交纳押金';
        this.setState({
          errorMsg,
          loading: false
        });
        message.error(errorMsg);
      });
  }

  handlePrint = () => {
    printJs({ printable: 'print-page', type: 'html', css: '/content/css/deposit-print.css', maxWidth: 1391 });
  };

  render() {
    const { loading, errorMsg, entity } = this.state;
    if (loading) {
      return <Skeleton active />;
    }
    if (errorMsg !== null) {
      return (
        <div style={{ textAlign: 'center', color: '#3b3b3b', padding: '20px' }}>
          <h1>{errorMsg}</h1>
        </div>
      );
    }
    return (
      <div className={styles.print}>
        <div className={'tool-bar fixed'}>
          <div />
          <div className={'center'} />
          <div>
            <Button onClick={this.handlePrint} className={'print-btn'}>
              打印
            </Button>
          </div>
        </div>
        <div className={'tool-bar op0'}>
          <Button>打印</Button>
        </div>
        <div className={`${styles.page} page`} id={'print-page'}>
          <div className={'title'}>
            <div className={'org'}>
              <div className={'logo'}>
                <img src={logo} width={40} />
              </div>
              <div>
                <div>收款公司：</div>
                <div>佛山市建鑫住房租赁有限公司</div>
              </div>
            </div>
            <div className={'center'}>收款收据</div>
            <div className={'latest'}>
              <div>No.{entity.paymentNumber}</div>
              <div>收款日期：{formatDateDay(entity.paymentAt)}</div>
            </div>
          </div>
          <div className={'table-container'}>
            <div className={'title table-title'}>
              <div className={'org'}>门店：{entity.houseName}</div>
              <div className={'center'}>房号：{entity.roomName}</div>
              <div className={'latest'}>客户名称：{entity.username}</div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>收款内容</th>
                  <th>费用期间</th>
                  <th>金额（小写）</th>
                  <th>金额（大写）</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>押金</td>
                  <td>固定金额{entity.deposit}元</td>
                  <td>￥&nbsp;{entity.deposit}</td>
                  <td>人民币&nbsp;{numberConverter.toWords(entity.deposit)}元</td>
                </tr>
                <tr>
                  <td>合计</td>
                  <td />
                  <td>￥&nbsp;{entity.deposit}</td>
                  <td>人民币&nbsp;{numberConverter.toWords(entity.deposit)}元</td>
                </tr>
              </tbody>
            </table>
            <div className={'title table-title'}>
              <div className={'org'}>收款方式：{paymentTypes[entity.type]}</div>
              <div className={'center'}>
                <div>打印次数：{(entity.total || 0) + 1}</div>
                <div>经办人：{entity.operator}</div>
              </div>
              <div className={'latest org'}>
                收款人：佛山市建鑫住房租赁有限公司 <img src={orgMark} width={150} alt={'佛山市建鑫住房租赁有限公司电子章'} />{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import styles from './split-text.less';
import React from 'react';

const SplitText = ({ values = [] }) => (
  <ul className={styles.splitContainer}>
    {values
      .filter(v => v && v.length > 0)
      .map(v => (
        <li className={styles.item}>{v}</li>
      ))}
  </ul>
);
export default SplitText;

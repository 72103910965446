import { Moment } from 'moment';
import { IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';

export interface IBlackList {
  id?: string;
  login?: string;
  mobile?: string;
  name?: string;
  description?: string;
  createdBy?: string;
  createdAt?: Moment;
  userId?: string;
}

export interface ISearchForm {
  id?: IStringFilter;
  description?: IStringFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
  userId?: IRangeFilter;
}

export const defaultValue: Readonly<IBlackList> = {};

export const defaultSearchForm: Readonly<ISearchForm> = {
  id: {
    contains: ''
  },
  description: {
    contains: ''
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  userId: {
    equals: ''
  }
};

export const enum CalcExpense {
  FIXED = 'FIXED',
  NATURE = 'NATURE'
}

export interface ISetting {
  id?: number;
  calcExpense?: CalcExpense;
  roundEnable?: boolean;
  generateOrderDayBefore?: number;
  taxid?: string; // 公司税号
}

export const defaultValue: Readonly<ISetting> = {
  roundEnable: false,
  generateOrderDayBefore: 10
};

import axios from 'axios';
import * as qs from 'qs';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { defaultValue, IparkingPay } from 'app/shared/model/parking-pay.model';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';

// 请求
export const ACTION_TYPES = {
  FETCH_PAY_LIST: 'renewal/FETCH_PAY_LIST'
};
// state
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IparkingPay>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type parkingPayState = Readonly<typeof initialState>;

// Reducer
export default (state: parkingPayState = initialState, action): parkingPayState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PAY_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case FAILURE(ACTION_TYPES.FETCH_PAY_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_PAY_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    default:
      return state;
  }
};

const apiUrl = 'api/jie-shun/serviceOperators'; // 月度车位账单
// Actions
// 月度车位账单
export const getEntities: ICrudGetListAction<IparkingPay> = (page, size, sort, searchForm) => {
  const requestUrl = `${apiUrl}?page=${page}&size=${size}`;
  return {
    type: ACTION_TYPES.FETCH_PAY_LIST,
    payload: axios.get<IparkingPay>(requestUrl, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params)
    }),
    meta: { transformUserName: true }
  };
};

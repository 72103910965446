// @ts-nocheck
import axios from 'axios';
import * as qs from 'qs';
import { ICrudGetAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { message } from 'antd';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IOrders, defaultValue } from 'app/shared/model/orders.model';
import { ICrudGetListAction } from 'app/shared/model/list';
import { handleFee } from './components/order-common';

// 请求
export const ACTION_TYPES = {
  FETCH_ORDERS_LIST: 'orders/FETCH_ORDERS_LIST',
  LOAD_ORDERS_LIST: 'orders/LOAD_ORDERS_LIST',
  FETCH_ORDERS: 'orders/FETCH_ORDERS',
  FETCH_TORDERS: 'orders/FETCH_TORDERS',
  REMIND_ORDER: 'orders/REMIND_ORDER',
  REMIND_ORDERS: 'orders/REMIND_ORDERS',
  CREATE_ORDERS: 'orders/CREATE_ORDERS',
  UPDATE_ORDERS: 'orders/UPDATE_ORDERS',
  CONFIRM_ORDERS: 'orders/CONFIRM_ORDERS',
  CONFIRM_CHECK_ORDERS: 'orders/CONFIRM_CHECK_ORDERS',
  LOCK_BELATED_TYPE: 'orders/LOCK_BELATED_TYPE',
  UNLOCK_BELATED_TYPE: 'orders/UNLOCK_BELATED_TYPE',
  CONFIRM_ROLL_BACK: 'orders/CONFIRM_ROLL_BACK',
  DELETE_ORDERS: 'orders/DELETE_ORDERS',
  BATCH_CONFIRM_ORDER: 'orders/BATCH_CONFIRM_ORDER',
  BATCH_CONFIRMS_ORDER: 'orders/BATCH_CONFIRMS_ORDER',
  BATCH_CHANGE_CHARGES: 'orders/BATCH_CHANGE_CHARGES',
  RESET: 'orders/RESET'
};

import { flattenKey } from 'app/shared/util/object-utils';
import React, { Children } from 'react';
// state
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IOrders>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type OrdersState = Readonly<typeof initialState>;

// Reducer
export default (state: OrdersState = initialState, action): OrdersState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.BATCH_CONFIRM_ORDER):
    case REQUEST(ACTION_TYPES.BATCH_CONFIRMS_ORDER):
    case REQUEST(ACTION_TYPES.CONFIRM_ORDERS):
    case REQUEST(ACTION_TYPES.CONFIRM_CHECK_ORDERS):
    case REQUEST(ACTION_TYPES.LOCK_BELATED_TYPE):
    case REQUEST(ACTION_TYPES.UNLOCK_BELATED_TYPE):
    case REQUEST(ACTION_TYPES.BATCH_CHANGE_CHARGES):
    case REQUEST(ACTION_TYPES.CONFIRM_ROLL_BACK):
    case REQUEST(ACTION_TYPES.FETCH_ORDERS_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_ORDERS_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_ORDERS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.FETCH_TORDERS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_ORDERS):
    case REQUEST(ACTION_TYPES.UPDATE_ORDERS):
    case REQUEST(ACTION_TYPES.DELETE_ORDERS):
    case REQUEST(ACTION_TYPES.REMIND_ORDER):
    case REQUEST(ACTION_TYPES.REMIND_ORDERS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_ORDERS_LIST):
    case FAILURE(ACTION_TYPES.BATCH_CONFIRM_ORDER):
    case FAILURE(ACTION_TYPES.BATCH_CONFIRMS_ORDER):
    case FAILURE(ACTION_TYPES.LOAD_ORDERS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ORDERS):
    case FAILURE(ACTION_TYPES.FETCH_TORDERS):
    case FAILURE(ACTION_TYPES.CONFIRM_ORDERS):
    case FAILURE(ACTION_TYPES.CONFIRM_CHECK_ORDERS):
    case FAILURE(ACTION_TYPES.LOCK_BELATED_TYPE):
    case FAILURE(ACTION_TYPES.UNLOCK_BELATED_TYPE):
    case FAILURE(ACTION_TYPES.BATCH_CHANGE_CHARGES):
    case FAILURE(ACTION_TYPES.CONFIRM_ROLL_BACK):
    case FAILURE(ACTION_TYPES.CREATE_ORDERS):
    case FAILURE(ACTION_TYPES.UPDATE_ORDERS):
    case FAILURE(ACTION_TYPES.DELETE_ORDERS):
    case FAILURE(ACTION_TYPES.REMIND_ORDER):
    case FAILURE(ACTION_TYPES.REMIND_ORDERS):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    // 账单管理 table查询
    case SUCCESS(ACTION_TYPES.FETCH_ORDERS_LIST):
      // @ts-ignore
      const data = action.payload.data && action.payload.data.length > 0 ? [...action.payload.data] : [];
      data.forEach((item, index) => {
        if (item.category === 'TCONTRACT_ORDERS' || item.category === 'MCONTRACT_ORDERS') {
          item.key = `id-${item.id}`;
          // 收费项设置
          const orderItems = [];
          // orderItems.push({ name: '总月租', amount: item.totalActualSingleMonthPrice });
          orderItems.push({ name: '总房租', amount: item.totalRental });
          orderItems.push({ name: '总押金', amount: item.totalDeposit });

          // 团租
          if (item.miniOrders && item.miniOrders.length > 0) {
            // item.children = [...item.miniOrders];
            // @ts-ignore
            item.children = item.miniOrders.map(i => {
              const { username } = item;
              return {
                // @ts-ignore
                ...i,
                username
              };
            });

            // 商业账号收费项显示所有收费项
            const orderChildItems = item.category === 'MCONTRACT_ORDERS' ? handleFee(item.miniOrders) : [];

            // @ts-ignore
            item.orderItems = [...orderItems, ...orderChildItems, ...item.orderItems];
          }
        }
      });

      return {
        ...state,
        loading: false,
        fetching: false,
        entities: data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_ORDERS_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_ORDERS):
      // 团租账单
      // @ts-ignore
      const data: Readonly<IOrders> = { ...action.payload.data };
      // @ts-ignore
      const { roomName, category, miniOrders } = data;
      // @ts-ignore
      if (category === 'TCONTRACT_ORDERS' || category === 'MCONTRACT_ORDERS') {
        if (miniOrders && miniOrders.length > 0) {
          // @ts-ignore
          data.tcontractItemId = miniOrders[0]['tcontractItemId'];
          // @ts-ignore
          data.roomName = roomName && roomName.indexOf('<br/>') > -1 ? roomName.split('<br/>').join('，') : roomName;
        }
      }
      // @ts-ignore
      return {
        ...state,
        loading: false,
        // @ts-ignore
        entity: data
      };
    case SUCCESS(ACTION_TYPES.CREATE_ORDERS):
    case SUCCESS(ACTION_TYPES.UPDATE_ORDERS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.REMIND_ORDER):
    case SUCCESS(ACTION_TYPES.REMIND_ORDERS):
      const html = { __html: action.payload.data ? action.payload.data : '' };
      message.success({
        content: React.createElement('div', {
          dangerouslySetInnerHTML: html
        })
      });
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case SUCCESS(ACTION_TYPES.DELETE_ORDERS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case SUCCESS(ACTION_TYPES.BATCH_CONFIRM_ORDER):
      message.success('批量确认账单操作成功');
      return state;
    case SUCCESS(ACTION_TYPES.BATCH_CONFIRMS_ORDER):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CONFIRM_ORDERS):
      // message.success('确认账单操作成功', 2);
      return {
        ...state,
        loading: false
      };
    case SUCCESS(ACTION_TYPES.CONFIRM_CHECK_ORDERS):
      // message.success('', 2);
      return {
        ...state,
        loading: false
        // entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.LOCK_BELATED_TYPE):
      message.success('锁定违约金操作成功');
      return state;
    case SUCCESS(ACTION_TYPES.UNLOCK_BELATED_TYPE):
      message.success('解除锁定违约金操作成功');
      return state;
    case SUCCESS(ACTION_TYPES.BATCH_CHANGE_CHARGES):
      message.success('批量调整费项操作成功');
      return state;
    case SUCCESS(ACTION_TYPES.CONFIRM_ROLL_BACK):
      message.success('撤销回退操作成功');
      return state;
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/orders'; // 账务管理-账单管理 表单查询
const payAprUrl = 'f-api/payments/qrpay'; // 账单-支付-二维码
// Actions
// 账单管理详情
export const getEntities: ICrudGetListAction<IOrders[]> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${
    sort ? `?page=${page}&size=${size}&sort=${sort}${sort.includes('roomName,') ? sort.replace('roomName,', '&roomCodeSort=') : ''}` : ''
  }`;
  return {
    type: ACTION_TYPES.FETCH_ORDERS_LIST,
    payload: axios.get<IOrders[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const loadEntities: ICrudGetListAction<IOrders[]> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.LOAD_ORDERS_LIST,
    payload: axios.get<IOrders[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const batchConfirm = (ids: any[]) => async dispatch => {
  const requestUrl = `${apiUrl}/confirms`;
  const result = await dispatch({
    type: ACTION_TYPES.BATCH_CONFIRM_ORDER,
    payload: axios.put<IOrders>(requestUrl, { ids }, { paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' }) })
  });
  // dispatch(getEntities());
  return result;
};
// 批量确认
export const checkConfirms = (ids: any[]) => async dispatch => {
  const requestUrl = `${apiUrl}/checkConfirms`;
  const result = await dispatch({
    type: ACTION_TYPES.BATCH_CONFIRMS_ORDER,
    payload: axios.post<IOrders>(requestUrl, { ids }, { paramsSerializer: params => qs.stringify(params) })
  });
  // dispatch(getEntities());
  return result;
};

export const lockBelatedType = (id, data) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/lock-belated`;
  const result = await dispatch({
    type: ACTION_TYPES.LOCK_BELATED_TYPE,
    payload: axios.put(requestUrl, data)
  });
  // dispatch(getEntities());
  return result;
};

export const unlockBelatedType = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/unlock-belated`;
  const result = await dispatch({
    type: ACTION_TYPES.UNLOCK_BELATED_TYPE,
    payload: axios.put(requestUrl, {})
  });
  // dispatch(getEntities());
  return result;
};

export const batchChangeCharges = data => async dispatch => {
  const requestUrl = `${apiUrl}/adjust-order-items-amount`;
  const result = await dispatch({
    type: ACTION_TYPES.BATCH_CHANGE_CHARGES,
    payload: axios.put<IOrders>(requestUrl, data, { paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' }) })
  });

  // dispatch(getEntities());
  return result;
};

export const getEntity: ICrudGetAction<IOrders> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ORDERS,
    payload: axios.get<IOrders>(requestUrl)
  };
};

export const getTEntity: ICrudGetAction<IOrders> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_TORDERS,
    payload: axios.get<IOrders>(requestUrl)
  };
};

export const confirm = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/confirm`;
  const result = await dispatch({
    type: ACTION_TYPES.CONFIRM_ORDERS,
    payload: axios.put<IOrders>(requestUrl)
  });
  // dispatch(getEntities());
  return result;
};

// 普通
export const checkConfirm = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/checkConfirm`;
  const result = await dispatch({
    type: ACTION_TYPES.CONFIRM_CHECK_ORDERS,
    payload: axios.get<IOrders>(requestUrl)
  });
  // dispatch(getEntities());
  return result;
};

export const confirmRollBack = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/confirm-roll-back   `;
  const result = await dispatch({
    type: ACTION_TYPES.CONFIRM_ROLL_BACK,
    payload: axios.put<IOrders>(requestUrl)
  });
  // dispatch(getEntities());
  return result;
};

export const transferred = (id, paymentMethodId, type, transferredRemark) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/transferred`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ORDERS,
    payload: axios.put<IOrders>(requestUrl, null, { params: { paymentMethodId, type, transferredRemark } })
  });
  // dispatch(getEntities());
  return result;
};

export const createEntity: ICrudPutAction<IOrders> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ORDERS,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IOrders> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ORDERS,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};

export const remind = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.REMIND_ORDER,
    payload: axios.put(`/api/orders/${id}/remind`)
  });
  return result;
};

export const reminds = ids => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.REMIND_ORDERS,
    payload: axios.put(`/api/orders/reminds`, { ids })
  });
  // dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IOrders> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ORDERS,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const loadTotal = searchForm =>
  axios.get<{ total: number; trulyTotal: number; orderItemsTotalMap: any }>(`${apiUrl}/total`, {
    params: flattenKey(searchForm),
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
  });

import { Moment } from 'moment';
import { IFilter, IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';

export interface IAds {
  id?: number;
  imageUrl?: string;
  name?: string;
  title?: string;
  description?: string;
  link?: string;
  createdAt?: Moment;
  createdBy?: string;
  updatedBy?: string;
  updatedAt?: Moment;
  houseRoomId?: number;
  type?: string;
  advertisingId?: number;
}

export const defaultType = 'HOUSE_ROOM';
export const defaultValue: Readonly<IAds> = {
  link: '',
  type: defaultType,
  description: '',
  name: '',
  title: ''
};

export interface ISearchForm {
  code?: IStringFilter;
  advertisingId?: IFilter;
  title?: IStringFilter;
  createdAt?: IRangeFilter;
}

export const defaultSearchForm = {
  code: {
    in: []
  },
  advertisingId: {
    in: []
  },
  title: {
    equals: ''
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  }
};

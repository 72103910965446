import axios from 'axios';
import { ICrudGetAction, ICrudPutAction, ICrudDeleteAction, IPayload } from 'react-jhipster';
import * as qs from 'qs';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ILifePrice, defaultValue, defaultSearchForm } from 'app/shared/model/life-price.model';
import { flattenKey } from 'app/shared/util/object-utils';
import { pickBy, identity } from 'lodash';

export declare type ICrudGetAllAction<T> = (page?: number, size?: number, sort?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);
// 请求
export const ACTION_TYPES = {
  FETCH_LIFEPRICE_LIST: 'lifePrice/FETCH_LIFEPRICE_LIST',
  LOAD_LIFEPRICE_LIST: 'lifePrice/LOAD_LIFEPRICE_LIST',
  FETCH_LIFEPRICE: 'lifePrice/FETCH_LIFEPRICE',
  CREATE_LIFEPRICE: 'lifePrice/CREATE_LIFEPRICE',
  UPDATE_LIFEPRICE: 'lifePrice/UPDATE_LIFEPRICE',
  DELETE_LIFEPRICE: 'lifePrice/DELETE_LIFEPRICE',
  RESET: 'lifePrice/RESET'
};
// state
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ILifePrice>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  total: 0,
  searchData: {
    page: 0,
    size: 20,
    sort: 'createdAt,desc',
    searchForm: defaultSearchForm
  }
};

export type LifePriceState = Readonly<typeof initialState>;

// Reducer

export default (state: LifePriceState = initialState, action): LifePriceState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_LIFEPRICE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_LIFEPRICE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_LIFEPRICE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_LIFEPRICE):
    case REQUEST(ACTION_TYPES.UPDATE_LIFEPRICE):
    case REQUEST(ACTION_TYPES.DELETE_LIFEPRICE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_LIFEPRICE_LIST):
    case FAILURE(ACTION_TYPES.LOAD_LIFEPRICE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_LIFEPRICE):
    case FAILURE(ACTION_TYPES.CREATE_LIFEPRICE):
    case FAILURE(ACTION_TYPES.UPDATE_LIFEPRICE):
    case FAILURE(ACTION_TYPES.DELETE_LIFEPRICE):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_LIFEPRICE_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        total: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_LIFEPRICE_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        total: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_LIFEPRICE):
      return {
        ...state,
        loading: false,
        entity: { ...action.payload.data }
      };
    case SUCCESS(ACTION_TYPES.CREATE_LIFEPRICE):
    case SUCCESS(ACTION_TYPES.UPDATE_LIFEPRICE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_LIFEPRICE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/life-prices'; // 集中抄表

// Actions

export const getEntities = (page, size, sort, searchForm) => ({
  type: ACTION_TYPES.FETCH_LIFEPRICE_LIST,
  payload: axios.get<ILifePrice>(`${apiUrl}?cacheBuster=${new Date().getTime()}`, {
    params: flattenKey(searchForm),
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
  })
});

export const loadEntities = (page, size, sort, searchForm) => ({
  type: ACTION_TYPES.LOAD_LIFEPRICE_LIST,
  payload: axios.get<ILifePrice>(`${apiUrl}?cacheBuster=${new Date().getTime()}`, {
    params: flattenKey(searchForm),
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
  })
});

export const getEntity: ICrudGetAction<ILifePrice> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_LIFEPRICE,
    payload: axios.get<ILifePrice>(requestUrl)
  };
};
// 创建集中抄表
export const createEntity = entity => async (dispatch, getState) => {
  const {
    lifePrice: { searchData }
  } = getState();
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_LIFEPRICE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities(searchData.page, searchData.size, searchData.sort, searchData.searchForm));
  return result;
};

export const getLatest = (roomId, id, type) =>
  axios.get(`${apiUrl}/${roomId}/latest`, {
    params: flattenKey(pickBy({ id, type }, identity))
  });
// 更新集中抄表
export const updateEntity = entity => async (dispatch, getState) => {
  const {
    lifePrice: { searchData }
  } = getState();
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_LIFEPRICE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities(searchData.page, searchData.size, searchData.sort, searchData.searchForm));
  return result;
};
// 删除集中抄表
export const deleteEntity = id => async (dispatch, getState) => {
  const {
    lifePrice: { searchData }
  } = getState();
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_LIFEPRICE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities(searchData.page, searchData.size, searchData.sort, searchData.searchForm));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

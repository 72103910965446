/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Modal } from 'antd';
import { translate } from 'react-jhipster';

import { clearRole, deleteRole, getRole } from './role-management.reducer';
import { IRootState } from 'app/shared/reducers';
import { IRole } from 'app/shared/model/role.model';
import { deleteToList } from 'app/shared/util/url-utils';

export interface IRoleManagementDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: any }> {}

export class RoleManagementDeleteDialog extends React.Component<IRoleManagementDeleteDialogProps> {
  componentDidMount() {
    const promise: Promise<IRole> = this.props.getRole(this.props.match.params.id) as any;
    promise.then(() => {
      const { history, match, role } = this.props;
      Modal.confirm({
        title: translate('entity.delete.title'),
        content: translate('roles.delete.question', { name: role.name }),
        okText: translate('entity.action.delete'),
        okType: 'danger',
        cancelText: translate('entity.action.cancel'),
        onOk: () => {
          return this.confirmDelete();
        },
        onCancel: () => {
          this.handleClose();
        }
      });
    });
  }

  confirmDelete = () => {
    const promise: Promise<any> = this.props.deleteRole(this.props.role.id) as any;
    promise.then(() => {
      this.handleClose();
    });
    return promise;
  };

  handleClose = () => {
    const { history, match } = this.props;
    history.replace(deleteToList(match.url));
    this.props.clearRole();
  };

  render() {
    return <></>;
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  role: storeState.roleManagement.role
});

const mapDispatchToProps = { getRole, deleteRole, clearRole };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleManagementDeleteDialog);

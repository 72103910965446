const config = {
  VERSION: process.env.VERSION
};

export default config;

export const SERVER_API_URL = process.env.SERVER_API_URL;

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  SYSTEM: 'ROLE_SYSTEM',
  ANONYMOUS: 'ROLE_ANONYMOUS'
};

export const USER = {
  ADMIN: 'admin',
  SYSTEM: 'system',
  ANONYMOUSUSER: 'anonymoususer'
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error'
};

export const ORDER_ALIAS = {
  desc: 'descend',
  asc: 'ascend'
};
// promise状态
export const PROMISE_PENDING = 'PENDING';
export const PROMISE_FULFILLED = 'FULFILLED';
export const PROMISE_REJECTED = 'REJECTED';
export const PROMISE_DELIMITER = '_';

// 时间格式
export const APP_LOCAL_MONTH_FORMAT = 'YYYY-MM';
export const APP_DATE_FORMAT = 'YYYY-MM-DD HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'YYYY-MM-DD';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
export const APP_LOCAL_DATETIME_T_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const APP_DATETIME_T_FORMAT = 'YYYY-MM-DDT00:00:00.000[Z]';
export const APP_DATETIME_T_FORMAT_Z = 'YYYY-MM-DDT23:59:59.999[Z]';
export const NOTICE_TYPE_ARRAY = ['', 'NOTICE', 'EVENT', 'NEWS', 'OTHER'];
export const NOTICE_STATUS_ARRAY = ['', 'FINISHED', 'WAITING'];
export const HOUSE_TYPE = ['APARTMENT', 'COMMERCIAL', 'FULL_RENT', 'SHARED_RENT'];
export const LIVING_STATE = ['OUTED', 'LIVING'];

// 这是token的统一key配置
export const REFERSH_NUM = 'jhi-regresh-num';
export const IS_REFRESH = 'jhi-is-refresh';
export const SAVE_LOCAL = 'jhi-saveLocal';
export const AUTH_TOKEN_KEY = 'jhi-authenticationToken';
export const AUTH_TOKEN_OVERTIME_KEY = 'jhi-authenticationToken-overtime';
export const AUTH_REFRESH_TOKEN_KEY = 'jhi-authenticationRefreshToken';
export const AUTH_REFRESH_TOKEN_OVERTIME_KEY = 'jhi-authenticationRefreshToken-overtime';
export const REQUEST_TIMES_KEY = 'REQUEST_TIMES';
export const API_LAST_TIME_KEY = 'API_LAST_TIME';
export const API_LAST_TIME_INTERVAL = 1800;

export const INIT_TODO_TIME = 'JHI_INIT_TODO_TIME';
// 10秒的时间间隔
export const INIT_TODO_TIME_INTERVAL_SECONDS = 10;

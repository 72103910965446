import axios from 'axios';
import * as qs from 'qs';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, ILiveInfo } from 'app/shared/model/live-info.model';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';
// 请求
export const ACTION_TYPES = {
  FETCH_LIVEINFO_LIST: 'liveInfo/FETCH_LIVEINFO_LIST',
  LOAD_LIVEINFO_LIST: 'liveInfo/LOAD_LIVEINFO_LIST',
  FETCH_LIVEINFO: 'liveInfo/FETCH_LIVEINFO',
  CREATE_LIVEINFO: 'liveInfo/CREATE_LIVEINFO',
  UPDATE_LIVEINFO: 'liveInfo/UPDATE_LIVEINFO',
  DELETE_LIVEINFO: 'liveInfo/DELETE_LIVEINFO',
  RESET: 'liveInfo/RESET'
};
// state
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ILiveInfo>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  searchForm: {}
};

export type LiveInfoState = Readonly<typeof initialState>;

// Reducer

export default (state: LiveInfoState = initialState, action): LiveInfoState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_LIVEINFO_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_LIVEINFO_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_LIVEINFO):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_LIVEINFO):
    case REQUEST(ACTION_TYPES.UPDATE_LIVEINFO):
    case REQUEST(ACTION_TYPES.DELETE_LIVEINFO):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_LIVEINFO_LIST):
    case FAILURE(ACTION_TYPES.LOAD_LIVEINFO_LIST):
    case FAILURE(ACTION_TYPES.FETCH_LIVEINFO):
    case FAILURE(ACTION_TYPES.CREATE_LIVEINFO):
    case FAILURE(ACTION_TYPES.UPDATE_LIVEINFO):
    case FAILURE(ACTION_TYPES.DELETE_LIVEINFO):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_LIVEINFO_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_LIVEINFO_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_LIVEINFO):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_LIVEINFO):
    case SUCCESS(ACTION_TYPES.UPDATE_LIVEINFO):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_LIVEINFO):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/live-infos';
const initpage = 0;
const initsize = 20;

// Actions
// 租务管理/租约管理/入住信息列表
export const getEntities: ICrudGetListAction<ILiveInfo> = (page, size, sort = 'createdAt', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  initialState.searchForm = { ...searchForm };
  return {
    type: ACTION_TYPES.FETCH_LIVEINFO_LIST,
    payload: axios.get<ILiveInfo>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const loadEntities: ICrudGetListAction<ILiveInfo> = (page, size, sort = 'createdAt', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.LOAD_LIVEINFO_LIST,
    payload: axios.get<ILiveInfo>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};
// 入住信息详情
export const getEntity: ICrudGetAction<ILiveInfo> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_LIVEINFO,
    payload: axios.get<ILiveInfo>(requestUrl)
  };
};
// 创建入住信息
export const createEntity: ICrudPutAction<ILiveInfo> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_LIVEINFO,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities(initpage, initsize, '', initialState.searchForm));
  return result;
};
// 更新入住信息
export const updateEntity: ICrudPutAction<ILiveInfo> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_LIVEINFO,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities(initpage, initsize, '', initialState.searchForm));
  return result;
};
// 删除入住信息
export const deleteEntity: ICrudDeleteAction<ILiveInfo> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_LIVEINFO,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities(initpage, initsize, '', initialState.searchForm));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

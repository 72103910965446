import moment, { Moment } from 'moment';
import { IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { IHouseRoom } from 'app/shared/model/house-room.model';

export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING'
}

export interface IRenewal {
  id?: number;
  auditState?: AuditState;
  auditAt?: Moment;
  auditDescription?: string;
  content?: string;
  diffDeposit?: number;
  userName?: string;
  parkingLotName?: string; // 车位名称
  mobile?: string; // 联系方式
  house?: IHouseRoom;
  createdAt?: Moment;
  createdBy?: string;
  updatedBy?: string;
  updatedAt?: Moment;
  parkingLotId?: number;
  pcontractId?: number;
  userId?: number;
  auditorId?: number;
  auditorName?: string;
  purchaseMonth?: number;
}

export const defaultValue: Readonly<IRenewal> = {};

export interface ISearchForm {
  purchaseMonth?: number;
  parkingLotId?: IRangeFilter; // 车位
  userId?: IRangeFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
  houseId?: IRangeFilter; // 房产
  auditState?: IStringFilter; // 审核状态
  auditAt?: IRangeFilter;
}

export const defaultSearchForm: ISearchForm = {
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  houseId: {
    in: []
  },
  parkingLotId: {
    in: []
  },
  userId: {
    in: []
  },
  auditState: {
    in: []
  },
  auditAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  }
};

import { Moment } from 'moment';
import { IMessage } from 'app/shared/model/message.model';
import { IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';

export interface IMessageContent {
  id?: number;
  title?: string;
  content?: string;
  hasAll?: boolean;
  rentBeforeDay?: number;
  exceedRentDay?: number;
  contractExpired?: number;
  createdAt?: Moment;
  createdBy?: string;
  updatedBy?: string;
  updatedAt?: Moment;
  messages?: IMessage[];
  recipient?: {
    hasAll?: boolean;
    rentBeforeDay?: number;
    exceedRentDay?: number;
    contractExpired?: number;
  };
}

export const defaultValue: Readonly<IMessageContent> = {};

export interface ISearchForm {
  title?: IStringFilter;
  content?: IStringFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
}

export const defaultSearchForm: ISearchForm = {
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  title: { contains: '' },
  content: { contains: '' }
};

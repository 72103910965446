import axios from 'axios';
import * as qs from 'qs';
import moment from 'moment';

import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction, Storage } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IPakingContract } from 'app/shared/model/parking-contract.model';
import { IContractExpense } from 'app/shared/model/contract-expense.model';
import { ExpenseType, IExpenseGradeTree } from 'app/shared/model/expense-grade.model';
import { FacilitiesType, IContractFacilitiesTree, IFacilities } from 'app/shared/model/facilities.model';
import { IContractFacilities } from 'app/shared/model/contract-facilites.model';
import { ICrudGetListAction } from 'app/shared/model/list';
import { flattenKey } from 'app/shared/util/object-utils';
import { message } from 'antd';

// 请求
export const ACTION_TYPES = {
  FETCH_CONTRACT_LIST: 'contract/FETCH_CONTRACT_LIST',
  LOAD_CONTRACT_LIST: 'contract/LOAD_CONTRACT_LIST',
  FETCH_CONTRACT: 'contract/FETCH_CONTRACT',
  CREATE_CONTRACT: 'contract/CREATE_CONTRACT',
  UPDATE_CONTRACT: 'contract/UPDATE_CONTRACT',
  CANCEL_CONTRACT: 'contract/CANCEL_CONTRACT',
  ENROLMENT_CONTRACT: 'contract/ENROLMENT_CONTRACT',
  DELETE_CONTRACT: 'contract/DELETE_CONTRACT',
  RESET: 'contract/RESET',
  UPDATE_FORM_ENTITY: 'contract/UPDATE_FORM_ENTITY',
  GET_FORM_ENTITY: 'contract/GET_FORM_ENTITY',
  NEXT_STEP: 'contract/NEXT_STEP',
  PREV_STEP: 'contract/PREV_STEP',
  RESET_STEP: 'contract/RESET_STEP',
  RESET_FORM_ENTITY: 'contract/RESET_FORM_ENTITY'
};
// state定义
const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPakingContract>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  formEntity: defaultValue,
  currentStep: 0,
  searchForm: {}
};

export type ParkingContractState = Readonly<typeof initialState>;

// Reducer
export default (state: ParkingContractState = initialState, action): ParkingContractState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CONTRACT_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_CONTRACT_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_CONTRACT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_CONTRACT):
    case REQUEST(ACTION_TYPES.UPDATE_CONTRACT):
    case REQUEST(ACTION_TYPES.CANCEL_CONTRACT):
    case REQUEST(ACTION_TYPES.ENROLMENT_CONTRACT):
    case REQUEST(ACTION_TYPES.DELETE_CONTRACT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_CONTRACT_LIST):
    case FAILURE(ACTION_TYPES.LOAD_CONTRACT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CONTRACT):
    case FAILURE(ACTION_TYPES.CREATE_CONTRACT):
    case FAILURE(ACTION_TYPES.UPDATE_CONTRACT):
    case FAILURE(ACTION_TYPES.CANCEL_CONTRACT):
    case FAILURE(ACTION_TYPES.ENROLMENT_CONTRACT):
    case FAILURE(ACTION_TYPES.DELETE_CONTRACT):
      return {
        ...state,
        loading: false,
        fetching: false,
        loadMore: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_CONTRACT_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_CONTRACT_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_CONTRACT):
      const formEntity = { ...action.payload.data };
      const { licensePlateNumber } = formEntity;

      formEntity.startAt = moment(formEntity.startAt);
      formEntity.endAt = moment(formEntity.endAt);

      if (licensePlateNumber && licensePlateNumber.length > 3) {
        formEntity.prefLicense = licensePlateNumber.substr(0, 1);
        formEntity.suffixLicense = licensePlateNumber.substr(1);
      }

      formEntity.academicCerts = [formEntity.academicCert, formEntity.academicCert2, formEntity.academicCert3].filter(item => item);
      return {
        ...state,
        loading: false,
        entity: formEntity,
        formEntity: { ...formEntity }
      };
    case SUCCESS(ACTION_TYPES.CREATE_CONTRACT):
    case SUCCESS(ACTION_TYPES.UPDATE_CONTRACT):
      Storage.local.remove('contract-form-entity');
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
        formEntity: defaultValue,
        currentStep: 0
      };
    case SUCCESS(ACTION_TYPES.DELETE_CONTRACT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case SUCCESS(ACTION_TYPES.CANCEL_CONTRACT):
      // message.success('撤消合同操作成功');
      return state;
    case SUCCESS(ACTION_TYPES.ENROLMENT_CONTRACT):
      message.success('网签登记成功');
      return state;
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.UPDATE_FORM_ENTITY:
      Storage.local.set('contract-form-entity', action.payload);
      return {
        ...state,
        formEntity: { ...action.payload }
      };
    case ACTION_TYPES.GET_FORM_ENTITY:
      const getFormEntityData = { ...(Storage.local.get('contract-form-entity') || state.formEntity) };
      getFormEntityData.startAt = getFormEntityData.startAt && moment(getFormEntityData.startAt);
      getFormEntityData.endAt = getFormEntityData.endAt && moment(getFormEntityData.endAt);
      return {
        ...state,
        formEntity: getFormEntityData
      };
    case ACTION_TYPES.NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1
      };
    case ACTION_TYPES.PREV_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1
      };
    case ACTION_TYPES.RESET_STEP:
      return {
        ...state,
        currentStep: 0
      };
    case ACTION_TYPES.RESET_FORM_ENTITY:
      Storage.local.remove('contract-form-entity');
      return {
        ...state,
        formEntity: defaultValue
      };
    default:
      return state;
  }
};

const apiUrl = 'api/pcontracts'; // 车位租约合同
const initpage = 0;
const initsize = 20;
// const apiUrlt = 'api/tcontract-items';
// Actions
// 租约管理-租约合同 列表
export const getEntities: ICrudGetListAction<IPakingContract> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  initialState.searchForm = { ...searchForm };
  return {
    type: ACTION_TYPES.FETCH_CONTRACT_LIST,
    payload: axios.get<IPakingContract>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export const loadEntities: ICrudGetListAction<IPakingContract> = (page, size, sort = 'createdAt,desc', searchForm) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.LOAD_CONTRACT_LIST,
    payload: axios.get<IPakingContract>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }),
    meta: { transformUserName: true }
  };
};

export function fetchEntity(id) {
  const requestUrl = `${apiUrl}/${id}`;
  return axios.get<IPakingContract>(requestUrl);
}
// 车位合同详情
export const getEntity: ICrudGetAction<IPakingContract> = id => ({
  type: ACTION_TYPES.FETCH_CONTRACT,
  payload: fetchEntity(id)
});
// 创建车位合同
export const createEntity: ICrudPutAction<IPakingContract> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CONTRACT,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities(initpage, initsize, '', initialState.searchForm));
  return result;
};
// 更新车位合同
export const updateEntity: ICrudPutAction<IPakingContract> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CONTRACT,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities(initpage, initsize, '', initialState.searchForm));
  return result;
};

export const cancelEntity: ICrudPutAction<IPakingContract> = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CANCEL_CONTRACT,
    payload: axios.put(`${apiUrl}/${id}/cancel`)
  });
  dispatch(getEntities(initpage, initsize, '', initialState.searchForm));
  return result;
};
export const enrolmentEntity: ICrudPutAction<IPakingContract> = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.ENROLMENT_CONTRACT,
    payload: axios.put(`${apiUrl}/${id}/enrolment`)
  });
  dispatch(getEntities(initpage, initsize, '', initialState.searchForm));
  return result;
};

export const auditEntity: ICrudPutAction<IPakingContract> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CONTRACT,
    payload: axios.put(`${apiUrl}/${entity.id}/audit`, cleanEntity(entity))
  });
  // dispatch(reset());
  dispatch(getEntities(initpage, initsize, '', initialState.searchForm));
  return result;
};

export const deleteEntity: ICrudDeleteAction<IPakingContract> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CONTRACT,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities(initpage, initsize, '', initialState.searchForm));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const updateFormEntity = (formEntity: IPakingContract) => ({
  type: ACTION_TYPES.UPDATE_FORM_ENTITY,
  payload: formEntity
});
export const getFormEntity = () => ({
  type: ACTION_TYPES.GET_FORM_ENTITY
});

export const nextStep = () => ({
  type: ACTION_TYPES.NEXT_STEP
});

export const prevStep = () => ({
  type: ACTION_TYPES.PREV_STEP
});

export const resetStep = () => ({
  type: ACTION_TYPES.RESET_STEP
});

export const resetFormEntity = () => ({
  type: ACTION_TYPES.RESET_FORM_ENTITY
});

export function reduceExpenses(expenses: IContractExpense[], expenseGradeTree: IExpenseGradeTree) {
  return expenses.reduce(
    (result, expense) => {
      const expenseGrade = expenseGradeTree.expenseGradesWithMap[expense.expenseGradeId];
      if (expenseGrade) {
        expense.expenseGrade = expenseGrade;
        if (expenseGrade.id === 1 || expenseGrade.id === 2) {
          return result;
        }
        expense.name = expenseGrade.name;
        if (expenseGrade.parentId && expenseGrade.parentId !== 1 && expenseGrade.parentId !== 2) {
          const parent = expenseGradeTree.expenseGradesWithMap[expenseGrade.parentId];
          if (parent) {
            expense.name = `${parent.name} / ${expense.name}`;
          }
        }
        if (expenseGrade.parentId === 1) {
          result.deposit.push(expense);
        } else if (expenseGrade.parentId === 2) {
          result.lifeFee.push(expense);
        } else if (expenseGrade.type === ExpenseType.IN) {
          result.inGrades.push(expense);
        }
      }
      return result;
    },
    { deposit: [], lifeFee: [], inGrades: [] }
  );
}

export function reduceContractFacilities(contractFacilities: IContractFacilities[], facilities: IFacilities[]): IContractFacilitiesTree {
  const contractFacilitiesWithId = contractFacilities.reduce((result, cf) => {
    result[cf.facilitiesId] = cf;
    return result;
  }, {});
  return facilities.reduce(
    (result, f) => {
      const facId = contractFacilitiesWithId[f.id];
      const fac = {
        total: facId ? facId.total : 0,
        facilitiesId: f.id,
        contractId: facId ? facId.contractId : null,
        facilities: f
      };
      if (f.type === FacilitiesType.BASIC) {
        result.basics.push(fac);
      } else if (f.type === FacilitiesType.FEATURE) {
        result.features.push(fac);
      }
      return result;
    },
    { basics: [], features: [] }
  );
}

export function existRentedParkingLot(id: number) {
  return axios.get<IPakingContract>(`${apiUrl}/${id}/existRentedParkingLot`);
}

import axios from 'axios';
import * as qs from 'qs';
import { ICrudGetAction, IPayload } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import {
  defaultSearchForm,
  defaultValue,
  ExpenseType,
  IExpenseGrade,
  ISearchForm,
  IExpenseGradeTree
} from 'app/shared/model/expense-grade.model';
import { flattenKey } from 'app/shared/util/object-utils';
import { isEmpty, values } from 'lodash';
import { ILiveInfo } from 'app/shared/model/live-info.model';

export declare type ICrudGetListAction<T> = (
  page?: number,
  size?: number,
  sort?: string,
  searchForm?: ISearchForm
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_EXPENSEGRADE_LIST: 'expenseGrade/FETCH_EXPENSEGRADE_LIST',
  LOAD_EXPENSEGRADE_LIST: 'expenseGrade/LOAD_EXPENSEGRADE_LIST',
  FETCH_EXPENSEGRADE: 'expenseGrade/FETCH_EXPENSEGRADE',
  FETCH_EXPENSEGRADE_PARENT_NAME: 'expenseGrade/FETCH_EXPENSEGRADE_PARENT_NAME',
  CREATE_EXPENSEGRADE: 'expenseGrade/CREATE_EXPENSEGRADE',
  UPDATE_EXPENSEGRADE: 'expenseGrade/UPDATE_EXPENSEGRADE',
  DELETE_EXPENSEGRADE: 'expenseGrade/DELETE_EXPENSEGRADE',
  RESET: 'expenseGrade/RESET',
  RESET_ENTITY: 'expenseGrade/RESET_ENTITY'
};

const initialState = {
  loading: false,
  fetching: false,
  loadMore: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IExpenseGrade>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
  searchData: {
    page: 0,
    size: 20,
    sort: 'createdAt,desc',
    searchForm: defaultSearchForm
  },
  parentName: ''
};

export type ExpenseGradeState = Readonly<typeof initialState>;

// Reducer

export default (state: ExpenseGradeState = initialState, action): ExpenseGradeState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_EXPENSEGRADE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        fetching: true
      };
    case REQUEST(ACTION_TYPES.LOAD_EXPENSEGRADE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadMore: true
      };
    case REQUEST(ACTION_TYPES.FETCH_EXPENSEGRADE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_EXPENSEGRADE):
    case REQUEST(ACTION_TYPES.UPDATE_EXPENSEGRADE):
    case REQUEST(ACTION_TYPES.DELETE_EXPENSEGRADE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_EXPENSEGRADE_LIST):
    case FAILURE(ACTION_TYPES.LOAD_EXPENSEGRADE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_EXPENSEGRADE):
    case FAILURE(ACTION_TYPES.FETCH_EXPENSEGRADE_PARENT_NAME):
    case FAILURE(ACTION_TYPES.CREATE_EXPENSEGRADE):
    case FAILURE(ACTION_TYPES.UPDATE_EXPENSEGRADE):
    case FAILURE(ACTION_TYPES.DELETE_EXPENSEGRADE):
      return {
        ...state,
        loading: false,
        updating: false,
        fetching: false,
        loadMore: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_EXPENSEGRADE_LIST):
      return {
        ...state,
        loading: false,
        fetching: false,
        entities: action.payload.data,
        searchData: action.meta.searchData,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.LOAD_EXPENSEGRADE_LIST):
      return {
        ...state,
        loadMore: false,
        entities: state.entities.concat(action.payload.data),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_EXPENSEGRADE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_EXPENSEGRADE_PARENT_NAME):
      return {
        ...state,
        loading: false,
        parentName: action.payload.data.name
      };
    case SUCCESS(ACTION_TYPES.CREATE_EXPENSEGRADE):
    case SUCCESS(ACTION_TYPES.UPDATE_EXPENSEGRADE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_EXPENSEGRADE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.RESET_ENTITY:
      return {
        ...state,
        entity: defaultValue
      };
    default:
      return state;
  }
};

const apiUrl = 'api/expense-grades'; // 财务管理-账单管理 应收、实收、房租等数据

// Actions

export const getEntities: ICrudGetListAction<IExpenseGrade> = (page, size, sort, searchForm) => ({
  type: ACTION_TYPES.FETCH_EXPENSEGRADE_LIST,
  payload: axios.get<IExpenseGrade>(
    `${apiUrl}${
      sort ? `?page=${page}&size=${size}&sort=${sort}&cacheBuster=${new Date().getTime()}` : `?cacheBuster=${new Date().getTime()}`
    }`,
    {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }
  ),
  meta: {
    searchData: {
      page,
      size,
      sort,
      searchForm
    }
  }
});

export const loadEntities: ICrudGetListAction<IExpenseGrade> = (page, size, sort, searchForm) => ({
  type: ACTION_TYPES.FETCH_EXPENSEGRADE_LIST,
  payload: axios.get<IExpenseGrade>(
    `${apiUrl}${
      sort ? `?page=${page}&size=${size}&sort=${sort}&cacheBuster=${new Date().getTime()}` : `?cacheBuster=${new Date().getTime()}`
    }`,
    {
      params: flattenKey(searchForm),
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
    }
  ),
  meta: {
    searchData: {
      page,
      size,
      sort,
      searchForm
    }
  }
});

export const getEntity: ICrudGetAction<IExpenseGrade> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_EXPENSEGRADE,
    payload: axios.get<IExpenseGrade>(requestUrl)
  };
};

export const createEntity = entity => async (dispatch, getState) => {
  const {
    expenseGrade: { searchData }
  } = getState();
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_EXPENSEGRADE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities(searchData.page, searchData.size, searchData.sort, searchData.searchForm));
  return result;
};

export const updateEntity = entity => async (dispatch, getState) => {
  const {
    expenseGrade: { searchData }
  } = getState();
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_EXPENSEGRADE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities(searchData.page, searchData.size, searchData.sort, searchData.searchForm));
  return result;
};

export const deleteEntity = id => async (dispatch, getState) => {
  const {
    expenseGrade: { searchData }
  } = getState();
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_EXPENSEGRADE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities(searchData.page, searchData.size, searchData.sort, searchData.searchForm));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const resetEntity = () => ({
  type: ACTION_TYPES.RESET_ENTITY
});

export const getParentName = id => ({
  type: ACTION_TYPES.FETCH_EXPENSEGRADE_PARENT_NAME,
  payload: axios.get(`${apiUrl}/${id}`)
});

export const getEntitiesAll = (searchForm = {}) =>
  axios.get<IExpenseGrade[]>(`${apiUrl}/all?cacheBuster=${new Date().getTime()}`, {
    params: flattenKey(searchForm),
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' })
  });
function divertExpenseGrades(expenseGrades: IExpenseGrade[]): IExpenseGradeTree {
  const expenseGradesWithMap = {};
  const deposit = [];
  const lifeFee = [];
  const inGradesWithMap = {};
  const outGradesWithMap = {};

  expenseGrades.forEach(expenseGrade => {
    expenseGradesWithMap[expenseGrade.id] = expenseGrade;
    if (expenseGrade.id === 1 || expenseGrade.id === 2) {
      return;
    }
    if (expenseGrade.parentId === 1) {
      deposit.push(expenseGrade);
    } else if (expenseGrade.parentId === 2) {
      lifeFee.push(expenseGrade);
    } else if (isEmpty(expenseGrade.ancestry) && expenseGrade.type === ExpenseType.IN) {
      inGradesWithMap[expenseGrade.id] = expenseGrade;
    } else if (isEmpty(expenseGrade.ancestry) && expenseGrade.type === ExpenseType.OUT) {
      outGradesWithMap[expenseGrade.id] = expenseGrade;
    }
  });

  expenseGrades.forEach(expenseGrade => {
    const inGrade = inGradesWithMap[expenseGrade.parentId];
    if (inGrade) {
      if (!inGrade.houseExpenses) {
        inGrade['houseExpenses'] = [];
      }
      inGrade.houseExpenses.push(expenseGrade);
    }
    const outGrade = outGradesWithMap[expenseGrade.parentId];
    if (outGrade) {
      if (!outGrade.houseExpenses) {
        outGrade['houseExpenses'] = [];
      }
      outGrade.houseExpenses.push(expenseGrade);
    }
  });
  return { deposit, lifeFee, inGrades: values(inGradesWithMap), outGrades: values(outGradesWithMap), expenseGradesWithMap };
}

export function getExpenseGradeTree() {
  return new Promise<IExpenseGradeTree>((resolve, reject) => {
    getEntitiesAll({ notId: { in: [1, 2] } })
      .then(response => {
        resolve(divertExpenseGrades(response.data));
      })
      .catch(() => {
        reject();
      });
  });
}

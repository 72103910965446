/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import styles from './password-strength-bar.less';

import React from 'react';
import { Col, Input, Row } from 'antd';
import { InputProps } from 'antd/lib/input';

export interface IPasswordStrengthInputProps extends InputProps {
  value?: string;
  onChange?: (any: any) => any;
}

export interface IPasswordStrengthInputStates {
  value: string;
}

export class PasswordStrengthInput extends React.Component<IPasswordStrengthInputProps, IPasswordStrengthInputStates> {
  static getDerivedStateFromProps(nextProps) {
    // Should be a controlled component.
    if ('value' in nextProps) {
      return {
        ...{ value: nextProps.value || '' }
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.value || ''
    };
  }

  handlePasswordChange = e => {
    const value = e.target.value || '';
    if (!('value' in this.props)) {
      this.setState({ value });
    }
    this.triggerChange({ value });
  };

  triggerChange = changedValue => {
    // Should provide an event to pass value to Form.
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(changedValue.value);
    }
  };

  render(): React.ReactNode {
    const { ...props } = this.props;
    const { value } = this.state;
    const colors = ['#f5222d', '#ffa940', '#fadb14', '#bae637', '#52c41a'];

    const measureStrength = (p: string): number => {
      let force = 0;
      const regex = /[$-/:-?{-~!"^_`\[\]]/g;
      const flags = {
        lowerLetters: /[a-z]+/.test(p),
        upperLetters: /[A-Z]+/.test(p),
        numbers: /[0-9]+/.test(p),
        symbols: regex.test(p)
      };

      const passedMatches = Object.values(flags).filter((isMatchedFlag: boolean) => !!isMatchedFlag).length;

      force += 2 * p.length + (p.length >= 10 ? 1 : 0);
      force += passedMatches * 10;

      // penalty (short password)
      force = p.length <= 6 ? Math.min(force, 10) : force;

      // penalty (poor variety of characters)
      force = passedMatches === 1 ? Math.min(force, 10) : force;
      force = passedMatches === 2 ? Math.min(force, 20) : force;
      force = passedMatches === 3 ? Math.min(force, 40) : force;

      return force;
    };

    const getColor = (s: number): any => {
      let idx = 0;
      if (s <= 10) {
        idx = 0;
      } else if (s <= 20) {
        idx = 1;
      } else if (s <= 30) {
        idx = 2;
      } else if (s <= 40) {
        idx = 3;
      } else {
        idx = 4;
      }
      return { idx: idx + 1, col: colors[idx] };
    };

    const getPoints = force => {
      const pts = [];
      for (let i = 0; i < 5; i++) {
        pts.push(
          <Col span={4} key={i} className="point" style={i < force.idx ? { backgroundColor: force.col } : { backgroundColor: '#DDD' }} />
        );
      }
      return pts;
    };

    const strength = getColor(measureStrength(value));
    const points = getPoints(strength);

    return (
      <div id="strength" className={styles['password-strength-bar']}>
        <Row className="strengthBar" type="flex" justify="space-between">
          {points}
        </Row>
        <Input {...props} type="password" value={value} onChange={this.handlePasswordChange} />
      </div>
    );
  }
}

export default PasswordStrengthInput;

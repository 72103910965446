import { IFilter, IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { Moment } from 'moment';

export const enum IEntityAuditAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

export interface IEntityAudit {
  id?: any;
  entityId?: string;
  entityType?: string;
  action?: IEntityAuditAction;
  entityValue?: string;
  commitVersion?: string;
  createdAt?: Moment;
  modifiedBy?: string;
  modifiedDate?: string;
  createdBy?: Moment;
}

export interface ISearchForm {
  action?: IFilter;
  type?: IStringFilter;
  entityId?: IStringFilter;
  entityType?: IStringFilter;
  createdAt?: IRangeFilter;
}

export const defaultValue: Readonly<IEntityAudit> = {
  id: '',
  entityId: '',
  action: IEntityAuditAction.CREATE,
  entityValue: '',
  commitVersion: '0'
};

export const defaultSearchForm: Readonly<ISearchForm> = {
  action: {
    in: []
  },
  type: {
    equals: ''
  },
  entityId: {
    equals: ''
  },
  entityType: {
    equals: ''
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  }
};

// 水电充值管理 定义
import { Moment } from 'moment';
import { IRangeFilter, IStringFilter, IFilter } from 'app/shared/model/filter.model';

// 返回状态定义
export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING'
}
export interface IHydropowerRecharge {
  id?: number; // id
  house?: string; // 房源名称
  houseId?: number; // 房源id
  houseRoomCode?: string; // 房间号
  houseRoomId?: number; // 房间号id
  customerName?: string; // 租户
  customerId?: number; // 租户id
  controlsName?: string; // 操作人
  controlsId?: number; // 操作人id
  rechargeType?: string; // 充值类型
  rechargeWay?: string; // 充值方式
  rechargeAmount?: number; // 充值金额
  rechargeDate?: Date; // 充值时间
}

export const defaultValue: Readonly<IHydropowerRecharge> = {};

export interface ISearchForm {
  houseId?: IRangeFilter; // 房源
  houseRoomId?: IRangeFilter; // 房号
  customerId?: IRangeFilter; // 租户
  rechargeDate?: IRangeFilter; // 充值时间
  state?: IRangeFilter; // 状态
  sort?: String; // 排序
  rechargeType?: IRangeFilter; // 充值类型
}

export const defaultSearchForm: ISearchForm = {
  houseId: { equals: 50 }, // 房源
  rechargeType: { equals: '' }, // 充值类型
  houseRoomId: {
    // 房间
    equals: null
  },
  customerId: {
    // 用户
    equals: null
  },
  rechargeDate: {
    // 缴费日期
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  state: { equals: 'COMPLETED' }, // 状态
  sort: 'rechargeDate,desc' // 排序
};

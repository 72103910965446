import { compact, floor } from 'lodash';
// @ts-ignore
export const handleFee = miniOrders => {
    const orderChildItems = [];
    miniOrders && miniOrders.length > 0 && miniOrders.forEach(aItem => {
        aItem.orderItems.forEach(aaItem => {
          if (aaItem.name.indexOf('物业') > -1) {
            const wamount = orderChildItems[0] ? (orderChildItems[0].amount * 100 + aaItem.amount * 100) / 100 : aaItem.amount;
            // 物业费
            orderChildItems[0] = {
              name: '物业费',
              amount: floor(wamount, 2)
            };
          } else if (aaItem.name.indexOf('电费') > -1) {
            const samount = orderChildItems[1] ? (orderChildItems[1].amount * 100 + aaItem.amount * 100) / 100 : aaItem.amount;
            // 电费
            orderChildItems[1] = {
              name: '电费',
              amount: floor(samount, 2)
            };
          } else if (aaItem.name.indexOf('水费') > -1) {
            const damount = orderChildItems[2] ? (orderChildItems[2].amount * 100 + aaItem.amount * 100) / 100 : aaItem.amount;
            // 水费
            orderChildItems[2] = {
              name: '水费',
              amount: floor(damount, 2)
            };
          }
        });
      });
    return compact(orderChildItems);
};

import React from 'react';
import { Switch } from 'react-router-dom';
import UserManagement from './user-management';
import UserManagementDetail from './user-management-detail';
import UserManagementUpdate from './user-management-update';
import UserManagementDeleteDialog from './user-management-delete-dialog';
import UserManagementPswUpdate from './user-management-psw-update';
import PrivateRoute from 'app/shared/auth/private-route';
import CertificationDetail from 'app/entities/certification/certification-detail';
import CertificationEdit from 'app/entities/certification/certification-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <PrivateRoute path={`${match.url}/:login/certification/edit`} component={CertificationEdit} />
      <PrivateRoute path={`${match.url}/:login/certification`} component={CertificationDetail} />
      <PrivateRoute exact path={`${match.url}/new`} component={UserManagementUpdate} hasAnyAuthorities={['User#create']} />
      <PrivateRoute exact path={`${match.url}/:login/edit`} component={UserManagementUpdate} hasAnyAuthorities={['User#update']} />
      <PrivateRoute
        exact
        path={`${match.url}/:login/pws/edit`}
        component={UserManagementPswUpdate}
        hasAnyAuthorities={['User#changeMobile']}
      />
      <PrivateRoute exact path={`${match.url}/:login`} component={UserManagementDetail} hasAnyAuthorities={['User#read']} />
      <PrivateRoute path={match.url} component={UserManagement} hasAnyAuthorities={['User#read']} />
    </Switch>
    <PrivateRoute path={`${match.url}/:login/delete`} component={UserManagementDeleteDialog} hasAnyAuthorities={['User#delete']} />
  </>
);

export default Routes;

/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';
import invariant from 'invariant';
import { Button, Tooltip } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { NativeButtonProps } from 'antd/lib/button/button';

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

export interface ILinkButtonProps extends NativeButtonProps, RouteComponentProps {
  onClick?: (e?: any) => any;
  replace?: boolean;
  target?: string;
  to: string;
  children?: any;
}

class LinkButton extends React.Component<ILinkButtonProps> {
  static defaultProps = {
    replace: false
  };

  handleClick = event => {
    if (this.props.onClick) this.props.onClick(event);

    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      !this.props.target && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault();

      const { replace, to, history } = this.props;

      if (replace) {
        history.replace(to);
      } else {
        history.push(to);
      }
    }
  };

  render(): React.ReactNode {
    const { children, replace, to, title, ...rest } = this.props;
    delete rest.staticContext;
    invariant(to !== undefined, 'You must specify the "to" property');

    return (
      <Tooltip title={title}>
        <Button {...rest} onClick={this.handleClick}>
          {...children}
        </Button>
      </Tooltip>
    );
  }
}

export default withRouter(LinkButton);

/*
 * Copyright (C) 2019 Hunan Fantastic Network Technology Co., Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { deleteRole, getRole, reset } from 'app/modules/administration/roles/role-management.reducer';
import { RouteComponentProps } from 'react-router';
import { TextFormat, translate, Translate } from 'react-jhipster';
import { Button, Card, Descriptions, Divider, Icon, List, Skeleton, Tag } from 'antd';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { IPermission } from 'app/shared/model/permission.model';
import LinkButton from 'app/shared/components/link-button';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { detailsToEdit } from 'app/shared/util/url-utils';
import { canNotOperate } from 'app/modules/administration/roles/role-management';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IUserManagementDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: any }> {}

export class RoleManagementDetail extends React.Component<IUserManagementDetailProps> {
  componentDidMount() {
    this.props.getRole(this.props.match.params.id);
  }

  checkAuth = (action: string) => {
    const {
      account: { permissions }
    } = this.props;
    return hasAnyAuthority(permissions, [`Role#${action}`]);
  };

  action() {
    const { role, match, loading } = this.props;
    return !this.checkAuth('update') || loading || canNotOperate(role.name) ? null : (
      <Button.Group>
        <LinkButton type={'primary'} htmlType={'button'} to={detailsToEdit(match.url, role.id + '')}>
          <Icon type={'edit'} /> <Translate contentKey="entity.action.edit">Edit</Translate>
        </LinkButton>
      </Button.Group>
    );
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { role } = this.props;
    const renderDescription = (item: IPermission) => {
      const options = item.actions
        .filter(p => p.length > 0)
        .map(p => (
          <Tag color="blue" key={`${item.resource}-${p}`}>
            {translate(`entity.action.${p}`)}
          </Tag>
        ));
      return <>{options.length > 0 ? options : <span>无</span>}</>;
    };
    const renderItem = (item: IPermission) => (
      <List.Item>
        <List.Item.Meta title={translate(`entity.model.${item.resource}`)} description={renderDescription(item)} />
      </List.Item>
    );

    return (
      <PageHeaderWrapper extra={this.action()}>
        <Card bordered={false}>
          <Skeleton loading={this.props.loading} active>
            <Descriptions title={translate('roles.detail.title')}>
              <Descriptions.Item label={translate('roles.name')}>
                <Translate contentKey={`roles.${role.name}`} children={role.name} />
              </Descriptions.Item>
              <Descriptions.Item label={translate('roles.createdBy')}>{role.createdBy}</Descriptions.Item>
              <Descriptions.Item label={translate('roles.createdAt')}>
                <TextFormat value={role.createdAt} type="date" format={APP_DATE_FORMAT} blankOnInvalid />
              </Descriptions.Item>
              <Descriptions.Item label={translate('roles.updatedBy')}>{role.updatedBy}</Descriptions.Item>
              <Descriptions.Item label={translate('roles.updatedAt')}>
                <TextFormat value={role.updatedAt} type="date" format={APP_DATE_FORMAT} blankOnInvalid />
              </Descriptions.Item>
            </Descriptions>
            <Divider dashed>{translate('roles.permissions')}</Divider>
            <List itemLayout={'horizontal'} dataSource={role.permissions} renderItem={renderItem} />
          </Skeleton>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

const mapStateTopProps = ({ roleManagement, authentication }: IRootState) => ({
  role: roleManagement.role,
  loading: roleManagement.loading,
  account: authentication.account
});

const mapDispatchTopProps = { getRole, deleteRole, reset };

type StateProps = ReturnType<typeof mapStateTopProps>;
type DispatchProps = typeof mapDispatchTopProps;

export default connect(
  mapStateTopProps,
  mapDispatchTopProps
)(RoleManagementDetail);

import { Route } from '@ant-design/pro-layout/lib/typings';
import { flatten } from 'app/shared/util/object-utils';
import { translate } from 'react-jhipster';

export const routes: Route[] = [
  {
    path: '/',
    icon: 'dashboard',
    locale: 'global.menu.home',
    name: translate('global.menu.home', {}, '首页')
  },
  // 房源管理
  {
    path: '/house',
    icon: 'shop',
    locale: 'global.menu.house.main',
    name: 'global.menu.house.main',
    authority: ['House#read'],
    isUrl: false,
    routes: [
      {
        path: '/house/centralized',
        name: 'global.menu.house.centralized.main',
        locale: 'global.menu.house.centralized.main'
      },
      {
        path: '/house/distributed',
        name: 'global.menu.house.distributed.main',
        locale: 'global.menu.house.distributed.main'
      }
    ]
  },
  // 租务管理
  {
    path: '/rental',
    name: 'global.menu.admin.rental',
    icon: 'bank',
    locale: 'global.menu.admin.rental',
    isUrl: false,
    routes: [
      {
        path: '/rental/appointment',
        name: 'global.menu.entities.appointment',
        authority: ['Appointment#read'],
        locale: 'global.menu.entities.appointment'
      },
      // 租约管理
      {
        path: '/rental/contract',
        name: 'global.menu.entities.contractMng',
        locale: 'global.menu.entities.contractMng',
        isUrl: false,
        routes: [
          // 租约合同
          {
            path: '/rental/contract/contract',
            authority: ['Contract#read'],
            name: 'global.menu.entities.contract',
            locale: 'global.menu.entities.contract'
          },
          // 团租合同
          {
            path: '/rental/contract/t-contract',
            authority: ['Tcontract#read'],
            name: 'global.menu.entities.tcontract',
            locale: 'global.menu.entities.tcontract'
          },
          // 入住信息
          {
            path: '/rental/contract/live-info',
            name: 'global.menu.entities.liveInfo',
            authority: ['LiveInfo#read'],
            locale: 'global.menu.entities.liveInfo'
          },
          // 换房申请
          {
            path: '/rental/contract/change-house',
            authority: ['ChangeHouse#read'],
            name: 'global.menu.entities.changeHouse',
            locale: 'global.menu.entities.changeHouse'
          },
          // 续租申请
          {
            path: '/rental/contract/renewal',
            authority: ['Renewal#read'],
            name: 'global.menu.entities.renewal',
            locale: 'global.menu.entities.renewal'
          },
          // 退房申请
          {
            path: '/rental/contract/refund-rental',
            authority: ['RefundRental#read'],
            name: 'global.menu.entities.refundRental',
            locale: 'global.menu.entities.refundRental'
          }
        ]
      },
      // 商业租约管理
      {
        path: '/commerce-rental/contract',
        name: 'global.menu.entities.mcontractMng',
        locale: 'global.menu.entities.mcontractMng',
        isUrl: false,
        routes: [
          // 商业合同
          {
            path: '/commerce-rental/contract/t-contract',
            authority: ['Tcontract#read'],
            name: 'global.menu.entities.contract',
            locale: 'global.menu.entities.contract'
          },
          // 商业续租申请
          {
            path: '/commerce-rental/contract/renewal',
            authority: ['Renewal#read'],
            name: 'global.menu.entities.renewal',
            locale: 'global.menu.entities.renewal'
          },
          // 商业退房申请
          {
            path: '/commerce-rental/contract/refund-rental',
            authority: ['RefundRental#read'],
            name: 'global.menu.entities.refundRental',
            locale: 'global.menu.entities.refundRental'
          }
        ]
      },
      {
        path: '/rental/message-mg',
        locale: 'global.menu.entities.messageManage',
        name: 'global.menu.entities.messageManage',
        isUrl: false,
        routes: [
          {
            path: '/rental/message-mg/messages',
            authority: ['MessageContent#read'],
            name: 'global.menu.entities.message',
            locale: 'global.menu.entities.message'
          },
          {
            path: '/rental/message-mg/message-templates',
            name: 'global.menu.entities.messageTemplate',
            authority: ['MessageTemplate#read'],
            locale: 'global.menu.entities.messageTemplate'
          }
        ]
      },
      {
        path: '/rental/visits',
        authority: ['Visit#read'],
        locale: 'global.menu.entities.visit',
        name: 'global.menu.entities.visit'
      }
    ]
  },
  // 车位月报
  {
    path: '/parking',
    icon: 'robot',
    locale: 'global.menu.parkingspaces.parking',
    isUrl: false,
    routes: [
      // 车位管理
      {
        path: '/parking/parking-spaces',
        locale: 'global.menu.parkingspaces.manage',
        authority: ['ParkingLot#view'],
        hideMobile: true
      },
      {
        path: '/parking/flowing',
        name: 'global.menu.parkingspaces.flowing',
        locale: 'global.menu.parkingspaces.flowing',
        isUrl: false,
        hideMobile: true,
        routes: [
          // 流动车位管理
          {
            path: '/parking/parking-flowing',
            locale: 'global.menu.parkingspaces.flowing',
            authority: ['Pcontract#flow'],
            hideMobile: true
          },
          // 流动车位统计
          {
            path: '/parking/parking-flowing/statistics',
            locale: 'global.menu.parkingspaces.statistics',
            authority: ['Pcontract#flowsta'],
            hideMobile: true
          }, // 临时车位
          {
            path: '/parking/temporary',
            name: 'global.menu.parkingspaces.temporary',
            authority: ['Precord#read'],
            locale: 'global.menu.parkingspaces.temporary'
          }
        ]
      },
      // 车位合同
      {
        path: '/parking/contract',
        name: 'global.menu.parkingspaces.contract',
        locale: 'global.menu.parkingspaces.contract',
        isUrl: false,
        hideMobile: true,
        routes: [
          {
            path: '/parking/parking-contract',
            authority: ['Pcontract#read'],
            name: 'global.menu.parkingspaces.contracts',
            locale: 'global.menu.parkingspaces.contracts',
            hideMobile: true
          },
          // 换车位
          {
            path: '/parking/change-parking',
            authority: ['Pchange#read'],
            name: 'global.menu.parkingspaces.changeParking',
            locale: 'global.menu.parkingspaces.changeParking',
            hideMobile: true
          },
          // 续租车位
          {
            path: '/parking/renewal-parking',
            authority: ['Prenewal#read'],
            name: 'global.menu.parkingspaces.renewal',
            locale: 'global.menu.parkingspaces.renewal',
            hideMobile: true
          },
          // 车位退租
          {
            path: '/parking/refund-rental',
            authority: ['PrefundRental#read'],
            name: 'global.menu.parkingspaces.refundRental',
            locale: 'global.menu.parkingspaces.refundRental',
            hideMobile: true
          }
        ]
      },
      // 月度缴费信息管理
      {
        path: '/parking/pay',
        locale: 'global.menu.parkingspaces.pay',
        authority: ['ServiceOperator#read'],
        hideMobile: true
      }
    ]
  },
  // 账务管理
  {
    path: '/financial',
    icon: 'money-collect',
    locale: 'global.menu.entities.orderMng',
    isUrl: false,
    routes: [
      {
        path: '/financial/order',
        name: 'global.menu.entities.orders',
        locale: 'global.menu.entities.orders',
        isUrl: false,
        routes: [
          // 房源账单管理
          {
            path: '/financial/orders',
            locale: 'global.menu.entities.orders',
            authority: ['Orders#read']
          },
          // 房源账单流水
          {
            path: '/financial/bill_records',
            locale: 'global.menu.entities.billRecord',
            authority: ['BillRecord#read']
          }
        ]
      },
      {
        path: '/financial/mcontract_orders',
        name: 'global.menu.entities.mcontractOrders',
        locale: 'global.menu.entities.mcontractOrders',
        isUrl: false,
        routes: [
          // 商业账单管理
          {
            path: '/financial/mcontract_orders',
            locale: 'global.menu.entities.mcontractOrders',
            authority: ['Orders#read']
          }
        ]
      },
      {
        path: '/parking/bills',
        name: 'global.menu.parkingspaces.bills',
        locale: 'global.menu.parkingspaces.bills',
        isUrl: false,
        hideMobile: true,
        routes: [
          // 车位账单
          {
            path: '/parking/parking-financial',
            locale: 'global.menu.parkingspaces.bills',
            authority: ['Porder#read'],
            hideMobile: true
          },
          {
            // 车位账单流水
            path: '/parking/parking-billflow',
            authority: ['PbillRecord#read'],
            locale: 'global.menu.parkingspaces.billflow',
            hideMobile: true
          }
        ]
      },
      {
        path: '/financial/statistical',
        locale: 'global.menu.entities.ordersStatistical',
        isUrl: false,
        authority: ['Orders#orderStatistical'],
        // hideMobile: true,
        routes: [
          {
            path: '/financial/statistical/house',
            locale: 'global.menu.entities.ordersStatisticalHouse'
          },
          {
            path: '/financial/statistical/date',
            locale: 'global.menu.entities.ordersStatisticalDate'
          }
        ]
      }
    ]
  },
  // 水电管理
  {
    path: '/hydropower',
    icon: 'money-collect',
    locale: 'global.menu.entities.hydropowerMng',
    isUrl: false,
    routes: [
      // 水电管理
      {
        path: '/hydropower/hydropower_configuration',
        name: 'global.menu.entities.hydConfigurationMng',
        locale: 'global.menu.entities.hydConfigurationMng',
        authority: ['WaterElectricityConfig#read'],
        isUrl: false
      },
      // 水电账号管理
      {
        path: '/hydropower/hydropower_account',
        name: 'global.menu.entities.hydropowerAccountMng',
        locale: 'global.menu.entities.hydropowerAccountMng',
        isUrl: false
      },
      // 充值管理
      {
        path: '/hydropower/hydropower_recharge',
        locale: 'global.menu.entities.hydropowerRecharge',
        isUrl: false
      },
      // // 使用记录
      {
        path: '/hydropower/hydropower_record',
        locale: 'global.menu.entities.hydropowerUsageRecords',
        isUrl: false,
        authority: ['WaterElectricityUseLog#read']
      },
      {
        path: '/hydropower/hydropower_report',
        locale: 'global.menu.entities.hydropowerUsageReport',
        isUrl: false,
        hideMobile: true
      }
    ]
  },
  // 招商管理
  {
    path: '/investment/house',
    name: 'global.menu.entities.investment',
    locale: 'global.menu.entities.investment',
    isUrl: false,
    icon: 'bank',
    routes: [
      // 看房调查
      {
        path: '/investment/house-survey',
        locale: 'global.menu.entities.house_survey',
        authority: ['ZsHouseView#read']
      },
      // 入住调查
      {
        path: '/investment/stay-survey',
        locale: 'global.menu.entities.stay_survey',
        authority: ['ZsOccupancy#read']
      },
      // 退租调查
      {
        path: '/investment/refund-rental-survey',
        locale: 'global.menu.entities.refund_rental_survey',
        authority: ['ZsHouseOut#read']
      },
      // 来电调查
      {
        path: '/investment/call-survey',
        locale: 'global.menu.entities.call_survey',
        authority: ['ZsCall#read']
      },
      // 招商统计
      {
        path: '/investment/investment-statistics',
        locale: 'global.menu.entities.investment_statistics',
        authority: ['ZsStats#read'],
        hideMobile: true,
        routes: [
          {
            path: '/investment/investment-statistics-year',
            locale: 'global.menu.entities.investment_statistics_year'
          },
          {
            path: '/investment/investment-statistics-month',
            locale: 'global.menu.entities.investment_statistics_month'
          }
        ]
      }
    ]
  },
  // 智慧健康服务
  {
    path: '/health/house',
    name: 'global.menu.entities.health_statistics',
    locale: 'global.menu.entities.health_statistics',
    isUrl: false,
    icon: 'bank',
    routes: [
      {
        path: '/health/transaction_statistics',
        locale: 'global.menu.entities.transaction_statistics',
        authority: ['BuyMedicineRecord#read']
      },
      {
        path: '/health/user_statistics',
        locale: 'global.menu.entities.user_statistics',
        authority: ['BuyMedicineRecord#read']
      }
    ]
  },
  // 月度统计
  {
    path: '/statistics/month',
    name: 'global.menu.entities.statisticsMonth',
    locale: 'global.menu.entities.statisticsMonth',
    isUrl: false,
    icon: 'bank',
    hideMobile: true,
    routes: [
      {
        path: '/financial/property_records',
        locale: 'global.menu.entities.property_records',
        authority: ['Contract#export'],
        hideMobile: true
      },
      {
        path: '/financial/property_details_records',
        locale: 'global.menu.entities.property_details_records',
        authority: ['Contract#export'],
        hideMobile: true
      },
      {
        path: '/statistics/month/all',
        locale: 'global.menu.entities.investment_statistics_month',
        hideMobile: true
      }
    ]
  },
  {
    path: '/org-management',
    icon: 'team',
    locale: 'global.menu.entities.orgManagement',
    isUrl: false,
    hideMobile: true,
    routes: [
      {
        path: '/org-management/user-group',
        authority: ['UserGroup#read'],
        locale: 'global.menu.entities.userGroup'
      }
    ]
  },
  {
    path: '/life-management',
    icon: 'robot',
    locale: 'global.menu.entities.life',
    isUrl: false,
    routes: [
      {
        path: '/life-management/life-prices',
        authority: ['LifePrice#read'],
        locale: 'global.menu.entities.lifePrice'
      },
      {
        path: '/life-management/maintenances',
        authority: ['Maintenance#read'],
        locale: 'global.menu.entities.maintenance'
      },
      {
        path: '/life-management/feedback',
        authority: ['Feedback#read'],
        locale: 'global.menu.entities.feedback',
        hideMobile: true
      }
    ]
  },
  {
    path: '/setting',
    icon: 'setting',
    locale: 'global.menu.account.settings',
    isUrl: false,
    routes: [
      {
        path: '/setting/facilities',
        authority: ['Facilities#read'],
        locale: 'global.menu.entities.facilities'
      },
      {
        path: '/setting/expense-grade',
        locale: 'global.menu.entities.expenseGrade',
        authority: ['ExpenseGrade#read'],
        isUrl: false,
        routes: [
          {
            path: '/setting/expense-grade/deposit',
            locale: 'global.menu.entities.deposit'
          },
          {
            path: '/setting/expense-grade/life',
            locale: 'global.menu.entities.life'
          },
          {
            path: '/setting/expense-grade/in',
            locale: 'global.menu.entities.inExpenseGrade'
          },
          {
            path: '/setting/expense-grade/out',
            locale: 'global.menu.entities.outExpenseGrade'
          }
        ]
      },
      {
        path: '/setting/calc',
        authority: ['Setting#read'],
        locale: 'global.menu.entities.setting'
      },
      {
        path: '/setting/sign-setting',
        authority: ['SignSetting#read'],
        locale: 'global.menu.entities.signSetting'
      },
      {
        path: '/setting/regional-divide',
        authority: ['RegionalDivide#read'],
        locale: 'global.menu.entities.regionalDivide',
        hideMobile: true
      },
      {
        path: '/setting/house-layout',
        authority: ['House#read'],
        locale: 'global.menu.entities.houseLayout'
      },
      {
        path: '/setting/custom-time',
        authority: ['CustomTime#read'],
        locale: 'global.menu.entities.customTime'
      }
    ]
  },
  {
    path: '/account',
    locale: 'global.menu.account.main',
    hideInMenu: true,
    routes: [
      {
        path: '/account/settings',
        locale: 'global.menu.account.settings'
      },
      {
        path: '/account/password',
        locale: 'global.menu.account.password'
      }
    ]
  },
  {
    path: '/information',
    locale: 'global.menu.infomation.main',
    icon: 'read',
    hideMobile: true,
    routes: [
      {
        path: '/information/recommend',
        authority: ['Recommend#read'],
        locale: 'global.menu.system.recommend.main',
        hideMobile: true
      },
      {
        path: '/information/marketing',
        authority: ['Marketing#read'],
        locale: 'global.menu.infomation.marketing.main',
        hideMobile: true
      },
      {
        path: '/information/popularize',
        authority: ['Popularize#read'],
        locale: 'global.menu.entities.popularize',
        hideMobile: true
      },
      {
        path: '/information/ads',
        authority: ['Ads#read'],
        locale: 'global.menu.entities.ads',
        hideMobile: true
      },
      {
        locale: 'global.menu.entities.news',
        path: '/information/news',
        authority: ['News#read'],
        hideMobile: true
      }
    ]
  },
  {
    path: '/system',
    locale: 'global.menu.system.main',
    icon: 'setting',
    hideMobile: true,
    routes: [
      {
        path: '/system/user-management',
        authority: ['User#read'],
        locale: 'global.menu.admin.userManagement',
        hideMobile: true
      },
      {
        path: '/system/settle',
        authority: ['Settle#read'],
        locale: 'global.menu.admin.settle'
      },
      {
        path: '/system/black',
        authority: ['BlankList#read'],
        locale: 'global.menu.admin.black',
        hideMobile: true
      },
      {
        path: '/system/role',
        authority: ['Role#read'],
        locale: 'global.menu.entities.role',
        hideMobile: true
      },
      {
        path: '/system/notice',
        authority: ['Notice#read'],
        locale: 'global.menu.system.notice.main',
        hideMobile: true
      },
      {
        path: '/system/audit/entity',
        authority: ['PersistentAuditEvent#read'],
        locale: 'global.menu.system.logs.main',
        hideMobile: true
      },
      {
        name: 'Indices Manage',
        locale: 'global.menu.indicesManage',
        path: '/system/indices/reset-indices',
        authority: ['indices#all'],
        hideMobile: true
      },
      {
        locale: 'global.menu.entities.guide',
        path: '/system/guide',
        authority: ['Guide#read'],
        hideMobile: true
      }
    ]
  }
];

export const flattenRoutes = flatten(routes).reduce((map, route) => {
  map[route.path] = route;
  return map;
}, {});

export default routes;

import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Card, Form, Input, Spin } from 'antd';
import { Translate, translate } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { createEntity, getEntity, reset, updateEntity } from './settle.reducer';
import { ISettle, SettleState } from 'app/shared/model/settle.model';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { editToList, newToList } from 'app/shared/util/url-utils';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import ImageUpload from 'app/shared/components/form-upload/image';
import UserInfoCard from 'app/shared/components/user-info/card';
import { UserType } from 'app/shared/model/user.model';

export interface ISettleUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
  form: WrappedFormUtils<ISettle>;
}

export interface ISettleUpdateState {
  isNew: boolean;
  state?: SettleState;
}

export class SettleUpdate extends React.Component<ISettleUpdateProps, ISettleUpdateState> {
  _form: WrappedFormUtils<ISettle>;
  // 表单样式
  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 8 }
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }
  // 组件更新
  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }
  // 组件初始化
  componentDidMount() {
    this.props.reset();
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }
  }
  // 成功
  auditSuccess = e => {
    this.setState({ state: SettleState.SUCCESS }, () => {
      this.saveEntity(this._form)(e);
    });
  };
  // auditFailure
  auditFailure = e => {
    this.setState({ state: SettleState.FAILURE }, () => {
      this.saveEntity(this._form)(e);
    });
  };
  // 保存
  saveEntity = (form: WrappedFormUtils<ISettle>) => event => {
    event.preventDefault();
    form.validateFields((errors, values) => {
      if (!errors) {
        const stateProp = {};
        if (this.state.state) {
          stateProp['state'] = this.state.state;
        }
        const { settleEntity } = this.props;
        const entity = {
          ...settleEntity,
          ...values,
          ...stateProp
        };

        if (this.state.isNew) {
          this.props.createEntity(entity);
        } else {
          this.props.updateEntity(entity);
        }
      }
    });
  };
  // 检验图片
  validateToAttachment = async (rule, value) => {
    if (value && (!value.startsWith('http://') && !value.startsWith('https://'))) {
      throw new Error('请等待图片上传完成');
    }
  };
  // 检验用户
  userValidator = async (rule, value) => {
    if (!value || !value.id) {
      throw new Error('请选择用户');
    }
  };
  // 关闭
  handleClose = () => {
    const { history, match } = this.props;
    const { isNew } = this.state;
    history.replace(isNew ? newToList(match.url) : editToList(match.url));
  };
  // 渲染表单
  renderForm(form: WrappedFormUtils<ISettle>) {
    const { settleEntity, loading, updating } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form onSubmit={this.saveEntity(form)}>
        <Form.Item {...this.formItemLayout} label={translate('lehomeApp.settle.user')}>
          {getFieldDecorator('user', {
            initialValue: settleEntity.user,
            rules: [
              {
                required: true,
                message: translate('entity.validation.required')
              },
              {
                validator: this.userValidator
              }
            ]
          })(<UserInfoCard type={[UserType.LEASEHOLDER]} />)}
        </Form.Item>
        <Form.Item {...this.formItemLayout} label={translate('lehomeApp.settle.houseAttach')}>
          {getFieldDecorator('houseAttach', {
            initialValue: settleEntity.houseAttach,
            rules: [
              { required: true, message: translate('entity.validation.required') },
              {
                validator: this.validateToAttachment
              }
            ]
          })(<ImageUpload />)}
        </Form.Item>
        <Form.Item {...this.formItemLayout} label={translate('lehomeApp.settle.idCardFronted')}>
          {getFieldDecorator('idCardFronted', {
            initialValue: settleEntity.idCardFronted,
            rules: [
              { required: true, message: translate('entity.validation.required') },
              {
                validator: this.validateToAttachment
              }
            ]
          })(<ImageUpload />)}
        </Form.Item>
        <Form.Item {...this.formItemLayout} label={translate('lehomeApp.settle.idCardBackend')}>
          {getFieldDecorator('idCardBackend', {
            initialValue: settleEntity.idCardBackend,
            rules: [
              { required: true, message: translate('entity.validation.required') },
              {
                validator: this.validateToAttachment
              }
            ]
          })(<ImageUpload />)}
        </Form.Item>
        <Form.Item {...this.formItemLayout} label={translate('lehomeApp.settle.remark')}>
          {getFieldDecorator('remark', {
            initialValue: settleEntity.remark
          })(<Input.TextArea placeholder={'请填写通过/不合格审核信息'} />)}
        </Form.Item>
        <Form.Item className={'tc'}>
          <Button.Group>
            <Button type="primary" htmlType="submit" size={'large'} loading={updating}>
              <Translate contentKey={'entity.action.save'} />
            </Button>
            {settleEntity.state === SettleState.WAITING && (
              <Button type="default" htmlType="button" size={'large'} onClick={this.auditSuccess} loading={updating}>
                通过
              </Button>
            )}
            {settleEntity.state === SettleState.WAITING && (
              <Button type="primary" htmlType="button" size={'large'} onClick={this.auditFailure} loading={updating}>
                不合格
              </Button>
            )}
          </Button.Group>
        </Form.Item>
      </Form>
    );
  }

  render() {
    const { loading, form } = this.props;
    const { isNew } = this.state;
    this._form = form;
    return (
      <PageHeaderWrapper
        title={translate(isNew ? 'lehomeApp.settle.home.createLabel' : 'lehomeApp.settle.home.editLabel')}
        extra={<Button icon={'arrow-left'} onClick={this.handleClose} />}
      >
        <Card bordered={false}>
          <Spin spinning={loading}>{this.renderForm(form)}</Spin>
        </Card>
      </PageHeaderWrapper>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  settleEntity: storeState.settle.entity,
  loading: storeState.settle.loading,
  updating: storeState.settle.updating,
  updateSuccess: storeState.settle.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create<ISettleUpdateProps>()(SettleUpdate));

import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IFaceAuthRecord, defaultValue } from 'app/shared/model/face-auth-record.model';
// 请求
export const ACTION_TYPES = {
  FETCH_FACEAUTHRECORD_LIST: 'faceAuthRecord/FETCH_FACEAUTHRECORD_LIST',
  FETCH_FACEAUTHRECORD: 'faceAuthRecord/FETCH_FACEAUTHRECORD',
  CREATE_FACEAUTHRECORD: 'faceAuthRecord/CREATE_FACEAUTHRECORD',
  UPDATE_FACEAUTHRECORD: 'faceAuthRecord/UPDATE_FACEAUTHRECORD',
  DELETE_FACEAUTHRECORD: 'faceAuthRecord/DELETE_FACEAUTHRECORD',
  RESET: 'faceAuthRecord/RESET'
};
// state
const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IFaceAuthRecord>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type FaceAuthRecordState = Readonly<typeof initialState>;

// Reducer

export default (state: FaceAuthRecordState = initialState, action): FaceAuthRecordState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_FACEAUTHRECORD_LIST):
    case REQUEST(ACTION_TYPES.FETCH_FACEAUTHRECORD):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_FACEAUTHRECORD):
    case REQUEST(ACTION_TYPES.UPDATE_FACEAUTHRECORD):
    case REQUEST(ACTION_TYPES.DELETE_FACEAUTHRECORD):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_FACEAUTHRECORD_LIST):
    case FAILURE(ACTION_TYPES.FETCH_FACEAUTHRECORD):
    case FAILURE(ACTION_TYPES.CREATE_FACEAUTHRECORD):
    case FAILURE(ACTION_TYPES.UPDATE_FACEAUTHRECORD):
    case FAILURE(ACTION_TYPES.DELETE_FACEAUTHRECORD):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_FACEAUTHRECORD_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_FACEAUTHRECORD):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_FACEAUTHRECORD):
    case SUCCESS(ACTION_TYPES.UPDATE_FACEAUTHRECORD):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_FACEAUTHRECORD):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/face-auth-records';

// Actions

export const getEntities: ICrudGetAllAction<IFaceAuthRecord> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_FACEAUTHRECORD_LIST,
    payload: axios.get<IFaceAuthRecord>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IFaceAuthRecord> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_FACEAUTHRECORD,
    payload: axios.get<IFaceAuthRecord>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IFaceAuthRecord> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_FACEAUTHRECORD,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IFaceAuthRecord> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_FACEAUTHRECORD,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IFaceAuthRecord> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_FACEAUTHRECORD,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

import { IRangeFilter, IStringFilter } from 'app/shared/model/filter.model';
import { Moment } from 'moment';

export const enum NoticeType {
  NOTICE = 'NOTICE',
  EVENT = 'EVENT',
  NEWS = 'NEWS',
  OTHER = 'OTHER'
}

export interface INotice {
  id?: any;
  title?: string;
  content?: string;
  sendAt?: string;
  type?: string;
  status?: string;
  seniority?: string;
  createdAt?: Moment;
  createdBy?: string;
  updatedBy?: string;
  updatedAt?: Moment;
}

export interface ISearchForm {
  title?: IStringFilter;
  content?: IStringFilter;
  type?: IStringFilter;
  sendAt?: IRangeFilter;
  status?: IStringFilter;
  createdAt?: IRangeFilter;
  updatedAt?: IRangeFilter;
}

export const defaultValue: Readonly<INotice> = {
  id: '',
  title: '',
  content: '',
  sendAt: '',
  status: '',
  type: NoticeType.NOTICE,
  seniority: '0',
  createdBy: '',
  updatedBy: ''
};

export const defaultSearchForm: Readonly<ISearchForm> = {
  title: {
    contains: ''
  },
  type: {
    equals: ''
  },
  status: {
    equals: ''
  },
  sendAt: {
    lessOrEqualThan: '',
    greaterOrEqualThan: ''
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  }
};

import { Moment } from 'moment';
import { IRangeFilter, IStringFilter, IFilter } from 'app/shared/model/filter.model';

export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING'
}
export interface IHouseSurvey {
  name?: string; // 姓名
  phone?: string; // 电话
  sex?: string; // 性别
  age?: number; // 年龄
  enteredBy?: number; // 录入人
  followBy?: string; // 跟进入
  option?: string; // 备注
  budget?: string; // 预算
  industry?: string; // 行业
  industry_other?: string; // 所属行业：其他
  source?: string; // 如何知道项目
  source_other?: string; // 如何知道项目其他
  satisfaction?: any; // 满意度
  satisfaction_other?: string; // 满意度: 其他
  opinion?: string; // 意见
  houseId?: number; // 房源
  houseName?: string; // 房源名称
  inputDate?: Date; // 录入日期
  enteredName?: string; // 录入人
  followName?: string; // 跟进人
}

export const defaultValue: Readonly<IHouseSurvey> = {};

export interface ISearchForm {
  name?: IStringFilter;
  sex?: IFilter;
  houseId?: IFilter;
  budget?: IFilter;
  isSign?: IFilter;
  satisfaction?: IStringFilter;
  enteredBy?: IFilter;
  followBy?: IFilter;
  source?: IStringFilter; // 来源
  age?: IFilter;
  industry?: string;
  industry_other?: string; //
  source_other?: string; // 来源其他
  createdAt?: IRangeFilter; // 创建日期
  signAt?: IRangeFilter; // 签约日期
  inputDate?: IRangeFilter; // 录入日期
}

export const defaultSearchForm: ISearchForm = {
  name: { contains: '' },
  sex: { equals: '' },
  budget: { equals: '' },
  isSign: { equals: '' },
  satisfaction: { contains: '' },
  source: { contains: '' },
  age: { equals: '' },
  houseId: { in: null },
  enteredBy: { in: [] },
  followBy: { in: [] },
  createdAt: {
    lessThan: '',
    greaterThan: ''
  },
  signAt: {
    lessThan: '',
    greaterThan: ''
  },
  inputDate: {
    lessThan: '',
    greaterThan: ''
  }
};

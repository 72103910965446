import moment, { Moment } from 'moment';
import { IHouseExpense } from 'app/shared/model/house-expense.model';
import { IFilter, IRangeFilter, IStringFilter, ISpecified } from 'app/shared/model/filter.model';
import { AuditState, defaultValue as houseDefaultValue, IHouse } from 'app/shared/model/house.model';
import { defaultValue as defaultLayout, IHouseLayout } from 'app/shared/model/house-layout.model';
import { IAttachment } from 'app/shared/model/attachment.model';

export const enum HouseState {
  IDLE = 'IDLE',
  RENTED = 'RENTED',
  FIXED = 'FIXED',
  UN_PUBLISH = 'UN_PUBLISH',
  PULLED_OFF_SHELVES = 'PULLED_OFF_SHELVES'
}

export interface IHouseRoomExpense {
  singleMonthPrice: number;
  deposit: number;
  propertyFee: number;
  paymentCycle: number;
  expenses: IHouseExpense[];
  rooms?: IHouseRoom[];
}

export interface IHouseRoom {
  customerName?: string; // 物业入住 员工姓名
  id?: number;
  floor?: number;
  toogleFloor?: number;
  buildingCode?: string;
  unitCode?: string;
  waterCode?: string;
  electricityCode?: string;
  roomCode?: string;
  description?: string;
  attachments?: IAttachment[];
  state?: HouseState;
  electricityAmount?: number; // 电费
  waterAmount?: number; // 水费
  singleMonthPrice?: number;
  paymentCycle?: number;
  extraDeposit?: number;
  currentPayAt?: Moment;
  lastUsedAt?: Moment;
  createdAt?: Moment;
  updatedAt?: Moment;
  createdBy?: string;
  hasMaintenance?: boolean;
  authState?: AuditState;
  updatedBy?: string;
  contract?: any; // 租户合同
  flowSingleMonthPrice?: number; // 流动车位月租
  name?: string;
  communityName?: string;
  houseExpenses?: IHouseExpense[];
  houseLayoutId?: number;
  houseLayout?: IHouseLayout;
  houseId?: number;
  house?: IHouse;
  regionalDivideId?: number;
  charterType?: string;
  charterCode?: string;
  charterUserCerType?: string;
  charterUserMobile?: string;
  charterUserCerCode?: string;
  charterUserName?: string;
  fwbh?: string;
  validCode?: string;
  ownerId?: number;
  deposit?: number;
  propertyFee?: number;
  fullName?: string;
  tcontract?: any; // 有数据就是团租，null=普通合同
  oldPropertyFee?: number; // 记录物业费
}

export const defaultHouseRoomExpense: Readonly<IHouseRoomExpense> = {
  deposit: 0.0,
  singleMonthPrice: 0.0,
  propertyFee: 0.0,
  paymentCycle: 3,
  expenses: [],
  rooms: []
};
export const defaultValue: Readonly<IHouseRoom> = {
  house: { ...houseDefaultValue },
  lastUsedAt: moment(),
  attachments: [],
  hasMaintenance: false,
  houseLayout: { ...defaultLayout }
};

export interface ISearchForm {
  name?: IStringFilter;
  roomCode?: IStringFilter;
  buildingCode?: IStringFilter;
  buildingType?: IStringFilter;
  unitCode?: IStringFilter;
  floor?: IRangeFilter;
  id?: IFilter;
  houseId?: IFilter;
  regionalDivideId?: IStringFilter;
  state?: IStringFilter;
  createdAt?: IRangeFilter;
  createdBy?: IStringFilter;
  updatedAt?: IRangeFilter;
  updatedBy?: IStringFilter;
  filterFields?: boolean;
  yunDingPasswordIds?: ISpecified;
}

export const defaultSearchForm: Readonly<ISearchForm> = {
  buildingCode: {
    contains: ''
  },
  unitCode: {
    contains: ''
  },
  roomCode: {
    contains: ''
  },
  name: {
    contains: ''
  },
  houseId: {
    equals: ''
  },
  buildingType: {
    equals: ''
  },
  id: {
    equals: ''
  },
  floor: {
    equals: ''
  },
  regionalDivideId: {
    equals: ''
  },
  state: {
    in: []
  },
  createdAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  createdBy: { contains: '' },
  updatedAt: {
    lessOrEqualThan: '',
    lessThan: '',
    greaterOrEqualThan: '',
    greaterThan: ''
  },
  updatedBy: { contains: '' },
  filterFields: false,
  yunDingPasswordIds: {
    specified: null
  }
};

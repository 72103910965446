import { Moment } from 'moment';
import { IRangeFilter, IStringFilter, IFilter } from 'app/shared/model/filter.model';

export const enum AuditState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING'
}

export interface IparkingPay {
  carNo?: string; // 车牌号
  itemId?: number; // 操作记录id，无需理会
  serviceId?: string; // 服务id，无需理会
  parkName?: string; // 停车场名称
  parkCode?: string; // 停车场编号
  operatorId?: string; // 操作员id
  operateType?: string; // 服务操作类型（OPEN 正常 RECHARGE 充值延期 REFUND 违规 FREEZE 冻结 UNFREEZE 解冻 LOCK 锁定 UNLOCK 开通 CLOSE 注销）
  operateTime?: string; // 操作时间
  operateName?: string; // 操作员姓名
  operateMoney?: number; // 操作金额
  delayStartTime?: string; // 延期开始时间
  delayEndTime?: string; // 延期结束时间
  oldState?: string; // 操作前服务状态（NORMAL 正常 FREEZE 冻结 LOCK 锁定 CLOSE 注销）
  newState?: string; // 操作后服务状态（NORMAL 正常 FREEZE 冻结 LOCK 锁定 CLOSE 注销）
  payType?: string; // 支付类型（WX 微信 ZFB 支付宝 YL 银联 JYF 捷钱包 YFB 易付宝 YZF 翼支付 CASH 现金）
  payFrom?: string; // 支付来源
  refundssMoney?: number; // 应退金额
  refundysMoney?: number; // 实退金额
  attach?: string; // 备用字段，无需理会
}

export const defaultValue: Readonly<IparkingPay> = {};

export interface ISearchForm {
  carNo?: IStringFilter; // 车牌号
  parkName?: IStringFilter; // 停车场名称
  operateTime?: IRangeFilter; // 操作时间
}

export const defaultSearchForm: ISearchForm = {
  operateTime: {
    lessThan: '',
    greaterThan: ''
  },
  carNo: { contains: '' },
  parkName: { contains: '' }
};
